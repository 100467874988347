import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../../redux';
import { Text } from '@fluentui/react';
import * as ApiType from '../../../../../solutionCenterApi/gen';
import { useIntl } from 'react-intl';
import DeploymentStateContainer from '../../../../../stateContainers/deploymentStateContainer';
import styles from '../armStyling/index.styles';
interface InitialProps {
  azureOfferRowKey: string;
}

export const InstanceDetails: FC<InitialProps> = (props: InitialProps): ReactElement => {
  const selectedAzureOffers = useSelector((state: RootStore) => state.azureInstance.selectedAzureOffers);
  const intlShape = useIntl();

  const getSelectedAzOfferRowKeyName = (): ReactElement => {
    const offer: ApiType.L03 | undefined = selectedAzureOffers?.filter(
      (offer) => offer.rowKey === props.azureOfferRowKey
    )[0];
    if (offer) {
      return (
        <Text className={styles.verticalSpacing}>
          <b>{intlShape.formatMessage({ id: 'arm_eachl03_instancedetails' }, { offerName: offer?.name })}</b>
        </Text>
      );
    } else {
      const optionalComponent: ApiType.OptionalComponent = DeploymentStateContainer.getOptionalComponents().filter(
        (component: ApiType.OptionalComponent) => component.rowKey === props.azureOfferRowKey
      )[0];
      return (
        <Text className={styles.verticalSpacing}>
          <b>
            {intlShape.formatMessage({ id: 'arm_eachl03_instancedetails' }, { offerName: optionalComponent?.name })}
          </b>
        </Text>
      );
    }
  };

  return <>{getSelectedAzOfferRowKeyName()}</>;
};
