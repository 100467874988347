import { mergeStyleSets } from '@fluentui/react';

export const setupStyles = mergeStyleSets({
  courseBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '100px',
    width: '100%',
    alignItems: 'center',
    marginBottom: '0px',
    '&:hover': {
      backgroundColor: '#F3F2F1',
      cursor: 'pointer',
    },
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      height: '300px',
      alignItems: 'flex-start',
      marginLeft: '0px',
    },
  },
  courseThumbnail: {
    '& img': {
      width: '108px',
      height: '60px',
      objectFit: 'cover',
      borderRadius: '8px',
    },
    '@media (max-width: 800px)': {
      marginTop: '10px',
      marginBottom: '10px',
      marginLeft: '20px',
    },
    pointerEvents: 'none',
  },
  courseInfo: {
    flex: 1,
    fontFamily: 'var(--Font-familyBase)',
    fontSize: 'var(--Font-size300)',
    fontWeight: 400,
    lineHeight: 'var(--Line-height300)',
    textAlign: 'left',
    marginLeft: '20px',
    marginRight: '80px',
    marginTop: '-40px',
    marginBottom: '-30px',
    color: '#323130',
    '@media (max-width: 800px)': {
      marginTop: '10px',
      marginBottom: '10px',
    },
  },
  courseDescription: {
    marginTop: '-10px',
  },
  learningButton: {
    borderRadius: '8px',
    marginRight: '5px',
    '&:hover': {
      backgroundColor: 'white',
    },
    '@media (max-width: 800px)': {
      marginBottom: '30px',
      alignSelf: 'flex-start',
      marginLeft: '20px',
      marginTop: '-20px',
    },
  },
  learningButtonIcon: {
    marginRight: '5px',
  },
});

export default setupStyles;
