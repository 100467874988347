export const PersistStoreStateContainer = {
  loadState: () => {
    try {
      const serializedState = localStorage.getItem('persistedState');
      if (!serializedState) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (e) {
      return undefined;
    }
  },
  saveState: (state: any) => {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('persistedState', serializedState);
  },
};
