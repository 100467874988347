import { FabricPackageSubType } from '../../../../../common/Enum';
import { ExtendedFabricComponentPayload } from '../../../../../redux/deployments/deploymentReducers';
import Utils from '../../../../../utils';

const areRequiredFieldsNonEmpty = (requiredFieldNames: string[], payload): boolean =>
  requiredFieldNames.every((fieldName) => payload[fieldName as string]);

const validateLakehouse = (payload: ExtendedFabricComponentPayload): boolean => {
  const requiredFieldNames: string[] = ['displayName'];
  const { displayName } = payload;
  return areRequiredFieldsNonEmpty(requiredFieldNames, payload) && !Utils.hasSpecialCharacters(displayName);
};

const validateEventStream = (payload: ExtendedFabricComponentPayload): boolean => {
  const requiredFieldNames: string[] = ['displayName'];
  return areRequiredFieldsNonEmpty(requiredFieldNames, payload);
};

export const validateFabricPayload = (payload: ExtendedFabricComponentPayload[]): boolean => {
  if (!payload || !Array.isArray(payload)) {
    return false;
  }

  return payload.every((item) => {
    switch (item.type) {
      case FabricPackageSubType.LakeHouse:
        return validateLakehouse(item);
      case FabricPackageSubType.EventStream:
        return validateEventStream(item);
      default:
        return false;
    }
  });
};
