import * as ApiType from '../solutionCenterApi/gen/index';
import * as Type from '../common/Type';
import * as Constants from '../common/Constants';
import { AzureManagementService } from '../services/azureManagementService';
import { telemetry } from '../services/telemetryService';
import config, { telemetryConstants } from '../config';
import AuthenticationStateContainer from '../stateContainers/authenticationStateContainer';
import InstallationStateContainer from '../stateContainers/installationStateContainer';
import { ArmDeploymentScope } from '../solutionCenterApi/gen/index';
import Utils from '.';
import { History } from 'history';
import DeploymentStateContainer from '../stateContainers/deploymentStateContainer';

export const createEndUserLaunchLink = (endUserLaunchLink: string) => {
  const tenantId = AuthenticationStateContainer.getTenantName();
  const subscriptionId = InstallationStateContainer.getSubscription();
  const resourceGroupName = InstallationStateContainer.getResourceGroup();
  return endUserLaunchLink
    .replace('{tenantId}', `@${tenantId!}`)
    .replace('{subscriptionId}', subscriptionId!)
    .replace('{resourceGroupName}', resourceGroupName!);
};

export const checkForAvailabeAzureOptionalComponents = async (
  optionalComponents: ApiType.OptionalComponent[],
  parameterDefinition?: Type.ParameterDefinitionDictionary[]
): Promise<Type.ParameterDefinitionDictionary[]> => {
  var customProps: Record<string, string> = {};
  let azureOptionalComponents: ApiType.OptionalComponent[] = [];
  let parameterDictionary: Type.ParameterDefinitionDictionary[] = parameterDefinition ?? [];

  azureOptionalComponents = optionalComponents.filter(
    (component: ApiType.OptionalComponent) => component.additionalDeploymentParametersAvailable === true
  );

  azureOptionalComponents.forEach(async (component: ApiType.OptionalComponent) => {
    if (component.rowKey) {
      customProps[Constants.TelemetryPropertyNames.optionalComponentNameKey] = component.name;
      const OCParameterDefinitions: ApiType.ParameterDefinition[] | null =
        await AzureManagementService.getOCParameterDefinitionList(component.rowKey);

      if (!OCParameterDefinitions) {
        telemetry.logEvents(telemetryConstants.events.OC_PARAMETER_DEFINTION_FAILED, customProps);
        return;
      }

      parameterDictionary.push({
        armDeploymentScope: ArmDeploymentScope.RESOURCE_GROUP,
        azureOfferRowKey: component.rowKey,
        parameterDefinitions: OCParameterDefinitions,
      });
    }
  });

  return parameterDictionary;
};

export const setDeploymentProps = (
  azurePackage: ApiType.L03 | ApiType.OptionalComponent,
  parameterDefinitions: Type.ParameterDefinitionDictionary[],
  location?: string
): Type.AzureDeploymentProps => {
  let deploymentName: string;
  deploymentName = 'Microsoft.Template' + Date.now();
  let stagedParameterDictionary: Type.ParameterDefinitionDictionary | undefined;
  stagedParameterDictionary = parameterDefinitions.find((val) => val.azureOfferRowKey === azurePackage.rowKey);
  let deploymentProperties: Type.ARMDeploymentProperties = generateDeploymentProperties(
    azurePackage,
    stagedParameterDictionary?.parameterDefinitions,
    location
  );

  return {
    deploymentName: deploymentName,
    deploymentProperties: deploymentProperties,
  };
};

export const generateDeploymentProperties = (
  selectedAzureOffers: ApiType.L03 | ApiType.OptionalComponent,
  parameterDefinitionDictionary?: ApiType.ParameterDefinition[],
  location?: string
): Type.ARMDeploymentProperties => {
  let armDeploymentProperties: Type.ARMDeploymentProperties;
  let deploymentProperties: Type.deploymentProperties;
  let debugSetting: Type.debugSetting;
  let detailLevel: string;
  let uri: string | void[] | undefined;
  detailLevel = 'none';
  debugSetting = { detailLevel: detailLevel };
  uri = selectedAzureOffers?.packages?.find(
    (component: ApiType.Package) => component.packageType === ApiType.PackageType.ArmTemplate
  )?.contextData;
  deploymentProperties = {
    debugSetting: debugSetting,
    mode: Constants.DEPLOYMENT_MODE,
    parameters: createDictionary(parameterDefinitionDictionary),
    templateLink: { uri: uri },
  };

  armDeploymentProperties = {
    location: location,
    properties: deploymentProperties,
  };
  return armDeploymentProperties;
};

export const createDictionary = (parameterDefinitions?: ApiType.ParameterDefinition[]) => {
  parameterDefinitions = parameterDefinitions?.filter((param) => param.value != null);
  let deploymentParameterDictionaryMap = Object.fromEntries(
    parameterDefinitions!.map((o) => [o.name, toDeploymentParameterDefinitionValue(o)])
  );
  return deploymentParameterDictionaryMap;
};

export const toDeploymentParameterDefinitionValue = (parameterDefinition: ApiType.ParameterDefinition) => {
  return {
    value: parameterDefinition.value,
  };
};

export const isWellFormedUriString = (uriString) => {
  let result;
  try {
    result = new URL(uriString);
  } catch {
    return false;
  }
  return result.href === uriString;
};

interface ArmParameterDefinition {
  armDeploymentScope: string;
  azureOfferRowKey: string;
  parameterDefinitions: ApiType.ParameterDefinition[];
}
interface ScopeToOfferMap {
  [ArmDeploymentScope.TENANT]: string[];
  [ArmDeploymentScope.RESOURCE_GROUP]: string[];
}

export const setAzureOffersScopeAndParameterDefinition = async (
  azureOffers: ApiType.L03[],
  setArmScope: (scopeMap: ScopeToOfferMap) => void,
  setParameterDefinition: (parameterDefinition: ArmParameterDefinition[]) => void,
  history: History
) => {
  let parameterDictionary = [];
  let scopeToOfferMap = {
    [ArmDeploymentScope.TENANT]: [],
    [ArmDeploymentScope.RESOURCE_GROUP]: [],
  };
  let scopeToOfferPkgMap = {};
  InstallationStateContainer.setArmDeploymentScopes(scopeToOfferPkgMap);
  for (const offer of azureOffers) {
    const offerRowKey = offer.rowKey;
    let currentScope: string = ArmDeploymentScope.RESOURCE_GROUP;

    await AzureManagementService.getL03OrOCDeploymentScope(offerRowKey, ApiType.DeploymentL03V2Type.L03).then(
      (scope) => {
        currentScope = scope;
        if (!Utils.isNullOrUndefined(scopeToOfferPkgMap[String(scope)])) {
          scopeToOfferPkgMap[String(scope)] = scopeToOfferPkgMap[String(scope)]?.concat(
            offer?.packages?.map((p) => p.rowKey)
          );
        } else {
          scopeToOfferPkgMap[String(scope)] = offer?.packages?.map((p) => p.rowKey);
        }
        scopeToOfferMap[String(scope)]?.push(offerRowKey);
        telemetry.logTrace('Azure Deployment scope for offer : ', offerRowKey + ', scope: ' + scope);
      }
    );

    await AzureManagementService.getL03ParameterDefinitionList(offerRowKey).then((response) => {
      let l03ParameterDefinitions = response;
      if (!l03ParameterDefinitions) {
        telemetry.logException(
          'additionalDeploymentParameters call failed with response',
          telemetryConstants.severity.SEVERITY_ERROR,
          l03ParameterDefinitions
        );
        telemetry.logTrace(telemetryConstants.events.ERROR_PAGE_SHOWN, l03ParameterDefinitions);
        history.push(config.routes.error, { l03ParameterDefinitions });
        return;
      }

      parameterDictionary.push({
        armDeploymentScope: currentScope,
        azureOfferRowKey: offerRowKey,
        parameterDefinitions: l03ParameterDefinitions,
      });
    });
  }
  setParameterDefinition(parameterDictionary);
  InstallationStateContainer.setArmDeploymentScopes(scopeToOfferPkgMap);
  DeploymentStateContainer.setDeploymentName('');
  setArmScope(scopeToOfferMap);
  return scopeToOfferPkgMap;
};
