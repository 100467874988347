import React from 'react';
import { IsDesktopMode, ResponsiveMode } from '../common/responsiveMode';

export const ResponsiveModeContext = React.createContext(ResponsiveMode.Desktop);

export const ResponsiveModeContextProvider = (props: any) => {
  ResponsiveModeContext.displayName = 'ResponsiveModeContext';
  return (
    <ResponsiveModeContext.Provider value={IsDesktopMode() ? ResponsiveMode.Desktop : ResponsiveMode.Zoom}>
      {props.children}
    </ResponsiveModeContext.Provider>
  );
};
