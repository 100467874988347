import React from 'react';
import { titleDescriptionStyles } from './TitleDescription.styles';

type titleDescriptionProps = {
  title: string;
  description: string;
  textLink?: { text: string; link: string } | null;
  titleStyle?: any;
  titleClassName: string;
};

class TitleDescription extends React.Component<titleDescriptionProps> {
  render() {
    const { title, description, textLink, titleStyle, titleClassName } = this.props;
    return (
      <div
        role="alert"
        aria-label={title}
        className={`${titleDescriptionStyles.root} ${titleClassName}`}
        style={{ ...titleStyle }}
      >
        <h2>
          <span className={titleDescriptionStyles.title}>{title}</span>
        </h2>
        {textLink ? (
          <div className={titleDescriptionStyles.container}>
            <span className={titleDescriptionStyles.description}>
              {description}
              <a className={titleDescriptionStyles.link} href={textLink.link} rel="noopener noreferrer" target="_blank">
                {textLink.text}
              </a>
            </span>
          </div>
        ) : (
          <p className={titleDescriptionStyles.description} dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </div>
    );
  }
}

export default TitleDescription;
