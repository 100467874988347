import RestService from './restService';
import config from '../config';
import SolutionsStateContainer from '../stateContainers/solutionsStateContainer';

const LearningsService = {
  getAllLearnings: async () => {
    try {
      const result = await RestService.get({
        endPoint: config.endpoints.getAllLearnings,
      });
      if (result) {
        SolutionsStateContainer.setAllLearnings(result);
        return result;
      }
      return [];
    } catch (error) {
      console.error('Error fetching learning items:', error);
      return [];
    }
  },
};

export default LearningsService;
