import styles from '../home.styles';
import React from 'react';
import Visible from '../../../components/visible/visible';
import YourDeployments from './yourDeployments';
import { RouteComponentProps } from 'react-router-dom';
import LoadingScreen from '../../../common/loading-screen';
import * as ApiType from '../../../solutionCenterApi/gen';
import DeployService from '../../../services/deployService';
import { telemetry } from '../../../services/telemetryService';
import config, { telemetryConstants } from '../../../config';
import SolutionService from '../../../services/solutionsService';

type deploymentsProps = {
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
  match: RouteComponentProps['match'];
};

type deploymentsState = {
  deployments: Array<ApiType.DeploymentDefinitionEntity>;
  loadingDeploymentDefinitions: boolean;
  announcements: Array<ApiType.Announcement>;
  loadingAnnouncements: boolean;
};

class Deployments extends React.Component<deploymentsProps, deploymentsState> {
  constructor(props: deploymentsProps) {
    super(props);

    this.state = {
      deployments: [],
      loadingDeploymentDefinitions: true,
      announcements: [],
      loadingAnnouncements: true,
    };
  }

  componentDidMount() {
    this.getDeployments().then(() => this.getUpdates());
    this.getAnnouncements();
  }

  getUpdates = async () => {
    try {
      let deployments = await DeployService.getDeploymentUpdates(this.state.deployments);
      this.setState({
        deployments: deployments,
      });
    } catch (error) {
      telemetry.logTrace('Error getting deployment updates: ', error);
    }
  };

  getDeployments = async () => {
    try {
      let deployments = await DeployService.getDeploymentDefinitons();
      this.setState({
        deployments: deployments,
        loadingDeploymentDefinitions: false,
      });
      telemetry.logTrace('Deployment cards shown to user', telemetryConstants.severity.SEVERITY_INFO);
    } catch (error) {
      telemetry.logTrace('Get deployments failed', telemetryConstants.severity.SEVERITY_ERROR);
      telemetry.logEvents(telemetryConstants.events.ERROR_PAGE_SHOWN);
      this.props.history.push(config.routes.error, { error });
      return;
    }
  };

  getAnnouncements = async () => {
    try {
      let announcements = await SolutionService.getAnnouncements();
      this.setState({
        announcements: announcements,
        loadingAnnouncements: false,
      });
      telemetry.logTrace('Announcements Loaded', telemetryConstants.severity.SEVERITY_INFO);
    } catch (error) {
      telemetry.logTrace('Get Announcements failed', telemetryConstants.severity.SEVERITY_ERROR);
      telemetry.logEvents(telemetryConstants.events.ERROR_PAGE_SHOWN);
      this.props.history.push(config.routes.error, { error });
      return;
    }
  };

  render() {
    const { loadingAnnouncements, deployments, announcements, loadingDeploymentDefinitions } = this.state;

    return (
      <>
        <div className={styles.homePageContentContainer}>
          <Visible
            when={!loadingDeploymentDefinitions && !loadingAnnouncements}
            fallback={<LoadingScreen isVisible={true} label={''} />}
          >
            <YourDeployments
              history={this.props.history}
              announcements={announcements}
              deployments={deployments}
              loadingDeploymentDefinitions={loadingDeploymentDefinitions}
            />
          </Visible>
        </div>
      </>
    );
  }
}

export default Deployments;
