import React, { FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { FontIcon } from '@fluentui/react';
import styles from '../progressStyling/index.styles';

export interface InitialProps {
  name: string;
}

export const CompletedSolutions: FC<InitialProps> = (props: InitialProps): ReactElement => {
  return (
    <>
      <div className={styles.progressTitle}>{<FormattedMessage id={props.name} />}</div>
      <div id="CompleteDescription" className={styles.completeDescriptor}>
        <FontIcon iconName="Completed" className={styles.successIconColor} />
        <span>
          <FormattedMessage id="postDeploymentConfigurations.complete" />
        </span>
      </div>
    </>
  );
};
