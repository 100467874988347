import { FontWeights, getTheme } from '@fluentui/react';
import { mergeStyleSets } from 'office-ui-fabric-react';

export const classStyles = mergeStyleSets({
  imageContainer: {
    textAlign: 'center',
    paddingTop: '75px',
  },
  textContainer: {
    textAlign: 'center',
  },
  title: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '18px',
  },
  bodyContainer: {
    marginTop: '20px',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  body: {
    fontSize: '14px',
    lineHeight: '12px',
    color: '#000000',
  },
  button: {
    minWidth: '100px',
    float: 'right',
    marginTop: '60px',
    marginBottom: '24px',
    '@media(max-width: 960px)': {
      marginRight: '10px',
    },
  },
});

const theme = getTheme();
export const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    '@media(max-width: 960px)': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  header: [
    theme.fonts?.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette?.themePrimary}`,
      color: theme.palette?.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights?.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    width: '500px',
    height: '500px',
    padding: '12px 24px 12px 24px',
    '@media(max-width: 960px)': {
      width: '100%',
      padding: '0px 10px 10px 0px',
    },
  },
});
