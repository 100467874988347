import * as React from 'react';
import {
  contentStyles,
  updateDialogIconButtonStyles,
  footerStackTokens,
  modalStyle,
} from '../../pages/deploymentDetails/update-dialog/update-dialog.styles';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconButton, Modal, Stack } from '@fluentui/react';
import * as Enum from '../../common/Enum';
import * as ApiType from '../../solutionCenterApi/gen/index';
import { telemetry } from '../../services/telemetryService';
import { telemetryConstants } from '../../config';
import ProgressService from '../../services/progressService';
import { DeploymentFailedMessageBar } from '../statusBars/deploymentFailedMessageBar';
import { useEffect } from 'react';
import { TelemetryPropertyNames } from '../../common/Constants';

export interface InProgressDialogProps {
  isOpen: boolean;
  onDismiss: () => void;
  closeAddComponents: () => void;
  deploymentId?: string;
}

export const InProgressDialog: React.FunctionComponent<InProgressDialogProps> = (props: InProgressDialogProps) => {
  const intl = useIntl();

  const { isOpen, onDismiss, deploymentId, closeAddComponents } = props;

  const [isDeploymentFailed, setIsDeploymentFailed] = React.useState<boolean>(false);
  const [failedDeploymentCorrelationId, setFailedDeploymentCorrelationId] = React.useState<string>('');

  useEffect(() => {
    runAfterProgressResolvedAndAfter60Seconds();
  }, []);

  const onOkButtonClick = (): void => {
    closeAddComponents();
    onDismiss();
  };

  const checkProgress = async (): Promise<boolean | undefined> => {
    var customProps: Record<string, string> = {};
    try {
      if (deploymentId) {
        customProps[TelemetryPropertyNames.deploymentIdKey] = deploymentId;
        const progress: ApiType.DeploymentStatusResponse = await ProgressService.getInstallationProgress(deploymentId);
        if (progress) {
          const deploymentFailed: boolean =
            progress.deploymentStatus === Enum.DeploymentStatus[Enum.DeploymentStatus.Failure];

          setIsDeploymentFailed(deploymentFailed);

          if (deploymentFailed) {
            setFailedDeploymentCorrelationId(progress?.correlationId);
            telemetry.logEvents(
              telemetryConstants.installation.INSTALLATION_TELEMETRY_DEPLOY_SOLUTION_FAILED,
              customProps
            );
          }
        }

        const deploymentCompleted: boolean =
          progress?.deploymentStatus === Enum.DeploymentStatus[Enum.DeploymentStatus.Completed];

        return !!deploymentCompleted;
      } else {
        telemetry.logTrace('Unable to retrieve deployment ID', telemetryConstants.severity.SEVERITY_ERROR);
      }
    } catch (error) {
      telemetry.logException(error);
      return false;
    }
  };

  const resolveIn60Seconds = (): Promise<void> => {
    return new Promise<void>((resolve) => {
      setTimeout(() => resolve(), 60000);
    });
  };

  const runAfterProgressResolvedAndAfter60Seconds = async (): Promise<void> => {
    await Promise.all([resolveIn60Seconds(), checkProgress()]).then(([_, isProgressCompleted]) => {
      if (!isProgressCompleted) {
        return runAfterProgressResolvedAndAfter60Seconds();
      }
    });
  };

  const renderBody = (): JSX.Element => {
    if (isDeploymentFailed) {
      return <DeploymentFailedMessageBar correlationId={failedDeploymentCorrelationId} />;
    } else {
      return (
        <div id="progressBody" className={contentStyles.updateContainer}>
          <div>
            <span>
              <FormattedMessage id="addComponents_deployment_description" />
            </span>
          </div>
          <br />
        </div>
      );
    }
  };

  const renderFooter = (): JSX.Element | null => {
    if (isDeploymentFailed) {
      return null;
    } else {
      return (
        <Stack
          id="progressFooter"
          className={contentStyles.confirmedFooter}
          horizontal
          horizontalAlign="end"
          tokens={footerStackTokens}
        >
          <PrimaryButton
            onClick={onOkButtonClick}
            className={contentStyles.okayButtonStyle}
            text={intl.formatMessage({ id: 'buttons.ok' })}
          />
        </Stack>
      );
    }
  };

  const renderTitle = (): string => {
    if (isDeploymentFailed) {
      return 'status.deploymentFailed';
    } else {
      return 'deploymentSplash.title';
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onDismiss={closeAddComponents}
        containerClassName={contentStyles.container}
        isBlocking={false}
        styles={modalStyle}
      >
        <div id="progressHeader" className={contentStyles.header}>
          <span>{intl.formatMessage({ id: renderTitle() })}</span>
          <IconButton styles={updateDialogIconButtonStyles} iconProps={{ iconName: 'Cancel' }} onClick={onDismiss} />
        </div>
        {renderBody()}
        {renderFooter()}
      </Modal>
    </>
  );
};
