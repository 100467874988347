import React, { FC, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { dropdownStyles, stackTokens } from '../azComponents/customComponentControls/dropdownControl.styles';
import CustomRenderLabel from '../azComponents/customComponentControls/customLabelRenderer';
import { IAzureSubscription } from '../models/azureResource.Models';
import { AzureManagementService } from '../../../../../services/azureManagementService';
import { RootStore } from '../../../../../redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSubscription,
  setErrorValidationCheckSubscriptionType,
  setResourceGroup,
  setHasPermissionAuthorization,
} from '../../../../../redux/azureInstance/azureInstanceActions';
import { telemetry } from '../../../../../services/telemetryService';
import styles from '../armStyling/index.styles';
import { permissionsResponse } from '../../../../../common/Type';
import config from '../../../../../config';
import * as Enum from '../../../../../common/Enum';

interface AccountSubscriptionProps {}
const AccountSubscription: FC<AccountSubscriptionProps> = (props: AccountSubscriptionProps): JSX.Element => {
  const intlShape = useIntl();
  const [subscriptionList, setSubscriptionList] = React.useState<IAzureSubscription[] | undefined>();
  const selectedSubscription = useSelector((state: RootStore) => state.azureInstance.subscription);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const dispatch = useDispatch();
  const azureToken = useSelector((state: RootStore) => state.authentication.azureToken);

  useEffect(() => {
    const getSubscriptionList = async () => {
      AzureManagementService.getSubscriptionList(azureToken).then((response: any) => {
        telemetry.logTrace(`Get Subscriptions List call returned: ${response}`);
        setSubscriptionList(response);
        setSubscriptionErrorMessage(response);
      });
    };
    getSubscriptionList();
  }, [azureToken]);

  const accountSubscriptionOptions = useMemo<IDropdownOption[]>(
    () =>
      subscriptionList?.map((subscription: IAzureSubscription, index: number) => ({
        key: subscription.subscriptionId,
        text: subscription.displayName,
        index: index,
      })),
    [subscriptionList]
  );

  const permissionsAuthorizationCheck = async (subscriptionId: string) => {
    let hasPermissions: boolean = true;
    const templateAllowedAction =
      config.armTemplates.azureSynapseAnalytics.permissions.storageBlobDataContributorAllowedAction;
    AzureManagementService.permissionsAuthorizationCheck(azureToken, subscriptionId).then(
      (response: permissionsResponse[] | undefined) => {
        if (response) {
          hasPermissions =
            hasPermissions &&
            (response[0]?.actions.includes(templateAllowedAction.actions) || response[0]?.actions.includes('*')) &&
            !response[0]?.notActions.includes(templateAllowedAction.actions);
        } else hasPermissions = false;
        hasPermissions
          ? dispatch(setHasPermissionAuthorization(Enum.PermissionState.GRANTED))
          : dispatch(setHasPermissionAuthorization(Enum.PermissionState.DENIED));
      }
    );
  };

  const setSubscriptionErrorMessage = (response: IAzureSubscription[]) => {
    if (!response || response.length === 0) {
      setErrorMessage(intlShape.formatMessage({ id: 'subscription.errormessage' }));
      dispatch(setErrorValidationCheckSubscriptionType(true));
    } else if (response && response.length > 0 && !selectedSubscription) {
      setErrorMessage(intlShape.formatMessage({ id: 'subscription.selection.errormessage' }));
      dispatch(setErrorValidationCheckSubscriptionType(true));
    }
  };

  const onSubscriptionChange = (option: IDropdownOption): void => {
    const subscription: IAzureSubscription = subscriptionList?.filter(
      (each: IAzureSubscription) => option.key === each.subscriptionId
    )[0];
    permissionsAuthorizationCheck(subscription.subscriptionId);
    dispatch(setSubscription(subscription));
    setErrorMessage('');
    dispatch(setErrorValidationCheckSubscriptionType(false));
    dispatch(setResourceGroup(null));
  };

  const onRenderLabel = (): JSX.Element => {
    return CustomRenderLabel(
      intlShape,
      'accountSubscription.selectSubscriptionDropdown',
      null,
      'accountSubscription.installTooltip',
      null,
      'accountSubscription.accessibility.subscriptionInfoIcon',
      null
    );
  };

  return (
    <div className={styles.verticalSpacing}>
      <Stack tokens={stackTokens}>
        <Dropdown
          options={accountSubscriptionOptions}
          selectedKey={selectedSubscription ? selectedSubscription?.subscriptionId : null}
          onChanged={onSubscriptionChange}
          errorMessage={errorMessage}
          placeHolder={intlShape.formatMessage({
            id: 'accountSubscription.placeholder',
          })}
          styles={dropdownStyles}
          onRenderLabel={onRenderLabel}
          label={intlShape.formatMessage({
            id: 'accountSubscription.selectSubscriptionDropdown',
          })}
          ariaLabel={intlShape.formatMessage({
            id: 'accountSubscription.accessibility.placeholder',
          })}
          role="none"
        />
      </Stack>
    </div>
  );
};

export default AccountSubscription;
