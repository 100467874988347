import { IStackTokens, IDropdownStyles } from '@fluentui/react';

export const stackTokens: IStackTokens = { childrenGap: 10 };

export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: {
    width: 520,
    color: '#605E5C!important',
    marginTop: '5px',
    '@media(max-width: 945px)': {
      width: 285,
    },
    '@media(max-width: 480px)': {
      width: 196,
    },
  },

  dropdownOptionText: {
    display: 'block',
    whiteSpace: 'none',
  },

  title: {
    color: '#605E5C',
    border: '1px solid #605E5C',
  },
  caretDown: {
    color: '#605E5C',
  },
};
