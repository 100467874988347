import { mergeStyles, mergeStyleSets } from '@fluentui/react';

export const styles = mergeStyleSets({
  descriptionContainer: {
    background: '#FAF9F8',
  },
  descriptionTextContainer: {
    marginLeft: '95px',
    paddingTop: '12px',
    marginRight: '88px',
    paddingBottom: '12px',
    borderBottom: '1px solid #EDEBE9',
    '@media(max-width: 480px)': {
      left: '14px!important',
      marginLeft: '10px',
    },
  },
  descriptionStyle: {
    fontWeight: 600,
  },
  licenseMissingStyle: {
    marginTop: '-10px',
  },
  DeploymentCheckFailedStyle: {
    marginTop: '-10px',
  },
  linkFontColour: {
    color: '#0074CC !important',
  },
});

export const style = mergeStyles({
  display: 'block',
  marginBottom: '10px',
});
