import { mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  dialogContentPropsText: {
    fontSize: '20px',
    margin: '0',
  },
  dialogContentParagraph: {
    fontSize: '14px',
  },
  dialogContent: {
    overflowWrap: 'break-word',
  },
});

export default styles;
