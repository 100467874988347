import { Dropdown, Stack, IconButton, TooltipHost, Text, Link, IDropdownOption } from '@fluentui/react';
import React, { FC, ReactElement, useEffect, useMemo } from 'react';
import { DATA_TEST_IDS } from '../../../../common/Constants';
import { FormattedMessage, useIntl } from 'react-intl';
import { stackTokens, regionStyles, dropdownStyles } from '../../regionalStyling/index.styles';
import { RegionAvailabilityService } from '../../../../services/regionAvailabilityService';
import { OrchestratorType } from '../../../../common/Enum';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../redux';
import { getRegionBasedonOrchestratorType } from '../../../../utils/regionAvailabilityUtil';

export interface AzureRegionsProps {
  regionSettingApplied: (orchestratorType: OrchestratorType, region: string) => void;
}

export const AzureRegions: FC<AzureRegionsProps> = (props: AzureRegionsProps): ReactElement => {
  const intlShape = useIntl();
  const regionsByL03Payload = useSelector((state: RootStore) => state.deployment.regionsByOrchestratorType);
  const regionSelection = getRegionBasedonOrchestratorType(regionsByL03Payload, OrchestratorType.ARM);
  const [selectedRegion, setSelectedRegion] = React.useState<string | null>(regionSelection);
  const [regionList, setRegionList] = React.useState<Array<string> | undefined>();

  const azureAriaLabelText = intlShape
    .formatMessage(
      { id: 'region_Panel.accessibility.placeholder' },
      {
        regionName: intlShape.formatMessage({
          id: 'region_Panel.Azure_Dropdown.title',
        }),
      }
    )
    .toString();

  useEffect(() => {
    const getAllAzureRegions = async () => {
      await RegionAvailabilityService.getAllAzureRegions().then((response: string[] | null) => {
        setRegionList(response);
      });
    };
    getAllAzureRegions();
  }, []);

  const azureRegionOptions = useMemo<IDropdownOption[]>(
    () =>
      regionList?.map((region: string, index: number) => ({
        key: region,
        text: region,
        index: index,
      })),
    [regionList]
  );

  const onAzureOptionChange = (option: IDropdownOption): void => {
    const region: string = regionList?.filter((eachRegion: string) => option.key === eachRegion)[0];
    props.regionSettingApplied(OrchestratorType.ARM, region);
    setSelectedRegion(region);
  };

  return (
    <div className={regionStyles.verticalSpacing} id={DATA_TEST_IDS.azureRegions}>
      <Stack tokens={stackTokens}>
        <div>
          <Text className={regionStyles.fontBold}>
            <FormattedMessage id="region_Panel.Azure_Dropdown.title" />
          </Text>
          <TooltipHost
            content={
              <FormattedMessage
                id="region_Panel.Azure_Dropdown.Tooltip"
                values={{
                  Link: (azureLink: string) => (
                    <Link
                      className={regionStyles.linkColor}
                      href="/"
                      target="_blank"
                      underline={true}
                      rel="noopener noreferrer"
                    >
                      {azureLink}
                    </Link>
                  ),
                }}
              />
            }
            calloutProps={{ gapSpace: 0 }}
          >
            <IconButton
              ariaLabel={intlShape.formatMessage({
                id: 'region_Panel.Azure_Dropdown.Tooltip',
              })}
              iconProps={{
                iconName: 'Info',
                className: regionStyles.infoIconBtn,
              }}
            />
          </TooltipHost>
        </div>
        <div className={regionStyles.dropdownSpacing}>
          <Dropdown
            options={azureRegionOptions}
            selectedKey={selectedRegion}
            onChanged={onAzureOptionChange}
            errorMessage=""
            placeholder={intlShape.formatMessage({
              id: 'region_Panel.Dropdowns.placeholder',
            })}
            styles={dropdownStyles}
            calloutProps={{ calloutMaxHeight: 270 }}
            ariaLabel={azureAriaLabelText}
            aria-live={'polite'}
            aria-atomic={true}
          />
        </div>
      </Stack>
    </div>
  );
};
