import React, { FC, ReactElement } from 'react';
import { styles } from './containers.styles';
import { useIntl } from 'react-intl';
import * as ApiType from '../../solutionCenterApi/gen/index';
import Utils from '../../utils';
import { LicenseMissingStatusBar } from '../statusBars/licenseMissingStatusBar';
import { Link } from '@fluentui/react';
import LearnMoreDialog from '../../pages/products/product/learn-more-dialog';
import InstallationStateContainer from '../../stateContainers/installationStateContainer';
import RuntimeConfigStateContainer from '../../stateContainers/runtimeConfigStateContainer';
import * as WebAppApiType from '../../webAppApi/index';
import { PreValidationCheckStatusBar } from '../statusBars/PreValidationCheckStatusBar';

export interface OptionalComponentsDescriptionContainerProps {
  optionalComponent: ApiType.OptionalComponent;
  deploymentL03s?: ApiType.L03[];
  isPreValidationCheckPassed?: boolean;
}

export const OptionalComponentsDescriptionContainer: FC<OptionalComponentsDescriptionContainerProps> = (
  props: OptionalComponentsDescriptionContainerProps
): ReactElement => {
  const runtimeConfig: WebAppApiType.ClientConfiguration = RuntimeConfigStateContainer.getConfiguration();
  const enableValidationsForUpdate = runtimeConfig.featureFlag.enableValidationsForUpdate;

  const { optionalComponent } = props;

  const [isLearnMoreOpen, setIsLearnMoreOpen] = React.useState<boolean>(false);

  const intlShape = useIntl();

  const createParentL03NamesList = (parentL03Names: string[]): string => {
    var result: string = parentL03Names.join(', ');
    return result;
  };

  const findParentL03Names = (): string[] => {
    var selectedOffers: ApiType.L03[] = [];
    if (props.deploymentL03s) {
      selectedOffers = props.deploymentL03s;
    } else {
      selectedOffers = InstallationStateContainer.getSelectedOffers();
    }

    var parentL03Names: string[] = [];
    selectedOffers.forEach((offer: ApiType.L03) => {
      if (offer.optionalComponents) {
        offer.optionalComponents.forEach((optionalComponent: ApiType.OptionalComponent) => {
          if (optionalComponent.rowKey) {
            if (props.optionalComponent.type === ApiType.PackageType.Data) {
              parentL03Names.push(offer.name);
            } else if (optionalComponent.rowKey === props.optionalComponent.rowKey) {
              parentL03Names.push(offer.name);
            }
          }
        });
      }
    });

    var uniqueParentL03Names: string[] = [];
    parentL03Names.forEach((name: string) => {
      var index: number = uniqueParentL03Names.findIndex((item) => item === name);
      if (index <= -1) {
        uniqueParentL03Names.push(name);
      }
    });

    return uniqueParentL03Names;
  };

  const renderLearnMoreDialog = (): JSX.Element | null => {
    var missingLicenses: ApiType.License[] = Utils.getProductMissingLicenses(optionalComponent);
    if (missingLicenses.length > 0) {
      return (
        <LearnMoreDialog
          missingLicenses={missingLicenses}
          isOpen={isLearnMoreOpen}
          close={() => setIsLearnMoreOpen(!isLearnMoreOpen)}
        />
      );
    } else {
      return null;
    }
  };

  const renderLicenseMissingStatusBar = (optionalComponent: ApiType.OptionalComponent): JSX.Element | null => {
    var skipLicenseCheck = optionalComponent.skipLicenseCheck ?? false;
    if (!skipLicenseCheck) {
      var isLicensePresent: boolean = Utils.productHasLicense(optionalComponent);
      if (!isLicensePresent) {
        return (
          <div id="licenseMissingStatusBar" className={styles.licenseMissingStyle}>
            <LicenseMissingStatusBar onToggleLearnMore={() => setIsLearnMoreOpen(!isLearnMoreOpen)} />
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const renderPreValidationCheckStatusBar = (optionalComponent: ApiType.OptionalComponent): JSX.Element | null => {
    if (enableValidationsForUpdate && !props.isPreValidationCheckPassed) {
      return (
        <div id="licenseMissingStatusBar" className={styles.DeploymentCheckFailedStyle}>
          <PreValidationCheckStatusBar />
        </div>
      );
    } else {
      return null;
    }
  };

  const learnMoreLink = (learnMoreLink: string, learnMoreLinkText?: string): JSX.Element => {
    var learnMore: string = intlShape.formatMessage({
      id: 'products.learnMore',
    });
    return (
      <>
        <br />
        <br />
        <Link
          id={learnMoreLink}
          className={styles.linkFontColour}
          underline={true}
          href={learnMoreLink}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={intlShape.formatMessage({
            id: 'products.learnMore',
          })}
        >
          {learnMoreLinkText ? learnMoreLinkText : learnMore}
        </Link>
      </>
    );
  };

  const renderLearnMoreLink = (optionalComponent: ApiType.OptionalComponent): JSX.Element | null => {
    if (optionalComponent.learnMoreLink && optionalComponent.learnMoreLinkText) {
      return learnMoreLink(optionalComponent.learnMoreLink, optionalComponent.learnMoreLinkText);
    } else if (optionalComponent.learnMoreLink) {
      return learnMoreLink(optionalComponent.learnMoreLink);
    } else {
      return null;
    }
  };

  return (
    <div className={styles.descriptionContainer}>
      {renderPreValidationCheckStatusBar(optionalComponent)}
      {renderLicenseMissingStatusBar(optionalComponent)}
      <div className={styles.descriptionTextContainer}>
        <span id="descriptionHeader" className={styles.descriptionStyle}>
          {intlShape.formatMessage({
            id: 'additionalComponents.description',
          })}
        </span>
        <br />
        <span>{optionalComponent.description}</span>
        {renderLearnMoreLink(optionalComponent)}
        <br />
        <br />
        <span id="enhancesHeader" className={styles.descriptionStyle}>
          {intlShape.formatMessage({
            id: 'additionalComponents.enhances',
          })}
        </span>
        <br />
        <span id="parentL03Names">{createParentL03NamesList(findParentL03Names())}</span>
      </div>
      {renderLearnMoreDialog()}
    </div>
  );
};
