import * as React from 'react';
import { ActionButton, IconButton } from '@fluentui/react';
import { PreDeploymentConfigurations } from '../../../deploymentDetails/solutionsContainer/../preDeploymentConfigurations/preDeploymentConfigurations';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import styles from '../../../deploymentDetails/solutionsContainer/solutionsContainer.styles';
import successStyles from '../success.styles';
import * as ApiType from '../../../deploymentDetails/solutionsContainer/../../../solutionCenterApi/gen/index';
import { telemetry } from '../../../deploymentDetails/solutionsContainer/../../../services/telemetryService';
import { telemetryConstants } from '../../../deploymentDetails/solutionsContainer/../../../config';
import { getUniqDependencies } from '../../../deploymentDetails/solutionsContainer/../../../utils';
import { AZURE, prePrefix } from '../../../deploymentDetails/solutionsContainer/../../../common/Constants';
import { ResponsiveModeContext } from '../../../deploymentDetails/solutionsContainer/../../../contexts/ResponsiveModeContext';
import {
  getDecoratedLaunchLinkWithFallback,
  isMultiPackagePresent,
} from '../../../deploymentDetails/solutionsContainer/../../../utils/deployHelper';
import EnvironmentTable from './environmentTable';

type solutionsContainerProps = {
  solution: ApiType.L03 | ApiType.OptionalComponent;
} & WrappedComponentProps;

type deploymentDetailsState = {
  downArrowClicked: boolean;
  showDependenciesClicked: boolean;
  preDeploymentDependencies: ApiType.L03Dependency[];
};

class SolutionTableWithDropdown extends React.Component<solutionsContainerProps, deploymentDetailsState> {
  constructor(props: solutionsContainerProps) {
    super(props);

    this.state = {
      downArrowClicked: false,
      showDependenciesClicked: false,
      preDeploymentDependencies: [],
    };
  }

  static contextType = ResponsiveModeContext;

  componentDidMount = async () => {
    const preDependencies: ApiType.L03Dependency[] | undefined = this.props.solution.l03Dependencies?.filter(
      (x) => x.preOrPostDeploy === prePrefix
    );
    const uniquePreDependencies: ApiType.L03Dependency[] = getUniqDependencies(preDependencies);
    this.setState({
      preDeploymentDependencies: uniquePreDependencies,
    });
  };

  logLinkClick = (name: string) => {
    var buttonSelected: any = {};
    buttonSelected[telemetryConstants.deploymentDetails.DEPLOYMENT_DETAILS_TELEMETRY_KEY] = name;
    telemetry.logEvents(telemetryConstants.deploymentDetails.DEPLOYMENT_DETAILS_TELEMETRY_NAME, buttonSelected);
  };

  externalLinkClickHandler = (name: string, url: string) => {
    this.logLinkClick(name);
    window.open(url, '_blank');
  };

  onDownButtonClick = () => {
    this.setState({
      downArrowClicked: true,
      showDependenciesClicked: true,
    });
  };

  onUpButtonClick = () => {
    this.setState({
      downArrowClicked: false,
      showDependenciesClicked: false,
    });
  };

  onShowDependenciesClicked = () => {
    this.setState({
      showDependenciesClicked: true,
    });
  };

  returnUserLaunchName = (): string => {
    return 'success.openSolution';
  };

  renderDeploymentStatus = (entity: ApiType.L03 | ApiType.OptionalComponent) => {
    var rawEndUserLaunchLink = getDecoratedLaunchLinkWithFallback(entity);
    if (rawEndUserLaunchLink) {
      return (
        <>
          <ActionButton
            onClick={() =>
              this.externalLinkClickHandler(telemetryConstants.events.LAUNCH_SOLUTION_CLICKED, rawEndUserLaunchLink)
            }
            className={styles.launchStyle}
            iconProps={{ iconName: 'OpenInNewWindow' }}
          >
            <FormattedMessage id={this.returnUserLaunchName()} />
          </ActionButton>
        </>
      );
    } else {
      return null;
    }
  };

  render() {
    const { downArrowClicked, preDeploymentDependencies } = this.state;

    const { intl, solution } = this.props;
    const preDependencies: ApiType.L03Dependency[] | undefined = solution.l03Dependencies?.filter(
      (x: ApiType.L03Dependency) => x.preOrPostDeploy === prePrefix && x.type !== AZURE
    );

    return (
      <div>
        <div className={styles.solutionBox}>
          <div className={styles.solutionTitleContainer}>
            <span className={styles.solutionTitleWithoutVersion}>{solution.name}</span>

            {downArrowClicked ? (
              <IconButton
                ariaLabel={intl.formatMessage({
                  id: 'deploymentDetails.button.upArrow',
                })}
                onClick={this.onUpButtonClick}
                className={styles.arrowStyle}
                iconProps={{ iconName: 'ChevronUp' }}
              ></IconButton>
            ) : preDependencies?.length !== 0 || isMultiPackagePresent([solution]) ? (
              <IconButton
                ariaLabel={intl.formatMessage({
                  id: 'deploymentDetails.button.downArrow',
                })}
                onClick={this.onDownButtonClick}
                className={styles.arrowStyle}
                iconProps={{ iconName: 'ChevronDown' }}
              ></IconButton>
            ) : null}

            {isMultiPackagePresent([solution]) ? null : this.renderDeploymentStatus(solution)}
          </div>
          {downArrowClicked ? (
            <div>
              {isMultiPackagePresent([solution]) ? (
                <div id="environmentTable" className={successStyles.mainSectionContainer}>
                  {solution && <EnvironmentTable selectedOffer={solution} instanceApiUrl={null} />}
                </div>
              ) : null}
              <div className={styles.preDeploymentConfigurationContainer}>
                {preDeploymentDependencies.length !== 0 ? (
                  <PreDeploymentConfigurations
                    intl={intl}
                    solution={solution}
                    preDeploymentDependencies={preDeploymentDependencies}
                  />
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default injectIntl(SolutionTableWithDropdown);
