import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { segoeRegular } from '../../common/Constants';

export const gettingStartedStyles = mergeStyleSets({
  mainContainer: {
    height: '768px',
    marginLeft: '80px',
    position: 'relative',
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    background: '#2266E3',
    '@media(max-width: 960px)': {
      boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
      borderRadius: '2px',
      background: '#2266E3',
      margin: 'unset',
    },
    '@media(max-width: 480px)': {
      width: '166px',
      height: '430px',
      boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
      borderRadius: '2px',
      background: '#2266E3',
      margin: 'unset',
    },
  },
  defaultTitle: {
    padding: '0',
    marginTop: '2%',
    marginBottom: '20px',
    borderTop: '1px solid #C8C8C8',
    width: '433px',
    maxHeight: '250px',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '32px',
    display: 'flex',
    alignItems: 'center',
    color: '#323130',
    '@media(max-width: 960px)': {
      width: '320px',
    },
    '@media(max-width: 480px)': {
      width: '140px',
      marginTop: '2px',
    },
  },
  headerTitle: {
    fontFamily: segoeRegular,
    fontWeight: '700',
    color: '#323130',
    padding: '0',
    marginTop: '40px',
    marginBottom: '20px',
    height: '67px',
    width: '440px',
    fontStyle: 'normal',
    fontSize: '24px',
    lineHeight: '32px',
    display: 'flex',
    alignItems: 'center',
    '@media(max-width: 960px)': {
      fontSize: '8px',
      lineHeight: '20px',
      width: '160px',
    },
    '@media(max-width: 480px)': {
      fontSize: '5px',
      lineHeight: '10px',
      marginTop: '5px',
    },
  },

  trailDescSpanCss: {
    position: 'absolute',
    width: '466px',
    height: '84px',
    left: '245px',
    top: '537px',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '28px',
    color: '#201F1E',
    marginTop: '60px',
  },
  marginTop: {
    '@media(max-width: 960px)': {
      marginTop: '5px',
      fontSize: '10px',
    },
    '@media(max-width: 480px)': {
      fontSize: '8px',
      lineHeight: '20px',
      marginTop: '10px',
    },
  },
  marginTopHeader: {
    marginTop: '8%',
    fontSize: '19px',
    fontWeight: '700',
    color: '#323130',
    '@media(max-width: 960px)': {
      margin: '0px',
      fontSize: '8px',
      width: '200px',
      lineHeight: '18px',
      fontWeight: '700',
      color: '#323130',
    },
    '@media(max-width: 480px)': {
      margin: 0,
      fontSize: '8px',
      lineHeight: '20px',
      width: '150px',
    },
  },
  marginBottom: {
    marginBottom: '30px',
    width: '354px',
    '@media(max-width: 480px)': {
      height: '50px',
      marginBottom: '2px',
    },
  },
  containerStyle: {
    width: '500px',
    margin: 'auto',
  },
  getStartedDiv: {
    float: 'left',
  },
  marginButtonTop: {
    marginTop: '160px',
  },
  marRight: {
    marginRight: '10px',
  },
  marTop: {
    marginTop: '50px',
    marginLeft: '130px',
    '@media(max-width: 960px)': {
      marginLeft: '10px',
      width: '158px',
    },
    '@media(max-width: 480px)': {
      marginLeft: '10px',
      marginTop: '0px',
    },
  },
  imgSolutionCss: {
    '@media(max-width: 960px)': {
      width: '130px',
      fontSize: '8px',
      fontFamily: segoeRegular,
      fontWeight: '400',
      fontStyle: 'normal',
    },
    '@media(max-width: 480px)': {
      width: '130px',
      fontSize: '6px',
      fontFamily: segoeRegular,
      fontWeight: '400',
      fontStyle: 'normal',
    },
  },
  imgLogoCss: {
    width: '175px',
    height: '53px',
    marginLeft: '150px',
    top: '2px',
    '@media(max-width: 960px)': {
      marginLeft: '10px',
    },
    '@media(max-width: 480px)': {
      marginLeft: '10px',
      width: '90px',
      height: '30px',
    },
  },
  noBullets: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  liDivStyleCss: {
    fontFamily: segoeRegular,
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    marginTop: '8%',
    width: '341px',
    '@media (max-width: 960px)': {
      fontSize: '8px',
    },
    '@media (max-width: 480px)': {
      fontSize: '6px',
      lineHeight: '16px',
    },
  },
  descriptionContainer: {
    fontWeight: '12px',
    lineHeight: '18px',
    height: '44%',
    maxHeight: '400px',
    width: '60%',
    color: '#323130',
    maxWidth: '100%',
    '-webkit-line-clamp': '4',
    '-webkit-box-orient': 'vertical',
    padding: '8px 10px 0 16px',
    'margin-bottom': '16px',
    overflow: 'hidden',
    border: '1px solid #C8C8C8',
    marginLeft: '95px',
    marginTop: '235px',
    marginRight: '140px',
    background: '#FFFFFF',
    boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
    borderRadius: '4px',
    position: 'absolute',
    '@media (max-width: 960px)': {
      marginLeft: '40px',
      width: '160px',
      height: '165px',
    },
    '@media (max-width: 480px)': {
      marginLeft: '10px',
      width: '92px',
      height: '30%',
      position: 'absolute',
      marginTop: '150px',
    },
  },
  trialDescriptionContainer: {
    position: 'absolute',
    width: '370px',
    height: '364px',
    left: 'calc(50% - 370px/2)',
    top: 'calc(50% - 364px/2 - 2px)',
    background: '#FFFFFF',
    boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
    borderRadius: '4px',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid #C8C8C8',
    '@media (max-width: 980px)': {
      float: 'left',
      display: 'flex',
      alignItems: 'unset',
    },
    '@media (max-width: 480px)': {
      width: '100%',
    },
  },
  marLeft: {
    '@media (max-width: 960px)': {
      marginLeft: '5%',
    },
    '@media (max-width: 480px)': {
      width: '50%',
    },
  },
  getStartedCss: {
    width: '306px',
    height: '32px',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#323130',
    '@media (max-width: 960px)': {
      fontSize: '10px',
      lineHeight: '10px',
    },
    '@media (max-width: 480px)': {
      fontSize: '6px',
      lineHeight: '10px',
    },
  },
  divMinHeight: {
    '@media (max-width: 960px)': {
      height: '20px',
    },
    '@media (max-width: 480px)': {
      height: '10px',
    },
  },
  headerMargin: {
    '@media (max-width: 960px)': {
      fontSize: '10px',
      lineHeight: '10px',
      marginTop: '15px',
    },
    '@media (max-width: 480px)': {
      fontSize: '10px',
      marginTop: '10px',
      marginBottom: '10px',
    },
  },
  signingTextCss: {
    width: '306px',
    height: '24px',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '12px',
    alignItems: 'center',
    color: '#605E5C',
    '@media (max-width: 960px)': {
      width: '160px',
      lineHeight: '0px',
      fontSize: '8px',
    },
    '@media (max-width: 480px)': {
      width: '100px',
      fontSize: '4px',
      lineHeight: '0px',
      marginTop: '5px',
    },
  },
  marDivDescContainer: {
    marginLeft: '20px',
    marginTop: '20px',
    '@media (max-width: 960px)': {
      marginLeft: '0px',
      marginTop: '0px',
    },
    '@media (max-width: 480px)': {
      marginLeft: '0px',
      marginTop: '0px',
    },
  },
  descContainerHeaderTitle: {
    width: '240px',
    height: '16px',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    color: '#484644',
    '@media (max-width: 960px)': {
      height: '1px',
      fontSize: '8px',
    },
    '@media (max-width: 480px)': {
      height: '1px',
      fontSize: '6px',
    },
  },
  linkColor: {
    color: '#605e5c',
  },
  supportedRegionText: {
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#FF0000',
    marginBottom: '10px',
    '@media (max-width: 960px)': {
      width: '160px',
      fontSize: '8px',
      lineHeight: '10px',
      marginBottom: '-5px',
    },
    '@media (max-width: 480px)': {
      width: '100px',
      fontSize: '4px',
      lineHeight: '1px',
      marginTop: '5px',
    },
  },
  startTrialMessageContainer: {
    position: 'absolute',
    bottom: '10%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    textAlign: 'left',
    '@media (max-width: 960px)': {
      height: '20px',
      bottom: '25%',
    },
    '@media (max-width: 480px)': {
      marginTop: '20px',
      height: '14px',
      bottom: '25%',
    },
  },
  startTrialBtnCss: {
    width: '180px',
    height: '20px',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#FFFFFF',
    '@media (max-width: 960px)': {
      width: '100px',
      height: '14px',
    },
    '@media (max-width: 480px)': {
      marginTop: '0px',
      width: '90px',
      height: '14px',
    },
  },
  freeTrialButtonStyle: {
    width: '180px',
    '@media (max-width: 960px)': {
      width: '100px',
      height: '16px',
      fontSize: '6px',
    },
    '@media (max-width: 480px)': {
      width: '90px',
      height: '12px',
      fontSize: '4px',
    },
  },
  imgGraphicCss: {
    marginTop: '630px',
    height: '110px',
    marginLeft: '311px',
  },
});
