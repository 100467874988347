import { PermissionState } from '../../common/Enum';
import * as Type from '../../common/Type';
import {
  IAzureResourceGroup,
  IAzureSubscription,
  IAzureLocation,
} from '../../pages/install/destination/azureResourceWidget/models/azureResource.Models';
import * as ApiType from '../../solutionCenterApi/gen/index';
import { ArmDeploymentScope } from '../../solutionCenterApi/gen/index';

export const SELECTED_AZURE_OFFERS = 'SELECTED_AZURE_OFFERS';
export const ARM_DEPLOYMENT_SCOPE_PROVIDER = 'DEPLOYMENT_SCOPE_PROVIDER';
export const PARAMETER_DEFINITION_PROVIDER = 'PARAMETER_DEFINITION_PROVIDER';
export const ERROR_VALIDATION_CONTROL_TYPE = 'ERROR_VALIDATION_CONTROL_TYPE';
export const ERROR_VALIDATION_CHECK_DROPDOWN_TYPE = 'ERROR_VALIDATION_CHECK_DROPDOWN_TYPE';
export const ERROR_VALIDATION_CHECK_SUBSCRIPTION_TYPE = 'ERROR_VALIDATION_CHECK_SUBSCRIPTION_TYPE';
export const ERROR_VALIDATION_CHECK_RESOURCEGROUP_TYPE = 'ERROR_VALIDATION_CHECK_RESOURCEGROUP_TYPE';
export const ERROR_VALIDATION_CHECK_LOCATION_TYPE = 'ERROR_VALIDATION_CHECK_LOCATION_TYPE';
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const RESOURCE_GROUP = 'RESOURCE_GROUP';
export const LOCATION = 'LOCATION';
export const HASPERMISSIONAUTHORIZATION = 'HASPERMISSIONAUTHORIZATION';

export interface selectedAzureOffers {
  type: typeof SELECTED_AZURE_OFFERS;
  payload: ApiType.L03[];
}

export interface deploymentScopeProvider {
  type: typeof ARM_DEPLOYMENT_SCOPE_PROVIDER;
  payload: { [key in keyof typeof ArmDeploymentScope]?: string[] };
}

export interface parameterDefinitionProvider {
  type: typeof PARAMETER_DEFINITION_PROVIDER;
  payload: Type.ParameterDefinitionDictionary[];
}

export interface errorValidationControlType {
  type: typeof ERROR_VALIDATION_CONTROL_TYPE;
  payload: boolean;
}

export interface errorValidationCheckSubscriptionType {
  type: typeof ERROR_VALIDATION_CHECK_SUBSCRIPTION_TYPE;
  payload: boolean;
}

export interface errorValidationCheckResourceGroupType {
  type: typeof ERROR_VALIDATION_CHECK_RESOURCEGROUP_TYPE;
  payload: boolean;
}

export interface subscription {
  type: typeof SUBSCRIPTION | null;
  payload: Partial<IAzureSubscription> | null;
}

export interface resourceGroup {
  type: typeof RESOURCE_GROUP;
  payload: Partial<IAzureResourceGroup> | null;
}

export interface location {
  type: typeof LOCATION | null;
  payload: IAzureLocation | null;
}

export interface hasPermissionAuthorization {
  type: typeof HASPERMISSIONAUTHORIZATION;
  payload: PermissionState;
}

export type AzureInstanceDispatchTypes =
  | selectedAzureOffers
  | deploymentScopeProvider
  | parameterDefinitionProvider
  | errorValidationControlType
  | errorValidationCheckResourceGroupType
  | errorValidationCheckSubscriptionType
  | subscription
  | resourceGroup
  | location
  | hasPermissionAuthorization;
