import { InstanceMetadata } from '../../solutionCenterApi/gen';
import {
  ERROR_VALIDATION_CI_INSTANCE_TYPE,
  EnvironmentDispatchTypes,
  CI_INSTANCE,
  SELECTED_CI_INSTANCE,
  ERROR_VALIDATION_DV_ENVIRONMENT_TYPE,
} from './environmentType';

export const setCiInstance = (ciInstance: InstanceMetadata | null): EnvironmentDispatchTypes => ({
  type: CI_INSTANCE,
  payload: ciInstance,
});

export const setSelectedInstance = (ciInstance: InstanceMetadata | null): EnvironmentDispatchTypes => ({
  type: SELECTED_CI_INSTANCE,
  payload: ciInstance,
});

export const setErrorValidationDvEnvironmentType = (
  errorValidationDvEnvironmentType: boolean
): EnvironmentDispatchTypes => ({
  type: ERROR_VALIDATION_DV_ENVIRONMENT_TYPE,
  payload: errorValidationDvEnvironmentType,
});

export const setErrorValidationCiInstanceType = (errorValidationCiInstanceType: boolean): EnvironmentDispatchTypes => ({
  type: ERROR_VALIDATION_CI_INSTANCE_TYPE,
  payload: errorValidationCiInstanceType,
});
