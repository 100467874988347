import React from 'react';
import { telemetry } from '../services/telemetryService';
import { telemetryConstants } from '../config';
import Utils from '../utils';

const WithBaseQueryParameterProcessing = (WrappedComponent, appProps, props) => {
  const queryParams = new URLSearchParams(props.location.search);
  const source = queryParams.get(telemetryConstants.queryParameterKeys.SOURCE);
  const l01 = queryParams.get(telemetryConstants.queryParameterKeys.SOLUTION);

  if (!Utils.isNullOrUndefinedOrEmpty(source)) {
    sessionStorage.setItem(telemetryConstants.queryParameterKeys.SOURCE, source);
    const eventData = { source, l01, queryParams: props.location.search };
    telemetry.logEvents(telemetryConstants.deepLinkEvents.PAGE_VISIT_FROM_DEEPLINK, eventData);
  }

  return <WrappedComponent {...appProps} {...props} queryParams={queryParams} source={source} l01={l01} />;
};

export default WithBaseQueryParameterProcessing;
