import React from 'react';
import { L03, Package } from '../../../../solutionCenterApi/gen';
import { FabricPackageSubType, PackageDeploymentType } from '../../../../common/Enum';
import EventStreamComponentList from './fabricComponents/eventstreamComponentList';
import LakeHouseComponentList from './fabricComponents/lakehouseComponentList';

export interface FabricSolutionComponentListProps {
  solutions: L03[];
}
const FabricSolutionComponentList = (props: FabricSolutionComponentListProps): JSX.Element => {
  const { solutions } = props;
  return (
    <>
      {solutions.map((solution: L03) => (
        <FabricSolutionComponent solution={solution} />
      ))}
    </>
  );
};

const FabricSolutionComponent = ({ solution }: { solution: L03 }): JSX.Element => {
  const fabricPackages = solution.packages.filter((pkg) => pkg.packageType === PackageDeploymentType.Fabric);
  const groupedPackages = fabricPackages.reduce((acc, pkg) => {
    if (!acc[pkg?.packageSubType]) {
      acc[pkg?.packageSubType] = [];
    }
    acc[pkg?.packageSubType].push(pkg);
    return acc;
  }, {} as Record<string, Package[]>);

  return (
    <>
      <br />
      <EventStreamComponentList packages={groupedPackages[FabricPackageSubType.EventStream]} />
      <LakeHouseComponentList packages={groupedPackages[FabricPackageSubType.LakeHouse]} />
    </>
  );
};

export default FabricSolutionComponentList;
