import React, { ReactElement } from 'react';
import { FontIcon } from '@fluentui/react';
import styles, { iconClass } from './statusBars.styles';
import { FormattedMessage } from 'react-intl';

export const DeploymentSuccessStatus = (): ReactElement => {
  return (
    <div className={styles.deploymentSuccessStyle}>
      <FontIcon iconName="Accept" className={iconClass} />
      <span>
        <FormattedMessage id="status.deploymentSuccess" />
      </span>
    </div>
  );
};
