import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { segoeRegular } from '../../common/Constants';

const styles = mergeStyleSets({
  sideSteper: {
    borderRight: '1px solid #E1DFDD',
    width: '320px',
    minHeight: 'calc(100vh - 109px)',
  },
  sideStepperDestination: {
    width: '320px',
  },
  content: {
    width: '100%',
    padding: '20px',
  },
  innerSideSteper: {
    marginTop: '34px',
    marginLeft: '16px',
  },
  wizardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    background: 'white',
    width: '100%',
    height: '90%',
    '@media(max-width: 960px)': {
      width: 'calc(100% - 360px)',
    },
  },
  wizardContent: {
    flex: 1,
    position: 'relative',
    paddingBottom: '64px',
  },
  wizardContentInner: {
    position: 'relative',
  },
  wizardHeaderContainer: {
    borderBottom: '1px solid #E1DFDD',
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    left: '0px',
    right: '0px',
    top: '0px',
  },
  wizardHeaderContainerIcon: {
    fontSize: '3rem',
    color: '#0078D4',
    cursor: 'pointer',
    lineHeight: '16px',
    'padding-right': '30px',
  },
  wizardHeaderContainerTitle: {
    flex: 'none',
    order: 0,
    alignSelf: 'center',
    margin: '12px 0px',
    fontFamily: segoeRegular,
    fontSize: '20px',
    height: '28px',
    left: '0px',
    right: '0px',
    top: 'calc(50% - 28px/2)',
    color: '#323130',
    marginLeft: '16px',
    fontWeight: 600,
    padding: '8px',
  },
  stepPageContainer: {
    height: '100%',
    '@media(min-width: 960px)': {
      display: 'flex',
    },
  },
  iframeContainer: {
    minHeight: 'calc(100vh - 62px)',
  },
  iconIndentArrowMirroredFontSize: {
    fontSize: '3rem !important',
  },
  stepperDropDownContainer: {
    borderBottom: '1px solid #E1DFDD',
  },
  stepperInProgress: {
    float: 'left',
    paddingTop: '3px',
  },
  stepperDropDown: {
    float: 'left',
    paddingTop: '10px',
  },
  arrowButton: {
    float: 'right',
    paddingRight: '20px',
    paddingLeft: '150px',
  },
});

export default styles;
