import React from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../../redux';
import { DefaultButton, PrimaryButton, IconButton } from '@fluentui/react';
import TitleDescription from '../../../components/title-description/TitleDescription';
import ConfigurationDependencyCard from './dependency-card';
import LoadingScreen from '../../../common/loading-screen';
import CardStylesWrapper from '../../../components/cards/cards-status-styles';
import Visible from '../../../components/visible/visible';
import CountdownTimer from '../../../utils/countdownTimer';
import DeployService from '../../../services/deployService';
import DependencyService from '../../../services/dependencyService';
import InstallationStateContainer from '../../../stateContainers/installationStateContainer';
import SolutionsStateContainer from '../../../stateContainers/solutionsStateContainer';
import { telemetryConstants } from '../../../config';
import { telemetry } from '../../../services/telemetryService';
import config from '../../../config';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import styles from './configuration.styles';
import { commonStyles } from '../commonStyles.styles';
import * as Constants from '../../../common/Constants';
import * as Type from '../../../common/Type';
import * as ApiType from '../../../solutionCenterApi/gen/index';
import { RouteComponentProps } from 'react-router';
import Util from '../../../utils';
import * as Utils from '../../../utils/helper';
import { DeploymentFailedMessageBar } from '../../../components/statusBars/deploymentFailedMessageBar';
import {
  IAzureLocation,
  IAzureResourceGroup,
  IAzureSubscription,
} from '../destination/azureResourceWidget/models/azureResource.Models';
import DeploymentStateContainer from '../../../stateContainers/deploymentStateContainer';
import { getPBIL03RowKeys } from '../../../utils/helper';
import PublicPreviewService from '../../../services/publicPreviewService';
import RuntimeConfigStateContainer from '../../../stateContainers/runtimeConfigStateContainer';
import { ArmDeploymentScope } from '../../../solutionCenterApi/gen/index';
import { AzureDeployableInstance } from '../../../common/Type';
import AuthenticationStateContainer from '../../../stateContainers/authenticationStateContainer';
import { FabricComponentPayloadList } from '../../../redux/deployments/deploymentReducers';

type configurationProps = {
  cdsEnvironment: Type.Environment;
  fabricWorkspace: ApiType.FabricWorkspace;
  azureRegion: IAzureLocation;
  subscription: IAzureSubscription;
  resourceGroup: IAzureResourceGroup;
  armScopeToOffersMap: { [key in keyof typeof ArmDeploymentScope]?: string[] };
  parameterDefinitions: Type.ParameterDefinitionDictionary[];
  selectedAzureOffers: ApiType.L03[];
  deploymentDefinition: ApiType.DeploymentDefinitionEntity;
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
  fabricPayload: FabricComponentPayloadList;
} & WrappedComponentProps;

type configurationState = {
  isLoading: boolean;
  includeSampleData: boolean;
  includeAdditionalSampleData: boolean;
  isCheckingDependencies: boolean;
  offers: ApiType.L03[];
  optionalComponents: ApiType.OptionalComponent[];
  checkedDependencies: ApiType.DependencyCheckFetchXMLResult[];
  dependencies: ApiType.L03[] | null;
  deploymentCallFailed: boolean;
  azureAvailableInstance: boolean;
  isPreviewSelected: boolean;
};

const mapStateToProps = (state: RootStore) => ({
  cdsEnvironment: state.deployment.cdsEnvironment,
  fabricWorkspace: state.deployment.fabricWorkspace,
  azureRegion: state.azureInstance.location,
  subscription: state.azureInstance.subscription,
  resourceGroup: state.azureInstance.resourceGroup,
  armScopeToOffersMap: state.azureInstance.armScopeToOffersMap,
  parameterDefinitions: state.azureInstance.parameterDefinitionProvider,
  selectedAzureOffers: state.azureInstance.selectedAzureOffers,
  deploymentDefinition: state.deployment.deploymentDefinition,
  fabricPayload: state.deployment.fabricPayload,
});

class Configuration extends React.Component<configurationProps, configurationState> {
  runtimeConfig = RuntimeConfigStateContainer.getConfiguration();
  countdownTimer = new CountdownTimer();
  constructor(props: configurationProps) {
    super(props);
    this.state = {
      isLoading: false,
      includeSampleData: false,
      includeAdditionalSampleData: false,
      isCheckingDependencies: false,
      optionalComponents: DeploymentStateContainer.getOptionalComponents(),
      offers: InstallationStateContainer.getSelectedOffers(),
      checkedDependencies: InstallationStateContainer.getCheckedDependencies(),
      dependencies: [],
      deploymentCallFailed: false,
      azureAvailableInstance: false,
      isPreviewSelected: InstallationStateContainer.getPreviewSelectionStatus(),
    };
  }

  componentDidMount = async () => {
    var metricName =
      telemetryConstants.metrics.TRACKING_TYPE_PAGE_VISIT_DURATION +
      ': ' +
      telemetryConstants.installation.INSTALLATION_TELEMETRY_CONFIGURE_DEPENDENCIES_VALUE;
    telemetry.logTrace(
      'Configuration: ' + telemetryConstants.stateManagement.CDS_INSTANCE_ID_SELECTED,
      telemetryConstants.severity.SEVERITY_INFO,
      this.props.cdsEnvironment
    );
    telemetry.logPageView(telemetryConstants.installation.INSTALLATION_TELEMETRY_CONFIGURE_DEPENDENCIES_VALUE);
    telemetry.startMetric(metricName);

    document.querySelector('h1')?.scrollIntoView();
  };

  componentWillUnmount = () => {
    var metricName =
      telemetryConstants.metrics.TRACKING_TYPE_PAGE_VISIT_DURATION +
      ': ' +
      telemetryConstants.installation.INSTALLATION_TELEMETRY_CONFIGURE_DEPENDENCIES_VALUE;

    telemetry.stopMetric(metricName);
  };

  handleCheckboxChange = () => {
    this.setState({
      includeSampleData: !this.state.includeSampleData,
    });
  };

  handleAdditionalSampleDataCheckboxChange = () => {
    this.setState({
      includeAdditionalSampleData: !this.state.includeAdditionalSampleData,
    });
  };

  handleBackClick = () => {
    var previousRoute = config.routes.installDeploymentSummary;
    this.props.history.push(previousRoute, {
      from: this.props.location.pathname,
    });
  };

  getAzureDeployableInstance = (): Type.AzureDeployableInstance | null => {
    let azureDeployInstance: AzureDeployableInstance;
    const subscription: IAzureSubscription = this.props.subscription;
    const resourceGroup: IAzureResourceGroup = this.props.resourceGroup;
    const azureRegion: IAzureLocation = this.props.azureRegion;
    let additionalDeploymentParameters: {
      [key: string]: ApiType.ParameterDefinition[];
    } = {};
    let customProps: any = {};
    if (this.props.parameterDefinitions && this.props.parameterDefinitions.length > 0) {
      this.props.parameterDefinitions.forEach((each) => {
        const requiredParameters = each.parameterDefinitions.filter((param) => param.value != null);
        additionalDeploymentParameters[each.azureOfferRowKey] = requiredParameters;
      });
    }
    const azureOptionalComponents: ApiType.OptionalComponent[] =
      DeploymentStateContainer.getOptionalComponents().filter(
        (component: ApiType.OptionalComponent) => component.additionalDeploymentParametersAvailable === true
      );
    const isAzureEnabled = this.props.selectedAzureOffers?.length > 0 || azureOptionalComponents.length > 0;
    if (isAzureEnabled) {
      if (this.props.armScopeToOffersMap?.RESOURCE_GROUP?.length ?? 0) {
        azureDeployInstance = {
          subscription: subscription,
          resourceGroup: resourceGroup,
          additionalDeploymentParametersMap: additionalDeploymentParameters,
        };
      }

      if (this.props.armScopeToOffersMap?.TENANT?.length ?? 0) {
        azureDeployInstance = azureDeployInstance ?? {};
        azureDeployInstance.azureRegion = azureRegion;
        azureDeployInstance.additionalDeploymentParametersMap = additionalDeploymentParameters;
      }
    }
    customProps[Constants.TelemetryPropertyNames.armPackageDetails] = azureDeployInstance;
    telemetry.logEvents(telemetryConstants.azureResourceManager.ARM_PACKAGE_DETAILS, customProps);

    return azureDeployInstance;
  };

  handlePreviewInstall = async () => {
    try {
      let customProps: Record<string, string> = {};
      const offers = InstallationStateContainer.getSelectedOffers();
      this.setState({ isLoading: true });
      const optinResponse: ApiType.PublicPreviewOptInResponse = await PublicPreviewService.optInEnvironment(
        offers,
        this.props.cdsEnvironment
      );
      customProps[Constants.TelemetryPropertyNames.instanceNameKey] = JSON.stringify(this.props.cdsEnvironment);
      customProps[Constants.TelemetryPropertyNames.offersKey] = JSON.stringify(offers);
      const publicPreviewOptInSuccess = optinResponse?.publicPreviewOptInSuccess;
      const optInFailure = Object.keys(publicPreviewOptInSuccess)?.some(
        (key) => publicPreviewOptInSuccess[key as string] === false
      );
      if (!optInFailure) {
        telemetry.logTrace(`Optin successful for environment ${this.props.cdsEnvironment.uniqueName}`, customProps);
        this.handleInstallClick();
      } else {
        telemetry.logTrace(`Optin failed for environment ${this.props.cdsEnvironment.uniqueName}`, customProps);
        this.setState({ deploymentCallFailed: true, isLoading: false });
      }
    } catch (ex) {
      telemetry.logException(ex);
      this.setState({ deploymentCallFailed: true, isLoading: false });
    }
  };

  handleInstallClick = async () => {
    let customProps: any = {};
    const { includeSampleData, includeAdditionalSampleData } = this.state;
    this.setState({
      isLoading: true,
    });
    const offers = InstallationStateContainer.getSelectedOffers();
    const fabricPayload: ApiType.FabricComponentPayload[] = Object.values(this.props.fabricPayload).map(
      ({ type, ...rest }) => rest
    );
    const optionalComponents: ApiType.OptionalComponent[] = DeploymentStateContainer.getOptionalComponents();
    var selectedOptionalComponents: ApiType.OptionalComponent[] = [];
    if (Utils.isAnyOptionalComponentsSampleData(optionalComponents)) {
      if (offers) {
        selectedOptionalComponents = Utils.getAllSampleDataOptionalComponents(offers, optionalComponents);
      }
    } else {
      selectedOptionalComponents = optionalComponents;
    }
    customProps[Constants.TelemetryPropertyNames.optionalComponentsKey] = selectedOptionalComponents;

    var pbiL03RowKeys: any = [];
    const azureDeployInstance = this.getAzureDeployableInstance();
    const armDeploymentScopes = this.props.armScopeToOffersMap;

    var instanceName: string = '';
    offers?.forEach((l03: ApiType.L03) => {
      l03?.packages
        ?.filter((a: ApiType.Package) => a.packageType === Constants.PBITemplateAppPackageType)
        .forEach((b: ApiType.Package) => {
          instanceName = !instanceName
            ? b.applicationGuid
            : this.props.intl.formatMessage({
                id: 'pbi_multiple_deployments.instance.name',
              });
          pbiL03RowKeys?.push(l03?.rowKey);
        });
    });
    var pbiPayload: ApiType.PBIPayload | undefined = {
      l03RowKeys: pbiL03RowKeys && pbiL03RowKeys.length > 0 ? pbiL03RowKeys : undefined,
    };

    telemetry.logTrace(
      'PBITemplateAppPackage Count - ' + pbiPayload?.l03RowKeys?.length,
      telemetryConstants.severity.SEVERITY_INFO
    );
    customProps[Constants.TelemetryPropertyNames.pbiSelectionKey] = pbiPayload?.l03RowKeys?.length;

    const environment = this.props.cdsEnvironment;
    customProps[Constants.TelemetryPropertyNames.instanceNameKey] = this.props.cdsEnvironment;
    const fabricWorkspace: ApiType.Workspace = this.props.fabricWorkspace;
    customProps[Constants.TelemetryPropertyNames.fabricWorkspaceIdKey] = this.props.fabricWorkspace?.id;
    const selectedCIEnvironment = InstallationStateContainer.getSelectedCIInstance();
    customProps[Constants.TelemetryPropertyNames.cIInstanceNameKey] = selectedCIEnvironment;
    const currentSolution = SolutionsStateContainer.getCurrentSolution();
    const selectedL01RowKey = currentSolution.rowKey;
    customProps[Constants.TelemetryPropertyNames.l01RowKey] = selectedL01RowKey;

    if (!environment?.friendlyName && !azureDeployInstance && pbiPayload?.l03RowKeys?.length! > 0) {
      telemetry.logTrace(
        'Instance name is set as PBI only offer(s) selected' + instanceName,
        telemetryConstants.severity.SEVERITY_INFO
      );
    }

    telemetry.logEvents(telemetryConstants.installation.DEPLOY_CLICKED, customProps);

    if (this.props.deploymentDefinition) {
      telemetry.logEvents(telemetryConstants.events.ADD_OC_TO_EXISTING_DEPLOYMENT_CLICKED, customProps);
      try {
        let result = await DeployService.complexUpdateDeploy(
          this.props.cdsEnvironment.environmentId,
          this.props.deploymentDefinition.rowKey!,
          this.props.deploymentDefinition,
          selectedOptionalComponents,
          selectedCIEnvironment,
          this.props.deploymentDefinition.l01RowKey,
          armDeploymentScopes,
          azureDeployInstance,
          instanceName,
          getPBIL03RowKeys(selectedOptionalComponents)
        );

        if (result) {
          if (result.deploymentId) {
            InstallationStateContainer.setDeploymentId(result.deploymentId);
            customProps[Constants.TelemetryPropertyNames.deploymentIdKey] = result.deploymentId;
            InstallationStateContainer.setDeploymentCorrelationId(result.correlationId);
            customProps[Constants.TelemetryPropertyNames.correlationIdKey] = result.correlationId;

            this.props.history.push(config.routes.installInstallation, {
              from: this.props.location.pathname,
            });
          }
        } else {
          this.setState({ deploymentCallFailed: true, isLoading: false });
        }
      } catch (error) {
        telemetry.logException(error);
        this.setState({ deploymentCallFailed: true, isLoading: false });
      }
    } else {
      telemetry.logEvents(telemetryConstants.events.DEPLOYMENT_CALLED, customProps);
      try {
        let result = await DeployService.deploy(
          offers,
          environment,
          fabricWorkspace?.workspaceId,
          includeSampleData,
          includeAdditionalSampleData,
          selectedCIEnvironment,
          selectedL01RowKey,
          armDeploymentScopes,
          azureDeployInstance,
          pbiPayload,
          fabricPayload,
          instanceName,
          selectedOptionalComponents
        );

        if (result) {
          if (result.deploymentId) {
            InstallationStateContainer.setDeploymentId(result.deploymentId);
            customProps[Constants.TelemetryPropertyNames.deploymentIdKey] = result.deploymentId;
            InstallationStateContainer.setDeploymentCorrelationId(result.correlationId);
            customProps[Constants.TelemetryPropertyNames.correlationIdKey] = result.correlationId;

            this.props.history.push(config.routes.installInstallation, {
              from: this.props.location.pathname,
            });
          }
        } else {
          this.setState({ deploymentCallFailed: true, isLoading: false });
        }
      } catch (error) {
        telemetry.logException(error, customProps);
        this.setState({ deploymentCallFailed: true, isLoading: false });
      }
    }
  };

  cancelInstallation = () => {
    this.props.history.push(config.routes.home);
  };

  recheckDependencies = async () => {
    var customProps: any = {};
    try {
      customProps[Constants.TelemetryPropertyNames.instanceNameKey] = this.props.cdsEnvironment;

      const dependencies = DependencyService.getDependencies();
      customProps[Constants.TelemetryPropertyNames.dependenciesKey] = dependencies;

      telemetry.logTrace(
        'DependencyService.getDependencies returned with the dependencies for the selected offers',
        telemetryConstants.severity.SEVERITY_INFO,
        customProps
      );

      if (dependencies.length !== 0) {
        this.setState({ isCheckingDependencies: true });
        telemetry.logTrace(
          'Excecuting dependency check for the given dependency ids',
          telemetryConstants.severity.SEVERITY_INFO,
          customProps
        );

        const dependencyCheckResult = await DependencyService.executeDependencyCheck(
          this.props.cdsEnvironment,
          dependencies
        );
        if (dependencyCheckResult) {
          customProps[Constants.TelemetryPropertyNames.dependenciesCheckResult] = dependencyCheckResult;
          telemetry.logTrace(
            'Dependency check  executed successfully and returned with the following results',
            telemetryConstants.severity.SEVERITY_INFO,
            customProps
          );

          if (dependencyCheckResult.dependencyCheckFetchXMLResult) {
            InstallationStateContainer.setCheckedDependencies(dependencyCheckResult.dependencyCheckFetchXMLResult);
          }
          this.props.history.push(config.routes.installConfigurationDetails, {
            from: this.props.location.pathname,
          });
        } else {
          telemetry.logEvents(telemetryConstants.events.DEPENDENCY_CHECK_FAILED, customProps);
          this.props.history.push(config.routes.error);
        }
      }
    } catch (error) {
      telemetry.logException(error);
      telemetry.logEvents(telemetryConstants.events.ERROR_PAGE_SHOWN, customProps);
      this.props.history.push(config.routes.error);
    } finally {
      this.setState({ isCheckingDependencies: false });
    }
  };

  renderDependencyCheckResult = (selection: ApiType.L03[] | ApiType.OptionalComponent[]): Type.DependencyChecked[] => {
    const { checkedDependencies } = this.state;
    var dependencies: Type.DependencyChecked[] = Util.getDependencies(selection).reduce(
      (acc: any, current: ApiType.L03Dependency) => {
        const checked = checkedDependencies?.find(
          (cd: ApiType.DependencyCheckFetchXMLResult) => cd.l03DependencyId === current.rowKey
        );
        if (!checked) {
          return acc.concat({
            ...current,
            isConfigured: true,
            isDeployed: true,
          });
        }
        return acc.concat({
          ...current,
          isDeployed: checked.isDeployed,
          isConfigured: checked.isConfigured,
        });
      },
      []
    );

    dependencies = dependencies.filter((x: ApiType.L03Dependency) => x.type !== Constants.AZURE);
    return dependencies;
  };

  renderDependencies = (): Type.DependencyChecked[] => {
    const { offers, optionalComponents } = this.state;
    var dependencies: Type.DependencyChecked[] = this.renderDependencyCheckResult(offers);

    var oCDependencies: Type.DependencyChecked[] = this.renderDependencyCheckResult(optionalComponents);

    if (dependencies.length !== 0) {
      dependencies = dependencies.concat(oCDependencies);
    } else {
      dependencies = oCDependencies;
    }

    return dependencies;
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { intl, selectedAzureOffers } = this.props;
    const { azureAvailableInstance } = this.state;
    const dependencies = this.renderDependencies();
    const environment = this.props.cdsEnvironment;
    const keyPostDeploymentSection = renderKeyPostDeploymentSection(this.state.offers);
    const ciInstance: ApiType.InstanceMetadata = InstallationStateContainer.getSelectedCIInstance();
    const isDeployBtnDisabled = dependencies.some((d: Type.DependencyChecked) => {
      if (Boolean(d.isDeployed) === false) {
        return true;
      } else if (Boolean(d.isConfigured) === false) {
        return true;
      }
      return false;
    });

    const azureOptionalComponents: ApiType.OptionalComponent[] =
      DeploymentStateContainer.getOptionalComponents().filter(
        (component: ApiType.OptionalComponent) => component.additionalDeploymentParametersAvailable === true
      );
    const isDeployBtnDisabledForAzure =
      (selectedAzureOffers?.length > 0 && azureAvailableInstance) ||
      (azureOptionalComponents.length > 0 && azureAvailableInstance)
        ? true
        : false;

    return (
      <>
        <LoadingScreen isVisible={this.state.isLoading} label={null} />
        <LoadingScreen
          isVisible={this.state.isCheckingDependencies}
          label={intl.formatMessage({
            id: 'configuration.checkingDependencies',
          })}
        />
        <div className={styles.visuallyHidden} role="alert">
          {<FormattedMessage id="configuration.configurationRecheckingCompleted" />}
        </div>
        {!this.state.deploymentCallFailed ? (
          <div>
            <div className={commonStyles.mainContainer}>
              <TitleDescription
                titleStyle={''}
                textLink={null}
                titleClassName={styles.titleDescriptionStyles}
                title={formatMessage({
                  id: 'configuration.configurationTitle',
                })}
                description={
                  dependencies && dependencies.length
                    ? formatMessage({
                        id: 'configuration.dependenciesDescription',
                      })
                    : formatMessage({
                        id: 'configuration.noDependenciesDescription',
                      })
                }
              />
              {dependencies && dependencies.length ? (
                <>
                  <div className={styles.dependencyListContainer}>
                    <Visible when={!this.state.isCheckingDependencies} fallback={!this.state.isCheckingDependencies}>
                      <div className={styles.dependenciesRecheckContainer}>
                        <span
                          role="presentation"
                          className={styles.dependenciesRecheckButtonArea}
                          onClick={this.recheckDependencies}
                        >
                          <IconButton
                            iconProps={{
                              iconName: 'Refresh',
                              className: styles.dependenciesRecheckIconCss,
                            }}
                            ariaLabel={intl.formatMessage({
                              id: 'configuration.reCheckDependenices',
                            })}
                            className={styles.dependenciesRecheckIcon}
                          />
                          <span>
                            <FormattedMessage id="configuration.reCheckDependenices" />
                          </span>
                        </span>
                      </div>
                    </Visible>
                  </div>
                  <div id="dependencyCards" className={styles.dependencyCardList}>
                    {dependencies.map((d: Type.DependencyChecked, i: number) => {
                      return (
                        <CardStylesWrapper
                          key={i}
                          hasError={!d.isDeployed && !d.isConfigured}
                          cardComponent={ConfigurationDependencyCard}
                          disableHover={d.isDeployed && d.isConfigured}
                          dependency={d}
                          env={d.name === Constants.CISolutionPackageType ? ciInstance : environment}
                          containerClassName={styles.cardStyleContainer}
                          containerStyle={null}
                        />
                      );
                    })}{' '}
                  </div>
                </>
              ) : null}
              {keyPostDeploymentSection.map((deploymentSection) => {
                return (
                  <section className={styles.keyPostDeploymentSection}>
                    <div className={styles.flexBoxContainer}>
                      <div>
                        <img
                          className={styles.headerImg}
                          src={
                            deploymentSection.icon !== null && deploymentSection.icon !== ''
                              ? deploymentSection.icon
                              : Util.getIconSvg('healthcare_for_provider')
                          }
                          alt={intl.formatMessage(
                            { id: 'altText.offer.icon' },
                            { offerName: deploymentSection.l03Name }
                          )}
                          title={intl.formatMessage(
                            { id: 'altText.offer.icon' },
                            { offerName: deploymentSection.l03Name }
                          )}
                        />
                      </div>
                      {deploymentSection.keyPostDeploymentHeader}
                    </div>
                    <div className={styles.keyPostDeploymentActions}>
                      <ul>{deploymentSection.keyPostDeploymentActionNames}</ul>
                    </div>
                    <div className={styles.keyPostDeploymentFooter}>
                      <DefaultButton
                        text={intl.formatMessage({ id: 'configuration.keyPostDeploymentSection.buttontext' })}
                        title={intl.formatMessage({ id: 'configuration.keyPostDeploymentSection.buttontext' })}
                        href={deploymentSection.keyPostDeploymentLink}
                        target="_blank"
                      />
                    </div>
                  </section>
                );
              })}
            </div>
            <div className={commonStyles.footerContainer}>
              <div className={styles.dependencyFooterContainerBody}>
                <DefaultButton
                  text={intl.formatMessage({
                    id: 'buttons.back',
                  })}
                  className={styles.configurationPageFooterLeftButton}
                  allowDisabledFocus
                  onClick={this.handleBackClick}
                />
                <PrimaryButton
                  disabled={this.state.isLoading || isDeployBtnDisabled || isDeployBtnDisabledForAzure}
                  text={intl.formatMessage({
                    id: 'buttons.deploy',
                  })}
                  data-customevent={telemetryConstants.events.SUBMIT_DEPLOY_CLICKED}
                  data-customtenantId={AuthenticationStateContainer.getTenantId()}
                  data-customsource={sessionStorage.getItem('source')}
                  onClick={this.state.isPreviewSelected ? this.handlePreviewInstall : this.handleInstallClick}
                  allowDisabledFocus
                />
              </div>
              <div className={styles.configurationPageFooterRightButton}>
                <DefaultButton
                  text={intl.formatMessage({
                    id: 'buttons.cancel',
                  })}
                  onClick={this.cancelInstallation}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className={commonStyles.mainContainer}>
              <DeploymentFailedMessageBar correlationId={InstallationStateContainer.getxMsCorrelationId()!} />
            </div>
            <div className={commonStyles.footerContainer}>
              <div className={styles.configurationPageFooterLeftButton}>
                <DefaultButton
                  text={intl.formatMessage({
                    id: 'buttons.cancel',
                  })}
                  onClick={this.cancelInstallation}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export const renderKeyPostDeploymentSection = (offers: ApiType.L03[]) => {
  const keyPostDeploymentSection = [];
  var parser = new DOMParser();
  offers.forEach((offer) => {
    let icon = offer?.icon;
    let l03Name = offer?.name;
    let keyPostDeploymentActionNames = [];
    let keyPostDeploymentHeader = null;
    var keyPostDeploymentHeaderText = offer?.keyPostDeploymentHeaderText;
    var keyPostDeploymentLink = offer?.keyPostDeploymentLink;
    var doc = parser.parseFromString(keyPostDeploymentHeaderText, 'text/html');
    if (!Util.isNullOrUndefinedOrEmpty(keyPostDeploymentHeaderText)) {
      keyPostDeploymentHeader = <h4 className={styles.keyPostDeploymentHeader}>{doc.body.textContent}</h4>;
      var keyPostDeploymentActions = offer?.keyPostDeploymentActions;
      keyPostDeploymentActions.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
      });
      keyPostDeploymentActions.forEach((keyPostDeploymentAction) => {
        var keyPostDeploymentActionName = keyPostDeploymentAction.name;
        var doc = parser.parseFromString(keyPostDeploymentActionName, 'text/html');
        keyPostDeploymentActionNames.push(<li>{doc.body.textContent}</li>);
      });
      keyPostDeploymentSection.push({
        keyPostDeploymentHeader,
        keyPostDeploymentActionNames,
        icon,
        l03Name,
        keyPostDeploymentLink,
      });
    }
  });
  return keyPostDeploymentSection;
};

export default connect(mapStateToProps, null)(injectIntl(Configuration));
