import { mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  container: {
    height: '100vh',
    width: '100%',
  },
  iframe: {
    border: 'none',
  },
});

export default styles;
