/* eslint-disable security/detect-object-injection */
import React, { useState, useEffect } from 'react';
import { Stack, Button, Spinner } from '@fluentui/react';
import { FaAngleLeft } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { setupGuideStyles, spinnerLabelStyles } from './setupGuides.styles';
import { telemetry } from '../../../../../src/services/telemetryService';
import config from '../../../../config';
import { SetupGuideBlockProps } from '../../../../components/cards/setupGuideBlock/setupGuideBlock';
import SolutionsStateContainer from '../../../../stateContainers/solutionsStateContainer';
import { SetupGuide } from '../../../../solutionCenterApi/gen';

export const SetupGuides: React.FC<SetupGuideBlockProps> = () => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [guideList, setGuideList] = useState<SetupGuide[]>([]);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const history = useHistory();
  const location = useLocation<SetupGuideBlockProps>();
  const { title, description, thumbnailLink, videoLink } = location.state || {};
  const [guideData, setGuideData] = useState<SetupGuideBlockProps>({ title, description, thumbnailLink, videoLink });

  useEffect(() => {
    const fetchData = async () => {
      const allSetupGuides = await fetchSetupGuidesData();
      await setGuideList(allSetupGuides);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (guideList.length > 0) {
      const data = guideList.find((guide) => guide.title === guideData.title);
      if (data) {
        setGuideData(data);
      } else {
        setGuideData(null);
      }
    }
  }, [guideData, guideList]);

  const fetchSetupGuidesData = async () => {
    try {
      const allSetupGuides = await SolutionsStateContainer.getAllSetupGuides();
      return allSetupGuides;
    } catch (error) {
      console.error('Error fetching setup guides:', error);
      telemetry.logException(error);
    }
  };

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  const handleBackClick = () => {
    history.push(config.routes.learningHub);
  };

  const handlePrev = () => {
    const currentIndex = guideList.findIndex((guide) => formatTitle(guide.title) === formatTitle(guideData.title));
    if (currentIndex !== -1) {
      const prevIndex = (currentIndex - 1 + guideList.length) % guideList.length;
      const prevGuideData = guideList[prevIndex];
      setGuideData(prevGuideData);
      history.push({
        pathname: `${config.routes.setupGuides.replace(':title', formatTitle(prevGuideData.title))}`,
        state: {
          title: prevGuideData.title,
          description: prevGuideData.description,
          thumbnailLink: prevGuideData.thumbnailLink,
          videoLink: prevGuideData.videoLink,
        },
      });
    }
  };

  const handleNext = () => {
    const currentIndex = guideList.findIndex((guide) => formatTitle(guide.title) === formatTitle(guideData.title));
    if (currentIndex !== -1) {
      const nextIndex = (currentIndex + 1) % guideList.length;
      const nextGuideData = guideList[nextIndex];
      setGuideData(nextGuideData);
      history.push({
        pathname: `${config.routes.setupGuides.replace(':title', formatTitle(nextGuideData.title))}`,
        state: {
          title: nextGuideData.title,
          description: nextGuideData.description,
          thumbnailLink: nextGuideData.thumbnailLink,
          videoLink: nextGuideData.videoLink,
        },
      });
    }
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const formatTitle = (title: string) => {
    return title.toLowerCase().replace(/\s+/g, '-');
  };

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <div>
        <Button className={setupGuideStyles.backBtn} onClick={handleBackClick}>
          <span className={setupGuideStyles.backBtnIcon}>
            <FaAngleLeft />
          </span>
          <span className={setupGuideStyles.backBtnText}>Back to Learning Hub</span>
        </Button>
      </div>
      <p className={setupGuideStyles.titleText} style={{ marginTop: '-25px' }}>
        Setup Guides
      </p>
      <div className={setupGuideStyles.page}>
        <div className={setupGuideStyles.embed}>
          {guideData && (
            <div className={setupGuideStyles.embed}>
              <iframe
                src={guideData.videoLink}
                width="100%"
                height="500px"
                frameBorder="0"
                allowFullScreen
                title={guideData.title}
                onLoad={handleVideoLoad}
              ></iframe>
              {!videoLoaded && (
                <div className={setupGuideStyles.loadingContent}>
                  <Spinner
                    label="Loading Content"
                    ariaLive="assertive"
                    labelPosition="bottom"
                    styles={{ label: spinnerLabelStyles }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className={setupGuideStyles.pageContent}>
          <div>
            <p className={setupGuideStyles.title}>{guideData?.title}</p>
            <p className={setupGuideStyles.description}>
              {showFullDescription ? guideData?.description : `${guideData?.description?.slice(0, 150)}...`}
            </p>
            <div className={setupGuideStyles.toggleBtn}>
              <button className={setupGuideStyles.toggle} onClick={toggleDescription}>
                {showFullDescription ? 'Show less' : 'Show more'}
              </button>
            </div>
          </div>
          <div className={setupGuideStyles.navBtn}>
            <Button
              className={setupGuideStyles.navigationButton}
              onClick={handlePrev}
              disabled={guideData !== null && guideData.title !== undefined && guideData.title === guideList[0]?.title}
            >
              Previous Video
            </Button>
            <Button
              className={setupGuideStyles.navigationButtonNext}
              onClick={handleNext}
              disabled={
                guideData !== null &&
                guideData.title !== undefined &&
                guideData.title === guideList[guideList.length - 1]?.title
              }
            >
              Next Video
            </Button>
          </div>
        </div>
      </div>
    </Stack>
  );
};

export default SetupGuides;
