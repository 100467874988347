import * as React from 'react';
import { ErrorIcon } from '../../../../components/error-icon/error-icon';
import { Modal, IconButton, DefaultButton } from '@fluentui/react';
import { telemetry } from '../../../../services/telemetryService';
import { telemetryConstants } from '../../../../config.js';
import { styles } from './learn-more-dialog.styles';
import { Link } from 'office-ui-fabric-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FC, ReactElement, useEffect } from 'react';
import * as ApiType from '../../../../solutionCenterApi/gen/index';

export interface LearnMoreDialogProps {
  close: React.MouseEventHandler<HTMLButtonElement>;
  isOpen: boolean;
  missingLicenses: ApiType.License[];
}

export const LearnMoreDialog: FC<LearnMoreDialogProps> = (props: LearnMoreDialogProps): ReactElement => {
  const intlShape = useIntl();

  const logMissingLicense = (missingLicenses: ApiType.License[]): void => {
    var licenseNotMet: Record<string, string> = {};
    if (missingLicenses?.length > 0) {
      licenseNotMet[telemetryConstants.events.LICENSE_ID] = missingLicenses[0].rowKey;
      telemetry.logEvents(telemetryConstants.events.LICENSE_NOT_MET_NAME, licenseNotMet);
    }
  };

  useEffect(() => {
    logMissingLicense(props.missingLicenses);
  }, []);

  const purchaseLink = (license: ApiType.License): ReactElement => {
    return (
      <Link
        id="purchaseLink"
        underline={true}
        className={styles.licenseLink}
        href={license.purchaseLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {license.partitionKey}
      </Link>
    );
  };

  const renderMissingLicensePurchaseLink = (license: ApiType.License): ReactElement => {
    if (license.purchaseLink == null || license.purchaseLink.length === 0) {
      return (
        <span id="productName" className={styles.licenseText}>
          {license.partitionKey}
        </span>
      );
    } else {
      return purchaseLink(license);
    }
  };

  return (
    <Modal containerClassName={styles.learnMoreModalContainer} isOpen={props.isOpen}>
      <div className={styles.licenseContent}>
        <h1 id="licenseNeeded" className={styles.headerTitle}>
          <FormattedMessage id="subSolutions.licenseNeeded" />
        </h1>
        <IconButton
          className={styles.closeButton}
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel={intlShape.formatMessage({ id: 'buttons.cancel' })}
          onClick={props.close}
        />
      </div>
      <div className={styles.additionalText}>
        <FormattedMessage id="subSolutions.missingLicenses" />
      </div>
      <div id="missingLicensesList" className={styles.listDependencies}>
        {props.missingLicenses.map((license: ApiType.License, index: number) => {
          return (
            <div key={index} className={styles.missingLicenseContainer}>
              <ErrorIcon />
              {renderMissingLicensePurchaseLink(license)}
            </div>
          );
        })}
      </div>
      <div id="footer" className={styles.footer}>
        <DefaultButton onClick={props.close} text={intlShape.formatMessage({ id: 'buttons.close' })} />
      </div>
    </Modal>
  );
};

export default LearnMoreDialog;
