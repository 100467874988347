import React, { FC, ReactElement } from 'react';
import { FontIcon } from '@fluentui/react';
import styles, { iconClass } from './statusBars.styles';
import { FormattedMessage } from 'react-intl';

type InProgressStatusProps = {
  top: string;
  left: string;
};

export const InProgressStatus: FC<InProgressStatusProps> = (props: InProgressStatusProps): ReactElement => {
  const { top, left } = props;

  return (
    <div style={{ top: top, left: left }} className={styles.inProgressStyle}>
      <FontIcon iconName="ProgressRingDots" className={iconClass} />
      <span>
        <FormattedMessage id="status.inProgress" />
      </span>
    </div>
  );
};
