import SolutionsStateContainer from '../stateContainers/solutionsStateContainer';
import { checkGroupMembership } from '../services/authenticationService';
import Utils from './index';
import * as ApiType from '../solutionCenterApi/gen';
import { telemetry } from '../services/telemetryService';

export const toggleVisibility = async (solutions: ApiType.L01[]) => {
  let arrayOfSolutions: ApiType.L01[] = [];
  for (let i = 0; i < solutions.length; i++) {
    if (solutions[i as number].isVisible === false) {
      telemetry.logTrace('Visibility is false for' + solutions[i as number].uniqueName);
      if (
        solutions[i as number].readerGroupMembershipIds &&
        solutions[i as number].readerGroupMembershipIds!.length !== 0
      ) {
        const groupIds = Utils.parseForTenantId(solutions[i as number].readerGroupMembershipIds) as never[];
        if (groupIds && groupIds.length !== 0) {
          telemetry.logTrace('checking readergroupMembership for' + solutions[i as number].uniqueName);
          await checkGroupMembership(groupIds).then((data) => {
            if (data?.value.length !== 0) {
              solutions[i as number].isVisible = true;
              arrayOfSolutions.push(solutions[i as number]);
            }
          });
        }
      }
    } else {
      arrayOfSolutions.push(solutions[i as number]);
    }
  }
  telemetry.logTrace('All solutions#: ' + solutions?.length);
  telemetry.logTrace('Number of solutions visible to user in the NavMenu: ' + arrayOfSolutions?.length);
  SolutionsStateContainer.setIsVisibleSolutions(arrayOfSolutions);
  return arrayOfSolutions;
};
