import AuthenticationStateContainer from '../stateContainers/authenticationStateContainer';
import * as ApiType from '../../src/solutionCenterApi/gen/index';
import { OrchestratorType } from '../common/Enum';
import config from '../config';
import * as _ from 'lodash';
import { telemetryConstants } from '../config';

export const getUniqDependencies = (dependencies) => {
  return [...new Map(dependencies.map((dependency) => [dependency['name'], dependency])).values()];
};

export const getUniqLicenses = (licenses) => {
  return [
    ...new Map(
      // eslint-disable-next-line security/detect-object-injection
      licenses.map((license, index) => (!licenses[index].isMissing ? [license['partitionKey'], license] : []))
    ).values(),
  ].filter((e) => e);
};
const Utils = {
  isInstanceTypePresent: (instanceMapString, instanceType) => {
    let instanceMap = {};
    if (!Utils.isNullOrUndefined(instanceMapString)) instanceMap = JSON.parse(instanceMapString);
    return instanceType in instanceMap;
  },
  getInstanceName: (deployment) => {
    if (deployment.instanceName) return deployment.instanceName;
    else if (deployment.azureSubscriptionName || deployment.azureResourceGroupName)
      return `${deployment.azureSubscriptionName} | ${deployment.azureResourceGroupName}`;
    else return deployment?.pbiWorkspace;
  },
  getTitleCase: (camelCaseString) => {
    var result = camelCaseString.replace(/([A-Z])/g, ' $1');
    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  },
  getDependencies: (selectedOffer = []) => {
    const dependencies = selectedOffer
      .reduce((result, offer) => result.concat(offer.l03Dependencies), [])
      .filter((x) => x.preOrPostDeploy === 'Pre');

    return getUniqDependencies(dependencies);
  },
  getLicenses: (allOffers = []) => {
    const licenses = allOffers.reduce((result, offer) => result.concat(offer.licenses), []);

    return getUniqLicenses(licenses);
  },

  getPostDependencies: (selectedOffer = []) => {
    const dependencies = selectedOffer
      .reduce((result, offer) => result.concat(offer.l03Dependencies), [])
      .filter((x) => x.preOrPostDeploy === 'Post');

    return getUniqDependencies(dependencies);
  },

  getDependenciesName: (selectedOffer) => {
    const dependencies = selectedOffer
      .reduce((result, offer) => result.concat(offer.l03Dependencies), [])
      .filter((x) => x.preOrPostDeploy === 'Pre');

    const unique = getUniqDependencies(dependencies);

    return unique.map((x) => ({
      name: x.name,
      icon: x.icon,
    }));
  },

  extractUrl: (str) => {
    let link,
      content = '';
    if (str) {
      // eslint-disable-next-line security/detect-unsafe-regex
      const regex = /<a\s+(?:[^>]*?\s+)?href=(["'])(?<link>.*?)\1/;
      const result = str.match(regex);
      if (result && result.length && result[2]) link = result[2];

      content = str.slice(0, str.indexOf('<'));
    }

    return { content, link };
  },

  splitInstanceUrl: (url) => {
    const regex = /(?<protocol>^https?:\/\/)(?:(?<orgName>[^.]+)\.)(?<geo>.+?)\.(?<domain_service>.*)/;
    const result = url.match(regex);
    if (result && result.length) {
      return result.groups;
    } else {
      throw new Error('Missing organization or geo in Url params, please contact your Admin');
    }
  },
  /**
   *
   * @param iconName {string} Name of the file separated by "_"
   * @param pathToResource {string} representing directory inside assets (Default value: icons)
   * @param ext {string} represents extension of file (Default value: svg)
   * @returns {string|*}
   */
  getIconSvg: (iconName, pathToResource = 'icons', ext = 'svg') => {
    if (!iconName) return './assets/logos/solution_logo.png';
    if (iconName.indexOf('http') !== -1 || iconName.indexOf('data') !== -1) return iconName;
    else return `./assets/${pathToResource}/${iconName.toLowerCase()}.${ext}`;
  },

  getNewSelectedOptionalComponents: (selection, selected) => {
    const alreadySelected = selected.some((o) => o.name === selection.name);
    return alreadySelected ? selected.filter((oc) => oc.name !== selection.name) : [...selected, selection];
  },

  getProductWithLicenses: (products) => {
    return products.filter((product) => Utils.productHasLicense(product));
  },

  productHasLicense: (product) => {
    if (product.licenses?.length) {
      const licenseGroups = Utils.arrayGroupBy(product.licenses, (x) => x.partitionKey);

      return licenseGroups.every((x) => x.group.some((x) => !x.isMissing));
    } else {
      return true;
    }
  },

  getProductDeploymentType: (product) => {
    if (!Utils.isNullOrUndefined(product)) {
      if (product.packages?.length === 0 && Utils.isNullOrUndefined(product.appSourceLink)) {
        return '';
      } else {
        if (!Utils.isNullOrUndefined(product.appSourceLink)) {
          return OrchestratorType.APPSOURCE;
        }
        let packageType = product.packages[0].packageType;
        if (packageType === ApiType.PackageType.ArmTemplate) {
          return OrchestratorType.ARM;
        } else if (
          packageType === ApiType.PackageType.Data ||
          packageType === ApiType.PackageType.Data2 ||
          packageType === ApiType.PackageType.Solution
        ) {
          return OrchestratorType.DATAVERSE;
        } else {
          return '';
        }
      }
    }
  },

  setProductsAvailability: (solution, availableL03s) => {
    solution.l02s.forEach(function (l02) {
      l02.l03s.forEach(function (l03) {
        let deploymentType = Utils.getProductDeploymentType(l03);
        if (config.regionAvailabilitySupportedDeploymentTypes.includes(deploymentType)) {
          l03.isAvailableInRegion = availableL03s.includes(l03.rowKey);
        } else {
          l03.isAvailableInRegion = true;
        }
      });
    });
    return solution;
  },

  getProductMissingLicenses: (product) => {
    if (product.licenses?.length) {
      const licenseGroups = Utils.arrayGroupBy(product.licenses, (x) => x.partitionKey);
      return licenseGroups.filter((p) => p.group.every((x) => x.isMissing)).map((p) => p.group[0]);
    } else {
      return [];
    }
  },
  arrayGroupBy: (theArray, hash) => {
    var _hash = hash
      ? hash
      : function (o) {
          return o;
        };

    var _map = {};
    var put = function (map, obj) {
      if (!map[_hash(obj)]) {
        map[_hash(obj)] = {};
        map[_hash(obj)].group = [];
        map[_hash(obj)].key = _hash(obj);
      }
      map[_hash(obj)].group.push(obj);
    };

    theArray.forEach(function (obj) {
      put(_map, obj);
    });

    return Object.keys(_map).map(function (key) {
      // eslint-disable-next-line security/detect-object-injection
      return { key: _map[key].key, group: _map[key].group };
    });
  },
  sliceMicrosoftFromSolutionName: (solutionName) => {
    return solutionName.indexOf('Microsoft') !== -1
      ? solutionName.slice(solutionName.indexOf('Microsoft') + 'Microsoft'.length, solutionName.length)
      : solutionName;
  },
  adjustSvgBase64Format: (base64Icon) => {
    return 'data:image/svg+xml;base64,' + base64Icon.slice(base64Icon.indexOf('/') + 1, base64Icon.length);
  },
  parseForTenantId: (requestBody) => {
    var tenantIdFromToken = AuthenticationStateContainer.getTenantId();
    if (!tenantIdFromToken) {
      const token = AuthenticationStateContainer.getGroupMembershipToken();
      const tokenData = AuthenticationStateContainer.parseJwt(token);
      tenantIdFromToken = tokenData.tid;
    }
    const request = JSON.parse(requestBody);
    var tenantIds = Object.keys(request);
    var matchingIndex = tenantIds.filter(function (key) {
      return key.indexOf(tenantIdFromToken) !== -1;
    });
    if (matchingIndex.length !== 0) {
      var newObj = {
        groupIds: request[matchingIndex[0]],
      };
      return newObj;
    } else {
      return [];
    }
  },
  delay: (ms) => {
    return new Promise((res) => setTimeout(res, ms));
  },
  trimMessage: (message, length) => {
    return message?.length < length ? message : message?.slice(0, length - 1) + '...';
  },
  isNullOrUndefined(value) {
    if (value === undefined || value === null) {
      return true;
    }
    return false;
  },
  isNullOrUndefinedOrEmpty(value) {
    return this.isNullOrUndefined(value) || value?.length === 0;
  },
  isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  },
  getTimeDifferenceInDays(date1, date2) {
    let time1 = date1.getTime();
    let time2 = date2.getTime();
    let DifferenceInTime = time1 - time2;
    let differenceInDays = DifferenceInTime / (1000 * 3600 * 24);
    const daysRemaining = differenceInDays.toFixed();
    return Number(daysRemaining);
  },
  getTimeDifferenceInHours(date1, date2) {
    let time1 = date1.getTime();
    let time2 = date2.getTime();
    let DifferenceInTime = time1 - time2;
    let differenceInHours = DifferenceInTime / (1000 * 3600);
    const hoursRemaining = differenceInHours.toFixed();
    return Number(hoursRemaining);
  },
  getL03ButtonTextId(buttonTextKey) {
    switch (buttonTextKey) {
      case ApiType.L03ButtonTextType.GoToSetup:
        return 'l03Button.text.goToSetup';
      case ApiType.L03ButtonTextType.DeployFromSetupGuide:
        return 'l03Button.text.deployFromSetupGuide';
      case ApiType.L03ButtonTextType.TryItNow:
        return 'l03Button.text.tryItNow';
      case ApiType.L03ButtonTextType.RequestADemo:
        return 'l03Button.text.requestADemo';
      case ApiType.L03ButtonTextType.LearnMore:
        return 'l03Button.text.learnMore';
      default:
        return 'l03Button.text.goToSetup';
    }
  },
  getL03ButtonEvent(buttonTextKey) {
    switch (buttonTextKey) {
      case ApiType.L03ButtonTextType.GoToSetup:
        return telemetryConstants.events.GO_TO_SETUP_CLICKED;
      case ApiType.L03ButtonTextType.DeployFromSetupGuide:
        return telemetryConstants.events.DEPLOY_FROM_SETUP_GUIDE_CLICKED;
      case ApiType.L03ButtonTextType.TryItNow:
        return telemetryConstants.events.TRY_NOW_CLICKED;
      case ApiType.L03ButtonTextType.RequestADemo:
        return telemetryConstants.events.REQUEST_A_DEMO_CLICKED;
      case ApiType.L03ButtonTextType.LearnMore:
        return telemetryConstants.events.LEARN_MORE_CLICKED;
      default:
        return telemetryConstants.events.GO_TO_SETUP_CLICKED;
    }
  },
  getUniqueFlightPublisherIds(solution) {
    let publisherIds = [];
    solution?.l02s?.forEach((l02) => {
      l02.l03s?.forEach((l03) => {
        if (!this.isNullOrUndefinedOrEmpty(l03.flights)) {
          l03.flights.forEach((flight) => {
            publisherIds.push(flight.publisherId);
          });
        }
      });
    });
    return _.uniq(publisherIds);
  },
  extractGuids(inputString) {
    const regex = /([a-fA-F\d]{8}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{12})/g;
    const matches = inputString.match(regex);
    console.log('matches', matches);
    return matches ?? [];
  },
  filterUniqueL03s(L03sList) {
    let uniqueL03s = [];
    L03sList.forEach((l03) => {
      let index = uniqueL03s.findIndex((item) => item.rowKey === l03.rowKey || item.name === l03.name);
      if (index <= -1) {
        uniqueL03s.push(l03);
      }
    });
    return uniqueL03s;
  },
  getDataverseOCs(selectedOCs) {
    let selectedDataverseOptionalComponentType = selectedOCs.filter(
      (component) =>
        component.type === ApiType.PackageType.Data2 ||
        component.type === ApiType.PackageType.Data ||
        component.type === ApiType.PackageType.Solution
    );
    return selectedDataverseOptionalComponentType;
  },
  getArmOCs(selectedOCs) {
    let selectedArmOptionalComponentType = selectedOCs.filter(
      (component) =>
        component.type === ApiType.PackageType.ArmTemplate || component.type === ApiType.PackageType.ArmTemplateTenant
    );
    return selectedArmOptionalComponentType;
  },
  getPbiCiOCs(selectedOCs) {
    let selectedPbiCiOptionalComponentType = selectedOCs.filter(
      (component) => component.type === ApiType.PackageType.PBITemplateApp || component.type === ApiType.PackageType.CI
    );
    return selectedPbiCiOptionalComponentType;
  },

  productDependancyCheck(product, dependancyResponse) {
    if (dependancyResponse === undefined) return true;

    const dependencies = product.l03Dependencies.reduce((result, offer) => {
      if (offer.preOrPostDeploy === 'Pre') {
        result = result.concat(offer.rowKey);
      }
      return result;
    }, []);

    for (let dependency of dependencies) {
      let dependencyFound = false;
      for (let response of dependancyResponse.dependencyCheckFetchXMLResult) {
        if (response.l03DependencyId === dependency) {
          dependencyFound = true;
          if (!response.isConfigured || !response.isDeployed) {
            return false;
          }
        }
      }
      if (!dependencyFound) return false;
    }

    return true;
  },
  hasCustomRpResource(solution) {
    return solution?.packages?.some((pkg) => pkg.isACustomRpResource) ?? false;
  },
  convertToCamelCase(str) {
    return `${str.charAt(0).toLowerCase()}${str.slice(1)}`;
  },
  convertKeysToLowerCase(objectarray) {
    return objectarray.map((obj) => {
      let result = {};
      for (const [key, value] of Object.entries(obj)) {
        result[Utils.convertToCamelCase(key)] = value;
      }
      return result;
    });
  },
  hasSpecialCharacters(str) {
    const specialCharactersRegex = /[-!@#$%^&*(),.?":{}|<>[\]\\\/'`~;=_+|]/g;
    return specialCharactersRegex.test(str);
  },
  stringifyIfObject(value) {
    if (typeof value === 'object') {
      return JSON.stringify(value);
    }
    return value;
  },
};

export default Utils;
