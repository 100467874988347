import React, { FC, ReactElement } from 'react';
import { FontIcon } from '@fluentui/react';
import styles, { iconClass } from './statusBars.styles';
import { FormattedMessage } from 'react-intl';

type deploymentFailedStatusProps = {
  top: string;
  left: string;
};

export const DeploymentFailedStatus: FC<deploymentFailedStatusProps> = (
  props: deploymentFailedStatusProps
): ReactElement => {
  const { top, left } = props;

  return (
    <div style={{ top: top, left: left }} className={styles.deploymentFailedStyle}>
      <FontIcon iconName="Error" className={iconClass} />
      <span>
        <FormattedMessage id="status.deploymentFailed" />
      </span>
    </div>
  );
};
