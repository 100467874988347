import { IButtonStyles, mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  listViewOfferButtonTextStyles: {
    verticalAlign: 'super',
  },
});

export const listViewOfferButtonStyles: IButtonStyles = {
  root: {
    borderWidth: 0,
    marginLeft: '-3px',
    minWidth: '30px',
    height: '25px',
    cursor: 'pointer',
    padding: 0,
    selectors: {
      img: {
        height: '24px',
      },
      span: {
        width: '25px',
      },
    },
  },
};

export default styles;
