import { Dropdown, Stack, IconButton, TooltipHost, Text, Link } from '@fluentui/react';
import React, { FC, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { stackTokens, regionStyles, dropdownStyles } from '../../regionalStyling/index.styles';

export interface PartnerAppsRegionsProps {}

export const ParterAppsRegions: FC<PartnerAppsRegionsProps> = (props: PartnerAppsRegionsProps): ReactElement => {
  const intlShape = useIntl();

  const partnerAriaLabelText = intlShape
    .formatMessage(
      { id: 'region_Panel.accessibility.placeholder' },
      {
        regionName: intlShape.formatMessage({
          id: 'region_Panel.Partner_Dropdown.title',
        }),
      }
    )
    .toString();

  const onPartnerAppsOptionChange = async () => {};

  return (
    <div className={regionStyles.verticalSpacing}>
      <Stack tokens={stackTokens}>
        <div>
          <Text className={regionStyles.fontWeight400}>
            <FormattedMessage id="region_Panel.Partner_Dropdown.title" />
          </Text>
          <TooltipHost
            content={
              <FormattedMessage
                id="region_Panel.Partner_Dropdown.Tooltip"
                values={{
                  Link: (regionLink) => (
                    <Link
                      className={regionStyles.linkFontColour}
                      underline={true}
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {regionLink}
                    </Link>
                  ),
                }}
              />
            }
            calloutProps={{ gapSpace: 0 }}
          >
            <IconButton
              ariaLabel={intlShape.formatMessage({
                id: 'region_Panel.Partner_Dropdown.Tooltip',
              })}
              iconProps={{
                iconName: 'Info',
                className: regionStyles.infoIconBtn,
              }}
            />
          </TooltipHost>
        </div>
        <div className={regionStyles.dropdownSpacing}>
          <Dropdown
            options={[]}
            selectedKey={''}
            onChanged={onPartnerAppsOptionChange}
            errorMessage=""
            disabled
            placeholder={intlShape.formatMessage({
              id: 'region_Panel.Dropdowns.placeholder',
            })}
            styles={dropdownStyles}
            aria-label={partnerAriaLabelText}
            aria-live={'polite'}
            aria-atomic={true}
          />
        </div>
      </Stack>
    </div>
  );
};
