import React from 'react';
import { useMediaQuery } from 'react-responsive';

export enum ResponsiveMode {
  Desktop,
  Zoom,
}

export type ResponsiveModeProps = {
  responsiveMode: ResponsiveMode;
};

export const IsDesktopMode = () => useMediaQuery({ query: '(min-width: 961px)' });
export const IsZoomMode = () => useMediaQuery({ query: '(max-width: 960px)' }); // re-purposes mobile and tablet views

export const withResponsiveMode = (WrappedComponent: any) => {
  return (props: any) => {
    return (
      <WrappedComponent responsiveMode={IsDesktopMode() ? ResponsiveMode.Desktop : ResponsiveMode.Zoom} {...props} />
    );
  };
};
