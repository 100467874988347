import React, { FC } from 'react';
import { Text } from '@fluentui/react';
import { FormattedMessage } from 'react-intl';
import { MultiStepperPackageType } from '../../../../common/Enum';
import { StepText } from '../headerUtil';

export interface FabricHeaderProps {
  stepNumber: string;
  solutionNames: string[];
}

const FabricHeader: FC<FabricHeaderProps> = (props: FabricHeaderProps): JSX.Element => {
  return (
    <div>
      <StepText
        stepNumber={props.stepNumber}
        solutionNames={props.solutionNames}
        deploymentType={MultiStepperPackageType.Fabric}
      />
      <div>
        <Text>
          <FormattedMessage id="destination.introductionTitle.subtitle" />
        </Text>
      </div>
    </div>
  );
};

export default FabricHeader;
