import * as ApiType from '../solutionCenterApi/gen/index';

const currentSessionStorage = window.sessionStorage;

const DeploymentStateContainer = {
  setDeploymentDefinitions: (deploymentDefinitions: Array<ApiType.DeploymentDefinitionEntity>) => {
    currentSessionStorage.setItem('deploymentDefinitions', JSON.stringify(deploymentDefinitions));
  },
  getDeploymentDefinitions: () => {
    const solutionsJson = currentSessionStorage.getItem('deploymentDefinitions');
    if (solutionsJson) {
      return JSON.parse(solutionsJson);
    } else {
      return [];
    }
  },
  setDeploymentDetails: (deploymentDetails: ApiType.DeploymentDefinitionEntity, deploymentDetailsRowKey: string) => {
    currentSessionStorage.setItem(deploymentDetailsRowKey, JSON.stringify(deploymentDetails));
  },
  getDeploymentDetails: (deploymentDetailsRowKey: string) => {
    const solutionsJson = currentSessionStorage.getItem(deploymentDetailsRowKey);
    if (solutionsJson) {
      return JSON.parse(solutionsJson);
    } else {
      return undefined;
    }
  },
  setDeploymentName: (deploymentName: string) => {
    currentSessionStorage.setItem('deploymentName', deploymentName);
  },
  getDeploymentName: () => {
    const deploymentName: string | undefined = currentSessionStorage.getItem('deploymentName') ?? undefined;
    return deploymentName;
  },
  setOptionalComponents: (optionalComponents: ApiType.OptionalComponent[]) => {
    currentSessionStorage.setItem('optionalComponents', JSON.stringify(optionalComponents));
  },
  getOptionalComponents: () => {
    const optionalComponents: string | null = currentSessionStorage.getItem('optionalComponents');
    if (optionalComponents) {
      return JSON.parse(optionalComponents);
    } else {
      return [];
    }
  },
};

export default DeploymentStateContainer;
