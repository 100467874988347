import React from 'react';
import styles from './solutionIntegration.styles';

export default class SolutionIntegration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className={styles.container}>
        <iframe
          title={'Support Ticket'}
          className={styles.iframe}
          src="https://admin.powerplatform.microsoft.com/newsupportticket/MicrosoftCloudForHealthcare"
          width="100%"
          height={window.innerHeight}
        />
      </div>
    );
  }
}
