import { mergeStyleSets } from '@fluentui/react';

export const dropdownStyles = {
  dropdown: { width: 300 },
  title: {
    color: '#737271',
  },
};

export const spinnerStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
  },
};

export const iconClass = mergeStyleSets({
  fontSize: 12,
  margin: '5px',
});

export const environmentCreationResultStyles = mergeStyleSets({
  resultStack: {
    position: 'absolute',
    width: '80%',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    '@media(max-width: 1224px)': {
      top: '60%',
    },
    '@media (max-width: 768px)': {
      top: '85%',
    },
    '@media (max-width: 480px)': {
      position: 'absolute',
      width: '60%',
      left: '50%',
      top: '60%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  },
  iconStyle: {
    fontSize: 50,
    height: 50,
    width: 50,
    marginLeft: '40%',
    color: '#605E5C',
  },
  resultImg: {
    height: '60px',
    width: '60px',
    margin: 'auto',
    '@media (max-width: 960px)': {
      height: '40px',
      width: '40px',
      margin: 'auto',
    },
    '@media (max-width: 768px)': {
      height: '80px',
      width: '80px',
      margin: 'auto',
    },
  },
  resultText: {
    textAlign: 'center',
    fontSize: 'medium',
    '@media(max-width: 1224px)': {
      textAlign: 'center',
      fontSize: 'small',
    },
  },
  resultHeader: {
    textAlign: 'center',
    fontSize: 'large',
    lineHeight: '25px',
    fontWeight: 600,
    '@media(max-width: 1224px)': {
      fontSize: 'small',
      lineHeight: '20px',
    },
  },
  inProgressText: {
    color: '#605E5C',
    fontFamily: 'Segoe UI',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'center',
  },
  conflictErrorMessagesHeader: {
    textAlign: 'center',
    fontSize: 'large',
    lineHeight: '25px',
    fontWeight: 'bold',
    '@media(max-width: 1224px)': {
      fontSize: 'small',
      lineHeight: '20px',
    },
  },
});

export const infoIconStyles = {
  root: {
    backgroundColor: 'rgb(243, 242, 241)',
    fontSize: 'small',
    marginTop: '10px',
    padding: '0 10px 0 10px',
  },
};
export const currencyInfoStyles = { marginTop: '0px' };
export const primaryButtonStyles = { root: { marginRight: 8 } };
export const primaryButtonDoneStyle = { root: { margin: 30 } };
export const retryButtonStyles = { root: { marginRight: 10, marginTop: 30 } };
export const typeInfoIconStyles = { root: { marginBottom: -3 } };
export const panelHeaderTextStyles = { headerText: { fontSize: 'large' } };
export const linkStyle = { paddingBottom: '30px' };
export const hyperlinkStyle = { color: '#1d66ec', textDecoration: 'underline' };
