import {
  ERROR_VALIDATION_FABRIC_WORKSPACE_TYPE,
  CREATE_WORKSPCE,
  CREATE_WORKSPCE_PAYLOAD,
  WorkspaceDispatchTypes,
} from './workspaceType';

export const setErrorValidationFabricWorkspaceType = (
  errorValidationFabricWorkspaceType: boolean
): WorkspaceDispatchTypes => ({
  type: ERROR_VALIDATION_FABRIC_WORKSPACE_TYPE,
  payload: errorValidationFabricWorkspaceType,
});

export function setWorkspaceDetails(workspaceDetails: boolean): WorkspaceDispatchTypes {
  return {
    type: CREATE_WORKSPCE,
    payload: workspaceDetails,
  };
}
export function setWorkspacePayload(workspacePayload: object): WorkspaceDispatchTypes {
  return {
    type: CREATE_WORKSPCE_PAYLOAD,
    payload: workspacePayload,
  };
}
