import { mergeStyleSets } from '@fluentui/react';

const fabricStyles = mergeStyleSets({
  Title: {
    fontFamily: 'Segoe UI',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'left',
  },
  subTitle: {
    fontFamily: 'Segoe UI',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '30px',
    textAlign: 'left',
  },
});

export default fabricStyles;
