import { IButtonStyles, mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  container: {
    display: 'inline-block',
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    padding: '16px 16px 0 16px',
    border: '2px solid rgba(0,0,0,0)',
    verticalAlign: 'top',
    boxSizing: 'border-box',
    backgroundColor: '#FFFFFF',
    '@media(max-width: 480px)': {
      maxWidth: '100%',
      width: '235px',
    },
  },
  checkedContainer: {
    display: 'inline-block',
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    padding: '16px 16px 0 16px',
    border: '2px solid #0078D4',
    verticalAlign: 'top',
    boxSizing: 'border-box',
    backgroundColor: '#FFFFFF',
    '@media(max-width: 480px)': {
      maxWidth: '100%',
      width: '235px',
    },
  },
  headerImg: {
    display: 'inline-block',
    width: '36px',
    height: '36px',
  },
  headerTextContainer: {
    display: 'inline-block',
    width: 'calc(100% - 36px)',
    height: '36px',
    verticalAlign: 'top',
    fontSize: '16px',
    fontWeight: '600',
    margin: 0,
    boxSizing: 'border-box',
    paddingLeft: '8px',
    marginBottom: '15px',
  },
  availabilityContainer: {
    background: 'black',
    borderRadius: '5px',
    paddingLeft: '5px',
    paddingBottom: '1px',
    width: '90px',
    height: '16px',
    justifyContent: 'center',
    marginBottom: '5px',
  },
  availabilitytitle: {
    color: 'white',
    marginBlockStart: '1px',
  },
  descriptionContainer: {
    fontWeight: '12px',
    lineHeight: '18px',
    height: '100px',
    maxHeight: '100px',
    color: '#323130',
    display: '-webkit-box',
    maxWidth: '100%',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '6',
    'margin-bottom': '20px',
    overflow: 'auto',
  },
  footer: {
    boxSizing: 'border-box',
    height: '45px',
  },
  footerLeftContainer: {
    display: 'inline-block',
    boxSizing: 'border-box',
    width: '49%',
  },
  footerRightContainer: {
    display: 'inline-block',
    width: '50%',
    boxSizing: 'border-box',
    textAlign: 'right',
  },
  footerRightContainerInner: {
    display: 'inline-block',
  },
});

export const offerButtonStyles: IButtonStyles = {
  label: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    cursor: 'pointer',
  },
};

export default styles;
