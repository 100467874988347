import * as React from 'react';
import { ReactElement, FC } from 'react';
import styles from './noSupportMessageView.styles';
import { FormattedMessage } from 'react-intl';
import htmlParse from 'html-react-parser';
export interface NoSupportMessageViewProps {
  l03NoSupportMsg: string;
}

export const NoSupportMessageView: FC<NoSupportMessageViewProps> = (props: NoSupportMessageViewProps): ReactElement => {
  const { l03NoSupportMsg } = props;

  return (
    <>
      <div className={styles.noSupportModalContainer}>
        <div>
          <div className={styles.noSupportMessageDetails}>
            {!l03NoSupportMsg ? <FormattedMessage id="globalSupport_Page_Message" /> : htmlParse(l03NoSupportMsg)}
          </div>
        </div>
      </div>
    </>
  );
};
