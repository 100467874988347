import * as React from 'react';
import { Text } from '@fluentui/react';
import styles from './statusIcon.styles';

/**
 * success, warning or error as props containing message.
 */
const StatusIcon = ({
  error,
  warning,
  success,
  containerClassName,
  labelClassName,
}: {
  error?: string;
  warning?: string;
  success?: string;
  containerClassName?: string;
  labelClassName?: string;
}) => {
  const renderError = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" width="32" height="32">
      <path d="M960 0q132 0 255 34t229 97 194 150 150 194 97 230 35 255q0 132-34 255t-97 229-150 194-194 150-230 97-255 35q-132 0-255-34t-229-97-194-150-150-194-97-229T0 960q0-132 34-255t97-229 150-194 194-150 229-97T960 0zm0 1792q115 0 221-29t199-84 168-130 130-168 84-199 30-222q0-115-29-221t-84-199-130-168-168-130-199-84-222-30q-115 0-221 29t-199 84-168 130-130 168-84 199-30 222q0 115 29 221t84 199 130 168 168 130 199 84 222 30zM384 896h1152v128H384V896z" />
    </svg>
  );
  const renderSuccess = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" width="32" height="32">
      <path d="M1491 595l90 90-749 749-365-365 90-90 275 275 659-659zM1024 0q141 0 272 36t245 103 207 160 160 208 103 245 37 272q0 141-36 272t-103 245-160 207-208 160-245 103-272 37q-141 0-272-36t-245-103-207-160-160-208-103-244-37-273q0-141 36-272t103-245 160-207 208-160T751 37t273-37zm0 1920q123 0 237-32t214-90 182-141 140-181 91-214 32-238q0-123-32-237t-90-214-141-182-181-140-214-91-238-32q-123 0-237 32t-214 90-182 141-140 181-91 214-32 238q0 123 32 237t90 214 141 182 181 140 214 91 238 32z" />
    </svg>
  );

  const renderWarning = () => (
    <svg width="32" height="32" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.60631 1.14099C7.29949 0.757048 8.16484 0.980143 8.59006 1.63448L8.64526 1.72637L13.8179 11.0652C13.9411 11.2876 14.0057 11.5377 14.0057 11.792C14.0057 12.5859 13.389 13.2358 12.6084 13.2885L12.5057 13.292H2.1619C1.90769 13.292 1.65765 13.2274 1.43526 13.1042C0.742037 12.7204 0.47201 11.8685 0.801046 11.1609L0.849645 11.0654L6.02084 1.72652C6.15719 1.48028 6.36008 1.27737 6.60631 1.14099ZM12.9431 11.5497L7.77048 2.21089C7.63668 1.96933 7.33239 1.88197 7.09083 2.01577C7.02927 2.04986 6.97584 2.09643 6.9338 2.15221L6.89568 2.21094L1.72448 11.5498C1.59071 11.7914 1.67811 12.0956 1.91969 12.2294C1.97528 12.2602 2.03606 12.28 2.09873 12.288L2.1619 12.292H12.5057C12.7819 12.292 13.0057 12.0681 13.0057 11.792C13.0057 11.7284 12.9936 11.6656 12.9702 11.6069L12.9431 11.5497L7.77048 2.21089L12.9431 11.5497ZM7.33368 9.95407C7.70137 9.95407 7.99945 10.2521 7.99945 10.6198C7.99945 10.9875 7.70137 11.2856 7.33368 11.2856C6.96598 11.2856 6.6679 10.9875 6.6679 10.6198C6.6679 10.2521 6.96598 9.95407 7.33368 9.95407ZM7.33088 4.95258C7.58401 4.95238 7.79335 5.14031 7.82666 5.38434L7.83128 5.45218L7.83368 8.45324C7.8339 8.72939 7.61022 8.95342 7.33408 8.95364C7.08095 8.95385 6.8716 8.76591 6.83829 8.52189L6.83368 8.45404L6.83128 5.45298C6.83106 5.17684 7.05473 4.9528 7.33088 4.95258Z"
        fill="#797775"
      />
    </svg>
  );

  const handleRender = () => {
    if (error) {
      return (
        <div className={`${styles.errorSvgStyle} ${containerClassName}`}>
          <div className={styles.inner}>
            {renderError()}
            <Text className={`${styles.errorLabelStyle} ${labelClassName}`}>{error}</Text>
          </div>
        </div>
      );
    }
    if (warning) {
      return (
        <div className={`${styles.warningSvgStyle} ${containerClassName}`}>
          <div className={styles.inner}>
            {renderWarning()}
            <Text className={`${styles.warningLabelStyle} ${labelClassName}`}>{warning}</Text>
          </div>
        </div>
      );
    }
    if (success) {
      return (
        <div className={`${styles.successSvgStyle} ${containerClassName}`}>
          <div className={styles.inner}>
            {renderSuccess()}
            <Text className={`${styles.successLabelStyle} ${labelClassName}`}>{success}</Text>
          </div>
        </div>
      );
    }
  };

  return <>{handleRender()}</>;
};

export default StatusIcon;
