import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Icon } from '@fluentui/react/lib/Icon';
import { getTheme } from '@fluentui/react/lib/Styling';
import verticalStyle from './wizard-step.styles';
import { StepStatus } from './stepStatus';
import { FormattedMessage } from 'react-intl';

export default class WizardStep extends React.Component {
  render() {
    const getTextClassName = (currentStepText) => {
      const { isLast, allSuccess, isIntermediateStep, isDisabled } = this.props;
      let className = isLast && allSuccess ? 'textTouched' : currentStepText;
      if (isIntermediateStep) {
        className += 'Small';

        if (isDisabled) {
          className += 'Disabled';
        }
      }
      return verticalStyle[String(className)];
    };
    const { description, isLast, status, allSuccess, isIntermediateStep, isNextIntermediate, isDisabled } = this.props;
    let shouldSetBlue = false;
    let shouldSetRed = false;
    let allCompleted = false;
    let currentStepStatus;
    let currentStepText;

    switch (status) {
      case StepStatus.InProgress: {
        currentStepStatus = 'CircleFill';
        currentStepText = 'textTouched';
        shouldSetBlue = true;
        break;
      }
      case StepStatus.Empty: {
        currentStepStatus = 'CircleRing';
        currentStepText = 'text';
        break;
      }
      case StepStatus.Finished: {
        currentStepStatus = 'SkypeCircleCheck';
        currentStepText = 'text';
        shouldSetBlue = true;
        break;
      }
      case StepStatus.Error: {
        currentStepStatus = 'StatusErrorFull';
        currentStepText = 'text';
        shouldSetRed = true;
        break;
      }
      default:
        break;
    }

    if (allSuccess) {
      currentStepStatus = 'SkypeCircleCheck';
      allCompleted = true;
    }

    return (
      <div className={verticalStyle.container}>
        <div className={verticalStyle.separatorWithDot}>
          <Icon
            className={[
              verticalStyle.defaultDot,
              shouldSetBlue ? verticalStyle.dotTouched : shouldSetRed ? verticalStyle.error : {},
              allSuccess ? verticalStyle.dotSuccess : {},
              isIntermediateStep ? verticalStyle.smallDot : {},
            ]}
            iconName={currentStepStatus}
          />
          <Stack.Item
            className={isLast ? null : isNextIntermediate ? verticalStyle.rootIntermediate : verticalStyle.root}
            theme={getTheme()}
          >
            <Separator
              className={[
                verticalStyle.defaultSeparator,
                shouldSetBlue
                  ? allCompleted
                    ? verticalStyle.success
                    : verticalStyle.separatorTouched
                  : verticalStyle.separator,
              ]}
              vertical
              alignContent="start"
              styles={{
                content: {
                  paddingTop: '2px',
                  paddingBottom: '2px',
                },
              }}
            />
          </Stack.Item>
        </div>
        <div className={verticalStyle.labelContainer}>
          <Text className={getTextClassName(currentStepText)}>
            {description}
            {isDisabled ? <FormattedMessage id="installStepper.disabled" values={{ linebreak: <br /> }} /> : ''}
          </Text>
        </div>
      </div>
    );
  }
}
