import { mergeStyleSets } from '@fluentui/react';

const style = mergeStyleSets({
  navMenuSection: {
    '@media(max-width: 960px)': {
      height: '20px',
    },
    '@media screen and (-ms-high-contrast: active)': {
      selectors: {
        'button.is-checked span': {
          backgroundColor: 'highlight',
        },
      },
    },
  },
  content: {
    '@media(max-width: 960px)': {
      backgroundColor: 'rgba(0,0,0,0.5)',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      zIndex: '10',
      height: '100%',
    },
    '@media(max-width: 480px)': {
      width: '100%',
    },
  },
});

export default style;
