import { AuthenticationDispatchTypes, AZURE_TOKEN, PBI_TOKEN } from './authenticationTypes';
import * as Type from '../../common/Type';

interface DefaultStateI {
  azureToken: string;
  pbiToken: Type.TokenResponse | string;
}

const initialState: DefaultStateI = {
  azureToken: '',
  pbiToken: '',
};

const authenticationReducer = (
  state: DefaultStateI = initialState,
  action: AuthenticationDispatchTypes
): DefaultStateI => {
  switch (action.type) {
    case AZURE_TOKEN:
      return {
        ...state,
        azureToken: action.payload,
      };
    case PBI_TOKEN:
      return {
        ...state,
        pbiToken: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default authenticationReducer;
