import React, { FC, ReactElement, useEffect } from 'react';
import { PrimaryButton } from '@fluentui/react';
import StatusIcon from '../../../../common/status-icon';
import styles from './configurationDependencyCard.styles';
import { useIntl } from 'react-intl';
import * as Type from '../../../../common/Type';
import Utils from '../../../../utils';
import { RecordCheck, TableCheck } from '../../../../common/Type';

interface ConfigurationDependencyCardProps {
  dependency: Type.DependencyChecked;
  env: Type.Environment;
}

const ConfigurationDependencyCard: FC<ConfigurationDependencyCardProps> = (
  props: ConfigurationDependencyCardProps
): ReactElement => {
  const { dependency, env } = props;
  const intlShape = useIntl();
  const { isConfigured, isDeployed } = dependency;

  const handleRedirectClick = (link: string) => {
    window.open(link, '_blank');
  };

  const [recordCheckDocLink, setDeployCheckDocLink] = React.useState<string>(undefined);
  const [tableCheckDocLink, setTableCheckDocLink] = React.useState<string>(undefined);

  useEffect(() => {
    let recordCheck: RecordCheck = !Utils.isNullOrUndefinedOrEmpty(dependency?.recordCheck)
      ? JSON.parse(dependency?.recordCheck)
      : undefined;
    let tableCheck: TableCheck = !Utils.isNullOrUndefinedOrEmpty(dependency?.tableCheck)
      ? JSON.parse(dependency?.tableCheck)
      : undefined;

    setDeployCheckDocLink(recordCheck?.DocLink);
    setTableCheckDocLink(tableCheck?.DocLink);
  }, []);

  return (
    <>
      <div className={styles.outerContainer}>
        <div className={styles.header}>
          <img
            src={Utils.getIconSvg(dependency.icon)}
            className={styles.headerImg}
            alt={intlShape.formatMessage({ id: 'altText.dependencies.icon' }, { dependencyName: dependency.name })}
          />
          <div className={styles.headerDependency}>{dependency.name}</div>
        </div>
        <div className={styles.main}>
          <div className={styles.mainH3}>
            {intlShape.formatMessage({
              id: 'configuration.environment',
            })}
            :
          </div>
          <p className={styles.mainP}>{env?.friendlyName ? env?.friendlyName : env?.uniqueName}</p>
        </div>
        <div className={styles.footer}>
          {!isDeployed ? (
            <div className={styles.actionContainerDeployment}>
              <StatusIcon
                error={intlShape.formatMessage({
                  id: 'configuration.needsToBeInstalled',
                })}
              />
              <PrimaryButton
                className={styles.actionContainerButtonsStyles}
                text={intlShape.formatMessage({
                  id: 'configuration.install',
                })}
                aria-label={dependency.name + ' install'}
                onClick={() => handleRedirectClick(tableCheckDocLink)}
              />
            </div>
          ) : (
            <StatusIcon
              success={intlShape.formatMessage({
                id: 'configuration.installed',
              })}
            />
          )}
          <hr className={styles.hrStyle} />
          {!isConfigured ? (
            <div className={styles.footerFlex}>
              <StatusIcon
                error={intlShape.formatMessage({
                  id: 'configuration.needsToBeConfigured',
                })}
                containerClassName={styles.actionContainerButtonsStyles}
              />
              <PrimaryButton
                disabled={!isDeployed}
                className={styles.actionContainerButtonsStyles}
                text={intlShape.formatMessage({
                  id: 'configuration.configure',
                })}
                aria-label={dependency.name + ' configure'}
                onClick={() => handleRedirectClick(recordCheckDocLink)}
              />
            </div>
          ) : (
            <StatusIcon
              success={intlShape.formatMessage({
                id: 'configuration.configured',
              })}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ConfigurationDependencyCard;
