import React from 'react';
import { Dialog, DialogType } from '@fluentui/react';
import SanitizedHtml from '../../../../common/sanitized-html';
import styles from './termsDialog.styles';
import { FormattedMessage } from 'react-intl';

const TermsDialog = ({ hidden, handleDialog, termsHtml }) => {
  return (
    <Dialog
      hidden={hidden}
      onDismiss={handleDialog}
      maxWidth="90%"
      dialogContentProps={{
        type: DialogType.normal,
        title: (
          <h1 className={styles.dialogContentPropsText}>
            <FormattedMessage id="destination.termsTitle" />
          </h1>
        ),
        closeButtonAriaLabel: 'Close',
      }}
      modalProps={{
        className: styles.dialogContent,
        isBlocking: true,
        topOffsetFixed: false,
        containerClassName: 'destination-terms-container',
      }}
    >
      <SanitizedHtml html={termsHtml} />
    </Dialog>
  );
};

export default TermsDialog;
