import { mergeStyleSets } from '@fluentui/react';
import { segoeRegular } from '../../../common/Constants';

const listViewColumnCommonStyle = {
  cursor: 'pointer',
  selectors: {
    span: {
      color: 'rgb(50, 49, 48)',
    },
  },
};

const styles = mergeStyleSets({
  title: {
    fontSize: '16px',
    fontWeight: '700',
    margin: '0',
    padding: '0',
  },
  description: {
    width: '900px',
    margin: '0',
    paddingTop: '9px',
    '@media(max-width: 1300px)': {
      width: '700px',
    },
    '@media(max-width: 925px)': {
      width: '375px',
    },
    '@media(max-width: 480px)': {
      width: '232px',
      whiteSpace: 'pre-wrap',
    },
  },
  checkAllContainer: {
    paddingTop: '16px',
  },
  l03sContainer: {
    paddingTop: '22px',
    marginRight: '-20px',
    display: 'flex',
    flexFlow: 'wrap',
  },
  noSolutionsContainer: {
    paddingTop: '22px',
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '50vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noSolutionsText: {
    fontSize: '18px',
    color: '#323130',
    fontFamily: segoeRegular,
    fontWeight: '600',
    lineHeight: '24px',
    textAlign: 'center',
    height: '24px',
    '@media(max-width: 1300px)': {
      width: '700px',
    },
    '@media(max-width: 925px)': {
      width: '375px',
    },
    '@media(max-width: 480px)': {
      width: '232px',
    },
  },
  noSolutionsFooterText: {
    fontSize: '14px',
    color: '#605E5C',
    fontFamily: segoeRegular,
    fontWeight: '600',
    lineHeight: '20px',
    height: '20px',
    textAlign: 'center',
    '@media(max-width: 1300px)': {
      width: '700px',
    },
    '@media(max-width: 925px)': {
      width: '375px',
    },
    '@media(max-width: 480px)': {
      width: '232px',
    },
  },
  imageContainer: {
    height: '236px',
    textAlign: 'center',
    paddingBottom: '50px',
    '@media(max-width: 1300px)': {
      width: '700px',
    },
    '@media(max-width: 925px)': {
      width: '375px',
    },
    '@media(max-width: 480px)': {
      width: '280px',
    },
  },
  image: {
    marginTop: '61px',
    marginBottom: '30px',
    textAlign: 'center',
    '@media(max-width: 1300px)': {
      width: '700px',
    },
    '@media(max-width: 925px)': {
      width: '375px',
    },
    '@media(max-width: 480px)': {
      width: '280px',
    },
  },
  listViewColumnSolutions: {
    selectors: {
      img: {
        height: 16,
        width: 16,
        marginRight: 8,
        display: 'inline',
      },
      span: {
        color: '#323130',
      },
    },
  },
  listViewColumnBusinessNeed: {
    ...listViewColumnCommonStyle,
    color: 'rgb(50, 49, 48)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  listViewColumnReleaseType: {
    selectors: {
      span: {
        color: 'rgb(50, 49, 48)',
      },
    },
  },
  listViewColumnRequirements: {
    ...listViewColumnCommonStyle,
    selectors: {
      img: {
        height: 16,
        width: 16,
        marginRight: 8,
        display: 'inline',
      },
      span: {
        color: 'rgb(50, 49, 48)',
      },
    },
  },
  listViewColumnActions: {
    selectors: {
      span: {
        color: 'rgb(50, 49, 48)',
      },
    },
  },
});

export default styles;
