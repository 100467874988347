import React, { FC, ReactElement } from 'react';
import { ErrorIcon } from '../error-icon/error-icon';
import styles from './statusBars.styles';
import { FormattedMessage, useIntl } from 'react-intl';
import './../../custom.css';
import { DATA_TEST_IDS } from '../../../src/common/Constants';

export interface RegionUnavailableStatusBarProps {}

export const RegionUnavailableStatusBar: FC<RegionUnavailableStatusBarProps> = (
  props: RegionUnavailableStatusBarProps
): ReactElement => {
  const intlShape = useIntl();
  const [isRegionPanelOpen, setIsRegionPanelOpen] = React.useState<boolean>(false);

  return (
    <div className={styles.infoContainer} id={DATA_TEST_IDS.regionUnavailableStatusBar}>
      <span className={styles.errorIconContainer}>
        <ErrorIcon />
      </span>
      <span className={styles.errorText}>
        <span
          className={styles.errorLicenseText}
          title={intlShape.formatMessage({
            id: 'regionAvailability.unavailable.message',
          })}
        >
          <FormattedMessage id="regionAvailability.unavailable.message" />
        </span>{' '}
        <button
          type="button"
          onClick={() => setIsRegionPanelOpen(!isRegionPanelOpen)}
          className="btnLikeAnchor"
          title={intlShape.formatMessage({
            id: 'regionAvailability.seeRegions',
          })}
        >
          <FormattedMessage id="regionAvailability.seeRegions" />
        </button>
      </span>
    </div>
  );
};
