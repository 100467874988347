import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { segoeRegular } from '../../common/Constants';
export const errorPageStyles = mergeStyleSets({
  imgPositionSettings: {
    marginTop: '10%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  imgLogoCss: {
    width: '200px',
    height: '200px',
    '@media(max-width: 960px)': {
      marginLeft: '10px',
    },
    '@media(max-width: 480px)': {
      width: '100px',
      height: '100px',
    },
  },
  microsoftLogoCss: {
    marginLeft: '150px',
    top: '2px',
    '@media(max-width: 960px)': {
      marginLeft: '10px',
    },
    '@media(max-width: 480px)': {
      marginLeft: '10px',
      width: '90px',
      height: '30px',
    },
  },
  learnMore: {
    marginTop: '20px',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '400',
    color: '#605E5C',
    fontSize: '14px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    '@media(max-width: 480px)': {
      fontSize: '6px',
    },
  },
  errorMessage: {
    maxWidth: '1200px',
  },
  errorPageText: {
    marginTop: '20px',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '400',
    color: '#605E5C',
    fontSize: '14px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    '@media(max-width: 480px)': {
      fontSize: '6px',
    },
  },
  errorPageTitle: {
    marginTop: '2%',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '32px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    color: '#323130',
    '@media(max-width: 480px)': {
      fontSize: '6px',
    },
  },
  supportedRegionText: {
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '450px',
    alignItems: 'center',
    '@media(max-width: 960px)': {
      fontSize: '7px',
      marginLeft: '260px',
    },
    '@media(max-width: 480px)': {
      fontSize: '7px',
      marginLeft: '90px',
    },
  },
  rowContainer: {
    width: '100%',
    margin: 'auto',
    borderTop: '1px solid #C8C8C8',
    '@media (max-width: 980px)': {
      width: '100%',
    },
    '@media (max-width: 480px)': {
      width: '100%',
    },
  },
  marginTop: {
    marginTop: '10px',
    '@media(max-width: 960px)': {
      marginTop: '10px',
      fontSize: '10px',
    },
    '@media(max-width: 480px)': {
      fontSize: '8px',
      lineHeight: '20px',
      marginTop: '10px',
    },
  },
  centerAlign: {
    width: '100%',
    margin: 'auto',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backBtnCss: {
    '@media(max-width: 960px)': {
      marginTop: '10px',
      fontSize: '10px',
    },
    '@media(max-width: 480px)': {
      fontSize: '4px',
      height: '15px',
      width: '20px',
      minWidth: '60px',
    },
  },
  faqLinkCss: {
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#0072C9',
  },
  tryBtnCss: {
    marginTop: '25px',
    height: '20px',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    '@media (max-width: 480px)': {
      marginTop: '2px',
      height: '10px',
    },
  },
});
