import React from 'react';
import Products from '../../pages/products/products';
import Utils from '../../utils';
import { telemetry } from '../../services/telemetryService';
import { telemetryConstants } from '../../config';

export interface Filters {
  businessNeeds: string[];
  availability: string[];
  requirements: string[];
  solutionType: string[];
  searchTerm: string;
}

const DataInjectedProducts = (props) => {
  const { queryParams, source, l01 } = props;

  const businessNeeds = queryParams.getAll(telemetryConstants.queryParameterKeys.BUSINESS_NEEDS);
  const availability = queryParams.getAll(telemetryConstants.queryParameterKeys.AVAILABILITY);
  const requirements = queryParams.getAll(telemetryConstants.queryParameterKeys.REQUIREMENTS);
  const solutionType = queryParams.getAll(telemetryConstants.queryParameterKeys.SOLUTION_TYPE);
  const searchTerm = queryParams.get(telemetryConstants.queryParameterKeys.SEARCH);
  const filters: Filters = { businessNeeds, availability, requirements, solutionType, searchTerm };

  if (!Utils.isNullOrUndefinedOrEmpty(source)) {
    const eventData = { source, l01, filterData: JSON.stringify(filters) };
    telemetry.logEvents(telemetryConstants.deepLinkEvents.PRODUCT_PAGE_VISIT_DEEPLINK, eventData);
  }
  return <Products {...props} filters={filters} />;
};

export default DataInjectedProducts;
