import { IStyleFunctionOrObject, ITeachingBubbleStyleProps, ITeachingBubbleStyles } from '@fluentui/react';

const styles: IStyleFunctionOrObject<ITeachingBubbleStyleProps, ITeachingBubbleStyles> = {
  root: {
    '@media(max-width: 480px)': {
      top: '3.5px!important',
      left: '44.317px',
      width: '240px',
      height: '124px',
    },
  },
  headline: {
    fontSize: '20px!important',
    'margin-bottom': '15px',
    '@media(max-width: 480px)': {
      fontSize: '14px!important',
      'margin-bottom': '2px',
    },
  },

  subText: {
    fontSize: '16px!important',
    '@media(max-width: 480px)': {
      fontSize: '7px!important',
    },
  },

  body: {
    '@media(max-width: 480px)': {
      'margin-bottom': '15px!important',
    },
  },

  bodyContent: {
    '@media(max-width: 480px)': {
      'padding-left': '10px',
      'padding-right': '13px',
      'padding-top': '7px',
      'padding-bottom': '6px',
    },
  },

  primaryButton: {
    'font-size': '15px',
    width: '46px',
    height: '25px',
    '@media(max-width: 480px)': {
      width: '34px',
      height: '15px',
      padding: '10px 12px',
    },
  },
};

export default styles;
