import React, { FC, ReactElement } from 'react';
import { FontIcon } from '@fluentui/react';
import styles, { iconClass } from './statusBars.styles';
import { FormattedMessage } from 'react-intl';

type earlyAccessAvailableStatusProps = {
  top: string;
  left: string;
};

export const EarlyAccessAvailableStatus: FC<earlyAccessAvailableStatusProps> = (
  props: earlyAccessAvailableStatusProps
): ReactElement => {
  const { top, left } = props;

  return (
    <div style={{ top: top, left: left }} className={styles.earlyAccessAvailableStyle}>
      <FontIcon iconName="Ticket" className={iconClass} />
      <span>
        <FormattedMessage id="status.earlyAccessAvailable" />
      </span>
    </div>
  );
};
