import { IIconStyles, IStackStyles, IStackTokens, ITextFieldStyles, mergeStyleSets } from '@fluentui/react';
import { segoeRegular } from '../../../common/Constants';

const styles = mergeStyleSets({
  destinationContainer: {
    padding: '45px',
    borderLeft: '1px solid #E1DFDD',
    '@media(max-width: 895px)': {
      width: 400,
    },
    '@media(max-width: 480px)': {
      width: 245,
      padding: 32,
    },
    '@media(max-width: 330px)': {
      width: 200,
      padding: 5,
    },
  },
  destinationTitleContainer: {
    marginBottom: '15px',
  },
  destinationSubTitleContainer: {
    verticalAlign: 'top',
    display: 'inline-block',
    textAlign: 'left',
    width: 'calc(50% - 2px)',
  },
  destinationSeparator: {
    background: '#EDEBE9',
    height: '1px',
    marginTop: '32px',
    marginBottom: '32px',
  },
  destinationFooter: {
    marginBottom: '25%',
    display: 'inline-block',
  },
  destinationTitleStyles: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600,
    fontFamily: segoeRegular,
  },
  destinationSubTitleStyles: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600,
    fontFamily: segoeRegular,
  },
  headerSpacing: {
    marginTop: '30px !important',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
    '@media(max-width: 330px)': {
      'flex-direction': 'column',
    },
  },
  fabricContainer: {
    marginTop: '10px',
  },
  headerText: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    marginTop: '10px',
  },
  instanceSeparator: {
    background: '#EDEBE9',
    height: '1px',
    marginTop: '32px',
  },
  destinationTermSectionTitle: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 500,
  },
  destinationTermSectionButton: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '5px',
    marginBottom: '8px',
  },
  destinationTermSectionContainer: {
    fontSize: '16px',
    lineHeight: '22px',
    fontFamily: segoeRegular,
  },
  iconSize: {
    width: '72px',
    height: '30px',
    marginRight: '5px',
    marginLeft: '8px',
    marginTop: '6px',
    marginBottom: '10px',
  },
  iconContainer: {
    marginLeft: '20%',
    display: 'inline-block',
  },
  iconText: {
    color: '#0078D4 !important',
  },
  descriptionText: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#323130',
  },
  destinationPageFooterLeftActionSection: {
    width: 'calc(50% - 2px)',
    textAlign: 'left',
    display: 'inline-block',
    zIndex: 1,
  },
  destinationPageFooterRightActionSection: {
    textAlign: 'right',
    display: 'inline-block',
    marginLeft: '10px',
    '@media(max-width: 960px)': {
      textAlign: 'left',
    },
  },
  destinationSolutionsContainer: {
    float: 'left',
    marginTop: '10px',
  },
  destinationSolutionsName: {
    paddingTop: '10px',
  },
  destinationDependenciesLeft: {
    float: 'left',
    marginTop: '10px',
  },
  destinationDependenciesRight: {
    paddingTop: '10px',
  },
  destinationTermSection: {
    marginTop: '10px',
    '@media(max-width: 960px)': {
      width: '400px',
    },
  },
  destinationTermSectionLeft: {
    float: 'left',
    marginRight: '8px',
    '@media(max-width: 480px)': {
      width: '200px',
    },
  },
  toolTipIconSize: {
    padding: '0px 10px 0px 8px',
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  createNewContainer: {
    marginTop: '8px',
  },
  mediumSeparaterContainer: {
    marginTop: '24px',
  },
  iconBtnMarTop: {
    marginTop: '20px !important',
  },
  infoIconBtn: {
    color: 'black !important',
  },
  linkFontColour: {
    color: '#0078D4 !important',
  },
  refreshIconDiv: {
    display: 'inline-flex',
    height: '38px',
  },
  marginUnset: {
    margin: 'unset',
  },
  marginDiv: {
    marginTop: '39px',
  },
  backButtonStyle: {
    marginRight: '8px',
  },
  messageBarStyling: {
    '@media(max-width: 480px)': {
      width: '100%',
      whiteSpace: 'pre-wrap',
    },
  },
  errorMessageBarStyling: {
    width: 520,
    '@media(max-width: 480px)': {
      width: '280px',
      whiteSpace: 'pre-wrap',
    },
  },
  flightItem: {
    marginTop: '10px',
  },
  flightLink: {
    marginLeft: '5px',
  },
  footerContainer: {
    width: 'calc(100%)',
    position: 'fixed',
    paddingTop: '16px',
    paddingLeft: '16px',
    paddingBottom: '16px',
    bottom: 0,
    background: '#ffff',
    zIndex: 1,
    '@media(max-width: 960px)': {
      left: 0,
    },
  },
  formattedMessageStyle: {
    paddingRight: '10px',
    '@media(max-width: 890px)': {
      paddingRight: '140px',
    },
    '@media(max-width: 480px)': {
      paddingRight: '70px',
    },
  },
  formattedMessageLinkStyle: {
    color: '#1f52f9',
    paddingRight: '5px',
    '@media(max-width: 890px)': {
      paddingRight: '70px',
    },
    '@media(max-width: 480px)': {
      paddingRight: '40px',
    },
  },
});

export const verticalStackStyles: IStackStyles = {
  root: {
    overflow: 'hidden',
    '@media(max-width: 925px)': {
      width: 400,
    },
  },
};

export const refreshButtonContainerStyle: IStackStyles = {
  root: {
    maxWidth: '12px',
    paddingTop: '75px',
  },
};

export const refreshCIButtonContainerStyle: IStackStyles = {
  root: {
    maxWidth: '40px',
    paddingBottom: '138px',
    '@media(max-width: 925px)': {
      paddingBottom: '158px',
    },
  },
};

export const stackTokens: IStackTokens = { childrenGap: 39 };

export const dropdownStyles = {
  dropdown: {
    width: 520,
    color: '#605E5C!important',
    marginTop: '5px',
    '@media(max-width: 945px)': {
      width: 375,
    },
    '@media(max-width: 480px)': {
      width: 240,
    },
  },
  title: {
    color: '#605E5C',
    border: '1px solid #605E5C',
  },
  caretDown: {
    color: '#605E5C',
  },
};

export const textfieldStyles: Partial<ITextFieldStyles> = {
  root: {
    width: 520,
    marginTop: '5px',
    '@media(max-width: 945px)': {
      width: 285,
    },
    '@media(max-width: 480px)': {
      width: 200,
    },
  },
};

export const previewStackTokens: IStackTokens = {
  childrenGap: 10,
};

export const infoButtonStyle: IIconStyles = {
  root: {
    color: '#0078D4',
  },
};

export default styles;
