import React, { FC, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { dropdownStyles, stackTokens } from '../azComponents/customComponentControls/dropdownControl.styles';
import CustomRenderLabel from '../azComponents/customComponentControls/customLabelRenderer';
import { IAzureLocation, IAzureSubscription } from '../models/azureResource.Models';
import { RootStore } from '../../../../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { setLocation } from '../../../../../redux/azureInstance/azureInstanceActions';
import { AzureManagementService } from '../../../../../services/azureManagementService';

interface LocationsProps {}
const Locations: FC<LocationsProps> = (props: LocationsProps): JSX.Element => {
  const intlShape = useIntl();
  const dispatch = useDispatch();
  const [locationsList, setLocationsList] = React.useState<IAzureLocation[] | undefined>();
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [selectedLocation, setSelectedLocation] = React.useState<IAzureLocation>(
    useSelector((state: RootStore) => state.azureInstance.location)
  );
  const [defaultSubscription, setDefaultSubscription] = React.useState<string>('');
  const azureToken = useSelector((state: RootStore) => state.authentication.azureToken);
  const subscription = useSelector((state: RootStore) => state.azureInstance.subscription);

  useEffect(() => {
    let response: any;
    const getLocationsList = async () => {
      if (subscription?.subscriptionId) {
        response = await AzureManagementService.getLocationsList(azureToken, subscription.subscriptionId);
      } else {
        await AzureManagementService.getSubscriptionList(azureToken).then((response: IAzureSubscription[]) => {
          let [defaultSubscription] = response;
          setDefaultSubscription(defaultSubscription.subscriptionId);
        });
        response = await AzureManagementService.getLocationsList(azureToken, defaultSubscription);
      }
      setLocationsList(response);
      setLocationsErrorMessage(response);
    };
    getLocationsList();
  }, [subscription, defaultSubscription]);

  const locationsOptions = useMemo<IDropdownOption[]>(
    () =>
      locationsList?.map((location: IAzureLocation, index: number) => ({
        key: location.id,
        text: location.displayName,
        index: index,
      })),
    [locationsList]
  );

  const setLocationsErrorMessage = (response: IAzureLocation[]) => {
    if (!response || response.length === 0) {
      setErrorMessage(intlShape.formatMessage({ id: 'locations.errormessage' }));
    } else {
      setErrorMessage('');
    }
  };

  const onRenderLocationsLabel = (): JSX.Element => {
    return CustomRenderLabel(
      intlShape,
      'locations.Dropdown',
      null,
      'locations.installTooltip',
      null,
      'locations.accessibility.InfoIcon',
      null
    );
  };

  const onLocationsChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ): void => {
    const location = locationsList?.filter((each: IAzureLocation) => option.key === each.id)[0];
    setSelectedLocation(location);
    dispatch(setLocation(location));
  };

  return (
    <>
      <Stack tokens={stackTokens}>
        <Dropdown
          options={locationsOptions}
          selectedKey={selectedLocation?.id}
          onChange={onLocationsChange}
          errorMessage={errorMessage}
          placeholder={intlShape.formatMessage({ id: 'locations.placeholder' })}
          styles={dropdownStyles}
          onRenderLabel={onRenderLocationsLabel}
          label={intlShape.formatMessage({ id: 'locations.Dropdown' })}
          ariaLabel={intlShape.formatMessage({ id: 'locations.accessibility.placeholder' })}
          role="none"
        />
      </Stack>
    </>
  );
};

export default Locations;
