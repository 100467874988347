import { mergeStyles, mergeStyleSets } from '@fluentui/react';
import { segoeRegular } from '../../common/Constants';

const statusCommonStyle = {
  width: '140px',
  height: 24,
  fontSize: '12px',
  lineHeight: '16px',
  fontFamily: segoeRegular,
  paddingRight: '10px',
  fontWeight: 500,
  borderRadius: '5px',
};

const styles = mergeStyleSets({
  updateAvailableStyle: {
    ...statusCommonStyle,
    display: 'inline-block',
    background: '#FCEFD3',
    color: '#835B00',
    '@media(max-width: 480px)': {
      left: '14px!important',
    },
  },
  inProgressStyle: {
    ...statusCommonStyle,
    background: '#F3F2F1',
    color: '#006EC2',
  },
  deploymentFailedStyle: {
    ...statusCommonStyle,
    background: '#F6D1D5',
    color: '#B10E1C',
    '@media(max-width: 480px)': {
      left: '14px!important',
    },
  },
  deploymentSuccessStyle: {
    ...statusCommonStyle,
    color: '#107C10',
  },
  earlyAccessAvailableStyle: {
    ...statusCommonStyle,
    background: '#EEDBEB',
    color: '#72396A',
    width: '150px',
  },
  licenseMissingStatus: {
    position: 'absolute',
    minWidth: '97px',
    height: 24,
    background: '#F9DCD1',
    borderRadius: '14px',
    color: '#C33400',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: segoeRegular,
    paddingRight: '10px',
    fontWeight: 500,
  },
  infoContainer: {
    height: '36px',
    width: '100%',
    background: '#EBE9E7',
    paddingLeft: '12px',
    marginTop: '10px',
    boxSizing: 'border-box',
    '@media(max-width: 480px)': {
      height: '40px',
      paddingLeft: '1px',
    },
  },
  errorContainer: {
    height: '36px',
    width: '100%',
    background: '#FDE7E9',
    paddingLeft: '12px',
    marginTop: '10px',
    boxSizing: 'border-box',
    '@media(max-width: 480px)': {
      height: '40px',
      paddingLeft: '1px',
    },
  },
  errorIconContainer: {
    marginTop: '10px',
    display: 'inline-block',
    '@media(max-width: 480px)': {
      marginTop: '5px',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-evenly',
      flexDirection: 'column-reverse',
      flexWrap: 'wrap',
      alignContent: 'space-around',
    },
  },
  errorText: {
    fontSize: '12px',
    color: '#323130',
    verticalAlign: 'top',
    paddingTop: '10px',
    paddingLeft: '8px',
    display: 'inline-flex',
    '@media(max-width: 480px)': {
      whiteSpace: 'pre-wrap',
      paddingTop: '1px',
      paddingLeft: '4px',
    },
  },
  errorLicenseText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '190px',
    cursor: 'pointer',
  },
  errorDeploymentCheckText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '190px',
    cursor: 'pointer',
  },
});

export const iconClass = mergeStyles({
  fontSize: 12,
  height: 16,
  width: 12,
  marginLeft: '10px',
  marginRight: '6px',
  marginTop: '4px',
});

export const inlineStyles = {
  iconName: 'ErrorBadge',
  color: '#A81000',
};

export const messageBarStyles = mergeStyleSets({
  messageBarContainer: {
    '@media(max-width: 960px)': {
      width: '90%',
    },
  },
});

export default styles;
