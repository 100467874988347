import * as React from 'react';
import { IStackTokens, Stack, TextField } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setWorkspaceDetails, setWorkspacePayload } from '../../redux/fabricWorkspace/workspaceAction';

export interface WorkSpaceCreationInputProps {}

export interface LableIconProps {
  icon: string;
  labelText: string;
  iconText: string;
}

const stackTokens: IStackTokens = { childrenGap: 15 };

export const WorkspaceCreationInputs: React.FunctionComponent<WorkSpaceCreationInputProps> = (
  envProps: WorkSpaceCreationInputProps
) => {
  const intlShape = useIntl();

  const [capacityId, setCapacityId] = React.useState(undefined);
  const [displayName, setDisplayName] = React.useState(undefined);
  const [description, setDescription] = React.useState(undefined);
  const dispatch = useDispatch();
  React.useEffect(() => validateInputAndCreatePayload());

  function validateInputAndCreatePayload() {
    if (capacityId && displayName && capacityId.trim() !== '' && displayName.trim() !== '') {
      dispatch(setWorkspaceDetails(true));
      dispatch(
        setWorkspacePayload({
          capacityId: capacityId,
          displayName: displayName,
          description: description,
        })
      );
    } else {
      dispatch(setWorkspaceDetails(false));
      dispatch(setWorkspacePayload(undefined));
    }
  }

  const onCapacityIdChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setCapacityId(newValue);
  };

  const onDisplayNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setDisplayName(newValue);
  };

  const onDiscriptionChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setDescription(newValue);
  };
  return (
    <Stack verticalFill verticalAlign="center" tokens={stackTokens}>
      <TextField
        label={intlShape.formatMessage({
          id: 'destination.capacityId',
        })}
        placeholder={intlShape.formatMessage({
          id: 'destination.capacityIdPlaceHolder',
        })}
        onChange={onCapacityIdChange}
        required
      />
      <TextField
        label={intlShape.formatMessage({
          id: 'fabric.textfield.displayname.label',
        })}
        placeholder={intlShape.formatMessage({
          id: 'destination.displayNamePlaceHolder',
        })}
        onChange={onDisplayNameChange}
        required
      />
      <TextField
        label={intlShape.formatMessage({
          id: 'destination.description.optional',
        })}
        placeholder={intlShape.formatMessage({
          id: 'destination.description.optionalPlaceHolder',
        })}
        onChange={onDiscriptionChange}
      />
    </Stack>
  );
};
