import { IProgressIndicatorStyles, mergeStyleSets } from '@fluentui/react';
import { segoeRegular } from '../../../../../common/Constants';
const styles = mergeStyleSets({
  itemDescription: {
    fontSize: '12px',
    fontFamily: segoeRegular,
    lineHeight: '16px',
  },
  errorIconColor: {
    color: '#A80000',
    paddingRight: '5px',
  },
  successIconColor: {
    color: '#107C10',
    paddingRight: '5px',
  },
  progressDescriptor: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#605E5C',
    paddingBottom: '10px',
  },
  completeDescriptor: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#605E5C',
  },
  title: {
    display: 'inline-block',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    fontFamily: segoeRegular,
    marginTop: '30px',
    marginBottom: '0px',
  },
  progressTitle: {
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    fontFamily: segoeRegular,
    paddingTop: '20px',
  },
});

export const successProgressIndicatorStyles: Partial<IProgressIndicatorStyles> = {
  root: {
    fontSize: '14px',
    fontFamily: segoeRegular,
    lineHeight: '20px',
    marginTop: '20px',
  },
  progressBar: {
    backgroundColor: '#0078D4',
  },
};

export const failureProgressIndicatorStyles: Partial<IProgressIndicatorStyles> = {
  root: {
    fontSize: '14px',
    fontFamily: segoeRegular,
    lineHeight: '20px',
    marginTop: '20px',
  },
  progressBar: {
    backgroundColor: '#A80000',
  },
};

export const inlineStyles = {
  iconName: 'Blocked2',
  color: '#A80000',
};

export default styles;
