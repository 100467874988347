import React from 'react';
import styles from './error.styles';
import Utils from '../../utils';
import { FormattedMessage } from 'react-intl';
import { telemetry } from '../../services/telemetryService';
import { telemetryConstants } from '../../config';

export const Error = (props: { location: { state: { error: any } } }) => {
  if (process.env.NODE_ENV !== 'production') {
    const error = props?.location?.state?.error;
    if (error) {
      throw error;
    }
  }
  telemetry.logPageView(telemetryConstants.navigation.ERROR_PAGE_TELEMETRY);

  return (
    <div className={styles.errorContainer}>
      <img src={Utils.getIconSvg('error')} alt="" />
      <span className={styles.errorText}>
        <FormattedMessage id="errorMessages.genericErrorTitle.composite" values={{ linebreak: <br /> }} />
      </span>
    </div>
  );
};
