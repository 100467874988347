import { mergeStyleSets, ISpinnerStyles } from '@fluentui/react';
import ZOOMED_MODE from '../../utils/root';

const styles = mergeStyleSets({
  loadingScreenContainer: {
    position: 'fixed',
    width: '100%',
    height: 'calc(100vh - 109px)',
    background: 'rgba(243, 242, 241, 0.9)',
    top: '0',
    right: '0',
    zIndex: '9999',
    '@media(max-width: 960px)': {
      height: '100%',
    },
  },
  loadingScreenIframeContainer: {
    height: 'calc(100vh - 61px)',
  },
  innerLoadingContainer: {
    marginTop: 'calc(50vh - 28px)',
  },
  label: {
    fontSize: ZOOMED_MODE.zoomFontSize,
    fontWeight: ZOOMED_MODE.zoomFontWeight,
  },
});

export const spinnerStyle: Partial<ISpinnerStyles> = {
  root: {
    position: 'fixed',
    zIndex: 1031,
    top: 'calc( 50% - ( 28px / 2) )',
    right: 'calc( 50% - ( 28px / 2) )',
  },
};

export const spinnerLabelStyle: Partial<ISpinnerStyles> = {
  root: {
    position: 'fixed',
    zIndex: 1031,
    top: '63%',
    right: '63%',
    '@media(max-width: 960px)': {
      top: '20%',
      left: '10%',
    },
  },
};

export default styles;
