import { Panel, PanelType, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import React, { FC, ReactElement, useEffect, useRef } from 'react';
import { telemetryConstants } from '../../config';
import { telemetry } from '../../services/telemetryService';
import AuthenticationService from '../../services/authenticationService';
import RestService from '../../services/restService';
import AuthenticationStateContainer from '../../stateContainers/authenticationStateContainer';
import { panelContentStyle, spinnerLabelStyle, style } from './product-support.styles';
import { FormattedMessage } from 'react-intl';
import Utils from '../../utils';
import { useIntl } from 'react-intl';
import { DATA_TEST_IDS } from '../../common/Constants';
import RuntimeConfigStateContainer from '../../stateContainers/runtimeConfigStateContainer';

export enum MessageType {
  SYN = 'SYN',
  ACK = 'ACK',
  RequestAuth = 'requestAuth',
  BearerToken = 'Bearer token',
  LoadNewTicket = 'loadNewTicket',
  CloseNewTicket = 'closeNewTicket',
}

export interface ProductSupportPanelProps {
  iframeTitle: string;
  iframeSrc: string;
  isOpen: boolean;
  onDismiss: () => void;
  onTicketCreated: () => void;
}

export const ProductSupportPanel: FC<ProductSupportPanelProps> = (props: ProductSupportPanelProps): ReactElement => {
  const runtimeConfig = RuntimeConfigStateContainer.getConfiguration();

  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  useEffect(() => {
    if (props.isOpen) {
      setTimeout(() => checkThirdPartyCookie(), runtimeConfig.productSupport.timeoutInSeconds * 1000);
    }
  });

  const iframeRef = useRef(null);
  const errorContainerRef = useRef(null);
  const newTicketLoadedRef = useRef(false);
  const loadingSpinnerRef = useRef(null);

  const checkThirdPartyCookie = () => {
    if (!newTicketLoadedRef.current && errorContainerRef.current) {
      errorContainerRef.current.style.display = 'flex';
      if (loadingSpinnerRef.current) {
        loadingSpinnerRef.current.style.display = 'none';
      }
      if (iframeRef.current) {
        iframeRef.current.style.display = 'none';
      }
      telemetry.logTrace(telemetryConstants.supportPanel.PPAC_TIMED_OUT, telemetryConstants.severity.SEVERITY_ERROR);
    }
  };

  const onMessage = async (e: any) => {
    const runtimeConfig = RuntimeConfigStateContainer.getConfiguration();

    if (runtimeConfig.productSupport.iframeUrl.includes(e.origin)) {
      logEvent(telemetryConstants.events.SUPPORT_PANEL_MESSAGE_RECEIVED, e.data);

      switch (e.data.type) {
        case MessageType.SYN:
          sendMessage({ id: e.data.id, type: MessageType.ACK });
          break;
        case MessageType.RequestAuth:
          sendMessage({
            id: e.data.id,
            type: MessageType.BearerToken,
            payload: await getToken(e.data.payload),
          });
          break;
        case MessageType.LoadNewTicket:
          newTicketLoadedRef.current = true;
          loadingSpinnerRef.current.style.display = 'none';
          break;
        case MessageType.CloseNewTicket:
          newTicketLoadedRef.current = false;
          props.onDismiss();
          if (e.data.payload.ticketCreated) {
            props.onTicketCreated();
          }
          break;
      }
    }
  };

  const getToken = async (resource: string) => {
    var resources = runtimeConfig.tokenScope.ppac;
    if (!Utils.isNullOrUndefinedOrEmpty(resource)) {
      resources = [`${resource.replace(/\/$/, '')}/.default`];
    }

    const refreshToken = AuthenticationStateContainer.getRefreshToken();
    const requestData = AuthenticationService.generateRefreshTokenBody(refreshToken, resources);
    const response = await RestService.urlEncodedPost({ data: requestData });
    return (await response?.json())?.access_token;
  };

  const sendMessage = (message: any) => {
    iframeRef.current.contentWindow.postMessage(message, '*');

    if (message.type === MessageType.BearerToken && message.payload) {
      message.payload = '***';
    }

    logEvent(telemetryConstants.events.SUPPORT_PANEL_MESSAGE_SENT, message);
  };

  const logEvent = (eventName: string, eventMessage: string) => {
    const customData: Record<string, string> = {};
    customData[telemetryConstants.supportPanel.CUSTOM_DATA_KEY_MESSAGE] = eventMessage;
    customData[telemetryConstants.supportPanel.CUSTOM_DATA_KEY_URL] = props.iframeSrc;
    telemetry.logEvents(eventName, customData);
  };
  const intlShape = useIntl();

  return (
    <>
      <Panel
        id={DATA_TEST_IDS.productSupportPanel}
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        type={PanelType.medium}
        hasCloseButton={false}
        styles={panelContentStyle}
      >
        <div role="alert" ref={loadingSpinnerRef}>
          <Spinner
            size={SpinnerSize.large}
            label={intlShape.formatMessage({ id: 'loading.pleaseWait' })}
            styles={spinnerLabelStyle}
          />
        </div>

        <div className={style.errorContainer} ref={errorContainerRef}>
          <img src={Utils.getIconSvg('error')} alt="" />
          <br />
          <br />
          <span className={style.errorTitle}>
            <FormattedMessage id="productSupport_error_title" />
          </span>
          <br />
          <span className={style.errorDescription}>
            <FormattedMessage id="productSupport_error_message" />
          </span>
        </div>

        <iframe src={props.iframeSrc} ref={iframeRef} className={style.iframe} title={props.iframeTitle} />
      </Panel>
    </>
  );
};
