import {
  mergeStyleSets,
  ISeparatorStyles,
  IButtonStyles,
  ITextFieldStyles,
  ISpinnerStyles,
  SpinnerSize,
} from '@fluentui/react';
import { ResponsiveMode } from '../../common/responsiveMode';

const styles = mergeStyleSets({
  deploymentDetailsContainer: {
    background: '#FAF9F8',
    minHeight: '100%',
  },
  headerStyle: {
    background: '#FFFFFF',
    height: 72,
    '@media(max-width: 960px)': {
      height: 90,
    },
  },
  buttonDiv: {
    float: 'left',
  },
  buttonStyle: {
    marginLeft: '40px',
    marginTop: '28px',
    '@media(max-width: 960px)': {
      marginLeft: '20px',
    },
  },
  titleDivStyle: {
    marginTop: '15px',
    marginRight: '16px',
    marginLeft: '8px',
    display: 'inline-block',
    '@media(max-width: 960px)': {
      width: 400,
    },
    '@media(max-width: 480px)': {
      width: 180,
    },
  },
  subTitleStyle: {
    color: '#605E5C',
    fontSize: '12px',
    lineHeight: '16px',
  },
  titleStyle: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
  },
  solutionsContainer: {
    marginTop: '44px',
    marginLeft: '76px',
    width: '954px',
    '@media(max-width: 1245px)': {
      width: 500,
    },
    '@media(max-width: 960px)': {
      marginTop: '10px',
      marginLeft: '20px',
      width: 400,
    },
  },
  solutionsTitleContainer: {
    marginBottom: '26px',
    '@media(max-width: 960px)': {
      marginBottom: '16px',
    },
    '@media(max-width: 480px)': {
      marginBottom: '16px',
      marginTop: '50px',
    },
  },
  solutionsTitle: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 600,
  },
  extendYourSolutionsContainer: {
    marginTop: '20px',
    marginLeft: '76px',
    width: '954px',
    '@media(max-width: 960px)': {
      marginLeft: '20px',
    },
    '@media(max-width: 1245px)': {
      width: 500,
    },
  },
  extendYourSolutionsTitleContainer: {
    marginBottom: '26px',
  },
  extendYourSolutionsTitle: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 600,
  },
});

export const separatorStyle: Partial<ISeparatorStyles> = {
  root: {
    ':before': {
      height: '2px',
    },
  },
};

export const editButtonStyle: Partial<IButtonStyles> = {
  icon: {
    fontSize: '16px',
  },
};

export const saveButtonStyle: Partial<IButtonStyles> = {
  icon: {
    fontSize: '16px',
  },
  root: {
    position: 'absolute',
    top: '36px',
    left: '380px',
    marginLeft: '15px',
  },
};

export const textFieldStyles: Partial<ITextFieldStyles> = {
  fieldGroup: {
    width: 300,
  },
  field: {
    fontWeight: 600,
    fontSize: '20px',
  },
};

export const spinnerStyle: Partial<ISpinnerStyles> = {
  root: {
    position: 'fixed',
    zIndex: 1031,
    top: 'calc( 50% - ( 28px / 2) )',
    right: 'calc( 50% - ( 28px / 2) )',
    '@media(max-width: 960px)': {
      top: '40%',
      right: '70%',
    },
  },
};

export const spinnerSize = (responsiveMode: ResponsiveMode): SpinnerSize =>
  responsiveMode === ResponsiveMode.Desktop ? SpinnerSize.large : SpinnerSize.small;

export default styles;
