import React, { FC, ReactElement } from 'react';
import { Modal, PrimaryButton } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import { classStyles, contentStyles } from './deployment-progress.styles';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';
import Utils from '../../utils';

type deploymentProgressProps = {
  isOpen: boolean;
  onDismiss: () => void;
} & WrappedComponentProps;

export const DeploymentProgress: FC<deploymentProgressProps> = (props: deploymentProgressProps): ReactElement => {
  const { isOpen, onDismiss } = props;

  const titleId = useId(
    props.intl.formatMessage({
      id: 'status.inProgress',
    })
  );

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={isOpen}
        onDismiss={onDismiss}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.body}>
          <div className={classStyles.imageContainer}>
            <img alt={'Home Banner'} src={Utils.getIconSvg('home_banner_image', 'images')} />
          </div>
          <div className={classStyles.textContainer}>
            <span className={classStyles.title}>
              <FormattedMessage id="deploymentSplash.title" />
            </span>
            <br />
            <div className={classStyles.bodyContainer}>
              <span className={classStyles.body}>
                <FormattedMessage id="deploymentSplash.body" />
              </span>
            </div>
          </div>
          <div>
            <PrimaryButton
              text={props.intl.formatMessage({
                id: 'buttons.ok',
              })}
              className={classStyles.button}
              onClick={onDismiss}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
