import React, { FC, ReactElement } from 'react';
import { ErrorIcon } from '../error-icon/error-icon';
import styles from './statusBars.styles';
import { FormattedMessage, useIntl } from 'react-intl';
import './../../custom.css';
import SolutionsStateContainer from '../../stateContainers/solutionsStateContainer';
import { telemetryConstants } from '../../config';
import AuthenticationStateContainer from '../../stateContainers/authenticationStateContainer';

export interface LicenseMissingStatusBarProps {
  onToggleLearnMore: React.MouseEventHandler<HTMLButtonElement>;
  l03Name?: string;
  l03RowKey?: string;
}

export const LicenseMissingStatusBar: FC<LicenseMissingStatusBarProps> = (
  props: LicenseMissingStatusBarProps
): ReactElement => {
  const { onToggleLearnMore, l03Name, l03RowKey } = props;

  const intlShape = useIntl();
  const tenantId = AuthenticationStateContainer.getTenantId();

  return (
    <div className={styles.errorContainer}>
      <span className={styles.errorIconContainer}>
        <ErrorIcon />
      </span>
      <span className={styles.errorText}>
        <span
          className={styles.errorLicenseText}
          title={intlShape.formatMessage({
            id: 'products.licenseMissing',
          })}
        >
          <FormattedMessage id="products.licenseMissing" />
        </span>{' '}
        <button
          type="button"
          onClick={onToggleLearnMore}
          className="btnLikeAnchor"
          title={intlShape.formatMessage({
            id: 'products.learnMore',
          })}
          data-customl01={SolutionsStateContainer.getCurrentSolution().solutionName}
          data-customl01rowkey={SolutionsStateContainer.getCurrentSolution().rowKey}
          data-customl03={l03Name}
          data-customl03rowkey={l03RowKey}
          data-customtenantId={tenantId}
          data-customsource={sessionStorage.getItem('source')}
          data-customevent={telemetryConstants.events.LEARN_MORE_CLICKED}
        >
          <FormattedMessage id="products.learnMore" />
        </button>
      </span>
    </div>
  );
};
