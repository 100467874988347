import React from 'react';
import { IconButton, Stack, TooltipHost, Text } from '@fluentui/react';
import { FormattedMessage, IntlShape } from 'react-intl';
import styles, { infoButtonStyle } from '../../armStyling/index.styles';
import { DATA_TEST_IDS } from '../../../../../../common/Constants';

const CustomRenderLabel = (
  intlShape: IntlShape,
  labelName: string,
  labelValues: string | null,
  tooltipDescriptionName: string,
  tooltipDescriptionValues: string | null,
  infoIconAccessibleName: string,
  nonRequiredField: string | null
): JSX.Element => {
  return (
    <Stack horizontal verticalAlign="center">
      <Text variant="tiny" className={styles.headerText} data-test-id={DATA_TEST_IDS.customLabel}>
        <FormattedMessage id={labelName} values={{ label: labelValues }} />
        {nonRequiredField === null && <span className={styles.requiredField}>*</span>}
      </Text>
      {tooltipDescriptionValues && (
        <TooltipHost
          content={
            <FormattedMessage
              id={tooltipDescriptionName}
              values={{
                description: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tooltipDescriptionValues,
                    }}
                  ></div>
                ),
              }}
            />
          }
          calloutProps={{ gapSpace: 0 }}
        >
          <IconButton
            ariaLabel={intlShape.formatMessage(
              {
                id: infoIconAccessibleName,
              },
              {
                fieldDescription: tooltipDescriptionValues,
              }
            )}
            iconProps={{
              iconName: 'Info',
              className: styles.infoIconBtn,
            }}
            styles={infoButtonStyle}
            className={styles.toolTipIconSize}
          />
        </TooltipHost>
      )}
    </Stack>
  );
};

export default CustomRenderLabel;
