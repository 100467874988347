import {
  ERROR_VALIDATION_FABRIC_WORKSPACE_TYPE,
  CREATE_WORKSPCE,
  CREATE_WORKSPCE_PAYLOAD,
  WorkspaceDispatchTypes,
} from './workspaceType';

interface DefaultWorkspaceStateI {
  errorValidationFabricWorkspaceType: boolean;
  isRequiredFieldFilled: boolean;
  workSpacePayload: object;
}

const initialState: DefaultWorkspaceStateI = {
  errorValidationFabricWorkspaceType: false,
  isRequiredFieldFilled: false,
  workSpacePayload: undefined,
};

const fabricWorkspaceReducer = (
  state: DefaultWorkspaceStateI = initialState,
  action: WorkspaceDispatchTypes
): DefaultWorkspaceStateI => {
  switch (action.type) {
    case ERROR_VALIDATION_FABRIC_WORKSPACE_TYPE:
      return {
        ...state,
        errorValidationFabricWorkspaceType: action.payload,
      };
    case CREATE_WORKSPCE:
      return {
        ...state,
        isRequiredFieldFilled: action.payload,
      };
    case CREATE_WORKSPCE_PAYLOAD:
      return {
        ...state,
        workSpacePayload: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default fabricWorkspaceReducer;
