import { mergeStyleSets, IPanelStyles } from '@fluentui/react';
import { segoeRegular } from '../../../common/Constants';

const styles = mergeStyleSets({
  progressBox: {
    background: '#FFFFFF',
    minHeight: '160px',
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    position: 'relative',
    '@media(max-width: 960px)': {
      minHeight: '77px',
    },
    '@media(max-width: 480px)': {
      minHeight: '77px',
      width: '230px',
    },
    marginBottom: '40px',
  },
  progressTitle: {
    position: 'relative',
    fontSize: '28px',
    lineHeight: '36px',
    fontWeight: 700,
    margin: '20',
    paddingBottom: '12px',
    paddingLeft: '32px',
    paddingTop: '24px',
  },
  progressTitleContainer: {
    minHeight: '57px',
  },
  progressMessage: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    paddingLeft: '32px',
    paddingRight: '32px',
    paddingBottom: '12px',
  },
  progressButtonStyle: {
    float: 'left',
    marginLeft: '32px',
  },
  progressButtonFooter: {
    height: '48px',
    paddingBottom: '15px',
  },
  panelContent: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  panelTitle: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    font: segoeRegular,
  },
});

export const panelContentStyle: Partial<IPanelStyles> = {
  content: {
    padding: 0,
  },
  commands: {
    '@media(max-width: 960px)': {
      marginTop: 0,
    },
  },
};

export default styles;
