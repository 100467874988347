import { Panel, PanelType, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import React, { FC, useContext, ReactElement, useEffect, useState, useReducer } from 'react';
import { style, dropdownStyles, stackTokens, spinnerLabelStyle } from './customSupportPanel.styles';
import { Dropdown, IDropdownOption, Stack, IconButton } from '@fluentui/react';
import { useIntl } from 'react-intl';
import './../../custom.css';
import { PrimaryButton } from '@fluentui/react';
import { ppacAvailableLanguages } from '../../config';
import { LocaleContext } from '../../contexts/LocaleContext';
import { ProductSupportPanel } from '../product-support/product-support';
import { TicketConfirmationDialog } from '../product-support/ticket-confirmation-dialog';
import { useBoolean } from '@fluentui/react-hooks';
import { NoSupportMessageView } from './noSupportMessageView';
import { telemetry } from '../../services/telemetryService';
import ProductSupportService from '../../services/productSupportService';
import { ProductSupportL01, ProductSupportL03 } from '../../common/Type';
import { DATA_TEST_IDS } from '../../common/Constants';
import RuntimeConfigStateContainer from '../../stateContainers/runtimeConfigStateContainer';
import Utils from '../../utils';

export interface CustomSupportPanelProps {
  isOpen: boolean;
  selectedL01RowKey: string | null;
  onDismiss: () => void;
}

export const CustomSupportPanel: FC<CustomSupportPanelProps> = (props: CustomSupportPanelProps): ReactElement => {
  const [locale]: any = useContext(LocaleContext);

  useEffect(() => {
    loadProductSupportL01s();
  }, [locale]);

  useEffect(() => {
    if (props.isOpen) {
      loadDataForL01(props.selectedL01RowKey);
    }
  }, [props.selectedL01RowKey, props.isOpen]);

  const intlShape = useIntl();

  const [isSupportPanelOpen, { setTrue: openSupportPanel, setFalse: closeSupportPanel }] = useBoolean(false);
  const [isConfirmationOpen, { setTrue: openConfirmation, setFalse: closeConfirmation }] = useBoolean(false);
  const [productSupportL01s, setProductSupportL01s] = useState<ProductSupportL01[]>([]);

  const reducerAction = {
    selectL01: 'selectL01',
    loadL03s: 'loadL03s',
    loadedL03s: 'loadedL03s',
    setSupportId: 'setSupportId',
    setSupportDetails: 'setSupportDetails',
  };

  const initialState = {
    selectedL01RowKey: '',
    selectedL03RowKey: '',
    productSupportL03s: [],
    isLoadingL03s: false,
    supportId: '',
    supportDetails: '',
  };

  const productSupportReducer = (state: any, action: any) => {
    switch (action.type) {
      case reducerAction.selectL01: {
        return {
          ...state,
          selectedL01RowKey: action.payload?.l01RowKey,
          selectedL03RowKey: '',
          productSupportL03s: [],
          supportId: action.payload?.supportId,
          supportDetails: '',
        };
      }
      case reducerAction.loadL03s: {
        return {
          ...state,
          isLoadingL03s: true,
          supportId: '',
        };
      }
      case reducerAction.loadedL03s: {
        return {
          ...state,
          productSupportL03s: action.payload,
          isLoadingL03s: false,
        };
      }
      case reducerAction.setSupportId: {
        return {
          ...state,
          selectedL03RowKey: action.payload.l03RowKey,
          supportId: action.payload.supportId,
          supportDetails: '',
        };
      }
      case reducerAction.setSupportDetails: {
        return {
          ...state,
          selectedL03RowKey: action.payload.l03RowKey,
          supportId: '',
          supportDetails: action.payload.details,
        };
      }
    }
  };

  const [state, dispatch] = useReducer(productSupportReducer, initialState);
  const { selectedL01RowKey, selectedL03RowKey, productSupportL03s, isLoadingL03s, supportId, supportDetails } = state;

  const runtimeConfig = RuntimeConfigStateContainer.getConfiguration();
  const iframeSrc = runtimeConfig.productSupport.iframeUrl
    .replace(
      '{locale}',
      ppacAvailableLanguages.find((loc) => loc.includes(locale))
    )
    .replace('{supportId}', supportId);

  const productSupportL01Options = productSupportL01s
    .map((l01: ProductSupportL01, index: number) => ({
      key: l01.l01RowKey,
      text: l01.l01Name,
      index: index,
    }))
    .sort((a: any, b: any) => a.text.localeCompare(b.text));

  const productSupportL03Options = productSupportL03s
    .map((l03: ProductSupportL03, index: number) => ({
      key: l03.l03RowKey,
      text: l03.l03Name,
      index: index,
    }))
    .sort((a: any, b: any) => a.text.localeCompare(b.text));

  const loadProductSupportL01s = async () => {
    const l01s = await ProductSupportService.getProductSupportL01s();
    telemetry.logTrace(`Number of solutions visible to user in the ProductSupport panel: ${l01s.length}`);
    setProductSupportL01s(l01s);
  };

  const loadDataForL01 = async (l01RowKey: string | null, logPrefix: string = '') => {
    const selectedProductSupportL01 = productSupportL01s.find((x) => x.l01RowKey === l01RowKey);
    dispatch({
      type: reducerAction.selectL01,
      payload: selectedProductSupportL01,
    });

    if (selectedProductSupportL01) {
      if (selectedProductSupportL01.supportId) {
        telemetry.logTrace(
          `${logPrefix}ProductSupport available for the selected L01: ${selectedProductSupportL01.l01Name}`
        );
      } else {
        dispatch({ type: reducerAction.loadL03s });

        telemetry.logTrace(
          `${logPrefix}Loading L03 data for the selected L01 to fetch ProductSupport Id: ${selectedProductSupportL01.l01Name}`
        );
        const productSupportL03s = await ProductSupportService.getProductSupportL03s(
          selectedProductSupportL01.l01RowKey,
          selectedProductSupportL01.l01UniqueName
        );
        telemetry.logTrace(
          `${logPrefix}Number of L03s visible to user in the ProductSupport panel: ${productSupportL03s.length} for the selected solution: ${selectedProductSupportL01.l01Name}`
        );

        dispatch({
          type: reducerAction.loadedL03s,
          payload: productSupportL03s,
        });
      }
    }
  };

  const onL01OptionChange = async (option: IDropdownOption) =>
    loadDataForL01(option.key as string, '(L01Change request) ');

  const onL03OptionChange = async (option: IDropdownOption) => {
    const selectedProductSupportL03 = productSupportL03s.find((x) => x.l03RowKey === option.key);

    if (
      selectedProductSupportL03.isSupportAvailable &&
      !Utils.isNullOrUndefinedOrEmpty(selectedProductSupportL03.supportId)
    ) {
      telemetry.logTrace(`PPAC ProductSupport available for the selected L03: ${selectedProductSupportL03.l03Name}`);
      dispatch({
        type: reducerAction.setSupportId,
        payload: selectedProductSupportL03,
      });
    }

    if (!selectedProductSupportL03.isSupportAvailable && selectedProductSupportL03.details) {
      telemetry.logTrace(
        `Custom support requests are shown for the selected L03: ${selectedProductSupportL03.l03Name}`
      );
      dispatch({
        type: reducerAction.setSupportDetails,
        payload: selectedProductSupportL03,
      });
    }
  };

  const onNextButtonClick = (): void => {
    openSupportPanel();
    telemetry.logTrace('Request submitted to open PPAC ProductSupport');
    props.onDismiss();
  };

  return (
    <>
      <Panel
        id={DATA_TEST_IDS.customSupportPanel}
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        type={PanelType.medium}
        hasCloseButton={false}
      >
        {isLoadingL03s && (
          <div>
            <Spinner
              size={SpinnerSize.large}
              label={intlShape.formatMessage({ id: 'loading.pleaseWait' })}
              styles={spinnerLabelStyle}
            />
          </div>
        )}

        <div className={style.divCss}>
          <div className={style.displayInline}>
            <div className={style.helpDivWidth}>{intlShape.formatMessage({ id: 'globalSupport.title' })}</div>
            <div className={style.onDismissDivWidth}>
              <IconButton
                iconProps={{ iconName: 'Cancel' }}
                onClick={props.onDismiss}
                className={style.dismissBtnColor}
                ariaLabel={intlShape.formatMessage({ id: 'buttons.close' })}
              />
            </div>
          </div>
          <div className={style.pageInfoWidth}>{intlShape.formatMessage({ id: 'globalSupport_Page_Information' })}</div>
          <div className={style.verticalSpacing}>
            <Stack tokens={stackTokens}>
              <Dropdown
                options={productSupportL01Options}
                selectedKey={selectedL01RowKey}
                onChanged={onL01OptionChange}
                errorMessage=""
                placeholder={intlShape.formatMessage({
                  id: 'l01solution.placeholder',
                })}
                styles={dropdownStyles}
                label={intlShape.formatMessage({
                  id: 'globalSupport_l01_dropdown_title',
                })}
                ariaLabel={intlShape.formatMessage({
                  id: 'globalSupport.l01.accessibility.placeholder',
                })}
                aria-live={'polite'}
                aria-atomic={true}
              />
            </Stack>
          </div>

          {productSupportL03s.length > 0 && (
            <div className={style.verticalSpacing}>
              <Stack tokens={stackTokens}>
                <Dropdown
                  options={productSupportL03Options}
                  selectedKey={selectedL03RowKey}
                  onChanged={onL03OptionChange}
                  errorMessage=""
                  placeholder={intlShape.formatMessage({
                    id: 'l03solution.placeholder',
                  })}
                  styles={dropdownStyles}
                  label={intlShape.formatMessage({
                    id: 'globalSupport_l03_dropdown_title',
                  })}
                  ariaLabel={intlShape.formatMessage({
                    id: 'globalSupport.l03.accessibility.placeholder',
                  })}
                  aria-live={'polite'}
                  aria-atomic={true}
                />
              </Stack>
            </div>
          )}
          <div></div>

          {supportDetails && (
            <div className={style.pageInfoWidth}>
              <NoSupportMessageView l03NoSupportMsg={supportDetails} />
            </div>
          )}

          {supportId && (
            <div className={style.footerContainer}>
              <div>
                <div className={style.verticalSpacing}>
                  <PrimaryButton
                    id="nextButton"
                    text={intlShape.formatMessage({
                      id: 'buttons.next',
                    })}
                    ariaLabel={intlShape.formatMessage({ id: 'buttons.next' })}
                    allowDisabledFocus
                    onClick={onNextButtonClick}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Panel>

      <section>
        <ProductSupportPanel
          iframeTitle={intlShape.formatMessage({
            id: 'productSupport_iframe_title',
          })}
          iframeSrc={iframeSrc}
          isOpen={isSupportPanelOpen}
          onDismiss={closeSupportPanel}
          onTicketCreated={openConfirmation}
        />
        <TicketConfirmationDialog isOpen={isConfirmationOpen} onDismiss={closeConfirmation} />
      </section>
    </>
  );
};
