import * as React from 'react';
import Utils from '../../../utils';
import { telemetry } from '../../../services/telemetryService';
import { telemetryConstants } from '../../../config';
import { useIntl } from 'react-intl';

const handleClick = (linkTitle: string, topic: string) => {
  var postDeploymentDetail: Record<string, string> = {};
  postDeploymentDetail[telemetryConstants.events.POST_DEPLOYMENT_TOPIC] = topic;
  postDeploymentDetail[telemetryConstants.events.POST_DEPLOYMENT_LINK_TITLE] = linkTitle;

  telemetry.logEvents(telemetryConstants.events.POST_DEPLOYMENT_NAME, postDeploymentDetail);
};

const styles = {
  cardContainer: {
    verticalAlign: 'top',
    width: '320px',
    height: '92px',
    background: '#FFFFFF',
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    marginRight: '16px',
    marginBottom: '68px',
    border: '1px solid transperant',
    display: 'inline-block',
    '@media(max-width: 480px)': {
      width: '235px',
    },
  },
};

const LearnMoreCard = ({
  iconName,
  topic,
  linkTitle,
  linkLocation = 'View more.',
}: {
  iconName: string;
  topic: string;
  linkTitle: string;
  linkLocation: string;
}) => {
  return (
    <div style={styles.cardContainer}>
      <div
        style={{
          display: 'inline-block',
          padding: '12px 16px 12px',
        }}
      >
        <img
          style={{
            width: '48px',
            height: '48px',
            verticalAlign: 'top',
            transform: 'translateY(21%)',
          }}
          src={Utils.getIconSvg(iconName)}
          alt={useIntl().formatMessage({
            id: 'altText.solutionSetupSuccess.learnMore',
          })}
        />
        <div
          style={{
            height: '64px',
            padding: '16px 10px',
            display: 'inline-block',
            lineHeight: '16px',
            marginTop: 'auto',
            marginBottom: 'auto',
            marginLeft: '5px',
            fontSize: '14px',
          }}
        >
          <div className="ms-fontSize-14 FontSizes.size14 ms-fontWeight-semibold " style={{ lineHeight: '20px' }}>
            {topic}
          </div>
          <div
            className="ms-fontSize-14 FontSizes.size14 ms-fontWeight-regular"
            style={{ lineHeight: '16px', color: '#0B53CE' }}
          >
            <a
              href={linkLocation}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleClick(linkTitle, topic)}
            >
              {linkTitle}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnMoreCard;
