export const ERROR_VALIDATION_FABRIC_WORKSPACE_TYPE = 'ERROR_VALIDATION_FABRIC_WORKSPACE_TYPE';
export const CREATE_WORKSPCE = 'CREATE_WORKSPCE';
export const CREATE_WORKSPCE_PAYLOAD = 'CREATE_WORKSPCE_PAYLOAD';

export interface errorValidationfabricWorkspaceType {
  type: typeof ERROR_VALIDATION_FABRIC_WORKSPACE_TYPE;
  payload: boolean;
}
export interface createWorkspace {
  type: typeof CREATE_WORKSPCE;
  payload: boolean;
}
export interface createWorkspacePayload {
  type: typeof CREATE_WORKSPCE_PAYLOAD;
  payload: object;
}

export type WorkspaceDispatchTypes = errorValidationfabricWorkspaceType | createWorkspace | createWorkspacePayload;
