import { mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  toolTipIconSize: {
    padding: '0px 10px 0px 8px',
  },
  infoIconBtn: {
    color: 'black !important',
  },
});

export default styles;
