import { PublicClientApplication, LogLevel, InteractionRequiredAuthError } from '@azure/msal-browser';
import axios from 'axios';
import AuthenticationStateContainer from '../stateContainers/authenticationStateContainer';
import { telemetry } from '../services/telemetryService';
import config from '../config';
import { v4 as uuidv4 } from 'uuid';

export async function loadShellNavbarData(accessToken) {
  const headers = {
    Authorization: 'Bearer ' + accessToken,
    'Access-Control-Allow-Origin': '*',
    'x-ms-correlation-id': uuidv4(),
  };
  const query = `https://webshell.suite.office.com/api/shell/navbardata?CurrentMainLinkId=SolutionsPowerPlatform&TrackingGuid=31fd22b3-2d3d-4df4-99f2-0c11b7a45ae9`;
  try {
    const response = await axios.get(query, {
      headers: headers,
    });
    if (response.status !== 200) {
      console.error(`Response with: ${response.status}`);
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const checkGroupMembership = async (body) => {
  await delay(1000);
  var groupMembershipToken = AuthenticationStateContainer.getGroupMembershipToken();
  if (groupMembershipToken !== null) {
    const headers = {
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + groupMembershipToken,
      Accept: 'application/json',
    };
    const query = config.groupMembershipUrl + config.endpoints.getGroupMembership;
    try {
      const response = await axios(query, {
        method: 'POST',
        headers: headers,
        data: body,
      });
      if (response.status !== 200) {
        telemetry.logException(`/checkMemberGroups call returned: ${response.status}`);
      }
      telemetry.logTrace('Request to /checkMemberGroups was successful');
      return response.data;
    } catch (error) {
      telemetry.logException(error);
    }
  }
};

class AuthenticationService {
  constructor() {
    this.msalConfig = this.generateMsalConfig(config.webShellConfig);
    this.msalInstance = new PublicClientApplication(this.msalConfig);
  }

  generateMsalConfig(config) {
    return {
      auth: {
        clientId: config.appId,
        authority: config.authority,
        redirectUri: config.redirectUri,
        knownAuthorities: config.knownAuthorities,
        navigateToLoginRequestUrl: false /* If the redirectUri is the same as the original request location, this flag should be set to false. */,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
              default:
                console.info(message);
                return;
            }
          },
          piiLoggingEnabled: false,
        },
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
    };
  }
  handleResponse(response, callback) {
    if (response) {
      if (callback) callback(response);
    } else {
      throw Error('Redirection promise failed!');
    }
  }
  async getTokenRedirect(silentRequest, interactiveRequest) {
    try {
      const response = await this.msalInstance.acquireTokenSilent(silentRequest);
      return response;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        this.msalInstance.acquireTokenRedirect(interactiveRequest).catch(console.error);
      } else {
        console.error(e);
      }
    }
  }

  logout() {
    this.msalInstance.logout();
  }

  async getAccessToken(scopes, callback) {
    try {
      const accounts = this.msalInstance.getAllAccounts();

      if (accounts.length) {
        console.log('User is already logged in, getting token silently');
        const tokenResponse = await this.getTokenRedirect(
          {
            scopes: scopes,
            account: accounts[0],
            forceRefresh: true,
          },
          { scopes: scopes }
        );

        callback(tokenResponse);
      } else {
        await this.msalInstance.loginRedirect({
          scopes: scopes,
          prompt: 'select_account',
        });
      }
    } catch (err) {
      await this.msalInstance
        .acquireTokenRedirect({
          scopes: scopes,
        })
        .catch((error) => {
          if (error.message.indexOf('interaction_in_progress') > -1) {
            return;
          }
          console.error(error);
        });

      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes

      if (this.isInteractionRequired(err)) {
        this.msalInstance
          .handleRedirectPromise()
          .then((tokenResponse) => {
            if (tokenResponse === null) {
              console.log('you are not coming back from an auth redirect');
            }
            // Check if the tokenResponse is null
            // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
            // If the tokenResponse === null, you are not coming back from an auth redirect.
            if (tokenResponse) {
              callback(tokenResponse);
            }
          })
          .catch((error) => {
            throw error;
          });
      } else {
        throw err;
      }
    }
  }

  isInteractionRequired(error) {
    if (!error.message || error.message.length <= 0) {
      return false;
    }

    return (
      error.message.indexOf('interaction_in_progress') > -1 ||
      error.message.indexOf('consent_required') > -1 ||
      error.message.indexOf('interaction_required') > -1 ||
      error.message.indexOf('login_required') > -1 ||
      error.message.indexOf('no_account_in_silent_request') > -1
    );
  }

  generateRefreshTokenBody(refreshToken, scopes) {
    return {
      ...config.identitySettings.refresh,
      client_id: config.webShellConfig.appId,
      scope: scopes.join(' '),
      refresh_token: refreshToken,
    };
  }
}
const authenticationService = new AuthenticationService();
export default authenticationService;
