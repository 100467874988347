import * as Type from '../../common/Type';
import * as ApiType from '../../solutionCenterApi/gen/index';
import { FabricComponentPayloadList } from './deploymentReducers';
export const CDS_ENVIRONMENT = 'CDS_ENVIRONMENT';
export const FABRIC_WORKSPACE = 'FABRIC_WORKSPACE';
export const SELECTED_CAPABILITY_INDEX = 'SELECTED_CAPABILITY_INDEX';
export const SELECTED_CAPABILITY_KEY = 'SELECTED_CAPABILITY_KEY';
export const SHOW_ALL_L03S = 'SHOW_ALL_L03S';
export const DEPLOYMENT_DEFINITION = 'DEPLOYMENT_DEFINITION';
export const CI_REGION_PAYLOAD = 'CI_REGION_PAYLOAD';
export const REGION_BY_ORCHESTRATOR_TYPE = 'REGIONS_BY_ORCHESTRATOR_TYPE';
export const SELECTED_OPTIONAL_COMPONENT = 'SELECTED_OPTIONAL_COMPONENT';
export const FABRIC_PAYLOAD = 'FABRIC_PAYLOAD';

export interface CdsEnvironment {
  type: typeof CDS_ENVIRONMENT | null;
  payload: Type.Environment | ApiType.Instance | null;
}

export interface FabricWorkspace {
  type: typeof FABRIC_WORKSPACE;
  payload: ApiType.Workspace | null;
}

export interface SelectedCapabilityIndex {
  type: typeof SELECTED_CAPABILITY_INDEX;
  payload: number;
}

export interface SelectedCapabilityKey {
  type: typeof SELECTED_CAPABILITY_KEY;
  payload: string;
}

export interface ShowAllL03s {
  type: typeof SHOW_ALL_L03S;
  payload: boolean;
}

export interface DeploymentDefinition {
  type: typeof DEPLOYMENT_DEFINITION;
  payload: ApiType.DeploymentDefinitionEntity | null;
}
export interface CIRegionPayload {
  type: typeof CI_REGION_PAYLOAD;
  payload: ApiType.CIRegionPayload | null;
}
export interface RegionsByOrchestratorType {
  type: typeof REGION_BY_ORCHESTRATOR_TYPE;
  payload: Array<Type.RegionsByOrchestratorType> | null;
}
export interface SelectedOptionalComponents {
  type: typeof SELECTED_OPTIONAL_COMPONENT;
  payload: Array<ApiType.OptionalComponent>;
}
export interface FabricPayload {
  type: typeof FABRIC_PAYLOAD;
  payload: FabricComponentPayloadList;
}

export type DeploymentDispatchTypes =
  | CdsEnvironment
  | SelectedCapabilityIndex
  | SelectedCapabilityKey
  | ShowAllL03s
  | DeploymentDefinition
  | CIRegionPayload
  | RegionsByOrchestratorType
  | FabricWorkspace
  | SelectedOptionalComponents
  | FabricPayload;
