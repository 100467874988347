import { telemetryConstants } from '../config';
import * as ApiType from '../solutionCenterApi/gen';
import { getJSONParsedArrayFromString } from '../utils/helper';
import Utils from '../utils/index.js';

const currentSessionStorage: Storage = window.sessionStorage;

const SolutionsStateContainer = {
  setAnnouncements: (data: ApiType.Announcement[]) => {
    currentSessionStorage.setItem(telemetryConstants.sessionStorage.ANNOUNCEMENTS, JSON.stringify(data));
  },
  getAnnouncements: () => {
    const announcementsJson = currentSessionStorage.getItem(telemetryConstants.sessionStorage.ANNOUNCEMENTS);
    if (announcementsJson) {
      return JSON.parse(announcementsJson);
    } else {
      return [];
    }
  },
  setSolutions: (data: ApiType.L01[]) => {
    currentSessionStorage.setItem(telemetryConstants.sessionStorage.ALL_AVAILABLE_SOLUTIONS, JSON.stringify(data));
  },
  getSolutions: () => {
    const solutionsJson = currentSessionStorage.getItem(telemetryConstants.sessionStorage.ALL_AVAILABLE_SOLUTIONS);
    if (solutionsJson) {
      return JSON.parse(solutionsJson);
    } else {
      return null;
    }
  },
  setAllLearnings: (data: ApiType.Learning[]) => {
    currentSessionStorage.setItem(telemetryConstants.sessionStorage.LEARNING, JSON.stringify(data));
  },
  getAllLearnings: (): ApiType.Learning[] => {
    const learningsJson = currentSessionStorage.getItem(telemetryConstants.sessionStorage.LEARNING);
    if (learningsJson) {
      return JSON.parse(learningsJson);
    } else {
      return null;
    }
  },
  setAllSetupGuides: (data: ApiType.SetupGuide[]) => {
    currentSessionStorage.setItem(telemetryConstants.sessionStorage.SETUP_GUIDE, JSON.stringify(data));
  },
  getAllSetupGuides: (): ApiType.SetupGuide[] => {
    const coursesJson = currentSessionStorage.getItem(telemetryConstants.sessionStorage.SETUP_GUIDE);
    if (coursesJson) {
      return JSON.parse(coursesJson);
    } else {
      return null;
    }
  },
  setDisplayEA: (displayEA: boolean) => {
    currentSessionStorage.setItem(telemetryConstants.sessionStorage.DISPLAY_EA, displayEA.toString());
  },
  getDisplayEA: () => {
    const displayEA = currentSessionStorage.getItem(telemetryConstants.sessionStorage.DISPLAY_EA);
    if (displayEA) {
      return displayEA === 'true';
    } else {
      return true;
    }
  },
  setAllSolutions: (data: ApiType.L01[]) => {
    currentSessionStorage.setItem(telemetryConstants.sessionStorage.ALL_SOLUTIONS, JSON.stringify(data));
  },
  getAllSolutions: (): ApiType.L01[] | null => {
    const solutionsJson = currentSessionStorage.getItem(telemetryConstants.sessionStorage.ALL_SOLUTIONS);
    if (solutionsJson) {
      return JSON.parse(solutionsJson);
    } else {
      return null;
    }
  },
  setCurrentSolution: (solution: ApiType.L01) => {
    currentSessionStorage.setItem(telemetryConstants.sessionStorage.CURRENT_SOLUTION, JSON.stringify(solution));
  },
  getCurrentSolution: () => {
    const solutionsJson = currentSessionStorage.getItem(telemetryConstants.sessionStorage.CURRENT_SOLUTION);
    if (solutionsJson) {
      return JSON.parse(solutionsJson);
    } else {
      return null;
    }
  },
  setAvailableLicenses: (data: ApiType.License[]) => {
    currentSessionStorage.setItem(telemetryConstants.sessionStorage.AVAILABLE_LICENSES, JSON.stringify(data));
  },
  getAvailableLicenses: () => {
    return JSON.parse(currentSessionStorage.getItem(telemetryConstants.sessionStorage.AVAILABLE_LICENSES));
  },
  setSelectedSolution: (solutionId: string) => {
    currentSessionStorage.setItem(telemetryConstants.sessionStorage.SELECTED_SOLUTION, solutionId);
  },
  getSelectedSolution: () => {
    return currentSessionStorage.getItem(telemetryConstants.sessionStorage.SELECTED_SOLUTION);
  },
  setIsVisibleSolutions: (data: ApiType.L01[]) => {
    currentSessionStorage.setItem(telemetryConstants.sessionStorage.VISIBLE_SOLUTIONS, JSON.stringify(data));
  },
  getIsVisibleSolutions: () => {
    const solutionsJson = currentSessionStorage.getItem(telemetryConstants.sessionStorage.VISIBLE_SOLUTIONS);
    if (solutionsJson) {
      return JSON.parse(solutionsJson);
    } else {
      return null;
    }
  },

  setProductSupport: (data: ApiType.ProductSupport[]) => {
    currentSessionStorage.setItem(telemetryConstants.sessionStorage.PRODUCT_SUPPORT, JSON.stringify(data));
  },
  getProductSupport: () => {
    const productSupportJson = currentSessionStorage.getItem(telemetryConstants.sessionStorage.PRODUCT_SUPPORT);
    if (productSupportJson) {
      return JSON.parse(productSupportJson);
    } else {
      return null;
    }
  },

  setSolutionName: (data: string) => {
    currentSessionStorage.setItem(telemetryConstants.sessionStorage.SOLUTION_NAME, data);
  },
  getSolutionName: () => {
    const solutionName = currentSessionStorage.getItem(telemetryConstants.sessionStorage.SOLUTION_NAME);
    if (solutionName) {
      return solutionName;
    } else {
      return null;
    }
  },

  setTrialDeploymentData: (solutionUniqueName: string, deploymentData: ApiType.TrialsDeploymentRequest): void => {
    currentSessionStorage.setItem(
      telemetryConstants.sessionStorage.TRIAL_DEPLOYMENT_DATA + solutionUniqueName,
      JSON.stringify(deploymentData)
    );
  },

  getTrialDeploymentData: (solutionUniqueName: string): ApiType.TrialsDeploymentRequest => {
    const deploymentData = currentSessionStorage.getItem(
      telemetryConstants.sessionStorage.TRIAL_DEPLOYMENT_DATA + solutionUniqueName
    );
    if (deploymentData) {
      return JSON.parse(deploymentData);
    } else {
      return null;
    }
  },

  setFilterL03sByRegion: (filterL03sByRegion: boolean) => {
    currentSessionStorage.setItem(
      telemetryConstants.sessionStorage.FILTER_L03S_BY_REGION,
      filterL03sByRegion.toString()
    );
  },

  getFilterL03sByRegion: (): boolean => {
    const filterL03sByRegion = currentSessionStorage.getItem(telemetryConstants.sessionStorage.FILTER_L03S_BY_REGION);
    if (filterL03sByRegion) {
      return filterL03sByRegion === 'true';
    } else {
      return false;
    }
  },

  setSelectedIndustryVerticalKey: (selectedIndustryVertical: string): void => {
    currentSessionStorage.setItem(
      telemetryConstants.sessionStorage.INDUSTRY_VERTICALS_KEY,
      JSON.stringify(selectedIndustryVertical)
    );
  },

  getSelectedIndustryVerticalKey: (): string => {
    let industryVerticalKey = currentSessionStorage.getItem(telemetryConstants.sessionStorage.INDUSTRY_VERTICALS_KEY);
    industryVerticalKey = JSON.parse(industryVerticalKey);
    if (industryVerticalKey) {
      return industryVerticalKey;
    } else {
      return null;
    }
  },

  setSelectedIndustryVerticalKeys: (verticals: Array<string>) => {
    if (!Utils.isNullOrUndefined(verticals)) {
      currentSessionStorage.setItem('selectedIndustryVerticalKeys', JSON.stringify(verticals));
    }
  },
  getSelectedIndustryVerticalKeys: (): Array<string> => {
    const verticalKeys = currentSessionStorage.getItem('selectedIndustryVerticalKeys');
    if (!Utils.isNullOrUndefined(verticalKeys)) {
      return JSON.parse(verticalKeys!);
    } else {
      return [];
    }
  },

  setFilteredSearchKeyword: (filteredSearchKeyword: string): void => {
    currentSessionStorage.setItem(telemetryConstants.sessionStorage.FILTER_SEARCH_KEY, filteredSearchKeyword);
  },

  getFilteredSearchKeyword: (): string => {
    const filteredSearchKeyword = currentSessionStorage.getItem(telemetryConstants.sessionStorage.FILTER_SEARCH_KEY);
    if (filteredSearchKeyword) {
      return filteredSearchKeyword;
    } else {
      return '';
    }
  },

  setGridListView: (isGridView: boolean): void => {
    currentSessionStorage.setItem(telemetryConstants.sessionStorage.FILTER_IS_GRID_VIEW, isGridView.toString());
  },

  getGridListView: (): boolean => {
    const isGridView = currentSessionStorage.getItem(telemetryConstants.sessionStorage.FILTER_IS_GRID_VIEW);
    if (isGridView) {
      return isGridView === 'true';
    } else {
      return true;
    }
  },

  setFilteredSelectedSort: (selectedSort: string): void => {
    currentSessionStorage.setItem(telemetryConstants.sessionStorage.FILTER_SELECTED_SORT, selectedSort);
  },

  getFilteredSelectedSort: (): string => {
    return currentSessionStorage.getItem(telemetryConstants.sessionStorage.FILTER_SELECTED_SORT);
  },

  setModifiedByFilterKeys: (modifiedByFilterKeys: Array<string>): void => {
    currentSessionStorage.setItem(
      telemetryConstants.sessionStorage.MODIFIED_BY_FILTER_KEYS,
      JSON.stringify(modifiedByFilterKeys)
    );
  },

  getModifiedByFilterKeys: (): Array<string> => {
    const modifiedByFilterKeys = currentSessionStorage.getItem(
      telemetryConstants.sessionStorage.MODIFIED_BY_FILTER_KEYS
    );
    return getJSONParsedArrayFromString(modifiedByFilterKeys);
  },

  setDeploymentStatus: (deploymentStatusFilterKeys: Array<string>): void => {
    currentSessionStorage.setItem(
      telemetryConstants.sessionStorage.DEPLOYMENT_STATUS_FILTER_KEYS,
      JSON.stringify(deploymentStatusFilterKeys)
    );
  },

  getDeploymentStatus: (): Array<string> => {
    const deploymentStatusFilterKeys = currentSessionStorage.getItem(
      telemetryConstants.sessionStorage.DEPLOYMENT_STATUS_FILTER_KEYS
    );
    return getJSONParsedArrayFromString(deploymentStatusFilterKeys);
  },
};

export default SolutionsStateContainer;
