import { IModalStyles, ISpinnerStyles, mergeStyleSets } from '@fluentui/react';

export const styles = mergeStyleSets({
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    margin: '0 0 55px',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
    '@media(min-width: 961px)': {
      width: '292px',
    },
  },
  checkBoxLabel: {
    paddingTop: '20px',
    color: '#323130',
  },
  checkBoxContainer: {
    marginTop: '18px',
  },
  footer: {
    paddingBottom: 24,
    paddingRight: 24,
  },
});

export const modalStyle: Partial<IModalStyles> = {
  scrollableContent: {
    minHeight: '340px',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    '@media(min-width: 961px)': {
      minWidth: '340px',
    },
  },
};

export const spinnerStyle: Partial<ISpinnerStyles> = {
  root: {
    position: 'fixed',
    zIndex: 1031,
    top: 'calc( 50% - ( 28px / 2) )',
    right: 'calc( 50% - ( 78px / 2) )',
  },
};
