import { mergeStyleSets } from 'office-ui-fabric-react';
import { segoeRegular } from '../../common/Constants';

export const splashStyles = {
  title: {
    lineHeight: '28px',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: segoeRegular,
    marginTop: '32.67px',
    fontWeight: 600,
    color: '#000000',
    '@media(max-width: 480px)': {
      width: '280px',
      whiteSpace: 'pre-wrap',
    },
  },
  midText: {
    lineHeight: '22px',
    fontSize: '16px',
    textAlign: 'center',
    fontFamily: segoeRegular,
    marginTop: '16px',
    color: '#000000',
    '@media(max-width: 480px)': {
      width: '280px',
      whiteSpace: 'pre-wrap',
    },
  },
  signedIn: {
    lineHeight: '20px',
    fontSize: '16px',
    textAlign: 'center',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: 350,
    marginTop: '28px',
    color: '#323130',
    overflowWrap: 'break-word',
  },
  line: {
    marginTop: '44px',
    marginLeft: '24px',
    marginRight: '24px',
    border: '1px solid #CCCCCC',
    backgroundColor: '#CCCCCC',
    '@media(max-width: 480px)': {
      width: '280px',
      whiteSpace: 'pre-wrap',
    },
  },
  footer: {
    marginTop: '24px',
    display: 'content',
    overflowWrap: 'break-word',
  },
  privacyDiv: {
    marginLeft: '24px',
    overflowWrap: 'break-word',
  },
  privacy: {
    fontFamily: segoeRegular,
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 450,
    overflowWrap: 'break-word',
  },
  continueBtnDiv: {
    marginLeft: '270px',
    '@media(max-width: 480px)': {
      marginLeft: '800px',
    },
  },
};

export const contentStyles = mergeStyleSets({
  container: {
    width: '720px',
    maxHeight: '100%',
    '@media(max-width: 480px)': {
      width: '280px',
    },
  },
  splashContainer: {
    height: '531px',
    width: '720px',
    '@media(max-width: 480px)': {
      width: '280px',
    },
  },
  imageContainer: {
    height: '236px',
    backgroundColor: '#0C3A8A',
    textAlign: 'center',
    '@media(max-width: 480px)': {
      width: '280px',
    },
  },
  image: {
    marginTop: '61px',
    '@media(max-width: 480px)': {
      width: '280px',
    },
  },
  stackStyle: {
    height: '295px',
    backgroundColor: '#FFFFFF',
    '@media(max-width: 480px)': {
      width: '280px',
    },
  },
  buttonStyle: {
    float: 'right',
    marginRight: '24px',
    '@media(max-width: 480px)': {
      marginRight: '124px',
    },
  },
});
