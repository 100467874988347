import { L03ByRegionTelemetryPropertyNames } from '../common/Constants';
import { OK } from '../common/HttpStatusCodes';
import config from '../config';
import { CIRegionPayload } from '../solutionCenterApi/gen';
import RestService from './restService';
import { telemetry } from './telemetryService';
import Utils from '../utils';

const configs = config.regionAvailabilityEndpoints;
export class RegionAvailabilityService {
  public static async getAllScaleUnitsCIRegions(): Promise<CIRegionPayload[] | string[] | null> {
    await RestService.refreshTokenIfCloseToExpire();
    await RestService.refreshTokenIfCloseToExpire();
    const queryUrl = `${configs.main}${configs.customerInsights}`;
    return this.getAllRegions(queryUrl, L03ByRegionTelemetryPropertyNames.CustomerInsightsAllRegions);
  }

  public static async getAllAzureRegions(): Promise<CIRegionPayload[] | string[] | null> {
    const queryUrl = `${configs.main}${configs.azure}`;
    return this.getAllRegions(queryUrl, L03ByRegionTelemetryPropertyNames.AzureAllRegions);
  }

  public static async GetAvailableL03sByRegionUniqueName(
    solutionUniqueName: string,
    deploymentType: string,
    region: string
  ): Promise<string[] | string | null> {
    if (!Utils.isNullOrUndefined(region)) {
      const endPointUrl = configs.azureL03
        .replace('{region}', region)
        .replace('{solutionUniqueName}', solutionUniqueName)
        .replace('{deploymentType}', deploymentType);
      const queryUrl = `${configs.main}${endPointUrl}`;
      const result = await RestService.get({
        endPoint: queryUrl,
      });
      telemetry.logTrace(
        `${L03ByRegionTelemetryPropertyNames.AvailableL03sByRegionUniqueName} for url ${queryUrl} returned response: ${result}`
      );
      return result;
    }
    return [];
  }

  public static async getAllRegions(
    queryUrl: string,
    telemetryString: string
  ): Promise<CIRegionPayload[] | string[] | null> {
    await RestService.refreshTokenIfCloseToExpire();
    let allRegionsResponse;
    var customProps: Record<string, string> = {};
    try {
      await RestService.get({
        endPoint: queryUrl,
      }).then((response) => {
        allRegionsResponse = response;
      });
      if (allRegionsResponse?.status !== OK || !allRegionsResponse) {
        customProps[telemetryString] = allRegionsResponse;

        telemetry.logException(
          `${telemetryString} call returned: ${allRegionsResponse.status}, with a response ${allRegionsResponse}`
        );
        return allRegionsResponse;
      }

      telemetry.logTrace(`Request to ${telemetryString} for URL ${queryUrl} was successful`, customProps);
      return allRegionsResponse;
    } catch (error) {
      telemetry.logException(`${telemetryString} call returned: ${error}`);
      return;
    }
  }
}
