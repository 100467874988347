import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { PostDeploymentConfigurations } from './postDeploymentConfigurations/postDeploymentConfigurations';
import styles, {
  separatorStyle,
  editButtonStyle,
  textFieldStyles,
  saveButtonStyle,
  spinnerStyle,
} from './deploymentDetails.styles';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import SolutionsContainer from './solutionsContainer/solutionsContainer';
import ProgressWidget from './progressWidget/progressWidget';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { IconButton, Separator, TextField, Link, MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import * as ApiType from '../../solutionCenterApi/gen/index';
import config, { telemetryConstants } from '../../config';
import DeployService from '../../services/deployService';
import { telemetry } from '../../services/telemetryService';
import * as Constant from '../../common/Constants';
import { AvailableComponentsTable } from '../../components/available-components-table/availableComponentsTable';
import { OptionalComponentContainer } from './solutionsContainer/optionalComponentContainer/optionalComponentContainer';
import SolutionsStateContainer from '../../stateContainers/solutionsStateContainer';
import Visible from '../../components/visible/visible';
import Utils, { getUniqDependencies } from '../../utils';
import * as Util from '../../utils/helper';
import { ResponsiveMode } from '../../common/responsiveMode';
import { ResponsiveModeContext } from '../../contexts/ResponsiveModeContext';
import * as Enum from '../../common/Enum';
import EnvironmentService from '../../services/environmentsService';
import { DeploymentsDetailsRefreshTimeoutInMS } from '../../common/Constants';

type deploymentDetailsProps = {
  history: RouteComponentProps['history'];
  location: RouteComponentProps<
    {},
    StaticContext,
    {
      from: { pathname: string } | string;
      deploymentDefinition?: ApiType.DeploymentDefinitionEntity;
    }
  >['location'];
} & WrappedComponentProps;

type deploymentDetailsState = {
  deploymentL03s: ApiType.DeploymentDefinitionEntity;
  editButtonClick: boolean;
  updatedDeploymentName: string;
  loadingDeploymentDetails: boolean;
  selectionDetails: ApiType.OptionalComponent[];
  displayEA: boolean;
  announcements: ApiType.Announcement | null;
  isUpdateAvailable: boolean;
  environmentID: string | undefined;
};

class DeploymentDetails extends React.Component<deploymentDetailsProps, deploymentDetailsState> {
  refreshClock!: NodeJS.Timeout;

  constructor(props: deploymentDetailsProps) {
    super(props);

    this.state = {
      deploymentL03s: {
        l03s: [],
        name: '',
        tenantId: '',
        deploymentStatus: '',
        isUpdateAvailable: false,
        l01Icon: '',
        l01RowKey: '',
        l01Name: '',
        instanceName: '',
        timestamp: new Date(),
        partitionKey: '',
        rowKey: '',
        isModifiedByCurrentUser: false,
      },
      editButtonClick: false,
      updatedDeploymentName: '',
      loadingDeploymentDetails: true,
      selectionDetails: [],
      displayEA: false,
      announcements: null,
      environmentID: undefined,
      isUpdateAvailable: this.props.location.state.deploymentDefinition.isUpdateAvailable,
    };
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  static contextType = ResponsiveModeContext;

  componentDidMount = async () => {
    await this.getDeploymentL03s().then(() => this.getL03Updates());
    await this.getAnnouncements();
    await this.getEnvironmentID();
    await this.getDeploymentsProgressRefresh();
    var metricName =
      telemetryConstants.metrics.TRACKING_TYPE_PAGE_VISIT_DURATION +
      ': ' +
      telemetryConstants.deploymentDetails.DEPLOYMENT_DETAILS_TELEMETRY_NAME;
    telemetry.logPageView(telemetryConstants.deploymentDetails.DEPLOYMENT_DETAILS_TELEMETRY_NAME);
    telemetry.startMetric(metricName);
  };

  componentWillUnmount = () => {
    var metricName =
      telemetryConstants.metrics.TRACKING_TYPE_PAGE_VISIT_DURATION +
      ': ' +
      telemetryConstants.deploymentDetails.DEPLOYMENT_DETAILS_TELEMETRY_NAME;
    clearTimeout(this.refreshClock);
    telemetry.stopMetric(metricName);
  };

  setSelectionDetails = (selectionDetails: ApiType.OptionalComponent[]) => {
    this.setState({
      selectionDetails: selectionDetails,
    });
  };

  handleDeploymentNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    event.preventDefault();
    if (!newValue || (newValue.length <= Constant.DeployNameMaxLength && newValue.replace(/\s/g, '').length !== 0)) {
      this.setState({
        ...this.state,
        updatedDeploymentName: newValue!,
      });
    }
  };

  onSaveButtonClick = async () => {
    var customProps: any = {};
    try {
      if (
        this.state.updatedDeploymentName === '' ||
        this.state.updatedDeploymentName === this.state.deploymentL03s.name
      ) {
        customProps[Constant.TelemetryPropertyNames.deploymentL03sKey] = this.state.deploymentL03s;
        customProps[Constant.TelemetryPropertyNames.deploymentDefinitionNameKey] = this.state.updatedDeploymentName;
        this.setState({
          deploymentL03s: this.state.deploymentL03s,
          editButtonClick: false,
        });
      } else {
        const deploymentDefinition: ApiType.DeploymentDefinitionEntity = {
          tenantId: this.state.deploymentL03s.tenantId,
          rowKey: this.state.deploymentL03s.rowKey,
          partitionKey: this.state.deploymentL03s.partitionKey,
          name: this.state.updatedDeploymentName,
          l01RowKey: this.state.deploymentL03s.l01RowKey,
          timestamp: new Date(),
          isModifiedByCurrentUser: false,
        };
        var result: ApiType.DeploymentDefinitionEntity = await DeployService.putDeploymentName(deploymentDefinition);
        customProps[Constant.TelemetryPropertyNames.deploymentDefinitionNameKey] = result.name;
        telemetry.logEvents(telemetryConstants.events.DEPLOYMENT_NAME_UPDATED, customProps);
        if (result.rowKey) {
          var deploymentL03s: ApiType.DeploymentDefinitionEntity = await DeployService.getDeploymentDetails(
            result.rowKey
          );
          customProps[Constant.TelemetryPropertyNames.deploymentL03sKey] = deploymentL03s;
          this.setState({
            deploymentL03s: deploymentL03s,
            editButtonClick: false,
          });
        } else {
          telemetry.logTrace(
            'PUT /deploymentName result rowkey is undefined',
            telemetryConstants.severity.SEVERITY_ERROR,
            customProps
          );
          this.setState({
            editButtonClick: false,
          });
        }
      }
    } catch (error) {
      telemetry.logException(error);
      telemetry.logEvents(telemetryConstants.events.ERROR_PAGE_SHOWN, customProps);
      this.props.history.push(config.routes.error);
    }
  };

  getDeploymentsProgressRefresh = async () => {
    await Promise.all([
      new Promise<void>((resolve) => {
        this.refreshClock = setTimeout(() => resolve(), DeploymentsDetailsRefreshTimeoutInMS);
      }),
    ]).then(() => {
      if (
        this.state.deploymentL03s.deploymentStatus === Enum.DeploymentStatus[Enum.DeploymentStatus.InProgress] ||
        this.state.deploymentL03s.deploymentStatus === Enum.DeploymentStatus[Enum.DeploymentStatus.NotStarted]
      ) {
        this.getDeploymentL03s();
        this.getDeploymentsProgressRefresh();
      }
    });
  };

  onButtonClick = (value: boolean) => {
    this.setState({
      editButtonClick: value,
    });
  };

  displayEA = () => {
    let sessionEA = SolutionsStateContainer.getDisplayEA();
    return sessionEA && this.state.displayEA && this.state.isUpdateAvailable;
  };

  onDismissClick = () => {
    SolutionsStateContainer.setDisplayEA(false);
    this.setState({
      displayEA: false,
    });
  };

  getAnnouncements = async () => {
    if (this.state.isUpdateAvailable) {
      let announcements = SolutionsStateContainer.getAnnouncements();
      if (announcements) {
        let announcement = announcements.find(
          (element) => element.partitionKey === this.state.deploymentL03s.l01RowKey
        );
        if (announcement) {
          this.setState({
            announcements: announcement,
            displayEA: true,
          });
        }
      }
    }
  };

  getEnvironmentID = async () => {
    if (this.state.isUpdateAvailable) {
      let environments = await EnvironmentService.getEnvironments(this.state.deploymentL03s.l01RowKey);
      let environmentID = environments.find(
        (element) => element.friendlyName === this.state.deploymentL03s.instanceName
      )?.environmentId;
      if (environmentID) {
        this.setState({
          environmentID: environmentID,
        });
      }
    }
  };

  getDeploymentL03s = async () => {
    var customProps: any = {};
    try {
      let deploymentL03s = await DeployService.getDeploymentDetails(
        this.props.location.state!.deploymentDefinition.rowKey
      );
      customProps[Constant.TelemetryPropertyNames.deploymentL03sKey] = deploymentL03s;
      this.setState({
        deploymentL03s: deploymentL03s,
        loadingDeploymentDetails: false,
      });
    } catch (error) {
      telemetry.logTrace('Get deploymentL03s failed', telemetryConstants.severity.SEVERITY_ERROR, customProps);
      telemetry.logEvents(telemetryConstants.events.ERROR_PAGE_SHOWN, customProps);
      this.props.history.push(config.routes.error, { error });
      return;
    }
  };

  getL03Updates = async () => {
    try {
      let updateAvailableResult = await DeployService.getUpdatesByDeploymentDefinitionRowKey(
        this.props.location.state!.deploymentDefinition.rowKey
      );
      this.setState({
        isUpdateAvailable: updateAvailableResult,
      });
    } catch (error) {
      telemetry.logTrace('Get deploymentL03s updates failed', telemetryConstants.severity.SEVERITY_ERROR);
    }
  };

  renderOptionalComponents = (deploymentL03s: ApiType.DeploymentDefinitionEntity) => {
    if (deploymentL03s.optionalComponents) {
      var filteredOptionalComponents: ApiType.OptionalComponent[] = Util.filterSampleDataOptionalComponents(
        deploymentL03s.optionalComponents
      );
      return filteredOptionalComponents.map((component: ApiType.OptionalComponent, i: number) => {
        return (
          <div key={component.rowKey} tabIndex={0}>
            <OptionalComponentContainer
              intl={this.props.intl}
              key={component.rowKey}
              deployStatus={component.deploymentStatus ? component.deploymentStatus : ''}
              l01RowKey={deploymentL03s.l01RowKey}
              component={component}
              getDeploymentL03s={this.getDeploymentL03s}
              deploymentdefinitionRowKey={deploymentL03s.rowKey}
            />
            <br />
          </div>
        );
      });
    }
  };

  getTotalOptionalComponents = (deploymentL03s: ApiType.DeploymentDefinitionEntity): ApiType.OptionalComponent[] => {
    var totalOptionalComponents: ApiType.OptionalComponent[] = [];
    if (deploymentL03s.l03s) {
      deploymentL03s.l03s.forEach((offer: ApiType.L03) => {
        if (offer.optionalComponents) {
          offer.optionalComponents.forEach((component: ApiType.OptionalComponent) => {
            totalOptionalComponents.push(component);
          });
        }
      });
    }

    var uniqueOptionalComponents: ApiType.OptionalComponent[] = [];
    totalOptionalComponents.forEach((oc: ApiType.OptionalComponent) => {
      var index: number = uniqueOptionalComponents.findIndex((item) => item.rowKey === oc.rowKey);
      if (index <= -1) {
        uniqueOptionalComponents.push(oc);
      }
    });

    var sortedUniqueOptionalComponents: ApiType.OptionalComponent[] = uniqueOptionalComponents.sort(
      (a: ApiType.OptionalComponent, b: ApiType.OptionalComponent) => a.order - b.order
    );

    return sortedUniqueOptionalComponents;
  };

  determineUninstalledOptionalComponents = (
    deploymentL03s: ApiType.DeploymentDefinitionEntity
  ): ApiType.OptionalComponent[] => {
    if (deploymentL03s.deploymentStatus !== Enum.DeploymentStatus[Enum.DeploymentStatus.Success]) {
      return [];
    }
    var totalOptionalComponents: ApiType.OptionalComponent[] = this.getTotalOptionalComponents(deploymentL03s);
    if (deploymentL03s.optionalComponents) {
      var uninstalledOptionalComponents: ApiType.OptionalComponent[] = totalOptionalComponents
        .filter((component: ApiType.OptionalComponent) => {
          return component.rowKey;
        })
        .filter((component: ApiType.OptionalComponent) => {
          return !deploymentL03s.optionalComponents?.some(
            (oc: ApiType.OptionalComponent) => oc.rowKey === component.rowKey
          );
        });
      return Util.filterSampleDataOptionalComponents(uninstalledOptionalComponents);
    } else {
      return Util.filterSampleDataOptionalComponents(totalOptionalComponents);
    }
  };

  renderAvailableComponentsTable = (deploymentL03s: ApiType.DeploymentDefinitionEntity): JSX.Element | null => {
    if (this.determineUninstalledOptionalComponents(deploymentL03s).length !== 0) {
      return (
        <AvailableComponentsTable
          setSelectionDetails={this.setSelectionDetails}
          selectionDetails={this.state.selectionDetails}
          history={this.props.history}
          location={this.props.location}
          optionalComponents={this.determineUninstalledOptionalComponents(deploymentL03s)}
          deploymentL03s={deploymentL03s}
          getDeploymentL03s={() => this.getDeploymentL03s()}
          l01RowKey={deploymentL03s.l01RowKey}
        />
      );
    } else {
      return null;
    }
  };

  renderExtendYourSolutionsTitle = (
    postDependencies: ApiType.L03Dependency[],
    deploymentL03s: ApiType.DeploymentDefinitionEntity
  ): JSX.Element | null => {
    if (postDependencies.length === 0 && this.determineUninstalledOptionalComponents(deploymentL03s).length === 0) {
      return null;
    } else {
      return (
        <div className={styles.extendYourSolutionsTitleContainer} tabIndex={0}>
          <span className={styles.extendYourSolutionsTitle}>
            <FormattedMessage id="postDeploymentConfigurations.extendYourSolutions" />
          </span>
        </div>
      );
    }
  };

  renderProgressWidget = (deploymentL03s: ApiType.DeploymentDefinitionEntity): JSX.Element => {
    return <ProgressWidget deploymentL03s={deploymentL03s} />;
  };

  isPreviewEnabled = () => {
    const solution = this.getCurrentSolution();
    return !Utils.isNullOrUndefinedOrEmpty(solution?.previewTermsLink);
  };

  getCurrentSolution = (): ApiType.L01 => {
    const currentSolution = SolutionsStateContainer.getAllSolutions().find(
      (solution) => solution.rowKey === this.state.deploymentL03s.l01RowKey
    );
    return currentSolution;
  };

  redirectToSolutionPage = () => {
    const solutionName = this.getCurrentSolution()?.solutionName;
    this.props.history.push({ pathname: solutionName });
  };

  render() {
    const { editButtonClick, deploymentL03s, loadingDeploymentDetails } = this.state;
    const { history, intl } = this.props;

    const postL03Dependencies: ApiType.L03Dependency[] = Utils.getPostDependencies(deploymentL03s.l03s);
    const postOCDependencies: ApiType.L03Dependency[] = Utils.getPostDependencies(deploymentL03s.optionalComponents);
    const totalPostDependencies = postL03Dependencies.concat(postOCDependencies);
    const postDependencies = getUniqDependencies(totalPostDependencies);

    return (
      <div className={styles.deploymentDetailsContainer}>
        <div className={styles.headerStyle}>
          <div className={styles.buttonDiv}>
            <IconButton
              className={styles.buttonStyle}
              onClick={() => history.goBack()}
              iconProps={{ iconName: 'ChromeBack' }}
              ariaLabel={intl.formatMessage({ id: '_navMenu.home.comment' })}
            />
          </div>
          <div className={styles.titleDivStyle}>
            <Visible when={!loadingDeploymentDetails} fallback={null}>
              <span className={styles.subTitleStyle}>{Utils.getInstanceName(deploymentL03s)}</span>
              <br />
              {editButtonClick ? (
                <TextField
                  ariaLabel={intl.formatMessage({
                    id: 'deploymentDetails.textbox.deploymentName',
                  })}
                  defaultValue={deploymentL03s.name}
                  onChange={this.handleDeploymentNameChange}
                  styles={textFieldStyles}
                />
              ) : (
                <span className={styles.titleStyle} tabIndex={0}>
                  {deploymentL03s.name}
                </span>
              )}
              {editButtonClick ? (
                <IconButton
                  ariaLabel={intl.formatMessage({
                    id: 'deploymentDetails.button.save',
                  })}
                  styles={saveButtonStyle}
                  onClick={() => this.onSaveButtonClick()}
                  iconProps={{ iconName: 'Save' }}
                />
              ) : (
                <IconButton
                  ariaLabel={intl.formatMessage({
                    id: 'deploymentDetails.button.edit',
                  })}
                  styles={editButtonStyle}
                  onClick={() => this.onButtonClick(true)}
                  iconProps={{ iconName: 'Edit' }}
                />
              )}
            </Visible>
          </div>
        </div>
        {this.displayEA() && (
          <MessageBar
            messageBarType={MessageBarType.info}
            isMultiline={false}
            onDismiss={this.onDismissClick}
            ariaLabel={'Close'}
            dismissButtonAriaLabel={'Close'}
            actions={
              <div>
                {this.state.environmentID ? (
                  <MessageBarButton
                    href={this.state.announcements.optInLink + 'environment/' + this.state.environmentID + '/hub'}
                    target="_blank"
                  >
                    <FormattedMessage id="earlyAccess.optIn" />
                  </MessageBarButton>
                ) : (
                  <MessageBarButton href={this.state.announcements.optInLink} target="_blank">
                    <FormattedMessage id="earlyAccess.optIn" />
                  </MessageBarButton>
                )}
              </div>
            }
          >
            {this.state.announcements.message}
            <Link href={this.state.announcements.learnMoreLink} underline target="_blank">
              <FormattedMessage id="success.learnMore" />
            </Link>
          </MessageBar>
        )}

        {this.isPreviewEnabled() && (
          <MessageBar
            messageBarType={MessageBarType.info}
            isMultiline={false}
            ariaLabel={intl.formatMessage({ id: 'buttons.close' })}
            dismissButtonAriaLabel={'Close'}
          >
            <FormattedMessage
              id="preview.deploymentDetails.showPreview"
              values={{
                Link: (linkText: string) => <Link onClick={this.redirectToSolutionPage}>{linkText}</Link>,
                cloud: this.getCurrentSolution()?.solutionName,
              }}
            />
          </MessageBar>
        )}

        <div className={styles.solutionsContainer}>
          <Visible
            when={!loadingDeploymentDetails}
            fallback={
              <Spinner
                size={this.context === ResponsiveMode.Desktop ? SpinnerSize.large : SpinnerSize.small}
                styles={spinnerStyle}
                label={intl.formatMessage({ id: 'deploymentDetails.spinnerLabel' })}
              />
            }
          >
            {this.renderProgressWidget(deploymentL03s)}
            <div className={styles.solutionsTitleContainer} tabIndex={0}>
              <span className={styles.solutionsTitle}>
                <FormattedMessage id="deploymentDetails.solutions" />
              </span>
            </div>
            {deploymentL03s.l03s?.map((deployedOffer: ApiType.L03, i: number) => {
              return (
                <div key={deployedOffer.rowKey} tabIndex={0}>
                  <SolutionsContainer
                    key={deployedOffer.rowKey}
                    deployStatus={deployedOffer.deploymentStatus ? deployedOffer.deploymentStatus : ''}
                    l01RowKey={deploymentL03s.l01RowKey}
                    solution={deployedOffer}
                    getDeploymentL03s={this.getDeploymentL03s}
                    deploymentdefinitionRowKey={deploymentL03s.rowKey}
                  />
                  <br />
                </div>
              );
            })}
            {this.renderOptionalComponents(deploymentL03s)}
            <Separator styles={separatorStyle} />
          </Visible>
        </div>
        <Visible
          when={
            !loadingDeploymentDetails &&
            deploymentL03s.deploymentStatus !== Enum.DeploymentStatus[Enum.DeploymentStatus.Failure]
          }
          fallback={null}
        >
          <div className={styles.extendYourSolutionsContainer}>
            {this.renderExtendYourSolutionsTitle(postDependencies, deploymentL03s)}
            <PostDeploymentConfigurations
              intl={intl}
              history={history}
              loadingDependenices={loadingDeploymentDetails}
              deploymentDependencies={postDependencies}
            />
            {postDependencies.length === 0 ? null : <br />}
            {this.renderAvailableComponentsTable(deploymentL03s)}
            <br />
          </div>
        </Visible>
      </div>
    );
  }
}

export default injectIntl(DeploymentDetails);
