import {
  FontWeights,
  getTheme,
  IButtonStyles,
  IIconProps,
  IIconStyles,
  IStackProps,
  ITextFieldStyles,
  mergeStyleSets,
} from '@fluentui/react';

const theme = getTheme();
const styles = mergeStyleSets({
  armResouceHeaderTitle: {
    marginTop: '30px !important',
  },
  headerText: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    paddingBottom: '5px',
  },
  iconSize: {
    width: '11px',
    marginTop: '30px',
    left: '15px',
    color: 'black',
    height: '25px !important',
  },
  infoIconBtn: {
    color: 'black !important',
  },
  toolTipIconSize: {
    padding: '0px 10px 0px 8px',
  },
  requiredField: {
    paddingLeft: '5px',
    color: 'red',
  },
  linkColor: {
    color: '#1d66ec !important',
    paddingLeft: '0px !important',
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  createNewContainer: {
    marginTop: '10px',
  },
  modalContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  verticalSpacing: {
    marginTop: '20px !important',
  },
  modalHeader: [
    theme.fonts?.large,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights?.semibold,
      padding: '12px 12px 14px 24px',
      marginTop: '20px',
    },
  ],
  modalBody: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  modalFooter: {
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
  },
  btnStyles: {
    marginRight: '20px',
  },
  subscriptionInstanceDetails: {
    marginTop: '10px !important',
  },
  messageBarAz: {
    '@media(max-width: 480px)': {
      minWidth: '230px',
    },
  },
});

export const stackProps: Partial<IStackProps> = {
  horizontal: true,
  tokens: { childrenGap: 40 },
  styles: { root: { marginBottom: 20 } },
};

export const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    marginLeft: 'auto',
    marginTop: '9px',
    marginRight: '9px',
    float: 'right',
  },
};

export const textfieldStyles: Partial<ITextFieldStyles> = {
  root: {
    width: 520,
    marginTop: '5px',
    '@media(max-width: 945px)': {
      width: 285,
    },
    '@media(max-width: 480px)': {
      width: 196,
    },
  },
};

export const infoButtonStyle: IIconStyles = {
  root: {
    color: '#0078D4',
  },
};

export const cancelIcon: IIconProps = { iconName: 'Cancel' };

export default styles;
