import { getUniqDependencies } from '.';
import * as ApiType from '../solutionCenterApi/gen/index';
import * as Type from '../common/Type';
import InstallationStateContainer from '../stateContainers/installationStateContainer';
import * as Constants from '../common/Constants';
import * as Enum from '../common/Enum';
import Utils from '../utils';
import { AssociatedIndustryVertical } from '../common/Type';
import { L02Capability } from '../solutionCenterApi/gen/index';
import { ListViewColumnFieldNames, SortOptionsL03s } from '../common/Enum';
import { IColumn } from '@fluentui/react';
import { L02Detail } from '../components/Panels/L02DetailsPanel/l02DetailsPanel';

export function getCurrentHost(): string {
  return `${window.location.protocol}//${window.location.host}`;
}

export function availableOptionalComponents(): boolean {
  var selectedOffers: ApiType.L03[] = InstallationStateContainer.getSelectedOffers();
  var availableOptionalComponents: ApiType.L03[] = selectedOffers.filter(
    (offer: ApiType.L03) => offer.optionalComponents?.length !== 0
  );
  if (availableOptionalComponents.length !== 0) {
    return true;
  } else {
    return false;
  }
}

export function getOptionalComponentRowKeys(optionalComponents: ApiType.OptionalComponent[]): string[] {
  var optionalComponentRowKeys: string[] = [];
  for (const component of optionalComponents) {
    if (component.rowKey) {
      optionalComponentRowKeys.push(component.rowKey);
      if (!optionalComponentRowKeys.includes(component.rowKey) && component.additionalDeploymentParametersAvailable) {
        optionalComponentRowKeys.push(component.rowKey);
      }
    }
  }

  return optionalComponentRowKeys;
}

export function filterSampleDataDeploymentProgressStatus(
  deploymentProgressStatus: ApiType.DeploymentL03ProgressStatus[]
): ApiType.DeploymentL03ProgressStatus[] {
  var aggregateSampleData: ApiType.DeploymentL03ProgressStatus = {
    deploymentStatus: Enum.DeploymentStatus[Enum.DeploymentStatus.NotStarted],
    failedPackages: 0,
    l03Name: Constants.OptionalComponent.SampleData,
    requestType: Constants.DeploymentRequestV2Type.Create,
    succeededPackages: 0,
    totalPackages: 0,
  };
  var filteredDeploymentProgressStatus: ApiType.DeploymentL03ProgressStatus[] = [];
  var totalSampleData: number = 0;
  deploymentProgressStatus.forEach((progressStatus: ApiType.DeploymentL03ProgressStatus) => {
    if (progressStatus.l03Name === Constants.OptionalComponent.SampleData) {
      aggregateSampleData.deploymentStatus = progressStatus.deploymentStatus;
      aggregateSampleData.failedPackages += progressStatus.failedPackages;
      aggregateSampleData.succeededPackages += progressStatus.succeededPackages;
      aggregateSampleData.totalPackages += progressStatus.totalPackages;
      if (progressStatus.requestType === Constants.DeploymentRequestV2Type.Update) {
        aggregateSampleData.requestType = Constants.DeploymentRequestV2Type.Update;
      }
      totalSampleData += 1;
    } else {
      filteredDeploymentProgressStatus.push(progressStatus);
    }
  });
  if (totalSampleData > 0) {
    filteredDeploymentProgressStatus.push(aggregateSampleData);
  }
  return filteredDeploymentProgressStatus;
}

export function getCheckedDependencies(
  preDependencies: ApiType.L03Dependency[],
  dependencyCheckResult: ApiType.DependencyCheckFetchXMLResult[]
): Type.DependencyChecked[] {
  var dependencies: Type.DependencyChecked[] = getUniqDependencies(preDependencies).reduce(
    (acc: any, current: ApiType.L03Dependency) => {
      const checked = dependencyCheckResult?.find(
        (cd: ApiType.DependencyCheckFetchXMLResult) => cd.l03DependencyId === current.rowKey
      );
      if (!checked) {
        return acc.concat({
          ...current,
          isConfigured: true,
          isDeployed: true,
        });
      }
      return acc.concat({
        ...current,
        isDeployed: checked.isDeployed,
        isConfigured: checked.isConfigured,
      });
    },
    []
  );

  dependencies = dependencies.filter((x: ApiType.L03Dependency) => x.type !== Constants.AZURE);
  return dependencies;
}

export const findEnvironmentBasedOnPackageType = (
  l03s: ApiType.L03[],
  packageType: ApiType.PackageType,
  propertyName: string
): string | undefined => {
  var matchingOffers: ApiType.L03[] = [];
  l03s.forEach((entity: ApiType.L03) => {
    entity.packages?.forEach((a: ApiType.Package) => {
      if (a.packageType === packageType) {
        matchingOffers.push(entity);
      }
    });
  });

  if (matchingOffers.length !== 0) {
    const selectedEntity: any = matchingOffers[0];
    return selectedEntity[propertyName as string];
  } else {
    return undefined;
  }
};

export const getPBIL03RowKeys = (
  selectedOptionalComponents: ApiType.OptionalComponent[]
): ApiType.PBIPayload | undefined => {
  var pbiL03RowKeys: string[] = [];
  var pbiPayload: ApiType.PBIPayload | undefined = undefined;

  selectedOptionalComponents.forEach((component: ApiType.OptionalComponent) => {
    component.packages
      ?.filter((a: ApiType.Package) => a.packageType === ApiType.PackageType.PBITemplateApp)
      .forEach((b: ApiType.Package) => {
        if (component.rowKey) {
          pbiL03RowKeys.push(component.rowKey);
        }
      });
  });

  if (pbiL03RowKeys.length > 0) {
    pbiPayload = {
      l03RowKeys: pbiL03RowKeys,
    };
  }

  if (pbiPayload) {
    return pbiPayload;
  } else {
    return undefined;
  }
};

export const generateDeploymentPayload = (
  selectedOptionalComponents: ApiType.OptionalComponent[],
  deploymentDefinition: ApiType.DeploymentDefinitionEntity
): ApiType.DeploymentPayloadV2 => {
  const data: ApiType.DeploymentPayloadV2 = {
    deploymentName: deploymentDefinition.name,
    instanceApiUrl: findDataverseEnivronmentProperties(deploymentDefinition.l03s!, 'instanceApiUrl'),
    instanceId: findDataverseEnivronmentProperties(deploymentDefinition.l03s!, 'instanceId') ?? '',
    instanceName: findDataverseEnivronmentProperties(deploymentDefinition.l03s!, 'instanceName'),
    instanceType: undefined,
    installSampleData: false,
    installAdditionalSampleData: false,
    l03RowKeys: [],
    ciInstanceId:
      findEnvironmentBasedOnPackageType(deploymentDefinition.l03s!, ApiType.PackageType.CI, 'instanceId') ?? undefined,
    ciInstanceName: findEnvironmentBasedOnPackageType(
      deploymentDefinition.l03s!,
      ApiType.PackageType.CI,
      'instanceName'
    ),
    l01RowKey: deploymentDefinition.l01RowKey,
    armScopeToOffersDict: undefined,
    azureDeploymentRegion: undefined,
    azureSubscriptionId: undefined,
    azureSubscriptionName: undefined,
    azureResourceGroupName: undefined,
    additionalDeploymentParameters: undefined,
    includeAzureDeployment: false,
    pbiTemplateAppPayload: getPBIL03RowKeys(selectedOptionalComponents),
    optionalComponentRowKeys: undefined,
    installOptionalComponent: false,
    environmentId: null,
  };

  return data;
};

export const findDataverseEnivronmentProperties = (l03s: ApiType.L03[], propertyName: string): string | undefined => {
  var dataverseProperty: string | undefined = findEnvironmentBasedOnPackageType(
    l03s,
    ApiType.PackageType.Solution,
    propertyName
  );
  if (!dataverseProperty) {
    dataverseProperty = findEnvironmentBasedOnPackageType(l03s, ApiType.PackageType.Data, propertyName)
      ? findEnvironmentBasedOnPackageType(l03s, ApiType.PackageType.Data, propertyName)
      : findEnvironmentBasedOnPackageType(l03s, ApiType.PackageType.Data2, propertyName);
  }

  return dataverseProperty;
};

export const filterSampleDataOptionalComponents = (optionalComponents: ApiType.OptionalComponent[]) => {
  var filteredOptionalComponents: ApiType.OptionalComponent[] = [];
  optionalComponents.forEach((oc: ApiType.OptionalComponent) => {
    if (oc.type !== ApiType.PackageType.Data) {
      filteredOptionalComponents.push(oc);
    } else {
      var checkIfSampleData: ApiType.OptionalComponent[] = filteredOptionalComponents.filter(
        (oc: ApiType.OptionalComponent) => {
          return oc.type === ApiType.PackageType.Data;
        }
      );
      if (checkIfSampleData.length === 0) {
        filteredOptionalComponents.push(oc);
      }
    }
  });

  return filteredOptionalComponents;
};

export const getAllSampleDataOptionalComponents = (
  selectedL03s: ApiType.L03[],
  selectedOptionalComponents: ApiType.OptionalComponent[]
): ApiType.OptionalComponent[] => {
  var totalOptionalComponents: ApiType.OptionalComponent[] = [];
  selectedL03s.forEach((offer: ApiType.L03) => {
    if (offer.optionalComponents) {
      offer.optionalComponents.forEach((optionalComponent: ApiType.OptionalComponent) => {
        totalOptionalComponents.push(optionalComponent);
      });
    }
  });

  var sampleDataOptionalComponents: ApiType.OptionalComponent[] = totalOptionalComponents.filter(
    (oc: ApiType.OptionalComponent) => {
      return oc.type === ApiType.PackageType.Data;
    }
  );

  var allSelectedAndSampleDataOptionalComponents = sampleDataOptionalComponents.concat(selectedOptionalComponents);

  var uniqueallSelectedAndSampleDataOptionalComponents: ApiType.OptionalComponent[] = [];
  allSelectedAndSampleDataOptionalComponents.forEach((oc: ApiType.OptionalComponent) => {
    var index: number = uniqueallSelectedAndSampleDataOptionalComponents.findIndex((item) => item.rowKey === oc.rowKey);
    if (index <= -1) {
      uniqueallSelectedAndSampleDataOptionalComponents.push(oc);
    }
  });

  return uniqueallSelectedAndSampleDataOptionalComponents;
};

export const isAnyOptionalComponentsSampleData = (selectedOptionalComponents: ApiType.OptionalComponent[]): boolean => {
  var sampleDataOptionalComponents: ApiType.OptionalComponent[] = selectedOptionalComponents.filter(
    (oc: ApiType.OptionalComponent) => {
      return oc.type === ApiType.PackageType.Data;
    }
  );

  if (sampleDataOptionalComponents.length !== 0) {
    return true;
  } else {
    return false;
  }
};

export const getFloatingDeployCharLimit = (viewportWidth: number): number => {
  if (viewportWidth <= 960 && viewportWidth > 480) {
    return Constants.FloatingDeployCharLimit200xZoom;
  } else if (viewportWidth <= 480) {
    return Constants.FloatingDeployCharLimit400xZoom;
  } else {
    return Constants.FloatingDeployCharLimitDefault;
  }
};

export const getDisplayOverflowOffers = (
  selectedOffers: ApiType.L03[],
  charLimit: number
): { displayOffers: ApiType.L03[]; overflowOffers: ApiType.L03[] } => {
  let combinedCharLength = 0;
  let displayOffers: ApiType.L03[] = [];
  let overflowOffers: ApiType.L03[] = [];

  if (selectedOffers.length === 1) {
    displayOffers = selectedOffers;
  } else {
    for (var offer of selectedOffers) {
      if (combinedCharLength + offer.name.length <= charLimit) {
        displayOffers.push(offer);
        combinedCharLength += offer.name.length;
      } else {
        overflowOffers.push(offer);
      }
    }
  }

  return { displayOffers, overflowOffers };
};

export const getAllIndustryVerticals = (
  showCapabilities: boolean,
  solution: ApiType.L01
): ApiType.IndustryVertical[] => {
  if (showCapabilities) {
    let industryVerticals: ApiType.IndustryVertical[] = solution.industryVerticals;
    if (industryVerticals?.length > 0) {
      if (!findSelectedVertical(solution, Constants.All)) {
        industryVerticals.push({
          partitionKey: '',
          rowKey: Constants.All,
          description: '',
          icon: '',
          modifiedBy: '',
          status: '',
          timestamp: new Date(),
          solutionName: Constants.All,
          uniqueName: Constants.All,
          l02s: solution.l02s,
        });
      }
    }
    return industryVerticals;
  } else {
    return [];
  }
};

export const getAllCapabilities = (
  solution: ApiType.L01,
  industryVerticalsEnabled: boolean,
  selectedIndustryVerticalKey: string
): Type.L02Entity[] => {
  if (
    industryVerticalsEnabled &&
    !Utils.isNullOrUndefined(selectedIndustryVerticalKey) &&
    selectedIndustryVerticalKey !== Constants.All
  ) {
    let vertical: ApiType.IndustryVertical = findSelectedVertical(solution, selectedIndustryVerticalKey);
    if (vertical) {
      const verticalsL02s: Array<string> = vertical.l02s?.map((l02: Type.L02Entity) => l02.rowKey);
      return verticalsL02s?.map((l02RowKey) =>
        solution.l02s?.find(
          (l02: ApiType.L02Entity) => l02.rowKey === l02RowKey && l02.l02Type === Constants.Capability
        )
      );
    }
  }
  return solution.l02s!.filter((l02: Type.L02Entity) => l02.l02Type === Constants.Capability);
};

export const getL02RowKeysForSelectedOffers = (solution: ApiType.L01, selectedOffers: ApiType.L03[]): Array<string> => {
  return selectedOffers?.map((offer) => solution.l02s?.find((l02) => l02.rowKey === offer.parentL02Id).rowKey);
};

export const getL02PanelData = (solution: ApiType.L01) => {
  let l02data: L02Detail[] = solution.l02s.map((l02) => {
    const name = l02.l02Type === Constants.Capability ? l02.capabilityName : l02.name;
    const description = l02.l02Type === Constants.Capability ? l02.capabilityDescription : l02.description;
    return { name, description };
  });
  return l02data;
};

export const getSelectedIndustryVerticalsCapabilities = (
  solution: ApiType.L01,
  selectedL02RowKeys: string[]
): L02Capability[] => {
  return solution?.industryVerticals?.map((iv) =>
    iv.l02s?.find((l02) => selectedL02RowKeys?.find((l02RowKey) => l02RowKey === l02.rowKey))
  );
};

export const findSelectedVerticalByL02RowKey = (solution: ApiType.L01, l02RowKey: string): ApiType.IndustryVertical => {
  return solution.industryVerticals?.find((vertical) => vertical.l02s.find((l02) => l02.rowKey === l02RowKey));
};

export const findSelectedVertical = (
  solution: ApiType.L01,
  selectedIndustryVerticalKey: string
): ApiType.IndustryVertical => {
  return solution.industryVerticals?.find((vertical) => vertical.rowKey === selectedIndustryVerticalKey);
};

export const getVerticalL02RowKeys = (solution: ApiType.L01, selectedIndustryVerticalKey: string): string[] => {
  var vertical = findSelectedVertical(solution, selectedIndustryVerticalKey);
  if (vertical) {
    return vertical?.l02s.reduce((acc, current) => {
      acc.push(current.rowKey);
      return acc;
    }, []);
  } else {
    return [];
  }
};

export function shortenSolutionName(solutionName: string) {
  const newSolutionName = solutionName.replace('Microsoft', '').replace('Cloud for', '').trim();
  return newSolutionName;
}

export function sortProductsByName(products: ApiType.L01[] | AssociatedIndustryVertical[]) {
  products.sort((a, b) => {
    const productName1 = (a.name || shortenSolutionName(a.solutionName)).replace(/\s/g, '');
    const productName2 = (b.name || shortenSolutionName(b.solutionName)).replace(/\s/g, '');
    if (productName1 < productName2) {
      return -1;
    } else if (productName1 > productName2) {
      return 1;
    } else {
      return 0;
    }
  });
}
export function parseAssociatedIndustryVerticals(solution: ApiType.L01 | null) {
  if (solution?.associatedIndustryVerticals && !Utils.isNullOrUndefinedOrEmpty(solution.associatedIndustryVerticals)) {
    if (typeof solution.associatedIndustryVerticals !== 'string') {
      return JSON.parse(JSON.stringify(solution.associatedIndustryVerticals));
    }
    return JSON.parse(solution.associatedIndustryVerticals);
  }
  return [];
}

export const hasAppSourceLink = (l03: ApiType.L03): boolean => {
  return !Utils.isNullOrUndefinedOrEmpty(l03.appSourceLink);
};

export const getPartnerSolutionList = (l02: Type.L02Entity, name: string) => {
  const partnerSolutionList = l02.l03s.filter((l03) => hasAppSourceLink(l03));
  if (!name) return partnerSolutionList;
  return partnerSolutionList?.slice(0, 8);
};

export const getFirstPartySolutionList = (l02: Type.L02Entity) => {
  window.numofL03RecordsFetched = 0;
  const records = l02.l03s
    .filter(
      (l03) =>
        !hasAppSourceLink(l03) &&
        Utils.isNullOrUndefinedOrEmpty(l03?.flights) &&
        l03?.availability !== Constants.L03_AVAILABILITY_PRIVATE_PREVIEW &&
        l03?.availability !== Constants.L03_AVAILABILITY_PUBLIC_PREVIEW
    )
    .sort((a: { order: number }, b: { order: number }) => a.order - b.order);

  window.numofL03RecordsFetched = records.length;

  return records;
};

export const getFirstPartyPreviewSolutionList = (l02: Type.L02Entity) => {
  return l02.l03s
    .filter(
      (l03) =>
        !hasAppSourceLink(l03) &&
        (!Utils.isNullOrUndefinedOrEmpty(l03?.flights) ||
          l03?.availability === Constants.L03_AVAILABILITY_PUBLIC_PREVIEW)
    )
    .sort((a: { order: number }, b: { order: number }) => a.order - b.order);
};

export const getFirstPartyPrivatePreviewSolutionList = (l02: Type.L02Entity) => {
  return l02.l03s
    .filter(
      (l03) =>
        !hasAppSourceLink(l03) &&
        !Utils.isNullOrUndefinedOrEmpty(l03?.availability) &&
        l03?.availability === Constants.L03_AVAILABILITY_PRIVATE_PREVIEW
    )
    .sort((a: { order: number }, b: { order: number }) => a.order - b.order);
};

export const applySortL03s = (sortOrder: string, l03s?: ApiType.L03[]) => {
  if (Utils.isNullOrUndefined(l03s)) return l03s;
  switch (sortOrder) {
    case SortOptionsL03s.SortAscending:
      l03s.sort((a: { name: string }, b: { name: string }) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name > b.name ? 1 : 0
      );
      break;
    case SortOptionsL03s.SortDescending:
      l03s.sort((a: { name: string }, b: { name: string }) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? -1 : a.name < b.name ? 1 : 0
      );
      break;
    default:
      break;
  }
  return l03s;
};

export const applySortDeployments = (sortOrder: string, deployments?: ApiType.DeploymentDefinitionEntity[]) => {
  if (Utils.isNullOrUndefined(deployments)) return deployments;
  deployments.forEach((deployment) => {
    deployment.timestamp = new Date(deployment.timestamp);
  });

  switch (sortOrder) {
    case SortOptionsL03s.SortNewest:
      deployments.sort((a, b) => {
        return sortByType(true, a.timestamp.getTime(), b.timestamp.getTime());
      });
      break;
    case SortOptionsL03s.SortOldest:
      deployments.sort((a, b) => {
        return sortByType(false, a.timestamp.getTime(), b.timestamp.getTime());
      });
      break;
    case SortOptionsL03s.SortAscending:
      deployments.sort((a, b) => {
        return sortByType(true, a.name.toLowerCase(), b.name.toLowerCase());
      });
      break;
    case SortOptionsL03s.SortDescending:
      deployments.sort((a, b) => {
        return sortByType(false, a.name.toLowerCase(), b.name.toLowerCase());
      });
      break;
    default:
      break;
  }
  return deployments;
};

export const sortByType = (isDescending: boolean, firstValue, secondValue) => {
  if (typeof firstValue === 'string' && typeof secondValue === 'string') {
    return isDescending ? firstValue.localeCompare(secondValue) : secondValue.localeCompare(firstValue);
  } else {
    return isDescending ? secondValue - firstValue : firstValue - secondValue;
  }
};

export const getBusinessNeedNamesByL03 = (l03: ApiType.L03, solution: ApiType.L01) => {
  const id = l03.rowKey;
  const businessNeeds = solution.l02s?.filter(
    (l02) => l02.l02Type !== Constants.Vertical && l02.l03s?.find((l03: ApiType.L03) => l03.rowKey === id)
  );
  const businessNeedsNames = [];
  businessNeeds?.forEach((l02: Type.L02Capability) => {
    if (l02.l02Type !== Constants.Vertical) {
      businessNeedsNames.push(l02.capabilityName);
    }
  });
  return businessNeedsNames;
};

export const filterAzureOffers = (l03s, considerOC) => {
  return l03s.filter((l03) => {
    if (l03.additionalDeploymentParametersAvailable === true) {
      return true;
    }

    if (considerOC && l03.optionalComponents) {
      return l03.optionalComponents.some(
        (optionalComponent) => optionalComponent.additionalDeploymentParametersAvailable === true
      );
    }

    return false;
  });
};

export const getSelectedSortKey = (column: IColumn, isSortedDescending: boolean) => {
  let selectedSortKey = '';
  switch (column.fieldName) {
    case ListViewColumnFieldNames.Solutions:
    case ListViewColumnFieldNames.DeploymentName:
      selectedSortKey = isSortedDescending ? SortOptionsL03s.SortAscending : SortOptionsL03s.SortDescending;
      break;
    case ListViewColumnFieldNames.LastModified:
      selectedSortKey = isSortedDescending ? SortOptionsL03s.SortNewest : SortOptionsL03s.SortOldest;
      break;
    default:
      break;
  }
  return selectedSortKey;
};

export const getIsSortDescendingInitialValue = (selectedSort: string) => {
  switch (selectedSort) {
    case SortOptionsL03s.SortAscending:
    case SortOptionsL03s.SortNewest:
      return true;
    case SortOptionsL03s.SortDescending:
    case SortOptionsL03s.SortOldest:
    default:
      return false;
  }
};

export const getSelectedSortFieldNames = (selectedSort: string) => {
  const solutions = ListViewColumnFieldNames.Solutions.toString();
  const deploymentName = ListViewColumnFieldNames.DeploymentName.toString();
  const lastModified = ListViewColumnFieldNames.LastModified.toString();
  switch (selectedSort) {
    case SortOptionsL03s.SortAscending:
    case SortOptionsL03s.SortDescending:
      return [solutions, deploymentName];
    case SortOptionsL03s.SortNewest:
    case SortOptionsL03s.SortOldest:
      return [lastModified];
    default:
      return [];
  }
};

export const getJSONParsedArrayFromString = (itemsInString: string) => {
  if (!Utils.isNullOrUndefinedOrEmpty(itemsInString)) {
    return JSON.parse(itemsInString);
  } else {
    return [];
  }
};
