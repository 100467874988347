export function getRenderSettings(top, initialRenderData, shellDataOverrides) {
  return {
    top,
    initialRenderData,
    shellDataOverrides,
    workloadHelpSubLinks: [
      {
        Id: 'helpMenuDocumentation',
        Text: 'HelpMenu.Documentation',
        Url: '/',
        TargetWindow: '_blank',
      },
      {
        Id: 'helpMenuCommunity',
        Text: 'HelpMenu.Community',
        Url: "'",
        TargetWindow: '_blank',
      },
      {
        Id: 'helpMenuIdeas',
        Text: 'HelpMenu.Ideas',
        Url: '/',
        TargetWindow: '_blank',
      },
      {
        Id: 'helpMenuTeamblog',
        Text: 'HelpMenu.Teamblog',
        Url: '/',
        TargetWindow: '_blank',
      },
      {
        Id: 'helpMenuSupport',
        Text: 'HelpMenu.Support',
        Url: '/',
        TargetWindow: '_blank',
      },
      {
        Id: 'helpMenuMicrosoftPrivacyStatement',
        Text: 'HelpMenu.MicrosoftPrivacyStatement',
        Url: '/',
        TargetWindow: '_blank',
      },
    ],
    isThinHeader: true,
  };
}

/*
declare global {
    interface Window {
        O365Shell: IO365ShellShim | null;
    }
}
*/

export const OfficeHeaderShellBootstrapHost = 'https://shell.cdn.office.net';
const OfficeHeaderShellBootstrapUrl = `${OfficeHeaderShellBootstrapHost}/api/shellbootstrapper/business/oneshell`;

export function shimOfficeHeader(onLoad, onError) {
  const officeHeaderScriptElement = document.createElement('script');
  officeHeaderScriptElement.type = 'text/javascript';
  officeHeaderScriptElement.src = OfficeHeaderShellBootstrapUrl;
  officeHeaderScriptElement.crossOrigin = 'anonymous';
  officeHeaderScriptElement.onload = onLoad;
  officeHeaderScriptElement.onerror = onError;
  var top = document.getElementById('top');
  if (!top.childNodes.length) {
    document.body.appendChild(officeHeaderScriptElement);
  }
}
