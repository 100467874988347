import * as React from 'react';
import { MessageBar, IStackProps, Stack, Link, MessageBarType } from '@fluentui/react';
import { FC } from 'react';
import styles from '../../armStyling/index.styles';
import { messageConfigType } from '../../../../../../common/Type';
import { FormattedMessage, useIntl } from 'react-intl';

interface IMessageBarControlProps {
  messageHeader?: string;
  messagePrimaryDescription?: string;
  messagePrimaryConfigurationList?: messageConfigType[];
  messageSecondaryDescription?: string;
  messageSecondaryConfigurationList?: messageConfigType[];
  ancillaryMessage?: string;
  helperLink?: string;
  messageBarType: string;
}

const verticalStackProps: IStackProps = {
  grow: true,
  tokens: { childrenGap: 20 },
};

const MessageBarControl: FC<IMessageBarControlProps> = (props: IMessageBarControlProps): JSX.Element => {
  const intlShape = useIntl();
  const {
    messageHeader,
    messagePrimaryDescription,
    messagePrimaryConfigurationList,
    messageSecondaryDescription,
    messageSecondaryConfigurationList,
    ancillaryMessage,
    helperLink,
    messageBarType,
  } = props;

  return (
    <>
      <Stack {...verticalStackProps}>
        <MessageBar
          isMultiline={true}
          ariaLabel={messagePrimaryDescription}
          dismissButtonAriaLabel="Close"
          messageBarType={messageBarType === 'warning' ? MessageBarType.warning : MessageBarType.info}
        >
          {messageHeader && (
            <b>
              {' '}
              {intlShape.formatMessage({ id: messageHeader })} <br />
            </b>
          )}

          {messagePrimaryDescription && (
            <FormattedMessage
              id={messagePrimaryDescription}
              values={{
                Link: (sbdc: string) => (
                  <Link
                    className={styles.linkColor}
                    href="https://go.microsoft.com/fwlink/?linkid=2124010"
                    target="_blank"
                    underline={true}
                    rel="noopener noreferrer"
                  >
                    {sbdc}
                  </Link>
                ),
              }}
            />
          )}

          {messagePrimaryConfigurationList && (
            <ul>
              {messagePrimaryConfigurationList?.map(
                (item, i) =>
                  (item.tagType === 'bold' && createBoldBulletFunction(i, item)) ||
                  (item.tagType === 'link' && createLinkBulletList(i, item))
              )}
            </ul>
          )}
          {messageSecondaryDescription && (
            <FormattedMessage
              id={messageSecondaryDescription}
              values={{
                b: (contributor: string) => <b>{contributor}</b>,
              }}
            />
          )}
          {messageSecondaryConfigurationList && (
            <ul>
              {messageSecondaryConfigurationList?.map(
                (item, i) => item.tagType === 'bold' && createBoldBulletFunction(i, item)
              )}
            </ul>
          )}
          {ancillaryMessage && (
            <p>
              {' '}
              {intlShape.formatMessage({ id: ancillaryMessage })} <br />
            </p>
          )}
          {helperLink && (
            <FormattedMessage
              id={helperLink}
              values={{
                Link: (learnmore: string) => (
                  <Link
                    className={styles.linkColor}
                    href="https://docs.microsoft.com/azure/synapse-analytics/security/how-to-set-up-access-control?WT.mc_id=Portal-Microsoft_Azure_Synapse"
                    target="_blank"
                    underline={true}
                    rel="noopener noreferrer"
                  >
                    {learnmore}
                  </Link>
                ),
              }}
            />
          )}
        </MessageBar>
      </Stack>
    </>
  );
};

function createBoldBulletFunction(i: number, item: messageConfigType): false | JSX.Element {
  return (
    <li key={i}>
      <FormattedMessage
        id={item.name}
        values={{
          b: (contributor: string) => <b>{contributor}</b>,
        }}
      />
    </li>
  );
}

function createLinkBulletList(i: number, item: messageConfigType): false | JSX.Element {
  return (
    <li key={i}>
      <FormattedMessage
        id={item.name}
        values={{
          Link: (synapserbac: string) => (
            <Link
              className={styles.linkColor}
              href="https://go.microsoft.com/fwlink/?linkid=2156451"
              target="_blank"
              underline={true}
              rel="noopener noreferrer"
            >
              {synapserbac}
            </Link>
          ),
        }}
      />
    </li>
  );
}
export default MessageBarControl;
