import { Dropdown, Stack, IconButton, TooltipHost, Text, Link, IDropdownOption } from '@fluentui/react';
import React, { FC, ReactElement, useEffect, useMemo } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DATA_TEST_IDS, L03ByRegionTelemetryPropertyNames } from '../../../../common/Constants';
import { OrchestratorType } from '../../../../common/Enum';
import { RootStore } from '../../../../redux';
import { RegionAvailabilityService } from '../../../../services/regionAvailabilityService';
import { telemetry } from '../../../../services/telemetryService';
import { CIRegionPayload } from '../../../../solutionCenterApi/gen';
import { getRegionBasedonOrchestratorType } from '../../../../utils/regionAvailabilityUtil';
import { stackTokens, regionStyles, dropdownStyles } from '../../regionalStyling/index.styles';

export interface CustomerInsightsRegionsProps {
  regionSettingApplied: (orchestratorType: OrchestratorType, region: string) => void;
  regionPayload: (regionPayload: CIRegionPayload) => void;
}

export const CustomerInsightsRegions: FC<CustomerInsightsRegionsProps> = (
  props: CustomerInsightsRegionsProps
): ReactElement => {
  const intlShape = useIntl();
  const regionsByL03Payload = useSelector((state: RootStore) => state.deployment.regionsByOrchestratorType);
  const regionSelection = getRegionBasedonOrchestratorType(regionsByL03Payload, OrchestratorType.CUSTOMERINSIGHTS);
  const [selectedRegion, setSelectedRegion] = React.useState<string | null>(regionSelection);
  const [regionList, setRegionList] = React.useState<Array<CIRegionPayload> | undefined>();

  useEffect(() => {
    const getCIRegionsList = async () => {
      await RegionAvailabilityService.getAllScaleUnitsCIRegions().then((response: Array<CIRegionPayload>) => {
        telemetry.logTrace(
          `${L03ByRegionTelemetryPropertyNames.CustomerInsightsAllRegions} get Regions service was called`
        );
        setRegionList(response);
      });
    };
    getCIRegionsList();
  }, []);

  const customerInsightsAriaLabelText = intlShape
    .formatMessage(
      { id: 'region_Panel.accessibility.placeholder' },
      {
        regionName: intlShape.formatMessage({
          id: 'region_Panel.CustomerInsights_Dropdown.title',
        }),
      }
    )
    .toString();

  const customerInsightsRegionOptions = useMemo<IDropdownOption[]>(
    () =>
      regionList?.map((region: CIRegionPayload, index: number) => ({
        key: region?.name,
        text: region?.name,
        index: index,
      })),
    [regionList]
  );

  const onCustomerInsightsOptionChange = (option: IDropdownOption): void => {
    const region: CIRegionPayload = regionList?.filter(
      (eachRegion: CIRegionPayload) => option.key === eachRegion?.name
    )[0];
    props.regionSettingApplied(OrchestratorType.CUSTOMERINSIGHTS, region.name);
    setSelectedRegion(region.name);
    props.regionPayload(region);
  };

  return (
    <div className={regionStyles.verticalSpacing} id={DATA_TEST_IDS.customerInsightsRegions}>
      <Stack tokens={stackTokens}>
        <div>
          <Text className={regionStyles.fontBold}>
            <FormattedMessage id="region_Panel.CustomerInsights_Dropdown.title" />
          </Text>
          <TooltipHost
            content={
              <FormattedMessage
                id="region_Panel.CustomerInsights_Dropdown.Tooltip"
                values={{
                  Link: (azureLink: string) => (
                    <Link
                      className={regionStyles.linkColor}
                      href="/"
                      target="_blank"
                      underline={true}
                      rel="noopener noreferrer"
                    >
                      {azureLink}
                    </Link>
                  ),
                }}
              />
            }
            calloutProps={{ gapSpace: 0 }}
          >
            <IconButton
              ariaLabel={intlShape.formatMessage({
                id: 'region_Panel.CustomerInsights_Dropdown.Tooltip',
              })}
              iconProps={{
                iconName: 'Info',
                className: regionStyles.infoIconBtn,
              }}
            />
          </TooltipHost>
        </div>
        <div className={regionStyles.dropdownSpacing}>
          <Dropdown
            options={customerInsightsRegionOptions}
            selectedKey={selectedRegion}
            onChanged={onCustomerInsightsOptionChange}
            errorMessage=""
            placeholder={intlShape.formatMessage({
              id: 'region_Panel.Dropdowns.placeholder',
            })}
            styles={dropdownStyles}
            calloutProps={{ calloutMaxHeight: 270 }}
            ariaLabel={customerInsightsAriaLabelText}
            aria-live={'polite'}
            aria-atomic={true}
          />
        </div>
      </Stack>
    </div>
  );
};
