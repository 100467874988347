import React, { FC, ReactElement, useEffect } from 'react';
import { PrimaryButton, Link, DefaultButton } from '@fluentui/react';
import SuccessTitleDescription from './title-description';
import LearnMoreCard from '../../../components/cards/card-learn-more/LearnMoreCard';
import { RootStore } from '../../../redux';
import successStyles from './success.styles';
import { commonStyles } from '../commonStyles.styles';
import InstallationStateContainer from '../../../stateContainers/installationStateContainer';
import Utils from '../../../utils';
import { telemetry } from '../../../services/telemetryService';
import config, { telemetryConstants } from '../../../config';
import SolutionsTableWithDropdown from './solutions-table/solutionWithDropdown';
import SolutionsStateContainer from '../../../stateContainers/solutionsStateContainer';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import * as ApiType from '../../../solutionCenterApi/gen/index';
import { useSelector } from 'react-redux';
import ProgressService from '../../../services/progressService';
import DeploymentStateContainer from '../../../stateContainers/deploymentStateContainer';
import RuntimeConfigStateContainer from '../../../stateContainers/runtimeConfigStateContainer';
import { createEndUserLaunchLink } from '../../../utils/azureHelper';
import {
  updateLaunchLinkListString,
  getLinkByType,
  replaceDataversePlaceholders,
  replaceCIPlaceholders,
  replacePbiOrFabricPlaceholders,
} from '../../../utils/deployHelper';
import './../../../custom.css';
import { PackageType } from '../../../common/Enum';
import { getEnvironmentTypeString } from '../../../common/EnumUtils';

export interface SuccessProps {
  history: RouteComponentProps['history'];
}

export const Success: FC<SuccessProps> = (props: SuccessProps): ReactElement => {
  const selectedEnvironment = useSelector((state: RootStore) => state.deployment.cdsEnvironment);

  const intl = useIntl();

  const currentSolution: ApiType.L01 = SolutionsStateContainer.getCurrentSolution();
  const selectedCIEnvironment: ApiType.InstanceMetadata = InstallationStateContainer.getSelectedCIInstance();
  const selectedFabricWorkspace: ApiType.Workspace = InstallationStateContainer.getSelectedFabricWorkspace();

  const [orgName, setOrgName] = React.useState<string>('');
  const [geoName, setGeoName] = React.useState<string>('');
  const [workspace, setWorkspace] = React.useState<string>('');
  const [dataverseDeployment, setDataverseDeployment] = React.useState<boolean>(false);

  useEffect(() => {
    const { geo, orgName } =
      selectedEnvironment && selectedEnvironment?.url ? Utils.splitInstanceUrl(selectedEnvironment?.url) : '';
    setGeoName(geo);
    setOrgName(orgName);
    isDeploymentHasDataverse();
    telemetry.logTrace(
      'Success: ' + telemetryConstants.stateManagement.CDS_INSTANCE_ID_SELECTED,
      telemetryConstants.severity.SEVERITY_INFO,
      selectedEnvironment
    );
    let metricName =
      telemetryConstants.metrics.TRACKING_TYPE_PAGE_VISIT_DURATION +
      ': ' +
      telemetryConstants.installation.INSTALLATION_TELEMETRY_SUCCESS_VALUE;

    telemetry.logPageView(telemetryConstants.installation.INSTALLATION_TELEMETRY_SUCCESS_VALUE);
    telemetry.startMetric(metricName);
    return () => {
      let metricName =
        telemetryConstants.metrics.TRACKING_TYPE_PAGE_VISIT_DURATION +
        ': ' +
        telemetryConstants.installation.INSTALLATION_TELEMETRY_SUCCESS_VALUE;

      telemetry.stopMetric(metricName);
    };
  }, []);

  const isDeploymentHasDataverse = () => {
    var selectedL03s: ApiType.L03[] = InstallationStateContainer.getSelectedOffers();
    var selectedOptionalComponents: ApiType.OptionalComponent[] = DeploymentStateContainer.getOptionalComponents();
    var isCurrentDeploymentHasDataverse: boolean = false;
    selectedL03s.forEach((l03: ApiType.L03) => {
      let innerArrayPackages: ApiType.Package[] | undefined = [];
      innerArrayPackages = l03?.packages?.filter(
        (a: ApiType.Package) =>
          a.packageType === ApiType.PackageType.Solution ||
          a.packageType === ApiType.PackageType.Data ||
          a.packageType === ApiType.PackageType.Data2
      );
      if (innerArrayPackages && innerArrayPackages?.length > 0) {
        telemetry.logTrace('Dataverse package exists');
        isCurrentDeploymentHasDataverse = true;
        return;
      }
    });

    var innerArrayOCPackages: ApiType.OptionalComponent[] = selectedOptionalComponents.filter(
      (oc: ApiType.OptionalComponent) =>
        oc.type === ApiType.PackageType.Solution ||
        oc.type === ApiType.PackageType.Data ||
        oc.type === ApiType.PackageType.Data2
    );
    if (innerArrayOCPackages && innerArrayOCPackages?.length > 0) {
      telemetry.logTrace('Dataverse package exists for Optional Components');
      isCurrentDeploymentHasDataverse = true;
      return;
    }

    isCurrentDeploymentHasDataverse ? setDataverseDeployment(true) : setDataverseDeployment(false);
  };

  const handleCloseClick = () => {
    telemetry.logEvents(telemetryConstants.events.CLOSE_BUTTON_SELECTED);
    props.history.push({
      pathname: config.routes.home + currentSolution.solutionName,
      state: {
        showCapabilities: false,
        onCapabilityClick: currentSolution.narrativeEnabled ?? false,
      },
    });
  };

  const getDecoratedOffers = (selectedOffers: ApiType.L03[]) => {
    return selectedOffers.map((current: ApiType.L03) => {
      let endUserLink = replaceDataversePlaceholders(current.endUserLaunchLink, orgName, geoName);
      let endUserLaunchLinkFromList = getLinkByType(
        getEnvironmentTypeString(PackageType.DATAVERSE_PACKAGE),
        current.endUserLaunchLinkList
      );
      endUserLaunchLinkFromList = replaceDataversePlaceholders(endUserLaunchLinkFromList, orgName, geoName);
      current.endUserLaunchLinkList = updateLaunchLinkListString(
        getEnvironmentTypeString(PackageType.DATAVERSE_PACKAGE),
        current.endUserLaunchLinkList,
        endUserLaunchLinkFromList
      );

      if (selectedCIEnvironment) {
        endUserLink = replaceCIPlaceholders(current.endUserLaunchLink, selectedCIEnvironment?.instanceId);
        endUserLaunchLinkFromList = getLinkByType(
          getEnvironmentTypeString(PackageType.CI_PACKAGE),
          current.endUserLaunchLinkList
        );
        endUserLaunchLinkFromList = replaceCIPlaceholders(endUserLaunchLinkFromList, selectedCIEnvironment?.instanceId);
        current.endUserLaunchLinkList = updateLaunchLinkListString(
          getEnvironmentTypeString(PackageType.CI_PACKAGE),
          current.endUserLaunchLinkList,
          endUserLaunchLinkFromList
        );
      }
      if (current.additionalDeploymentParametersAvailable) {
        endUserLink = createEndUserLaunchLink(current.endUserLaunchLink ? current.endUserLaunchLink : '');
        endUserLaunchLinkFromList = getLinkByType(
          getEnvironmentTypeString(PackageType.ARM_PACKAGE),
          current.endUserLaunchLinkList
        );
        if (endUserLaunchLinkFromList != null) {
          endUserLaunchLinkFromList = createEndUserLaunchLink(endUserLaunchLinkFromList);
        }
        current.endUserLaunchLinkList = updateLaunchLinkListString(
          getEnvironmentTypeString(PackageType.ARM_PACKAGE),
          current.endUserLaunchLinkList,
          endUserLaunchLinkFromList
        );
      }

      if (selectedFabricWorkspace) {
        current.endUserLaunchLink = replacePbiOrFabricPlaceholders(endUserLink, selectedFabricWorkspace.workspaceId);
        endUserLaunchLinkFromList = getLinkByType(
          getEnvironmentTypeString(PackageType.FABRIC_PACKAGE),
          current.endUserLaunchLinkList
        );

        endUserLaunchLinkFromList = replacePbiOrFabricPlaceholders(
          endUserLaunchLinkFromList,
          selectedFabricWorkspace.workspaceId
        );
        current.endUserLaunchLinkList = updateLaunchLinkListString(
          getEnvironmentTypeString(PackageType.FABRIC_PACKAGE),
          current.endUserLaunchLinkList,
          endUserLaunchLinkFromList
        );
      }

      const isPbiEntity = current?.packages?.filter(
        (a: ApiType.Package) => a.packageType === ApiType.PackageType.PBITemplateApp
      );
      if (isPbiEntity != null && isPbiEntity.length > 0) {
        telemetry.logTrace('getPBIInstallStatus called to retrieve the workspace details');
        var deploymentId = InstallationStateContainer.getDeploymentId();
        ProgressService.getPBIInstallStatus(deploymentId!, current?.rowKey!).then((response) => {
          if (response) {
            setWorkspace(response?.workspaceId!);
            endUserLaunchLinkFromList = getLinkByType(
              getEnvironmentTypeString(PackageType.PBI_PACKAGE),
              current.endUserLaunchLinkList
            );
            endUserLaunchLinkFromList = replacePbiOrFabricPlaceholders(endUserLaunchLinkFromList, workspace);
            current.endUserLaunchLinkList = updateLaunchLinkListString(
              getEnvironmentTypeString(PackageType.PBI_PACKAGE),
              current.endUserLaunchLinkList,
              endUserLaunchLinkFromList
            );
          }
        });
      }

      return {
        ...current,
        endUserLaunchLink: isPbiEntity ? replacePbiOrFabricPlaceholders(endUserLink, workspace) : endUserLink,
      };
    });
  };

  const getDecoratedOptionalComponents = (selectedOptionalComponents: ApiType.OptionalComponent[]) => {
    return selectedOptionalComponents.map((current: ApiType.OptionalComponent) => {
      if (current.endUserLaunchLink) {
        let endUserLink = current.endUserLaunchLink.replace('{orgname}', orgName).replace('{geo}', geoName);

        if (selectedCIEnvironment) {
          endUserLink = endUserLink.replace('{instanceId}', selectedCIEnvironment?.instanceId);
        }
        if (current.additionalDeploymentParametersAvailable) {
          endUserLink = createEndUserLaunchLink(current.endUserLaunchLink);
        }
        const isPbiEntity = current?.packages?.filter(
          (a: ApiType.Package) => a.packageType === ApiType.PackageType.PBITemplateApp
        );
        if (isPbiEntity != null && isPbiEntity.length > 0) {
          telemetry.logTrace('getPBIInstallStatus called to retrieve the workspace details');
          var deploymentId = InstallationStateContainer.getDeploymentId();
          ProgressService.getPBIInstallStatus(deploymentId!, current?.rowKey!).then((response) => {
            setWorkspace(response?.workspaceId!);
          });
        }

        return {
          ...current,
          endUserLaunchLink: isPbiEntity ? endUserLink.replace('{workspaceId}', workspace) : endUserLink,
        };
      } else {
        return {
          ...current,
        };
      }
    });
  };

  const renderSuccessSection = (environmentId: string | undefined | null) => ({
    title: <FormattedMessage id="success.section.title" />,
    description: (
      <FormattedMessage
        id={
          dataverseDeployment
            ? 'success.section.description.composite'
            : 'success.section.nonDataverse.description.composite'
        }
        values={{
          Link: (success: JSX.Element) => (
            <Link
              className="environmentLinkCss"
              underline={true}
              href={`https://admin.powerplatform.microsoft.com/environments/instance/${environmentId}/hub`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {success}
            </Link>
          ),
        }}
      />
    ),
  });

  const renderExplorerSection = (ecosystemLink: string | undefined, ecosystemLinkText: string | undefined) => ({
    title: <FormattedMessage id="success.explorerSection.title" />,
    description: (
      <>
        <FormattedMessage id="success.explorerSection.description" /> &nbsp;
        <a href={ecosystemLink} target="_blank" rel="noopener noreferrer">
          {ecosystemLinkText}
        </a>
      </>
    ),
  });

  const selectedOffers: ApiType.L03[] | undefined = getDecoratedOffers(InstallationStateContainer.getSelectedOffers());
  const selectedOptionalComponents: ApiType.OptionalComponent[] | undefined = getDecoratedOptionalComponents(
    DeploymentStateContainer.getOptionalComponents()
  );
  const successSection = renderSuccessSection(selectedEnvironment?.id);
  const explorerSection = renderExplorerSection(currentSolution.ecosystemLink, currentSolution.ecosystemLinkText);
  const deploymentSuccessScreenSection = renderDeploymentSuccessScreenSection(selectedOffers);

  return (
    <>
      <div className={commonStyles.mainContainer} role="alert" aria-label={successStyles.topSectionTitle}>
        <div className={successStyles.topSection}>
          <div className={successStyles.topSectionContainer}>
            <h2
              className={`ms-fontSize-20 ms-fontWeight-semibold FontSizes.size20 FontWeights.semibold ${successStyles.topSectionTitle}`}
            >
              {successSection.title}
            </h2>
            <span
              className={`ms-fontSize-14 FontSizes.size14 ms-fontWeight-regular ${successStyles.topSectionSecondaryTitle}`}
            >
              {successSection.description}
            </span>
          </div>
        </div>

        {deploymentSuccessScreenSection.map((deploymentSection) => {
          return (
            <section className={successStyles.deploymentSuccessScreenSection}>
              <div className={successStyles.flexBoxContainer}>
                <div>
                  <img
                    className={successStyles.headerImg}
                    src={
                      deploymentSection.icon !== null && deploymentSection.icon !== ''
                        ? deploymentSection.icon
                        : Utils.getIconSvg('healthcare_for_provider')
                    }
                    alt={intl.formatMessage({ id: 'altText.offer.icon' }, { offerName: deploymentSection.l03Name })}
                    title={intl.formatMessage({ id: 'altText.offer.icon' }, { offerName: deploymentSection.l03Name })}
                  />
                </div>
                {deploymentSection.deploymentSuccessScreenHeader}
              </div>
              <div className={successStyles.deploymentSuccessScreenActions}>
                <ul>{deploymentSection.deploymentSuccessScreenActionNames}</ul>
              </div>
              <div className={successStyles.deploymentSuccessScreenFooter}>
                <DefaultButton
                  text={intl.formatMessage({ id: 'configuration.deploymentSuccessScreenSection.buttontext' })}
                  title={intl.formatMessage({ id: 'configuration.deploymentSuccessScreenSection.buttontext' })}
                  href={deploymentSection.deploymentSuccessScreenLink}
                  target="_blank"
                />
              </div>
            </section>
          );
        })}

        {
          <>
            {selectedOffers?.map((deployedOffer: ApiType.L03, i: number) => {
              return (
                <div key={deployedOffer.rowKey} tabIndex={0}>
                  <SolutionsTableWithDropdown key={deployedOffer.rowKey} solution={deployedOffer} />
                  <br />
                </div>
              );
            })}
            {selectedOptionalComponents?.map((selectedOcOffer: ApiType.OptionalComponent, i: number) => {
              return (
                <div key={selectedOcOffer.rowKey} tabIndex={0}>
                  <SolutionsTableWithDropdown key={selectedOcOffer.rowKey} solution={selectedOcOffer} />
                  <br />
                </div>
              );
            })}
          </>
        }
        {currentSolution.ecosystemLink && currentSolution.ecosystemLinkText ? (
          <SuccessTitleDescription title={explorerSection.title} description={explorerSection.description} />
        ) : null}
        <SuccessTitleDescription
          title={intl.formatMessage({
            id: 'success.learnMore',
          })}
          description={null}
        />
        {currentSolution.learnMoreLink ? (
          <LearnMoreCard
            iconName="learn_more"
            topic={intl.formatMessage({
              id: 'success.learnMore',
            })}
            linkTitle={intl.formatMessage({
              id: 'success.viewDocumentation',
            })}
            linkLocation={currentSolution.learnMoreLink}
          />
        ) : null}
        {currentSolution?.complianceOfferingLink && currentSolution?.complianceOfferingLink ? (
          <LearnMoreCard
            iconName="learn_more"
            topic={intl.formatMessage({
              id: 'success.complainceOffering',
            })}
            linkTitle={intl.formatMessage({
              id: 'success.viewDocumentation',
            })}
            linkLocation={currentSolution.complianceOfferingLink}
          />
        ) : null}
        <div className={commonStyles.footerContainer}>
          <PrimaryButton
            text={intl.formatMessage({
              id: 'buttons.close',
            })}
            onClick={handleCloseClick}
            allowDisabledFocus
          />
        </div>
      </div>
    </>
  );
};

export const renderDeploymentSuccessScreenSection = (offers: ApiType.L03[]) => {
  const runtimeConfig = RuntimeConfigStateContainer.getConfiguration();
  const deploymentSuccessScreenSection = [];
  var parser = new DOMParser();
  offers.forEach((offer) => {
    let icon = offer?.icon;
    let l03Name = offer?.name;
    let deploymentSuccessScreenActionNames = [];
    let deploymentSuccessScreenHeader = null;
    var deploymentSuccessScreenHeaderText = offer?.deploymentSuccessScreenHeaderText;
    var deploymentSuccessScreenLink = offer?.deploymentSuccessScreenLink;
    var doc = parser.parseFromString(deploymentSuccessScreenHeaderText, 'text/html');
    if (!Utils.isNullOrUndefinedOrEmpty(deploymentSuccessScreenHeaderText)) {
      deploymentSuccessScreenHeader = (
        <h4 className={successStyles.deploymentSuccessScreenHeader}>{doc.body.textContent}</h4>
      );
      var deploymentSuccessScreenActions = offer?.deploymentSuccessScreenActions;
      deploymentSuccessScreenActions.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
      });
      deploymentSuccessScreenActions.forEach((deploymentSuccessScreenAction) => {
        var deploymentSuccessScreenActionName = deploymentSuccessScreenAction.name;
        var doc = parser.parseFromString(deploymentSuccessScreenActionName, 'text/html');
        deploymentSuccessScreenActionNames.push(<li>{doc.body.textContent}</li>);
      });
      if (runtimeConfig.featureFlag.enableDeploymentSuccessScreen) {
        deploymentSuccessScreenSection.push({
          deploymentSuccessScreenHeader,
          deploymentSuccessScreenActionNames,
          icon,
          l03Name,
          deploymentSuccessScreenLink,
        });
      }
    }
  });
  return deploymentSuccessScreenSection;
};
