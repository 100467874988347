import React, { FC } from 'react';
import { ReactElement } from 'react';
import styles from '../destination.styles';
import FabricHeader, { FabricHeaderProps } from './fabricHeader';
import InstallationStateContainer from '../../../../stateContainers/installationStateContainer';
import FabricWorkspace from './fabricWorkspace';
import { L03 } from '../../../../solutionCenterApi/gen';
import { PackageDeploymentType } from '../../../../common/Enum';
import FabricSolutionComponentList from './fabricSolutionComponentList';

interface FabricComponentProps {
  stepNumber: string;
}

export const FabricComponentBuilder: FC<FabricComponentProps> = (props: FabricComponentProps): ReactElement => {
  const headerProps: FabricHeaderProps = {
    stepNumber: props.stepNumber,
    solutionNames: InstallationStateContainer.getSelectedOffers().map((offer) => offer.name),
  };
  const fabricL03s: L03[] = InstallationStateContainer.getSelectedOffers().filter((offer: L03) =>
    offer.packages.some((pkg) => pkg.packageType === PackageDeploymentType.Fabric)
  );
  return (
    <div className={styles.destinationContainer}>
      <FabricHeader {...headerProps} />
      <FabricWorkspace />
      <FabricSolutionComponentList solutions={fabricL03s} />
    </div>
  );
};
