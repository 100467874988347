import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import * as ApiType from '../../../solutionCenterApi/gen/index';
import { RootStore } from '../../../redux';
import React from 'react';
import config from '../../../config';
import { connect } from 'react-redux';
import { FabricComponentBuilder } from './fabricWidget/fabricComponentBuilder';
import { getRouteUtil, getStepUtil } from '../../../utils/deployHelper';
import DestinationFooter from './commonWidget/footer';
import { FabricComponentPayloadList } from '../../../redux/deployments/deploymentReducers';
import { validateFabricPayload } from './fabricWidget/validators/fabricPayloadValidator';

type destinationProps = {
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
  fabricWorkspace: ApiType.FabricWorkspace;
  errorValidationFabricWorkspaceType: boolean;
  fabricPayload: FabricComponentPayloadList;
} & WrappedComponentProps;

type destinationState = {
  fabricWorkspace: ApiType.FabricWorkspace | null;
  correlationId: string;
  errorValidationFabricWorkspaceType: boolean;
};

export const mapStateToProps = (state: RootStore) => ({
  fabricWorkspace: state.deployment.fabricWorkspace,
  errorValidationFabricWorkspaceType: state.workspace.errorValidationFabricWorkspaceType,
  fabricPayload: state.deployment.fabricPayload,
});

class DestinationFabric extends React.Component<destinationProps, destinationState> {
  constructor(props: destinationProps) {
    super(props);
    this.state = {
      fabricWorkspace: this.props.fabricWorkspace,
      errorValidationFabricWorkspaceType: this.props.errorValidationFabricWorkspaceType,
      correlationId: null,
    };
  }

  handleNextClick = () => {
    let nextRoute = getRouteUtil(this.props.location.pathname, true);
    this.props.history.push(nextRoute, {
      from: this.props.location.pathname,
    });
  };

  handleCancelClick = () => {
    this.props.history.push(config.routes.home);
  };

  handleBackClick = () => {
    let nextRoute = getRouteUtil(this.props.location.pathname, false);
    this.props.history.push(nextRoute, {
      from: this.props.location.pathname,
    });
  };

  disableNextButton = () => {
    const isPayloadValid = validateFabricPayload(Object.values(this.props.fabricPayload));
    return !isPayloadValid || this.props.errorValidationFabricWorkspaceType || this.props.fabricWorkspace == null;
  };

  render() {
    return (
      <>
        {
          <div>
            <FabricComponentBuilder stepNumber={getStepUtil(this.props.location.pathname)} />
            <DestinationFooter
              isRunningValidation={false}
              isNextDisabled={this.disableNextButton()}
              handleNextClick={this.handleNextClick}
              handleBackClick={this.handleBackClick}
              handleCancelClick={this.handleCancelClick}
            />
          </div>
        }
      </>
    );
  }
}

export default connect(mapStateToProps)(injectIntl(DestinationFabric));
