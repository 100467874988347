import { IDetailsListStyleProps, IDetailsListStyles, IStyleFunction, mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  sortArrowIcon: {
    color: '#2266E3',
  },
  container: {
    marginTop: '10px',
    selectors: {
      '.ms-DetailsRow-cell': {
        width: '25%',
      },
      '.ms-DetailsHeader-cell:first-child': {
        width: '35%',
        flex: '0 0 35%',
      },
      '.ms-DetailsHeader-cell:nth-child(2)': {
        width: '35%',
        flex: '0 0 35%',
      },
    },
  },
  customColumnStyles: {
    headerWrapper: {
      selectors: {
        '.ms-DetailsHeader-cell:first-child': {
          width: '35%',
          flex: '0 0 35%',
        },
        '.ms-DetailsHeader-cell:nth-child(2)': {
          width: '35%',
          flex: '0 0 35%',
        },
      },
    },
  },
});

export const customColumnStyles: IStyleFunction<IDetailsListStyleProps, IDetailsListStyles> = () => {
  return {
    headerWrapper: {
      selectors: {
        '.ms-DetailsHeader-cell:first-child': {
          width: '35%',
          flex: '0 0 35%',
        },
        '.ms-DetailsHeader-cell:nth-child(2)': {
          width: '35%',
          flex: '0 0 35%',
        },
      },
    },
  };
};
export default styles;
