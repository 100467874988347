import * as React from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { DefaultButton, Link, PrimaryButton } from '@fluentui/react';
import { CreationResult } from './create-result';
import { environmentCreationStates } from '../../../common/Constants';
import { useIntl } from 'react-intl';
import { linkStyle, panelHeaderTextStyles, primaryButtonStyles } from './create-styles';
import { Panel } from 'office-ui-fabric-react';
import { useDispatch, useSelector } from 'react-redux';
import { setWorkspaceDetails, setWorkspacePayload } from '../../../redux/fabricWorkspace/workspaceAction';
import * as ApiType from '../../../solutionCenterApi/gen';

export interface CreationPanelProps {
  panelOpenText: string;
  panelOpenButtonText: string;
  footerSuccessButtonText: string;
  footerCancelButtonText: string;
  refreshData: () => void;
  createMethod: (worspacePayload: ApiType.FabricWorkspaceRequest) => Promise<ApiType.FabricWorkspace | string>;
  createPage: React.ReactNode;
  createPageTitle: string;
  reduxStateName: string;
  inProgressTitle: string;
  inProgressText: string;
  createFailedTextTitle: string;
  createSuccessTextTitle: string;
  createSuccessText: string;
  createFailedText: string;
}

export const CreationPanel: React.FunctionComponent<CreationPanelProps> = (props: CreationPanelProps) => {
  const intlShape = useIntl();
  const [isOpen, { setTrue: openPanel, setFalse: closePanel }] = useBoolean(false);
  const [envCreationState, setEnvCreationState] = React.useState<string>(environmentCreationStates.NOT_STARTED);
  const [errorMessage, setErrorMessage] = React.useState<string>(undefined);
  const [displayName, setDisplayName] = React.useState<string>(undefined);
  const reduxState = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const startCreationProcess = async (): Promise<void> => {
    setEnvCreationState(environmentCreationStates.IN_PROGRESS);
    let response = await props.createMethod(reduxState[props.reduxStateName].workSpacePayload);
    if (typeof response === 'object' && 'displayName' in response) {
      setEnvCreationState(environmentCreationStates.SUCCESS);
      setDisplayName(response.displayName);
      props.refreshData();
    } else {
      setErrorMessage(String(response));
      setEnvCreationState(environmentCreationStates.FAILED);
    }
  };

  const dismissPanel = async (): Promise<void> => {
    closePanel();
    setEnvCreationState(environmentCreationStates.NOT_STARTED);
    clearInputValues();
  };

  const clearInputValues = (): void => {
    dispatch(setWorkspaceDetails(false));
    dispatch(setWorkspacePayload(undefined));
  };

  const onRenderFooterContent = React.useCallback(
    () =>
      envCreationState === environmentCreationStates.NOT_STARTED && (
        <div>
          <PrimaryButton
            data-createnewenvironmentinputname={displayName}
            onClick={() => startCreationProcess()}
            styles={primaryButtonStyles}
            disabled={!reduxState[props.reduxStateName].isRequiredFieldFilled}
          >
            {props.footerSuccessButtonText}
          </PrimaryButton>
          <DefaultButton onClick={dismissPanel}>{props.footerCancelButtonText}</DefaultButton>
        </div>
      ),
    [dismissPanel]
  );

  const getPanelHeaderId = (envCreationState: string): string => {
    switch (envCreationState) {
      case environmentCreationStates.IN_PROGRESS:
        return props.inProgressTitle;
      case environmentCreationStates.SUCCESS:
        return props.createSuccessTextTitle;
      case environmentCreationStates.NOT_STARTED:
        return props.createPageTitle;
      default:
        return props.createFailedTextTitle;
    }
  };

  return (
    <div>
      {props.panelOpenText} &nbsp;
      <Link onClick={openPanel} data-createnewenvironment={'CreateNewEnvironmentPanelOpen'} style={linkStyle}>
        {props.panelOpenButtonText}
      </Link>
      <Panel
        styles={panelHeaderTextStyles}
        isOpen={isOpen}
        onDismiss={dismissPanel}
        headerText={intlShape.formatMessage({ id: getPanelHeaderId(envCreationState) })}
        closeButtonAriaLabel={intlShape.formatMessage({ id: 'buttons.close' })}
        onRenderFooterContent={onRenderFooterContent}
      >
        {envCreationState === environmentCreationStates.NOT_STARTED ? (
          <div>{props.createPage}</div>
        ) : (
          <CreationResult
            createSuccessText={props.createSuccessText}
            inProgressText={props.inProgressText}
            createFailedText={props.createFailedText}
            displayName={displayName}
            errorMessage={errorMessage}
            envCreationResult={envCreationState}
            onRetryButtonClick={() => startCreationProcess()}
            onCancelButtonClick={dismissPanel}
          />
        )}
      </Panel>
    </div>
  );
};
