import React from 'react';
import { availableLanguages, defaultLang, multiRegionLanguages } from '../config.js';

export const LocaleContext = React.createContext({});

export const LocaleContextProvider = (props) => {
  const newLocale = useLanguage();
  const [locale, setLocale] = React.useState(newLocale);
  return <LocaleContext.Provider value={[locale, setLocale]}>{props.children}</LocaleContext.Provider>;
};

function useLanguage() {
  let newLocale = window.navigator.language;
  if (multiRegionLanguages.includes(newLocale.toLowerCase())) return newLocale;
  else {
    newLocale = newLocale.split(/[-_]/)[0];
    return availableLanguages.includes(newLocale) ? newLocale : defaultLang;
  }
}
