import React, { FC, ReactElement } from 'react';
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import styles, {
  tableHeaderStyles,
  getIsDeployedClassName,
  getIsConfiguredClassName,
  getIsConfiguredIconClassName,
  getIsDeployedIconClassName,
  detailsRowStyles,
} from './preDeploymentConfigurations.styles';
import { FormattedMessage, IntlShape, WrappedComponentProps } from 'react-intl';
import * as Type from '../../../common/Type';
import * as ApiType from '../../../solutionCenterApi/gen/index';
import { FontIcon } from '@fluentui/react';
import Utils from '../../../utils';

type preDeploymentConfigurationsProps = {
  intl: IntlShape;
  solution: ApiType.L03 | ApiType.OptionalComponent;
  preDeploymentDependencies: ApiType.L03Dependency[];
} & WrappedComponentProps;

export const PreDeploymentConfigurations: FC<preDeploymentConfigurationsProps> = (
  props: preDeploymentConfigurationsProps
): ReactElement => {
  const columnHeaders: IColumn[] = [
    {
      key: 'iconColumn',
      name: 'File Type',
      className: styles.fileIconCell,
      iconClassName: styles.fileIconHeaderIcon,
      iconName: 'Page',
      isIconOnly: true,
      fieldName: 'Icon',
      isRowHeader: true,
      styles: tableHeaderStyles,
      minWidth: 36,
      maxWidth: 36,
      onRender: (item: Type.DependencyChecked) => (
        <div aria-busy="true">
          <img src={Utils.getIconSvg(item.icon)} className={styles.fileIconImg} alt={`${item.name}`} />
        </div>
      ),
    },
    {
      key: 'dependencyNameColumn',
      name: props.intl.formatMessage({
        id: 'postDeploymentConfigurations.dependency',
      }),
      fieldName: 'Dependency',
      minWidth: 230,
      maxWidth: 500,
      isResizable: true,
      styles: tableHeaderStyles,
      onRender: (item: Type.DependencyChecked) => (
        <div aria-busy="true">
          <span className={styles.textStyle}>{item.name}</span>
        </div>
      ),
    },
    {
      key: 'dependencyInstalledColumn',
      name: props.intl.formatMessage({
        id: 'postDeploymentConfigurations.installed',
      }),
      fieldName: 'IsDeployed',
      minWidth: 132,
      maxWidth: 132,
      isResizable: true,
      styles: tableHeaderStyles,
      onRender: (item: Type.DependencyChecked) => {
        return (
          <div className={getIsDeployedClassName(item)} aria-busy="true">
            <FontIcon
              iconName={!item.hasOwnProperty('isDeployed') ? 'Accept' : !item.isDeployed ? 'Cancel' : 'Accept'}
              className={getIsDeployedIconClassName(item)}
            />
            <FormattedMessage
              id={
                !item.hasOwnProperty('isDeployed') ? 'constant.yes' : !item.isDeployed ? 'constant.no' : 'constant.yes'
              }
            />
          </div>
        );
      },
    },
    {
      key: 'dependencyConfiguredColumn',
      name: props.intl.formatMessage({
        id: 'postDeploymentConfigurations.configured',
      }),
      fieldName: 'isConfigured',
      minWidth: 161,
      maxWidth: 161,
      styles: tableHeaderStyles,
      onRender: (item: Type.DependencyChecked) => {
        return (
          <div className={getIsConfiguredClassName(item)} aria-busy="true">
            <FontIcon
              iconName={!item.hasOwnProperty('isConfigured') ? 'Accept' : !item.isConfigured ? 'Cancel' : 'Accept'}
              className={getIsConfiguredIconClassName(item)}
            />
            <FormattedMessage
              id={
                !item.hasOwnProperty('isConfigured')
                  ? 'constant.yes'
                  : !item.isConfigured
                  ? 'constant.no'
                  : 'constant.yes'
              }
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.detailsListStyle} aria-busy="true">
      <DetailsList
        styles={detailsRowStyles}
        items={props.preDeploymentDependencies}
        columns={columnHeaders}
        setKey="set"
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
        isHeaderVisible={true}
      />
    </div>
  );
};
