import RestService from './restService';
import config from '../config';
import * as ApiType from '../solutionCenterApi/gen/index';

const ProgressService = {
  getInstallationProgress: async (deploymentId: string): Promise<ApiType.DeploymentStatusResponse> => {
    return await RestService.get({
      endPoint: config.endpoints.getDeploymentStatus + deploymentId + '/status',
    });
  },
  getPBIInstallStatus: async (
    deploymentId: string,
    l03RowKey: string
  ): Promise<ApiType.PBITemplateAppDeploymentRequest> => {
    return await RestService.get({
      endPoint: config.endpoints.getPBIInstallStatus + deploymentId + '/' + l03RowKey,
    });
  },
};

export default ProgressService;
