import React from 'react';
import AuthenticationStateContainer from '../../stateContainers/authenticationStateContainer';
import * as Constants from '../../common/Constants';
import { Modal, PrimaryButton } from 'office-ui-fabric-react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Link } from 'office-ui-fabric-react';
import * as classStyles from './splash.styles';
import { FormattedMessage } from 'react-intl';
import './../../custom.css';

export default class splash extends React.Component {
  findUsername() {
    var msalValues = AuthenticationStateContainer.getAllMsalValues();
    return msalValues
      .filter((value) => {
        var currentValue = JSON.parse(value);
        return currentValue.authorityType === 'MSSTS';
      })
      .map((value) => {
        var currentValue = JSON.parse(value);
        return currentValue.username;
      })[0];
  }

  render() {
    var email = this.findUsername();

    return (
      <div className={classStyles.contentStyles.splashContainer}>
        <Modal
          className="splashscreen"
          isOpen={this.props.showSplashScreen}
          isBlocking={true}
          containerClassName={classStyles.contentStyles.container}
        >
          <div className={classStyles.contentStyles.imageContainer}>
            <img
              className={classStyles.contentStyles.image}
              src={'./assets/images/WelcomeIllustration.svg'}
              alt=""
            ></img>
          </div>
          <div className={classStyles.contentStyles.stackStyle}>
            <Stack>
              <h2 style={classStyles.splashStyles.title}>
                <FormattedMessage
                  id="splashScreen.welcomeTitle"
                  values={{ MicrosoftCloud: Constants.SOLUTION_PRODUCT_NAME }}
                />
              </h2>
              <span style={classStyles.splashStyles.midText}>
                <FormattedMessage id="splashScreen.welcomeText" />
              </span>
              <span style={classStyles.splashStyles.signedIn}>
                <FormattedMessage id="splashScreen.signInAs.composite" values={{ email }} />
              </span>
              <div style={classStyles.splashStyles.line} className="line"></div>
              <div style={classStyles.splashStyles.footer}>
                <div style={classStyles.splashStyles.privacyDiv}>
                  <span style={classStyles.splashStyles.privacy}>
                    <FormattedMessage
                      id="splashScreen.privacyStatementLink.composite"
                      values={{
                        Link: (privacy) => (
                          <Link
                            className="linkColor"
                            underline={true}
                            href="https://go.microsoft.com/fwlink/?LinkId=521839"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {privacy}
                          </Link>
                        ),
                      }}
                    />
                  </span>
                </div>
                <div style={classStyles.splashStyles.continueBtnDiv}>
                  <PrimaryButton
                    onClick={this.props.toggleSplashScreen}
                    className={classStyles.contentStyles.buttonStyle}
                  >
                    <FormattedMessage id="buttons.continue" />
                  </PrimaryButton>
                </div>
              </div>
            </Stack>
          </div>
        </Modal>
      </div>
    );
  }
}
