import { mergeStyleSets, mergeStyles } from '@fluentui/react';
import { ICardTokens, ICardStyles } from '@uifabric/react-cards';
import { IStackTokens, IStackStyles } from 'office-ui-fabric-react';

export const iconClass = mergeStyles({
  fontSize: 12,
  height: 16,
  width: 12,
  marginLeft: '10px',
  marginRight: '6px',
  marginTop: '4px',
});

const styles = mergeStyleSets({
  yourDeploymentsContainer: {
    paddingTop: '42px',
    paddingLeft: '16px',
    '@media(max-width: 960px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'left',
    },
    '@media(max-width: 480px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'left',
      paddingTop: '20px',
    },
  },
  yourDeployments: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 600,
    display: 'block',
  },
  filterSectionContainer: {
    marginTop: '30px',
    marginRight: '32px',
    selectors: {
      '> *': {
        display: 'inline-block',
      },
    },
  },
  gridListButtonContainer: {
    float: 'right',
    paddingRight: 0,
  },
  sortButtonContainer: {
    float: 'right',
    paddingRight: '12px',
  },
  searchComponentContainer: {
    float: 'left',
    paddingRight: '12px',
  },
  deploymentsDescription: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
  },
  deploymentCardsContainer: {
    marginTop: '8px',
    maxWidth: '1104px',
  },
  iconClass: {
    fontSize: 16,
    margin: '0 5px',
    color: '#201F1E',
  },
  cardItemStyle: {
    position: 'relative',
    display: 'flex',
  },
  cardFooterStyle: {
    position: 'relative',
    display: 'flex',
    minHeight: '12px',
    marginBottom: '15px',
  },
  imageStyle: {
    marginLeft: '16px',
    marginTop: '10px',
    marginBottom: '14px',
  },
  headerStyle: {
    marginTop: '10px',
    marginLeft: '8px',
    height: '75px',
  },
  instanceNameStyle: {
    marginLeft: '16px',
    marginTop: '5px',
  },
  imgNameStyle: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
  },
  titleStyle: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 600,
    color: '#323130',
    marginLeft: '5px',
  },
  descriptionStyle: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#605E5C',
    display: '-webkit-box',
    maxWidth: '200px',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  loadingIndicatorStyle: {
    marginTop: '10px',
    width: '1092px',
  },
  showMoreLink: {
    alignitem: 'left',
  },
  listViewColumnDeploymentName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    selectors: {
      span: {
        color: 'rgb(50, 49, 48)',
      },
    },
  },
  listViewColumnEnvironmentName: {
    color: 'rgb(50, 49, 48)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    selectors: {
      span: {
        color: 'rgb(50, 49, 48)',
      },
    },
  },
  listViewColumnLastModified: {
    selectors: {
      span: {
        color: 'rgb(50, 49, 48)',
      },
    },
  },
});

export const cardStyles: ICardStyles = {
  root: {
    backgroundColor: '#FFFFFF',
    minWidth: 225,
    minHeight: 112,
    height: '136px',
    flex: '0 0 calc(33.33% - 14px)',
    maxWidth: 'calc(33.33%)',
    inlineSize: 'min-content',
    overflowWrap: 'anywhere',
    justifyContent: 'space-evenly',
    '@media(min-width: 1920px)': {
      flex: '0 0 calc(25% - 14px)',
      maxWidth: 'calc(25%)',
    },
    '@media(max-width: 960px)': {
      margin: '20 20',
      maxWidth: '352px',
    },
    '@media(max-width: 480px)': {
      margin: '20 20',
      maxWidth: '352px',
    },
  },
};

export const cardTokens: ICardTokens = { childrenMargin: 12 };

export const sectionStackTokens: IStackTokens = { childrenGap: 14 };

export const stackStyles: IStackStyles = {
  root: {
    width: '100%',
    marginTop: '34px',
    '@media(max-width: 960px)': {
      width: '20%',
    },
    selectors: {
      '& > :first-child': {
        marginRight: '25px',
      },
    },
  },
};

export default styles;
