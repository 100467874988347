import React, { FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Panel, PanelType } from 'office-ui-fabric-react';
import styles, { panelContentStyle } from '../progressWidget.styles';
import DeploymentProgressContainer from '../../../install/installation/deploymentProgressContainer';
import * as ApiType from '../../../../solutionCenterApi/gen/index';
import { useIntl } from 'react-intl';

export interface InitialProps {
  isOpen: boolean;
  onDismiss: () => void;
  deploymentFailed: boolean;
  deploymentProgressStatus: ApiType.DeploymentL03ProgressStatus[];
}

export const ProgressPanel: FC<InitialProps> = (props: InitialProps): ReactElement => {
  const intlShape = useIntl();
  return (
    <>
      <Panel
        id="progressPanel"
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        type={PanelType.smallFixedFar}
        hasCloseButton={true}
        styles={panelContentStyle}
        closeButtonAriaLabel={intlShape.formatMessage({ id: 'buttons.close' })}
      >
        <div className={styles.panelContent}>
          <span className={styles.panelTitle}>
            <FormattedMessage id="deploymentDetails.progressPanel.title" />
          </span>
          <div>
            <DeploymentProgressContainer
              success={!props.deploymentFailed}
              deploymentProgressStatus={props.deploymentProgressStatus}
            />
          </div>
        </div>
      </Panel>
    </>
  );
};

export default ProgressPanel;
