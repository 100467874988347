import { mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  root: {
    width: '295px',
    height: '180px',
    background: ' #FFFFFF',
    filter: 'drop-shadow(0px 0.3px 0.9px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1.6px 3.6px rgba(0, 0, 0, 0.13))',
    justifyContent: 'space-between',
    flexDirection: 'column',
    display: 'flex',
    ':hover': {
      filter: 'drop-shadow(0px 1.2px 3.6px rgba(0, 0, 0, 0.1)) drop-shadow(0px 6.4px 14.4px rgba(0, 0, 0, 0.13))',
    },
    ':focus': {
      filter: 'drop-shadow(0px 1.2px 3.6px rgba(0, 0, 0, 0.1)) drop-shadow(0px 6.4px 14.4px rgba(0, 0, 0, 0.13))',
    },
    '@media(max-width: 480px)': {
      width: '220px',
    },
  },
  headerImage: {
    width: '100%',
    height: '120px',
    objectFit: 'cover',
  },
  titleContainer: {
    paddingLeft: '5px',
    paddingBottom: '18px',
    paddingRight: '5px',
    lineHeight: '22px',
    fontWeight: 600,
    textAlign: 'center',
    color: '#323130',
    '@media(max-width: 480px)': {
      minWidth: '200px',
    },
  },
  descriptionContent: {
    lineHeight: '20px',
  },
  rootIndustryVertical: {
    width: '320px',
    height: '300px',
    background: ' #FFFFFF',
    filter: 'drop-shadow(0px 0.3px 0.9px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1.6px 3.6px rgba(0, 0, 0, 0.13))',
    justifyContent: 'space-between',
    flexDirection: 'column',
    display: 'flex',
    ':hover': {
      filter: 'drop-shadow(0px 1.2px 3.6px rgba(0, 0, 0, 0.1)) drop-shadow(0px 6.4px 14.4px rgba(0, 0, 0, 0.13))',
    },
    ':focus': {
      filter: 'drop-shadow(0px 1.2px 3.6px rgba(0, 0, 0, 0.1)) drop-shadow(0px 6.4px 14.4px rgba(0, 0, 0, 0.13))',
    },
    '@media(max-width: 480px)': {
      width: '220px',
    },
  },
  headerImageIndustryVertical: {
    width: '100%',
    height: '98px',
    objectFit: 'cover',
  },
  titleContainerIndustryVertical: {
    paddingLeft: '32px',
    paddingBottom: '18px',
    paddingRight: '32px',
    paddingTop: '16px',
    lineHeight: '22px',
    height: '202px',
    fontWeight: 600,
    color: '#323130',
    '@media(max-width: 480px)': {
      minWidth: '200px',
    },
  },
  descriptionContentIndustryVertical: {
    lineHeight: '22px',
    paddingRight: '13px',
  },
  industryVerticalTitle: {
    color: '#0078D4',
    lineHeight: '20px',
    fontWeight: 400,
    ':hover': {
      cursor: 'pointer',
      filter: 'drop-shadow(0px 1.2px 3.6px rgba(0, 0, 0, 0.1)) drop-shadow(0px 6.4px 14.4px rgba(0, 0, 0, 0.13))',
    },
  },
  industryVerticalList: {
    listStyle: 'none',
    paddingLeft: 0,
    marginTop: '10px',
  },
});

export default styles;
