import { Checkbox, DefaultButton } from '@fluentui/react';
import React from 'react';
import config, { telemetryConstants } from '../../../config';
import Utils from '../../../utils';
import { Capability } from '../../../common/Constants';
import { useIntl } from 'react-intl';
import * as ApiType from '../../../solutionCenterApi/gen/index';
import { v4 as uuid } from 'uuid';
import SolutionsStateContainer from '../../../stateContainers/solutionsStateContainer';
import AuthenticationStateContainer from '../../../stateContainers/authenticationStateContainer';
import styles, { listViewOfferButtonStyles } from './listViewOfferActionButton.styles';

const ListViewOfferActionButton = ({
  offer,
  isChecked,
  onHandleCheckChange,
  handlePreviewSelection,
}: {
  offer: ApiType.L03;
  isChecked: boolean;
  onHandleCheckChange: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  handlePreviewSelection: (value: ApiType.L03) => void;
}) => {
  const uniqueId = uuid();
  const uniqueIdSmall = uniqueId.slice(0, 8);
  const intlShape = useIntl();
  const chkAriaLabelAdded = intlShape.formatMessage({
    id: 'subSolutions.AddedLO3',
  });
  const chkAriaLabelAdd = intlShape.formatMessage({
    id: 'subSolutions.addLO3',
  });
  const tenantId = AuthenticationStateContainer.getTenantId();
  const l01 = SolutionsStateContainer.getCurrentSolution();
  const isLicenseMissing = !Utils.productHasLicense(offer);
  if (Utils.isNullOrUndefinedOrEmpty(l01)) {
    return <Checkbox id={offer.name + '/' + uniqueIdSmall} />;
  }
  const l02 = l01.l02s.find((l02) => l02.rowKey === offer.parentL02Id);
  const l02Name = l02.l02Type === Capability ? l02.capabilityName : l02.name;
  const buttonText = intlShape.formatMessage({
    id: Utils.isNullOrUndefined(offer.appSourceLink)
      ? Utils.getL03ButtonTextId(offer.linkButtonText)
      : intlShape.formatMessage({
          id: 'listViewL02.offerActionSuccess',
        }),
  });
  const onPreviewCardClick = () => {
    handlePreviewSelection(offer);
  };

  if (offer.action === config.offerTypes.Link) {
    return (
      <div>
        <DefaultButton
          styles={listViewOfferButtonStyles}
          href={Utils.isNullOrUndefinedOrEmpty(offer.endUserLaunchLink) ? offer.appSourceLink : offer.endUserLaunchLink}
          target="_blank"
          disabled={isLicenseMissing}
          data-customl01={l01.solutionName}
          data-customl01rowkey={l01.rowKey}
          data-customl02={l02Name}
          data-customl03={offer.name}
          data-customl03rowkey={offer.rowKey}
          data-customtenantId={tenantId}
          data-customl02rowkey={l02.rowKey}
          data-customevent={Utils.getL03ButtonEvent(offer.linkButtonText)}
          onRenderIcon={() => (
            <img
              src={'./Assets/icons/open_new_tab.svg'}
              alt={intlShape.formatMessage({ id: 'altText.listView.openNewTabIcon' })}
            ></img>
          )}
        />
        <span className={styles.listViewOfferButtonTextStyles}>{buttonText}</span>
      </div>
    );
  } else {
    if (!Utils.isNullOrUndefinedOrEmpty(offer?.flights)) {
      return (
        <div>
          <DefaultButton
            onClick={onPreviewCardClick}
            onKeyPress={onPreviewCardClick}
            disabled={isLicenseMissing}
            styles={listViewOfferButtonStyles}
            data-customl01={l01.solutionName}
            data-customl01rowkey={l01.rowKey}
            data-customl02={l02Name}
            data-customl03={offer.name}
            data-customl03rowkey={offer.rowKey}
            data-customtenantId={tenantId}
            data-customl02rowkey={l02.rowKey}
            data-customevent={telemetryConstants.events.TRY_PREVIEW_CLICKED}
            onRenderIcon={() => (
              <img
                src={'./Assets/icons/open_new_tab.svg'}
                alt={intlShape.formatMessage({ id: 'altText.listView.openNewTabIcon' })}
              ></img>
            )}
          />
          <span className={styles.listViewOfferButtonTextStyles}>
            {intlShape.formatMessage({ id: 'preview.card.text' })}
          </span>
        </div>
      );
    }
    return (
      <div
        onClick={isLicenseMissing ? null : onHandleCheckChange}
        onKeyPress={isLicenseMissing ? null : onHandleCheckChange}
      >
        <Checkbox
          id={offer.name + '/' + uniqueIdSmall}
          ariaLabel={isChecked ? chkAriaLabelAdded : chkAriaLabelAdd}
          checked={isChecked}
          disabled={isLicenseMissing}
          label={
            isLicenseMissing
              ? intlShape.formatMessage({
                  id: 'status.licenseMissing',
                })
              : isChecked
              ? intlShape.formatMessage({
                  id: 'subSolutions.AddedLO3',
                })
              : intlShape.formatMessage({
                  id: 'subSolutions.addLO3',
                })
          }
        />
      </div>
    );
  }
};

export default ListViewOfferActionButton;
