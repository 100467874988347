function CountdownTimer() {
  this.startTime = null;
  this.endTime = null;
}

CountdownTimer.prototype.startTimer = function () {
  this.startTime = new Date();
  return this.startTime;
};

CountdownTimer.prototype.stopTimer = function () {
  this.stopTimer = new Date();
  return (this.stopTimer.getTime() - this.startTime.getTime()) / 1000;
};

export default CountdownTimer;
