import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getAzureAccessToken, getPBIAccessToken } from '../../redux/authentication/authenticationActions';
import AuthenticationService from '../../services/authenticationService';
import RestService from '../../services/restService';
import authenticationStateContainer from '../../stateContainers/authenticationStateContainer';
import config from '../../config';
import * as Constants from '../../common/Constants';
import AuthenticationStateContainer from '../../stateContainers/authenticationStateContainer';
import * as Type from '../../common/Type';
import { AuthenticationDispatchTypes } from '../../redux/authentication/authenticationTypes';
import { AppProps } from '../../App';
import RuntimeConfigStateContainer from '../../stateContainers/runtimeConfigStateContainer';
import { ConfigClient } from '../../webAppApi';
import TelemetryWrapper from '../telemetry/telemetryWrapper';

interface AuthProviderState {
  isAuthenticated: boolean;
  tokenResponse?: Type.TokenResponse | string | undefined;
  headerTokenResponse?: Type.TokenResponse | null;
  groupMembershipTokenResponse?: Type.TokenResponse | string;
  cITokenResponse?: Type.TokenResponse | string;
  azureTokenResponse?: Type.TokenResponse | string;
  pbiTokenResponse: '';
  ppacTokenResponse?: Type.TokenResponse | string;
  neptuneTokenResponse?: Type.TokenResponse | string;
  error: any;
}

export default function withAuthProvider(WrappedComponent: ComponentType<AppProps>) {
  const mapDispatchToProps = (dispatch: Dispatch<AuthenticationDispatchTypes>) => ({
    getAzureAccessToken: (tokenResponse: string) => dispatch(getAzureAccessToken(tokenResponse)),
    getPBIAccessToken: (tokenResponse: Type.TokenResponse) => dispatch(getPBIAccessToken(tokenResponse)),
  });

  interface InjectedAuthProviderProps {
    getAzureAccessToken: (tokenResponse: string | null) => void;
    getPBIAccessToken: (tokenResponse: Type.TokenResponse | null) => void;
  }

  class AuthProviderHOC extends React.Component<InjectedAuthProviderProps, AuthProviderState> {
    constructor(props: InjectedAuthProviderProps) {
      super(props);
      this.state = {
        error: null,
        isAuthenticated: false,
        tokenResponse: '',
        headerTokenResponse: { access_token: '' },
        groupMembershipTokenResponse: '',
        azureTokenResponse: '',
        cITokenResponse: '',
        pbiTokenResponse: '',
        ppacTokenResponse: '',
        neptuneTokenResponse: '',
      };
    }

    async componentDidMount() {
      const runtimeConfig = await new ConfigClient().get();
      RuntimeConfigStateContainer.setConfiguration(runtimeConfig);

      await AuthenticationService.getAccessToken(runtimeConfig.tokenScope.solutionCenter, this.setUserToken);
      RestService.init(this.setUserToken);
    }

    componentDidUpdate(prevProps: any, prevState: any) {
      if (this.state.tokenResponse !== prevState.tokenResponse) {
        this.getGroupMembershipToken();
        this.getHeaderAccessToken();
        this.getCIAccessToken();
        this.getAzureAccessToken();
        this.getPBIAccessToken();
        this.getPPACAccessToken();
        this.getNeptuneAccessToken();
      }
    }

    setUserToken = (tokenResponse: Type.TokenResponse) => {
      if (tokenResponse) {
        this.setState({
          isAuthenticated: true,
          tokenResponse: tokenResponse,
        });
      }
    };

    setHeaderToken = (tokenResponse: Type.TokenResponse) => {
      if (tokenResponse) {
        AuthenticationStateContainer.setHeaderAccessToken(tokenResponse.access_token);
        this.setState({ headerTokenResponse: tokenResponse });
      }
    };

    setPPACAccessToken = (tokenResponse: Type.TokenResponse) => {
      if (tokenResponse) {
        AuthenticationStateContainer.setPPACAccessToken(tokenResponse.access_token);
        this.setState({ ppacTokenResponse: tokenResponse });
      }
    };

    setNeptuneAccessToken = (tokenResponse: Type.TokenResponse) => {
      if (tokenResponse) {
        AuthenticationStateContainer.setNeptuneAccessToken(tokenResponse.access_token);
        this.setState({ neptuneTokenResponse: tokenResponse });
      }
    };

    setGroupMembershipToken = (tokenResponse: Type.TokenResponse) => {
      if (tokenResponse) {
        AuthenticationStateContainer.setGroupMembershipToken(tokenResponse.access_token);
        this.setState({ groupMembershipTokenResponse: tokenResponse });
      }
    };

    setAzureAccessToken = (tokenResponse: Type.TokenResponse) => {
      if (tokenResponse) {
        this.props.getAzureAccessToken(tokenResponse.access_token);
      }
    };

    setCIAccessToken = (tokenResponse: Type.TokenResponse) => {
      if (tokenResponse) {
        AuthenticationStateContainer.setCIAccessToken(tokenResponse.access_token);
        this.setState({ cITokenResponse: tokenResponse });
      }
    };

    setPBIAccessToken = (tokenResponse: Type.TokenResponse) => {
      if (tokenResponse) {
        this.props.getPBIAccessToken(tokenResponse);
      }
    };

    getHeaderAccessToken = () => {
      if (process.env.NODE_ENV === Constants.PRODUCTION) {
        const refreshToken = authenticationStateContainer.getRefreshToken();
        const requestData = AuthenticationService.generateRefreshTokenBody(
          refreshToken,
          config.tokenScopes.webShellSopes
        );
        RestService.urlEncodedPost({ data: requestData })
          .then((response) => response?.json())
          .then((responseData) => this.setHeaderToken(responseData));
      }
    };

    getPPACAccessToken = () => {
      const runtimeConfig = RuntimeConfigStateContainer.getConfiguration();
      const refreshToken = authenticationStateContainer.getRefreshToken();
      const requestData = AuthenticationService.generateRefreshTokenBody(refreshToken, runtimeConfig.tokenScope.ppac);
      RestService.urlEncodedPost({ data: requestData })
        .then((response) => response?.json())
        .then((responseData) => this.setPPACAccessToken(responseData));
    };

    getNeptuneAccessToken = () => {
      const runtimeConfig = RuntimeConfigStateContainer.getConfiguration();
      const refreshToken = authenticationStateContainer.getRefreshToken();
      const requestData = AuthenticationService.generateRefreshTokenBody(
        refreshToken,
        runtimeConfig.tokenScope.neptune
      );
      RestService.urlEncodedPost({ data: requestData })
        .then((response) => response?.json())
        .then((responseData) => this.setNeptuneAccessToken(responseData));
    };

    getGroupMembershipToken = () => {
      const refreshToken = authenticationStateContainer.getRefreshToken();
      const requestData = AuthenticationService.generateRefreshTokenBody(
        refreshToken,
        config.tokenScopes.groupMembershipScopes
      );
      RestService.urlEncodedPost({ data: requestData })
        .then((response) => response?.json())
        .then((responseData) => this.setGroupMembershipToken(responseData));
    };

    getAzureAccessToken = () => {
      const refreshToken = authenticationStateContainer.getRefreshToken();
      const requestData = AuthenticationService.generateRefreshTokenBody(refreshToken, config.tokenScopes.azureScopes);
      RestService.urlEncodedPost({ data: requestData })
        .then((response) => response?.json())
        .then((responseData) => this.setAzureAccessToken(responseData));
    };

    getCIAccessToken = () => {
      const refreshToken = authenticationStateContainer.getRefreshToken();
      const requestData = AuthenticationService.generateRefreshTokenBody(refreshToken, config.tokenScopes.cIScopes);
      RestService.urlEncodedPost({ data: requestData })
        .then((response) => response?.json())
        .then((responseData) => this.setCIAccessToken(responseData));
    };

    getPBIAccessToken = () => {
      const refreshToken = authenticationStateContainer.getRefreshToken();
      const requestData = AuthenticationService.generateRefreshTokenBody(refreshToken, config.tokenScopes.pbiScopes);
      RestService.urlEncodedPost({ data: requestData })
        .then((response) => response?.json())
        .then((responseData) => this.setPBIAccessToken(responseData));
    };

    render() {
      return (
        <TelemetryWrapper groupMembershipToken={this.state.groupMembershipTokenResponse}>
          <WrappedComponent
            {...this.props}
            tokenResponse={this.state.tokenResponse}
            headerTokenResponse={this.state.headerTokenResponse}
            cdsEnvironment={null}
          />
        </TelemetryWrapper>
      );
    }
  }

  const ConnectedHoc = connect<undefined, typeof mapDispatchToProps>(null, mapDispatchToProps)(AuthProviderHOC);
  return ConnectedHoc;
}
