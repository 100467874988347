import { mergeStyleSets } from '@fluentui/react';

export const commonStyles = mergeStyleSets({
  mainContainer: {
    padding: '32px 32px 0px',
    marginBottom: '32px',
    minHeight: '550px',
    borderLeft: '1px solid #E1DFDD',
    '@media(max-width: 960px)': {
      width: 400,
    },
    '@media(max-width: 480px)': {
      width: 240,
      paddingLeft: '7px',
    },
  },
  footerContainer: {
    width: 'calc(100%)',
    position: 'fixed',
    padding: '16px',
    bottom: 0,
    background: '#ffff',
    zIndex: 1,
    '@media(max-width: 960px)': {
      left: 0,
    },
  },
});
