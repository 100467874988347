import { telemetryConstants } from '../config';
import EnvironmentService from '../services/environmentsService';
import * as Constants from '../common/Constants';
import * as ApiType from '../solutionCenterApi/gen/index';
import { telemetry } from '../services/telemetryService';
import { TelemetryPropertyNames } from '../common/Constants';
import { OrchestratorType, PackageDeploymentType } from '../common/Enum';
import Utils from '.';
import DependencyService from '../services/dependencyService';

export const validateApplicationPackages = async (
  l01RowKey: string,
  offer: ApiType.OptionalComponent | ApiType.L03
): Promise<boolean> => {
  let isValid: boolean = true;
  let response: ApiType.InstanceValidationResponse;
  let envId: string;
  let customProps: any = {};
  customProps[TelemetryPropertyNames.instanceIdKey] = offer?.instanceId;
  customProps[TelemetryPropertyNames.offersKey] = offer.rowKey;

  if (offer?.isUpdateAvailable === true) {
    let isADataversePackage = Utils.isInstanceTypePresent(offer?.instanceTypeMap, PackageDeploymentType.TPS.toString());

    if (isADataversePackage || offer.instanceType.toLowerCase() === OrchestratorType.DATAVERSE) {
      try {
        telemetry.logTrace(
          `Initiating application validation check for solution: ${offer.name}`,
          telemetryConstants.severity.SEVERITY_INFO,
          customProps
        );

        telemetry.logTrace(
          'Getting environment id for the given existing deployment',
          telemetryConstants.severity.SEVERITY_INFO
        );

        const envs = await EnvironmentService.getEnvironments(l01RowKey);
        const existingEnv: ApiType.Instance[] = envs.filter((env: ApiType.Instance) => env.id === offer.instanceId);

        if (existingEnv.length !== 0) {
          envId = existingEnv[0].environmentId;
          customProps[TelemetryPropertyNames.environmentIdKey] = envId;

          telemetry.logTrace('Environment id obtained:', telemetryConstants.severity.SEVERITY_INFO, customProps);

          response = await EnvironmentService.validateInstanceApplicationPackages(envId, [offer.rowKey]);
          if (!response || response?.validationResult === false) {
            customProps[Constants.TelemetryPropertyNames.correlationIdKey] = response?.correlationId;
            telemetry.logTrace(
              `Instance ApplicationPackages validation failed: ${response?.correlationId}`,
              telemetryConstants.severity.SEVERITY_ERROR,
              customProps
            );
            isValid = false;
          } else {
            isValid = true;
            telemetry.logTrace(
              `Instance ApplicationPackages validation succeeded: ${response?.correlationId}`,
              telemetryConstants.severity.SEVERITY_INFO,
              customProps
            );
          }
        } else {
          telemetry.logTrace(`No matching environment id`, telemetryConstants.severity.SEVERITY_INFO, customProps);
          isValid = false;
        }
      } catch (error) {
        telemetry.logTrace(
          `Instance ApplicationPackages validation failed: ${error}`,
          telemetryConstants.severity.SEVERITY_ERROR
        );
        isValid = false;
      }
    } else {
      telemetry.logTrace(
        'Offer does not contain dataverse components and hence skipping application package validation',
        customProps
      );
    }
  }
  return isValid;
};

export const validateUniqueDependency = async (
  deploymentL03s: ApiType.L03[],
  l01RowKey: string,
  offers: ApiType.OptionalComponent[] | ApiType.L03[]
) => {
  let dependencycheckresult: ApiType.DependencyCheckResult;
  const instanceId = deploymentL03s.find((item) => item.instanceId).instanceId;
  try {
    const preDependencies: ApiType.L03Dependency[] | undefined = offers
      .map((obj) => obj.l03Dependencies)
      .reduce((result, offer) => result.concat(offer), [])
      .filter((x) => x.preOrPostDeploy === 'Pre');

    if (preDependencies.length !== 0) {
      const envs = await EnvironmentService.getEnvironments(l01RowKey);
      const existingEnv = envs.filter((env: ApiType.Instance) => env.id === instanceId);
      if (existingEnv.length !== 0) {
        let instanceApiUrl: string | undefined = existingEnv[0].apiUrl;
        dependencycheckresult = await DependencyService.getPreDeploymentDependencies(
          instanceApiUrl ?? existingEnv[0].apiUrl!,
          existingEnv[0].id,
          preDependencies
        );
        if (dependencycheckresult !== undefined) {
          return dependencycheckresult;
        } else {
          telemetry.logTrace(`Dependancy Check failed:`, telemetryConstants.severity.SEVERITY_INFO);
          return dependencycheckresult;
        }
      } else {
        telemetry.logTrace(
          'Unable to get environment Id for dependency check',
          telemetryConstants.severity.SEVERITY_ERROR
        );
        dependencycheckresult = {
          instanceId: '',
          dependencyCheckFetchXMLResult: [],
        };
        return dependencycheckresult;
      }
    } else {
      dependencycheckresult = {
        instanceId: '',
        dependencyCheckFetchXMLResult: [],
      };
      return dependencycheckresult;
    }
  } catch (error) {
    telemetry.logException(error);
  }
  return dependencycheckresult;
};
