import React, { FC, ReactElement } from 'react';
import { Dialog, DialogType, Link, PrimaryButton } from '@fluentui/react';
import { style } from './product-support.styles';
import { FormattedMessage } from 'react-intl';
import { DATA_TEST_IDS } from '../../common/Constants';
import RuntimeConfigStateContainer from '../../stateContainers/runtimeConfigStateContainer';

interface TicketConfirmationDialogProps {
  isOpen: boolean;
  onDismiss: () => void;
}

export const TicketConfirmationDialog: FC<TicketConfirmationDialogProps> = (
  props: TicketConfirmationDialogProps
): ReactElement => {
  const runtimeConfig = RuntimeConfigStateContainer.getConfiguration();
  return (
    <Dialog
      hidden={!props.isOpen}
      onDismiss={props.onDismiss}
      maxWidth="20%"
      dialogContentProps={{
        type: DialogType.normal,
        title: (
          <h1 className={style.dialogContentPropsText}>
            <FormattedMessage id="productSupport_ticket_confirmation_title" />
          </h1>
        ),
        closeButtonAriaLabel: 'Close',
      }}
      modalProps={{
        className: style.dialogContent,
        isBlocking: true,
        topOffsetFixed: false,
        containerClassName: 'destination-terms-container',
      }}
    >
      <div id={DATA_TEST_IDS.ticketConfirmationDescription}>
        <FormattedMessage
          id="productSupport_ticket_confirmation_description"
          values={{
            Link: (privacy: any) => (
              <Link href={runtimeConfig.productSupport.homeUrl} target="_blank" rel="noopener noreferrer">
                {privacy}
              </Link>
            ),
          }}
        />
      </div>

      <div className={style.dialogButton}>
        <PrimaryButton onClick={props.onDismiss}>
          <FormattedMessage id="buttons.ok" />
        </PrimaryButton>
      </div>
    </Dialog>
  );
};
