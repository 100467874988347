import { Interface, type } from 'implement-js';

//Interface that defines the telemetry logging methods
export const TelemetryInterface = Interface('Telemetry')(
  {
    logEvents: type('function'),
    logTrace: type('function'),
    logException: type('function'),
    logPageView: type('function'),
    startMetric: type('function'),
    stopMetric: type('function'),
    logMetrics: type('function'),
  },
  { error: true }
);
