import React, { FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import * as ApiType from '../../../../solutionCenterApi/gen/index';
import { DeploymentProgressIndicator } from './progressIndicators/deploymentProgressIndicator';
import { CompletedSolutions } from './completedSolutions/completedSolutions';
import styles from './progressStyling/index.styles';
import Visible from '../../../../components/visible/visible';
import { IProgress } from './models/deploymentProgressModels';
import * as Type from '../../../../common/Type';

interface InitialProps {
  success: boolean;
  deploymentProgressStatus: ApiType.DeploymentL03ProgressStatus[];
}

const renderProgress = (success: boolean, deploymentProgresses: IProgress[]) => {
  return deploymentProgresses.map((progress: IProgress) => {
    return (
      <DeploymentProgressIndicator percentComplete={progress.percentComplete} label={progress.name} success={success} />
    );
  });
};

const renderCompletedSolutions = (deploymentProgresses: IProgress[]) => {
  return deploymentProgresses.map((progress: IProgress) => {
    return <CompletedSolutions name={progress.name} />;
  });
};

function filterDeploymentProgresses(
  deploymentL03Progresses: ApiType.DeploymentL03ProgressStatus[]
): Type.FilteredDeploymentProgresses {
  let inProgress: IProgress[] = [],
    complete: IProgress[] = [];
  for (let deploymentL03Progress of deploymentL03Progresses) {
    var percentComplete = deploymentL03Progress.succeededPackages / deploymentL03Progress.totalPackages;
    var progress = {
      name: deploymentL03Progress.l03Name,
      percentComplete: percentComplete,
    };
    if (percentComplete >= 1) {
      complete.push(progress);
    } else {
      inProgress.push(progress);
    }
  }
  return {
    inProgress: inProgress.sort((a, b) => (a.percentComplete < b.percentComplete ? 0 : -1)),
    complete: complete,
  };
}

const DeploymentProgressContainer: FC<InitialProps> = (props: InitialProps): ReactElement => {
  const deploymentStatus = props.success ? 'status.inProgress' : 'status.deploymentFailed';
  const filteredDeploymentProgresses = filterDeploymentProgresses(props.deploymentProgressStatus);
  return (
    <>
      <Visible
        when={filteredDeploymentProgresses.inProgress.length > 0}
        fallback={filteredDeploymentProgresses.inProgress.length > 0}
      >
        <div className={styles.title}>{<FormattedMessage id={deploymentStatus} />}</div>
        {renderProgress(props.success, filteredDeploymentProgresses.inProgress)}
      </Visible>

      <Visible
        when={filteredDeploymentProgresses.complete.length > 0}
        fallback={filteredDeploymentProgresses.complete.length > 0}
      >
        <div className={styles.title}> {<FormattedMessage id="success_deployedSolutions" />} </div>
        <div> {renderCompletedSolutions(filteredDeploymentProgresses.complete)} </div>
      </Visible>
    </>
  );
};

export default DeploymentProgressContainer;
