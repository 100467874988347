import React from 'react';
import styles from './successTitleDescription.styles';

const SuccessTitleDescription = ({
  title,
  description,
}: {
  title: JSX.Element | string;
  description: JSX.Element | string | null;
}) => {
  return (
    <div className={styles.container}>
      <h3 className={`ms-fontSize-16 FontSizes.size16 ms-fontWeight-semibold ${styles.titleStyles}`}>{title}</h3>
      <div className={`ms-fontSize-14 FontSizes.size14 ms-fontWeight-regular ${styles.descriptionStyles}`}>
        {description}
      </div>
    </div>
  );
};
export default SuccessTitleDescription;
