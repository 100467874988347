import { mergeStyleSets } from '@fluentui/react';

export const termsStyle = mergeStyleSets({
  termsContainer: {
    width: '70%',
  },
  title: {
    fontFamily: 'Segoe UI',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  subtitle: {
    fontFamily: 'Segoe UI',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    marginLeft: '20px',
  },
});
