import RestService from './restService';
import config from '../config';
import SolutionsStateContainer from '../stateContainers/solutionsStateContainer';
import * as ApiType from '../solutionCenterApi/gen';

const SolutionService = {
  getAnnouncements: async () => {
    const result = await RestService.get({
      endPoint: config.endpoints.getCurrentSolution + 'announcements',
    });
    if (result) {
      SolutionsStateContainer.setAnnouncements(result);
      return result;
    }
    return [];
  },
  getAnnouncementsByRowKey: async (l01RowKey: string) => {
    const result = await RestService.get({
      endPoint: config.endpoints.getCurrentSolution + l01RowKey + '/announcements',
    });
    if (result) {
      return result;
    }
    return null;
  },
  getallAnnouncementsByRowKey: async (l01RowKey: string) => {
    const result = await RestService.get({
      endPoint: config.endpoints.getCurrentSolution + l01RowKey + '/all/announcements',
    });
    if (result) {
      return result;
    }
    return null;
  },
  getSolutions: async () => {
    const result = await RestService.get({
      endPoint: config.endpoints.getAllL01s,
    });
    if (result) {
      SolutionsStateContainer.setSolutions(result);
    }
    return result;
  },
  getAllSolutions: async (): Promise<ApiType.L01Entity[]> => {
    const result = await RestService.get({
      endPoint: config.endpoints.getAllSolutions,
    });
    if (result) {
      SolutionsStateContainer.setAllSolutions(result);
    }
    return result;
  },
  getCurrentSolution: async (
    solutionUniqueName: string,
    disableSessionstorageUpdate: boolean = false
  ): Promise<ApiType.L01> => {
    const result = await RestService.get({
      endPoint: config.endpoints.getCurrentSolution + solutionUniqueName,
    });
    if (!disableSessionstorageUpdate && result) {
      SolutionsStateContainer.setCurrentSolution(result);
    }
    return result;
  },
};

export default SolutionService;
