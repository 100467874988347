import RestService from './restService';
import config from '../config';
import * as ApiType from '../solutionCenterApi/gen';

const FabricWorkspaceService = {
  getFabricWorkspaces: async (): Promise<ApiType.FabricWorkspace[]> => {
    let result: ApiType.FabricWorkspace[] = await RestService.get({
      endPoint: config.endpoints.getFabricWorkspaces,
    });
    if (result?.length > 0) {
      result.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
    } else {
      result = [];
    }

    return result;
  },
  createWorkspace: async (
    worspacePayload: ApiType.FabricWorkspaceRequest
  ): Promise<ApiType.FabricWorkspace | string> => {
    const result = await RestService.post({
      endPoint: config.endpoints.createWorkspace,
      data: JSON.stringify(worspacePayload),
    });

    if (result && result.displayName && result.id) {
      return result;
    }
    let errorMessage = '';
    if (result && result.data) {
      errorMessage = `ErrorMessage ${result.data.Message}; CorrelationId: ${result.data.CorrelationId}`;
    }
    return errorMessage;
  },
};

export default FabricWorkspaceService;
