import { InstanceMetadata } from '../../solutionCenterApi/gen';
import {
  CI_INSTANCE,
  EnvironmentDispatchTypes,
  ERROR_VALIDATION_CI_INSTANCE_TYPE,
  ERROR_VALIDATION_DV_ENVIRONMENT_TYPE,
  SELECTED_CI_INSTANCE,
} from './environmentType';

interface DefaultEnvionmentStateI {
  errorValidationCiInstanceType: boolean;
  errorValidationDvEnvironmentType: boolean;
  selectedCiInstance: InstanceMetadata;
  ciInstance: InstanceMetadata;
}

const initialState: DefaultEnvionmentStateI = {
  errorValidationCiInstanceType: false,
  errorValidationDvEnvironmentType: false,
  selectedCiInstance: null,
  ciInstance: null,
};

const environmentInstanceReducer = (
  state: DefaultEnvionmentStateI = initialState,
  action: EnvironmentDispatchTypes
): DefaultEnvionmentStateI => {
  switch (action.type) {
    case SELECTED_CI_INSTANCE:
      return {
        ...state,
        selectedCiInstance: action.payload,
      };
    case CI_INSTANCE:
      return {
        ...state,
        ciInstance: action.payload,
      };
    case ERROR_VALIDATION_CI_INSTANCE_TYPE:
      return {
        ...state,
        errorValidationCiInstanceType: action.payload,
      };
    case ERROR_VALIDATION_DV_ENVIRONMENT_TYPE:
      return {
        ...state,
        errorValidationDvEnvironmentType: action.payload,
      };
    default: {
      return state;
    }
  }
};
export default environmentInstanceReducer;
