import { IconButton, IIconStyles, TooltipHost } from '@fluentui/react';
import * as React from 'react';
import styles from './cutomTooltip.styles';

interface toolTipProps {
  content: string;
  iconLabel?: string;
  iconStyles?: IIconStyles;
}
export const CustomToolTip = (props: toolTipProps) => {
  const { content, iconLabel, iconStyles } = props;

  return (
    <TooltipHost content={content} calloutProps={{ gapSpace: 0 }}>
      <IconButton
        ariaLabel={content + ' ' + iconLabel ?? 'info-icon'}
        className={styles.toolTipIconSize}
        iconProps={{
          iconName: 'Info',
          className: styles.infoIconBtn,
        }}
        styles={iconStyles}
      />
    </TooltipHost>
  );
};
