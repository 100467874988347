import { IStackStyles, IStackTokens } from '@fluentui/react';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';

export const styles = mergeStyleSets({
  tableStyle: {
    backgroundColor: '#FFFFFF',
    flex: 1,
    minHeight: 360,
    width: 954,
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    borderRadius: 2,
    '@media(max-width: 1245px)': {
      width: 400,
    },
  },
  labelStyle: {
    paddingTop: 7,
    paddingLeft: 5,
  },
  divStyle: {
    borderTop: '1px solid #EDEBE9',
  },
  optionalComponentsListStyle: {
    marginTop: '44px',
    marginLeft: '76px',
    width: '954px',
    '@media(max-width: 1245px)': {
      width: 500,
    },
  },
  footer: {
    height: 64,
    background: '#FFFFFF',
    borderRadius: 2,
    margin: '0px 0px',
  },
});

export const headerStackStyles: IStackStyles = {
  root: {
    height: 48,
  },
};

export const headerTokens: IStackTokens = { padding: 10 };

export const footerTokens: IStackTokens = { padding: 16 };
