import React, { ReactElement, FC } from 'react';
import * as ApiType from '../../solutionCenterApi/gen/index';
import Utils from '../../utils/index';
import styles from './postDeployConfig.styles';
import { useIntl } from 'react-intl';
import { DATA_TEST_IDS } from '../../common/Constants';

interface postDeployConfigProps {
  title: string;
  description: string;
  l03s?: Array<ApiType.L03Dependency>;
}

export const PostDeploymentConfigDependencies: FC<postDeployConfigProps> = ({
  title,
  description,
  l03s,
}): ReactElement => {
  const dependencies = l03s?.filter((l03) => l03.preOrPostDeploy.toUpperCase() === 'POST');
  const intlShape = useIntl();

  return (
    <div data-test-id={DATA_TEST_IDS.postDeployMainContainerTestId}>
      {dependencies?.length !== 0 ? (
        <div>
          <div className={styles.dependencyHeader}>
            <span
              data-test-id={DATA_TEST_IDS.postDeployTitleTestId}
              className={styles.title}
              aria-label={intlShape.formatMessage({ id: 'quickView.title' }, { title })}
            >
              {title}
            </span>
            <div
              data-test-id={DATA_TEST_IDS.postDeployDescriptionTestId}
              className={styles.solutionDependenciesDescription}
              aria-label={intlShape.formatMessage({ id: 'quickView.description' }, { description })}
            >
              {description}
            </div>
          </div>
          <div data-test-id={DATA_TEST_IDS.postDeployContainerTestId} className={styles.dependencyContainer}>
            {dependencies?.map((dependency, index) => (
              <div aria-hidden={false} key={index} className={styles.dependencyRow}>
                <img
                  src={Utils.getIconSvg(dependency.icon)}
                  className={styles.dependencyIcon}
                  alt={intlShape.formatMessage(
                    { id: 'accessibility_postDeployQuickView_iconReference' },
                    { dependencyIconName: dependency.name }
                  )}
                />
                <span>{dependency.name}</span>
              </div>
            ))}
          </div>
          <p className={styles.footer}></p>
        </div>
      ) : null}
    </div>
  );
};
