import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';

const styles = mergeStyleSets({
  inner: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  successLabelStyle: {
    paddingLeft: 5,
    color: '#107C10',
  },
  errorLabelStyle: {
    paddingLeft: 5,
    color: '#A80000',
  },
  warningLabelStyle: {
    paddingLeft: 5,
    color: '#797775',
  },
  successSvgStyle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    selectors: {
      svg: {
        fill: '#107C10',
        width: 16,
      },
    },
  },
  errorSvgStyle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    selectors: {
      svg: {
        fill: '#A80000',
        width: 16,
      },
    },
  },
  warningSvgStyle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    selectors: {
      svg: {
        fill: '#797775',
        width: 16,
      },
    },
  },
});
export default styles;
