import React from 'react';
import { Package } from '../../../../../solutionCenterApi/gen';
import { TextField } from '@fluentui/react';
import styles, { textfieldStyles } from '../../destination.styles';
import { useIntl } from 'react-intl';
import FabricComponentList from '../fabricComponentList';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../../redux';
import { setFabricPayload } from '../../../../../redux/deployments/deploymentActions';
import { FabricComponentPayloadList } from '../../../../../redux/deployments/deploymentReducers';
import { FabricPackageSubType } from '../../../../../common/Enum';

interface EventStreamComponentListProps {
  packages: Package[];
}

const EventStreamComponentList: React.FC<EventStreamComponentListProps> = ({ packages }) => {
  const intl = useIntl();
  return (
    <FabricComponentList
      packages={packages}
      renderItem={(pkg) => <EventStreamComponent fabPackage={pkg} />}
      title={intl.formatMessage({ id: 'fabric.evenstreamlist.header' })}
      subtitle={intl.formatMessage({ id: 'fabric.evenstreamlist.subheader' })}
    />
  );
};

const EventStreamComponent = ({ fabPackage }: { fabPackage: Package }): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const displayNameKey = 'displayName';
  const descriptionKey = 'description';
  const applicationGuid = fabPackage.applicationGuid;
  const fabricPayload: FabricComponentPayloadList = useSelector(
    (state: RootStore) => state.deployment.fabricPayload ?? {}
  );

  if (!fabricPayload[applicationGuid as string]) {
    fabricPayload[applicationGuid as string] = {
      applicationId: applicationGuid,
      displayName: '',
      description: '',
      type: fabPackage.packageSubType as FabricPackageSubType,
    };
  }

  const handleChange = (value: string | undefined, key: string) => {
    const fabricPayloadPackage = fabricPayload[applicationGuid as string];
    const updatedFabricPayload = {
      ...fabricPayload,
      [applicationGuid]: { ...fabricPayloadPackage, [key]: value },
    };
    dispatch(setFabricPayload(updatedFabricPayload));
  };

  return (
    <>
      <br />
      <span className={styles.headerText}>{fabPackage.packageName}</span>
      <TextField
        styles={textfieldStyles}
        label={intl.formatMessage({ id: 'fabric.textfield.displayname.label' })}
        required
        onChange={(e, value) => handleChange(value, displayNameKey)}
        value={fabricPayload[applicationGuid as string]?.displayName}
      />
      <TextField
        styles={textfieldStyles}
        label={intl.formatMessage({ id: 'fabric.textfield.description.label' })}
        onChange={(e, value) => handleChange(value, descriptionKey)}
        value={fabricPayload[applicationGuid as string]?.description}
      />
    </>
  );
};

export default EventStreamComponentList;
