import { mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  tableHeadContainer: {
    display: 'block',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  tableDescriptionLeft: {
    display: 'inline-block',
  },
  tableDescriptionRight: {
    display: 'inline-block',
    paddingRight: '20px',
    float: 'right',
  },
  tableRowContainer: {
    display: 'block',
    paddingBottom: '14px',
  },
  tableRowName: {
    display: 'inline-block',
    '@media(max-width: 960px)': {
      width: '65%',
    },
  },
  envrionmentTableRowName: {
    display: 'inline-block',
    '@media(max-width: 960px)': {
      width: '65%',
    },
    paddingLeft: '28px',
  },

  tableRowContent: {
    display: 'inline-block',
    float: 'right',
  },
  tableRowActionAnchor: {
    textDecoration: 'none',
  },
  tableRowActionTitle: {
    color: '#323130',
    paddingLeft: '2px',
  },
  tableRowSeparator: {
    background: '#EDEBE9',
    height: '1px',
    marginBottom: '13px',
  },
  sectionSeparator: {
    background: '#EDEBE9',
    height: '1px',
    marginTop: '11px',
    marginBottom: '13px',
  },
  endUserLaunchLink: {
    float: 'right',
  },
});

export const inlineStyles = {
  tableRowActionIcon: {
    color: '#106EBE',
  },
};

export default styles;
