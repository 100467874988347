import * as React from 'react';
import { FC, ReactElement, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { OptionalComponentsList } from '../lists/optionalComponentsList';
import * as ApiType from '../../solutionCenterApi/gen/index';
import { Label, PrimaryButton, Stack } from '@fluentui/react';
import { styles, headerTokens, headerStackStyles, footerTokens } from './styles';
import { useIntl } from 'react-intl';
import { AddComponentsDialog } from '../dialogs/addComponentsDialog';
import DeploymentStateContainer from '../../stateContainers/deploymentStateContainer';
import SolutionsStateContainer from '../../stateContainers/solutionsStateContainer';
import * as Type from '../../common/Type';
import { telemetry } from '../../services/telemetryService';
import { generateDeploymentPayload, getOptionalComponentRowKeys } from '../../utils/helper';
import { RootStore } from '../../redux';
import {
  setLocation,
  setParameterDefinitionProvider,
  setResourceGroup,
  setSubscription,
} from '../../redux/azureInstance/azureInstanceActions';
import getSelectedCdsEnvironment, {
  getDeploymentDefinition,
  getSelectedFabricWorkspace,
} from '../../redux/deployments/deploymentActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  IAzureLocation,
  IAzureResourceGroup,
  IAzureSubscription,
} from '../../pages/install/destination/azureResourceWidget/models/azureResource.Models';
import { CIPackageValidator } from '../../models/packageValidator';
import { PackageType } from '../../common/Enum';
import InstallationStateContainer from '../../stateContainers/installationStateContainer';
import { checkForAvailabeAzureOptionalComponents } from '../../utils/azureHelper';
import { getRouteUtil } from '../../utils/deployHelper';
import * as Constant from '../../common/Constants';

export interface AvailableComponentProps {
  setSelectionDetails: (selectionDetails: ApiType.OptionalComponent[]) => void;
  selectionDetails: ApiType.OptionalComponent[];
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
  optionalComponents: ApiType.OptionalComponent[];
  deploymentL03s: ApiType.DeploymentDefinitionEntity;
  getDeploymentL03s: () => Promise<void>;
  l01RowKey: string;
}

export const AvailableComponentsTable: FC<AvailableComponentProps> = (props: AvailableComponentProps): ReactElement => {
  const intlShape = useIntl();
  const dispatch = useDispatch();

  const { setSelectionDetails, selectionDetails, optionalComponents, deploymentL03s, l01RowKey } = props;
  const [isAddComponentsDialogOpen, setIsAddComponentsDialogOpen] = useState<boolean>(false);

  const parameterDefinition: Type.ParameterDefinitionDictionary[] | undefined = useSelector(
    (state: RootStore) => state.azureInstance.parameterDefinitionProvider
  );

  const setParameterDefinition = (dictionary: Type.ParameterDefinitionDictionary[]) =>
    dispatch(setParameterDefinitionProvider(dictionary));
  const setAzureSubscription = (subscription: IAzureSubscription | null) => dispatch(setSubscription(subscription));
  const setAzureResourceGroup = (resourceGroup: IAzureResourceGroup | null) =>
    dispatch(setResourceGroup(resourceGroup));
  const setAzureLocation = (location: IAzureLocation | null) => dispatch(setLocation(location));
  const setDeploymentDefinition = (deploymentDefinition: ApiType.DeploymentDefinitionEntity | null) =>
    dispatch(getDeploymentDefinition(deploymentDefinition));
  const setSelectedCdsEnvironment = (environment: Type.Environment | null) =>
    dispatch(getSelectedCdsEnvironment(environment));
  const setSelectedFabricWorkspace = (fabricWorkspace: ApiType.FabricWorkspace | null) =>
    dispatch(getSelectedFabricWorkspace(fabricWorkspace));
  useEffect(() => {
    setParameterDefinition([]);
    setAzureSubscription(null);
    setAzureResourceGroup(null);
    setAzureLocation(null);
    setDeploymentDefinition(null);
    setSelectedCdsEnvironment(null);
    setSelectedFabricWorkspace(null);
    DeploymentStateContainer.setDeploymentName('');
  }, []);

  const onDeployButtonClick = async (): // eslint-disable-next-line @typescript-eslint/no-unused-vars

  Promise<void> => {
    DeploymentStateContainer.setOptionalComponents(selectionDetails);
    InstallationStateContainer.setSelectedOffers([]);

    const data: ApiType.DeploymentPayloadV2 = generateDeploymentPayload(selectionDetails, deploymentL03s);
    data.l03RowKeys = getOptionalComponentRowKeys(selectionDetails);

    const ciValidation = new CIPackageValidator(PackageType.CI_PACKAGE);
    const validCIParams = ciValidation.validation(data);

    const selectedArmOptionalComponentType: boolean = selectionDetails.some(
      (component: ApiType.OptionalComponent) => component.type === ApiType.PackageType.ArmTemplate
    );
    const selectedCIOptionalComponentTypes: boolean = selectionDetails.some(
      (component: ApiType.OptionalComponent) => component.type === ApiType.PackageType.CI
    );
    const selectedDataverseOptionalComponentsWithDependencies: ApiType.OptionalComponent[] = selectionDetails.filter(
      (component: ApiType.OptionalComponent) => {
        return component.type === ApiType.PackageType.Solution && component.l03Dependencies?.length !== 0;
      }
    );

    if (selectedArmOptionalComponentType) {
      SolutionsStateContainer.setSelectedSolution(deploymentL03s?.l01RowKey!);
      setDeploymentDefinition(deploymentL03s);
      try {
        const parameterDictionary: Type.ParameterDefinitionDictionary[] = await checkForAvailabeAzureOptionalComponents(
          selectionDetails,
          parameterDefinition
        );

        setParameterDefinition(parameterDictionary);
      } catch (error) {
        telemetry.logException(error);
      }

      const nextRoute = getRouteUtil(Constant.productPage, true);

      props.history.push(nextRoute, {
        from: props.location.pathname,
      });
    } else if (
      (selectedCIOptionalComponentTypes && !validCIParams.ok) ||
      selectedDataverseOptionalComponentsWithDependencies.length !== 0
    ) {
      setDeploymentDefinition(deploymentL03s);
      SolutionsStateContainer.setSelectedSolution(deploymentL03s.l01RowKey);
      const nextRoute = getRouteUtil(Constant.productPage, true);

      props.history.push(nextRoute, {
        from: props.location.pathname,
      });
    } else {
      setIsAddComponentsDialogOpen(true);
    }
  };

  return (
    <div>
      <div id="available-components-table" className={styles.tableStyle}>
        <div id="available-components-header">
          <Stack horizontal horizontalAlign="space-between" tokens={headerTokens} styles={headerStackStyles}>
            <Label className={styles.labelStyle}>{intlShape.formatMessage({ id: 'availableComponents.header' })}</Label>
          </Stack>
        </div>
        <div id="listOfComponents" className={styles.divStyle}></div>
        <OptionalComponentsList
          optionalComponents={optionalComponents}
          setSelectionDetails={setSelectionDetails}
          isAdditionalComponentsParent={false}
          deploymentL03s={deploymentL03s?.l03s}
          l01RowKey={l01RowKey}
        />
        <div id="available-components-footer" className={styles.footer}>
          <Stack horizontal horizontalAlign="end" tokens={footerTokens} styles={headerStackStyles}>
            <PrimaryButton
              onClick={onDeployButtonClick}
              disabled={props.selectionDetails.length === 0}
              text={intlShape.formatMessage(
                { id: 'subSolutions.deploy' },
                { selectedOffersLength: `(${selectionDetails.length})` }
              )}
            />
          </Stack>
        </div>
      </div>
      <AddComponentsDialog
        isOpen={isAddComponentsDialogOpen}
        onDismiss={() => setIsAddComponentsDialogOpen(false)}
        l01RowKey={deploymentL03s.l01RowKey}
        deploymentL03s={deploymentL03s}
        getDeploymentL03s={() => props.getDeploymentL03s()}
      />
    </div>
  );
};
