import { mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  container: {
    display: 'block',
    paddingTop: '42px',
    paddingBottom: '12px',
  },
  titleStyles: {
    lineHeight: '24px',
    marginBottom: '12px',
    marginTop: '0px',
  },
  descriptionStyles: {
    lineHeight: '20px',
  },
});

export default styles;
