import { mergeStyleSets } from '@fluentui/react';
import { segoeRegular } from '../../common/Constants';

export const titleDescriptionStyles = mergeStyleSets({
  root: {
    width: '98%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '9px',
    marginBottom: '10.59px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '28px',
    fontFamily: segoeRegular,
  },
  description: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: segoeRegular,
  },
  link: {
    marginLeft: '5px',
    textDecoration: 'none',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: segoeRegular,
  },
});
