const currentSessionStorage = window.sessionStorage;

const AuthenticationStateContainer = {
  parseJwt: (token: string) => {
    let jsonPayload;
    let parsesJSONPayload;
    try {
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
      parsesJSONPayload = JSON.parse(jsonPayload);
    } catch (error) {
      console.log(error);
    }
    return parsesJSONPayload;
  },
  getAccessToken: () => {
    const sessionStorageKey = Object.keys(currentSessionStorage).filter((x) => x.includes('accesstoken'))[0];
    if (sessionStorageKey) {
      return JSON.parse(sessionStorage[sessionStorageKey]).secret;
    }
  },
  getIdToken: () => {
    const sessionStorageKey = Object.keys(currentSessionStorage).filter((x) => x.includes('idtoken'))[0];
    if (sessionStorageKey) {
      return JSON.parse(sessionStorage[sessionStorageKey]).secret;
    }
  },
  getRefreshToken: () => {
    const sessionStorageKey = Object.keys(currentSessionStorage).filter((x) => x.includes('refreshtoken'))[0];
    if (sessionStorageKey) {
      return JSON.parse(sessionStorage[sessionStorageKey]).secret;
    }
  },
  setHeaderAccessToken: (token: string) => {
    const key = 'header-access-token';
    currentSessionStorage.setItem(key, token);
  },
  getHeaderAccessToken: () => {
    const key = 'header-access-token';
    return currentSessionStorage.getItem(key);
  },
  getAllMsalValues: () => {
    return Object.keys(currentSessionStorage)
      .filter((x) => x.includes('login.windows.net'))
      .map((key) => currentSessionStorage[key]);
  },
  setDataBoundary: (token: string) => {
    const key = 'user-tenant-data-boundary';
    currentSessionStorage.setItem(key, token);
  },
  getDataBoundary: (): string => {
    const key = 'user-tenant-data-boundary';
    return currentSessionStorage.getItem(key);
  },
  setUserId: (userId: string) => {
    currentSessionStorage.setItem('userId', userId);
  },
  setTenantId: (tenantId: string) => {
    currentSessionStorage.setItem('tenantId', tenantId);
  },
  setTenantName: (tenantId: string) => {
    currentSessionStorage.setItem('tenantName', tenantId);
  },
  setCorrelationId: (correlationId: string) => {
    currentSessionStorage.setItem('x-ms-correlation-id', correlationId);
  },
  getUserId: () => {
    return currentSessionStorage.getItem('userId');
  },
  getTenantId: () => {
    return currentSessionStorage.getItem('tenantId');
  },
  getTenantName: () => {
    return currentSessionStorage.getItem('tenantName');
  },
  getCorrelationId: () => {
    return currentSessionStorage.getItem('x-ms-correlation-id');
  },
  setGroupMembershipToken: (token: string) => {
    const key = 'group-membership-token';
    currentSessionStorage.setItem(key, token);
  },
  getGroupMembershipToken: () => {
    const key = 'group-membership-token';
    return currentSessionStorage.getItem(key);
  },
  setCIAccessToken: (token: string) => {
    const key = 'ci-access-token';
    currentSessionStorage.setItem(key, token);
  },
  getCIAccessToken: () => {
    const key = 'ci-access-token';
    return currentSessionStorage.getItem(key);
  },
  setPPACAccessToken: (token: string) => {
    const key = 'ppac-access-token';
    currentSessionStorage.setItem(key, token);
  },
  getPPACAccessToken: () => {
    const key = 'ppac-access-token';
    return currentSessionStorage.getItem(key);
  },
  setNeptuneAccessToken: (token: string) => {
    const key = 'neptune-access-token';
    currentSessionStorage.setItem(key, token);
  },
  getNeptuneAccessToken: () => {
    const key = 'neptune-access-token';
    return currentSessionStorage.getItem(key);
  },
};

export default AuthenticationStateContainer;
