import { InstanceMetadata } from '../../solutionCenterApi/gen';

export const ERROR_VALIDATION_CI_INSTANCE_TYPE = 'ERROR_VALIDATION_CI_INSTANCE_TYPE';
export const ERROR_VALIDATION_DV_ENVIRONMENT_TYPE = 'ERROR_VALIDATION_DV_ENVIRONMENT_TYPE';
export const CI_INSTANCE = 'CI_INSTANCE';
export const SELECTED_CI_INSTANCE = 'SELECTED_CI_INSTANCE';

export interface ciInstance {
  type: typeof CI_INSTANCE | null;
  payload: InstanceMetadata | null;
}

export interface selectedCiInstance {
  type: typeof SELECTED_CI_INSTANCE;
  payload: InstanceMetadata;
}

export interface errorValidationCiInstanceType {
  type: typeof ERROR_VALIDATION_CI_INSTANCE_TYPE;
  payload: boolean;
}

export interface errorValidationDvEnvironmentType {
  type: typeof ERROR_VALIDATION_DV_ENVIRONMENT_TYPE;
  payload: boolean;
}

export type EnvironmentDispatchTypes =
  | selectedCiInstance
  | ciInstance
  | errorValidationDvEnvironmentType
  | errorValidationCiInstanceType;
