import React, { FC, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { noAvailabilityRegStyles } from './noAvailabilityRegion.styles';
import SolutionsStateContainer from '../../stateContainers/solutionsStateContainer';
import { Link } from 'office-ui-fabric-react';
import { TrialComponents } from '../../common/Constants';
export const NoAvailabilityRegion: FC = (): ReactElement => {
  const intl = useIntl();
  const currentSolution = SolutionsStateContainer.getCurrentSolution();
  return (
    <div>
      <div className={noAvailabilityRegStyles.marginTop} tabIndex={0}>
        <img
          className={noAvailabilityRegStyles.microsoftLogoCss}
          src={TrialComponents.trialMicrosoftLogo}
          alt={intl.formatMessage({ id: 'altText.microsoftLogo.image' })}
        />
      </div>
      <div className={noAvailabilityRegStyles.rowContainer}>
        <div className={noAvailabilityRegStyles.imgPositionSettings}>
          <img
            className={noAvailabilityRegStyles.imgLogoCss}
            src={TrialComponents.trialEmptyStateLogo}
            alt={intl.formatMessage({
              id: 'altText.noAvailabilityRegion.image',
            })}
          />
        </div>
        <div className={noAvailabilityRegStyles.nonAvailableSolTitle}>
          {intl.formatMessage({ id: 'trial.nonAvailable.text' }, { solutionName: currentSolution!.solutionName })}
        </div>
        <div className={noAvailabilityRegStyles.supportedRegionText}>
          <FormattedMessage
            id="trial.nonSupportedRegion.text"
            values={{
              Link: (noSupportedText: string) => (
                <Link underline={true} href="/">
                  {noSupportedText}
                </Link>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};
