import * as React from 'react';
import { DefaultButton, FontIcon, IStackTokens, PrimaryButton, ProgressIndicator, Stack, Text } from '@fluentui/react';
import { environmentCreationStates } from '../../../common/Constants';
import { environmentCreationResultStyles, primaryButtonDoneStyle, completedTextFontStyle } from './create-styles';
import { FormattedMessage, useIntl } from 'react-intl';

export interface CreationResultProps {
  createSuccessText: string;
  inProgressText: string;
  createFailedText: string;
  displayName: string;
  envCreationResult: string;
  errorMessage: string;
  onRetryButtonClick: () => Promise<void>;
  onCancelButtonClick: () => Promise<void>;
}

export const CreationResult: React.FunctionComponent<CreationResultProps> = (props: CreationResultProps) => {
  const intlShape = useIntl();
  const stackTokens: IStackTokens = { childrenGap: 15 };
  let currentEnvStatus = props.envCreationResult;

  switch (currentEnvStatus) {
    case environmentCreationStates.SUCCESS:
      return (
        <Stack tokens={stackTokens}>
          <div className={environmentCreationResultStyles.resultStack}>
            <Stack tokens={stackTokens}>
              <FontIcon
                iconName="Completed"
                className={environmentCreationResultStyles.iconStyle}
                style={completedTextFontStyle}
                aria-hidden="true"
              ></FontIcon>
              {props.displayName} &nbsp;
              <Text className={environmentCreationResultStyles.resultHeader}>
                <FormattedMessage id={props.createSuccessText} />
              </Text>
            </Stack>
            <PrimaryButton onClick={props.onCancelButtonClick} styles={primaryButtonDoneStyle}>
              {intlShape.formatMessage({ id: 'buttons.done' })}
            </PrimaryButton>
          </div>
        </Stack>
      );

    case environmentCreationStates.FAILED:
      return (
        <Stack tokens={stackTokens}>
          <div className={environmentCreationResultStyles.resultStack}>
            <Stack tokens={stackTokens}>
              <img
                className={environmentCreationResultStyles.resultImg}
                src="./Assets/icons/env_creation_failed.png"
                alt={intlShape.formatMessage({ id: 'createEnvironment.createNewEnvironmentFailedText' })}
              />
              <Text className={environmentCreationResultStyles.resultHeader}>
                <FormattedMessage id={props.createFailedText} />
              </Text>
              <Text className={environmentCreationResultStyles.resultHeader}>{props.errorMessage}</Text>
              <br />
            </Stack>
            <DefaultButton onClick={props.onCancelButtonClick}>
              {' '}
              {intlShape.formatMessage({ id: 'buttons.cancel' })}
            </DefaultButton>
          </div>
        </Stack>
      );

    case environmentCreationStates.IN_PROGRESS:
      return (
        <Stack tokens={stackTokens}>
          <div className={environmentCreationResultStyles.resultStack}>
            <Stack tokens={stackTokens}>
              <FontIcon
                iconName="DateTimeMirrored"
                className={environmentCreationResultStyles.iconStyle}
                aria-hidden="true"
              ></FontIcon>
              <Text className={environmentCreationResultStyles.inProgressText}>
                <FormattedMessage id={props.inProgressText} />
              </Text>
              <ProgressIndicator />
            </Stack>
          </div>
        </Stack>
      );
  }
};
