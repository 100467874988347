import { mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  errorContainer: {
    backgroundColor: '#FAF9F8',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '15%',
  },
  errorTitle: {
    fontWeight: 600,
    fontSize: '20px',
  },
  errorDescription: {
    fontWeight: 400,
    fontSize: '16px',
  },
});

export default styles;
