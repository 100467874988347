import React, { useState } from 'react';
import Utils from '../../utils';
import { telemetry } from '../../services/telemetryService';
import config, { telemetryConstants } from '../../config';
import * as Constant from '../../common/Constants';
import Install from './install';
import InstallationStateContainer from '../../stateContainers/installationStateContainer';
import DeploymentStateContainer from '../../stateContainers/deploymentStateContainer';
import * as Util from '../../utils/helper';
import { useDispatch } from 'react-redux';
import * as ApiType from '../../solutionCenterApi/gen';
import {
  getSelectedAzureOffers,
  setArmDeploymentScopeProvider,
  setParameterDefinitionProvider,
} from '../../redux/azureInstance/azureInstanceActions';
import { createDeploymentOverview, getAvailablePackageTypes, getRouteUtil } from '../../utils/deployHelper';
import SolutionService from '../../services/solutionsService';
import SolutionsStateContainer from '../../stateContainers/solutionsStateContainer';
import LoadingScreen from '../../common/loading-screen/loadingScreen';
import { useIntl } from 'react-intl';
import { setAzureOffersScopeAndParameterDefinition } from '../../utils/azureHelper';

const DataInjectedInstall = (props) => {
  const { queryParams, source, l01 } = props;
  const [isLoading, setIsLoading] = useState<boolean>(props.location.pathname === config.routes.installDeeplink);
  const intl = useIntl();
  const dispatch = useDispatch();
  let selectedOffers = queryParams.getAll(telemetryConstants.queryParameterKeys.SELECTED_OFFER);
  const setArmScope = (armScope) => dispatch(setArmDeploymentScopeProvider(armScope));
  const setParameterDefinition = (parameterDefinition) => dispatch(setParameterDefinitionProvider(parameterDefinition));

  if (!Utils.isNullOrUndefinedOrEmpty(source)) {
    const eventData = { source, l01, filterData: JSON.stringify(selectedOffers) };
    telemetry.logEvents(telemetryConstants.deepLinkEvents.INSTALL_PAGE_VISIT_DEEPLINK, eventData);
  }

  if (props.location.pathname === config.routes.installDeeplink) {
    if (selectedOffers.length === 0 || Utils.isNullOrUndefinedOrEmpty(l01)) {
      props.history.push(config.routes.home, { from: props.location.pathname });
    } else {
      let l03sToBeDeployed: ApiType.L03[] = [];
      SolutionService.getAllSolutions().then((res) => {
        SolutionsStateContainer.setAllSolutions(res);
        const currSolutionRowkey = res?.find((sol) => sol.uniqueName === l01)?.rowKey;
        SolutionsStateContainer.setSelectedSolution(currSolutionRowkey);
      });
      SolutionService.getCurrentSolution(l01).then((res) => {
        SolutionsStateContainer.setCurrentSolution(res);
        res?.l02s?.forEach((l02) => {
          l02.l03s?.forEach((l03) => {
            if (selectedOffers.includes(l03.rowKey)) {
              l03sToBeDeployed.push(l03);
              selectedOffers = selectedOffers.filter((item) => item !== l03.rowKey);
            }
          });
        });
        const azureOffers = Util.filterAzureOffers(l03sToBeDeployed, true);
        const azureOffersWithoutOC = Util.filterAzureOffers(l03sToBeDeployed, false);
        InstallationStateContainer.setSelectedOffers(l03sToBeDeployed);
        DeploymentStateContainer.setOptionalComponents([]);
        telemetry.logEvents(telemetryConstants.events.OFFERS_SELECTED_NAME, {
          [telemetryConstants.events.OFFERS_SELECTED_KEY]: selectedOffers.map((offer) => offer.name),
        });

        dispatch(getSelectedAzureOffers(azureOffersWithoutOC));
        let scopeToOfferMap = {};
        if (azureOffers.length > 0) {
          scopeToOfferMap = setAzureOffersScopeAndParameterDefinition(
            azureOffers,
            setArmScope,
            setParameterDefinition,
            props.history
          );
        }
        navigateToDestinationPage(l03sToBeDeployed, props, setIsLoading, scopeToOfferMap);
      });
    }
  }

  return isLoading ? (
    <LoadingScreen
      isVisible={isLoading}
      label={intl.formatMessage({
        id: 'loading.pleaseWait',
      })}
    />
  ) : (
    <Install {...props} />
  );
};

const navigateToDestinationPage = (selectedOffers, props, setIsLoading, scopeToOfferMap?) => {
  var deploymentOverview = createDeploymentOverview(selectedOffers, scopeToOfferMap);
  var availablePackages = getAvailablePackageTypes(deploymentOverview);
  InstallationStateContainer.setAvailablePackages(JSON.stringify(availablePackages));
  let nextRoute = getRouteUtil(Constant.productPage, true);
  setIsLoading(false);
  props.history.push(nextRoute, { from: props.location.pathname });
};

export default DataInjectedInstall;
