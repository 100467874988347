import * as React from 'react';
import { ReactElement } from 'react';
import { FC } from 'react';
import {
  ActionButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IIconProps,
  Label,
  SelectionMode,
  Stack,
} from '@fluentui/react';
import styles, { tableHeaderStyles, headerStackStyles, headerTokens } from './postDeploymentConfigurations.styles';
import { RouteComponentProps } from 'react-router-dom';
import { FormattedMessage, IntlShape, WrappedComponentProps } from 'react-intl';
import * as ApiType from '../../../solutionCenterApi/gen/index';
import Utils from '../../../utils';
import { telemetryConstants } from '../../../config';
import { telemetry } from '../../../services/telemetryService';
import Visible from '../../../components/visible/visible';
import * as Constant from '../../../common/Constants';

const linkIcon: IIconProps = { iconName: 'Link' };

type postDeploymentConfigurationsProps = {
  deploymentDependencies: ApiType.L03Dependency[];
  loadingDependenices: boolean;
  history: RouteComponentProps['history'];
  intl: IntlShape;
} & WrappedComponentProps;

export const PostDeploymentConfigurations: FC<postDeploymentConfigurationsProps> = (
  props: postDeploymentConfigurationsProps
): ReactElement => {
  const columns: IColumn[] = [
    {
      key: 'dependencyIconColumn',
      name: 'File Type',
      className: styles.fileIconCell,
      iconClassName: styles.fileIconHeaderIcon,
      iconName: 'Page',
      isIconOnly: true,
      fieldName: 'Icon',
      minWidth: 36,
      maxWidth: 36,
      onRender: (item: ApiType.L03Dependency) => (
        <div className={styles.containerTextStyle}>
          <img src={Utils.getIconSvg(item.icon)} className={styles.fileIconImg} alt={`${item.name}`} />
        </div>
      ),
    },
    {
      key: 'dependencyNameColumn',
      name: props.intl.formatMessage({
        id: 'postDeploymentConfigurations.app',
      }),
      fieldName: 'Name',
      minWidth: 230,
      maxWidth: 330,
      isResizable: true,
      styles: tableHeaderStyles,
      onRender: (item: ApiType.L03Dependency) => (
        <div className={styles.containerTextStyle}>
          <span className={styles.textStyle}>{item.name}</span>
        </div>
      ),
    },
    {
      key: 'dependencyDescriptionColumn',
      name: props.intl.formatMessage({
        id: 'postDeploymentConfigurations.description',
      }),
      fieldName: 'Description',
      minWidth: 132,
      maxWidth: 370,
      isResizable: true,
      data: 'string',
      styles: tableHeaderStyles,
      onRender: (item: ApiType.L03Dependency) => (
        <div className={styles.containerTextStyle}>
          <span className={styles.textStyle}>{item.description}</span>
        </div>
      ),
    },
    {
      key: 'dependencyActionColumn',
      name: props.intl.formatMessage({
        id: 'postDeploymentConfigurations.action',
      }),
      fieldName: 'action',
      minWidth: 161,
      maxWidth: 161,
      styles: tableHeaderStyles,
      onRender: (item: ApiType.L03Dependency) => {
        const linkTitle =
          item.type === Constant.POWERBI || item.type === Constant.AZURE
            ? props.intl.formatMessage({ id: 'success.getItNow' })
            : props.intl.formatMessage({ id: 'success.viewDocumentation' });
        return (
          <div>
            <ActionButton
              onClick={() =>
                externalLinkClickHandler(telemetryConstants.events.CONFIGURE_NOW_CLICKED, item.configureLink)
              }
              iconProps={linkIcon}
            >
              {linkTitle}
            </ActionButton>
          </div>
        );
      },
    },
  ];

  const logLinkClick = (name: string) => {
    var buttonSelected: any = {};
    buttonSelected[telemetryConstants.deploymentDetails.DEPLOYMENT_DETAILS_TELEMETRY_KEY] = name;
    telemetry.logEvents(telemetryConstants.deploymentDetails.DEPLOYMENT_DETAILS_TELEMETRY_NAME, buttonSelected);
  };

  const externalLinkClickHandler = (name: string, url: string) => {
    logLinkClick(name);
    window.open(url, '_blank');
  };

  return (
    <>
      {!props.loadingDependenices && props.deploymentDependencies.length === 0 ? null : (
        <div className={styles.extendYourSolutionsContainer}>
          <Visible when={!props.loadingDependenices} fallback={null}>
            <div className={styles.postDeploymentConfigurationsContainer}>
              <Stack horizontal horizontalAlign="space-between" tokens={headerTokens} styles={headerStackStyles}>
                <Label className={styles.postDeploymentTitle} tabIndex={0}>
                  <FormattedMessage id="postDeploymentConfigurations.postDeploymentConfigurations" />
                </Label>
              </Stack>
              <div className={styles.configurationDetailsContainer}>
                <div className={styles.configurationDetails}>
                  <span className={styles.configurationDetailsTitle} tabIndex={0}>
                    <FormattedMessage id="postDeploymentConfigurations.configurationDetails" />
                  </span>
                  <br />
                  <div className={styles.configurationDetailsSubTitleContainer}>
                    <span className={styles.configurationDetailsSubTitle} tabIndex={0}>
                      <FormattedMessage id="postDeploymentConfigurations.learnHowToConfigure" />
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.listContainer}>
                <DetailsList
                  items={props.deploymentDependencies}
                  columns={columns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.none}
                  isHeaderVisible={true}
                />
              </div>
            </div>
          </Visible>
        </div>
      )}
    </>
  );
};
