import React from 'react';
import fabricStyles from './fabricComponentStyles.styles';
import { Package } from '../../../../solutionCenterApi/gen';

interface FabricComponentListProps {
  packages: Package[];
  renderItem: (item: Package) => JSX.Element;
  title: string;
  subtitle: string;
}

const FabricComponentList = ({ packages, renderItem, title, subtitle }: FabricComponentListProps): JSX.Element => {
  if (!packages || packages.length === 0) {
    return null;
  }

  return (
    <>
      <br />
      <br />
      <span className={fabricStyles.Title}>{title}</span>
      <br />
      <span className={fabricStyles.subTitle}>{subtitle}</span>
      {packages.map((pkg) => (
        <div key={pkg.applicationGuid}>{renderItem(pkg)}</div>
      ))}
      <br />
    </>
  );
};

export default FabricComponentList;
