import { IButtonStyles, mergeStyleSets } from '@fluentui/react';
import { segoeRegular } from '../../../common/Constants';

export const styles = mergeStyleSets({
  additionalComponentsContainer: {
    minHeight: '100%',
    paddingBottom: '65px',
    ':after': {
      content: '',
      display: 'block',
    },
  },
  titleContainer: {
    marginBottom: '15px',
    marginLeft: '32px',
    marginTop: '34px',
    '@media(max-width: 1105px)': {
      width: 500,
    },
    '@media(max-width: 895px)': {
      width: 380,
    },
  },
  titleStyles: {
    '@media(max-width: 380px)': {
      width: '200px',
    },
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600,
    fontFamily: segoeRegular,
  },
  subTextStyles: {
    marginTop: '11px',
    maxWidth: 707,
    '@media(max-width: 480px)': {
      width: 200,
    },
  },
  optionalComponentsTableStyle: {
    marginTop: '37px',
    width: '844px',
    marginLeft: '25px',
    overflowY: 'visible',
    '@media(max-width: 1260px)': {
      width: 700,
    },
    '@media(max-width: 1105px)': {
      width: 500,
    },
    '@media(max-width: 895px)': {
      width: 400,
    },
    '@media(max-width: 480px)': {
      width: 245,
    },
  },
  pageFooterLeftActionSection: {
    width: 'calc(50% - 2px)',
    textAlign: 'left',
    display: 'inline-block',
    zIndex: 1,
  },
  buttonLeftActionSection: {
    textAlign: 'left',
    display: 'inline-block',
    marginLeft: '10px',
    '@media(max-width: 960px)': {
      textAlign: 'left',
    },
  },
  buttonRightActionSection: {
    textAlign: 'right',
    display: 'inline-block',
    marginLeft: '10px',
    '@media(max-width: 960px)': {
      textAlign: 'left',
    },
  },
});

export const buttonStyle: IButtonStyles = {
  root: {
    border: '1px solid #FFFFFF',
  },
};
