import RestService from './restService';
import config from '../config';
import SolutionsStateContainer from '../stateContainers/solutionsStateContainer';
import * as ApiType from '../solutionCenterApi/gen';
import { ProductSupportL01, ProductSupportL03 } from '../common/Type';
import SolutionService from './solutionsService';
import { ProductSupport } from '../common/Constants';
import { toggleVisibility } from '../utils/toggleVisibility';

const ProductSupportService = {
  getAllProductSupports: async (): Promise<ApiType.ProductSupport[]> => {
    const productSupport = await RestService.get({
      endPoint: config.endpoints.getProductSupport,
    });

    return productSupport;
  },

  getProductSupportL01s: async (): Promise<ProductSupportL01[]> => {
    const allProductSupports = await ProductSupportService.getAllProductSupports();
    const allSolutions = SolutionsStateContainer.getAllSolutions() ?? (await SolutionService.getAllSolutions());
    const allVisibleSolutions =
      SolutionsStateContainer.getIsVisibleSolutions() ?? (await toggleVisibility(allSolutions));

    const uniqueL01s = [...new Map(allProductSupports.map((x) => [x.partitionKey, x])).values()];

    const productSupportL01s: ProductSupportL01[] = uniqueL01s.map(
      (productSupport: ApiType.ProductSupport, index: number) => ({
        l01RowKey: productSupport.partitionKey,
        supportId: productSupport.rowKey === ProductSupport.L01SupportRowKey ? productSupport.id : '',
        l01Name: allVisibleSolutions.find((x) => x.rowKey === productSupport.partitionKey)?.solutionName,
        l01UniqueName: allVisibleSolutions.find((x) => x.rowKey === productSupport.partitionKey)?.uniqueName,
      })
    );

    return productSupportL01s.filter((x) => x.l01Name !== undefined);
  },

  getProductSupportL03s: async (l01RowKey: string, l01UniqueName: string): Promise<ProductSupportL03[]> => {
    const allProductSupports = await ProductSupportService.getAllProductSupports();
    const currentSolution = await SolutionService.getCurrentSolution(l01UniqueName);
    const l03s = currentSolution.l02s.reduce((acc, current) => acc.concat(current.l03s), []);
    const l03Supports = allProductSupports.filter(
      (x) => x.partitionKey === l01RowKey && x.rowKey !== ProductSupport.L01SupportRowKey
    );

    const productSupportL03s: ProductSupportL03[] = l03Supports.map(
      (productSupport: ApiType.ProductSupport, index: number) => ({
        l03RowKey: productSupport.rowKey,
        supportId: productSupport.id,
        l03Name:
          productSupport.rowKey === ProductSupport.GeneralSupportRowKey
            ? productSupport.rowKey
            : l03s.find((x) => x.rowKey === productSupport.rowKey).name,
        isSupportAvailable: productSupport.isPPACSupportAvailable,
        details: productSupport.details,
      })
    );

    return productSupportL03s;
  },
};

export default ProductSupportService;
