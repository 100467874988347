import { mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  installationTitleContainer: {
    width: '75%',
    '@media(max-width: 960px)': {
      width: '100%',
    },
  },
  stepTitle: {
    display: 'inline',
    paddingLeft: '0px',
  },
  loadingContainer: {
    position: 'relative',
    paddingTop: '122px',
    display: 'hidden',
    '@media(max-width: 960px)': {
      paddingTop: '20px',
    },
  },
  loadingContainerImage: {
    margin: 'auto',
    width: '50%',
  },
  loadingImage: {
    width: '208px',
    margin: '0 auto',
    paddingLeft: '114px',
    paddingBottom: '91px',
    '@media(max-width: 960px)': {
      paddingLeft: '20px',
      width: '160px',
      paddingBottom: '10px',
    },
    '@media(max-width: 480px)': {
      paddingLeft: '20px',
    },
  },
  loadingImageLabel: {
    width: '420px',
    flexDirection: 'column',
    alignItems: 'center',
    '@media(min-width: 961px)': {
      display: 'flex',
    },
    '@media(max-width: 480px)': {
      width: '185px',
    },
  },
  dependenciesTableContainer: {
    paddingTop: '30px',
    width: '60%',
    margin: 'auto',
  },
  progressContainer: {
    width: '50%',
    paddingBottom: '50px',
  },
});

export const inlineStyles = {
  iconName: 'ErrorBadge',
  color: '#A81000',
};
export default styles;
