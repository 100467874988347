import React, { useEffect } from 'react';
import { mergeStyles } from '@fluentui/react/lib/Styling';

const mergedStyles = mergeStyles({
  margin: '8px',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  display: 'inline-block',
});
const styles = {
  normal: {
    /* Elevation / Depth4 */
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    border: '1px solid rgba(0, 0, 0, 0)',
  },
  hover: {
    boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    border: '1px solid rgba(0, 0, 0, 0)',
  },
  focus: {
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    /* Comm Blue / Primary #0078D4 */
    border: '1px solid #0078D4',
    boxSizing: 'content-box',
    marginBottom: '3px',
  },
  error: {
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    /* Message / Web / Error-Blocked Icon #A80000 */
    border: '1px solid #A80000',
    boxSizing: 'content-box',
    marginBottom: '3px',
  },
};

const CardStylesWrapper = ({
  hasError = false,
  cardComponent: CardComponent,
  containerStyle,
  containerClassName,
  disableHover,
  ...rest
}) => {
  const [defaultStyle, setDefaultStyle] = React.useState(styles.normal);
  const [isSelected, setIsSelected] = React.useState(false);

  useEffect(() => {
    if (hasError) {
      return setCanvas(styles.error);
    }
    if (isSelected) {
      return setCanvas(styles.focus);
    }
    return setCanvas(styles.normal);
  }, [isSelected, hasError]);

  const setCanvas = (style) => {
    setDefaultStyle({ ...style });
  };

  return (
    <>
      <div
        className={`${mergedStyles} ${containerClassName}`}
        style={{ ...defaultStyle, ...containerStyle }}
        onClick={(e) => {
          if (!disableHover) {
            e.stopPropagation();
            setIsSelected(!isSelected);
          }
        }}
        onMouseOverCapture={(e) => {
          if (!disableHover && !isSelected && !hasError) setCanvas(styles.hover);
        }}
        onMouseOut={(e) => {
          if (!disableHover && !isSelected && !hasError) setCanvas(styles.normal);
        }}
      >
        <CardComponent {...rest} />
      </div>
    </>
  );
};

export default CardStylesWrapper;
