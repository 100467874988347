import RestService from './restService';
import config from '../config';
import * as ApiType from '../solutionCenterApi/gen';

const TrialService = {
  getTrialDeployments: async (): Promise<ApiType.TrialsDeploymentRequest[]> => {
    const result = await RestService.get({
      endPoint: config.endpoints.getTrialDeployments,
    });
    return result;
  },

  updateTrialDeploymentsWithActualExpiry: async (
    existingTrialsPayload: ApiType.ExistingTrialsPayload
  ): Promise<Array<ApiType.TrialsDeploymentRequest>> => {
    const result = await RestService.put({
      endPoint: config.endpoints.updateTrialDeploymentsWithExpiry,
      data: existingTrialsPayload,
    });
    return result;
  },

  deploy: async (
    scenarioType: string,
    l01RowKey: string,
    environmentDisplayName: string
  ): Promise<ApiType.DeploymentResponse> => {
    const data = {
      scenarioType: scenarioType,
      l01RowKey: l01RowKey,
      environmentDisplayName: environmentDisplayName,
    };
    const result = await RestService.post({
      endPoint: config.endpoints.getTrialDeployments,
      data,
    });
    return result;
  },

  getTrialDeploymentStatus: async (trialsDeploymentRequestRowKey: string): Promise<ApiType.TrialsDeploymentRequest> => {
    let trialDeploymentStatusEndPoint = config.endpoints.getTrialDeploymentStatus.replace(
      '{trialsDeploymentRequestRowKey}',
      trialsDeploymentRequestRowKey
    );
    return await RestService.get({
      endPoint: trialDeploymentStatusEndPoint,
    });
  },

  getTrialDeploymentsforSolution: async (l01RowKey: string): Promise<ApiType.TrialsDeploymentRequest> => {
    let trialDeploymentForSolutionendPoint = config.endpoints.getTrialDeploymentsForSolution.replace(
      '{l01RowKey}',
      l01RowKey
    );
    return await RestService.get({
      endPoint: trialDeploymentForSolutionendPoint,
    });
  },

  getTenantDefaultLocation: async (): Promise<ApiType.BapLocation> => {
    const result = await RestService.get({
      endPoint: config.endpoints.getDefaultTenantLocation,
    });
    return result;
  },

  getInstances: async (): Promise<ApiType.Instance[]> => {
    return await RestService.get({
      endPoint: config.endpoints.getInstances,
    });
  },

  getEnvironments: async (): Promise<ApiType.BapEnvironment[]> => {
    return await RestService.get({
      endPoint: config.endpoints.getTrialEnvironments,
    });
  },
};
export default TrialService;
