import RestService from './restService';
import config from '../config';
import SolutionsStateContainer from '../stateContainers/solutionsStateContainer';
import { SetupGuide } from '../solutionCenterApi/gen';

const setupGuideService = {
  getAllSetupGuides: async () => {
    try {
      const result: SetupGuide[] = await RestService.get({
        endPoint: config.endpoints.getAllSetupGuides,
      });
      if (result) {
        SolutionsStateContainer.setAllSetupGuides(result);
        return result;
      }
      return [];
    } catch (error) {
      console.error('Error fetching setup guide items:', error);
      return [];
    }
  },
};

export default setupGuideService;
