import * as React from 'react';
import {
  Dropdown,
  FontIcon,
  IconButton,
  IDropdownOption,
  IStackTokens,
  Label,
  Link,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import {
  dropdownStyles,
  iconClass,
  infoIconStyles,
  currencyInfoStyles,
  typeInfoIconStyles,
  hyperlinkStyle,
} from './environment-creation-styles';
import {
  capacityConstraintsLink,
  englishLanguageCode,
  environmentTypes,
  environmentTypesLink,
  usdCurrencyCode,
} from '../../../common/Constants';
import { FormattedMessage, useIntl } from 'react-intl';

export interface EnvironmentCreationInputProps {
  envRegions: IDropdownOption[];
  envCurrency: IDropdownOption[];
  envLanguage: IDropdownOption[];
  onEnvNameChange: (newValue?: string) => void;
  onEnvRegionChange: (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => void;
  onEnvTypeChange: (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => void;
  onEnvCurrencyChange: (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => void;
  onEnvLanguageChange: (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => void;
}

export interface LableIconProps {
  icon: string;
  labelText: string;
  iconText: string;
}

const stackTokens: IStackTokens = { childrenGap: 15 };

export const EnvironmentCreationInputs: React.FunctionComponent<EnvironmentCreationInputProps> = (
  envProps: EnvironmentCreationInputProps
) => {
  const intlShape = useIntl();

  const [envNameErrorMessage, setEnvNameErrorMessage] = React.useState<string>('');

  const onRenderLabel = (props: LableIconProps): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="center">
        <Label required>{props.labelText}</Label>
        <IconButton
          iconProps={{ iconName: props.icon }}
          title={props.iconText}
          styles={typeInfoIconStyles}
          href={environmentTypesLink}
          target={'_blank'}
        />
      </Stack>
    );
  };

  const onEnvNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    if (newValue && !(newValue?.trim().length < 1)) {
      envProps.onEnvNameChange(newValue.trim());
      setEnvNameErrorMessage('');
    } else {
      envProps.onEnvNameChange(undefined);
      let errMsg = intlShape.formatMessage({
        id: 'destination.invalidEnvironmentNameErrMsg',
      });
      setEnvNameErrorMessage(errMsg);
    }
  };

  return (
    <Stack verticalFill verticalAlign="center" tokens={stackTokens}>
      <Text styles={infoIconStyles}>
        <FontIcon style={iconClass} iconName="info" />
        <FormattedMessage id="destination.capacityConstraints1" />
        <Link href={capacityConstraintsLink} target={'_blank'} style={hyperlinkStyle}>
          {' '}
          <FormattedMessage id="destination.capacityConstraints2" />
        </Link>
      </Text>
      <TextField
        label={intlShape.formatMessage({
          id: 'destination.inputEnvironmentName',
        })}
        placeholder={intlShape.formatMessage({
          id: 'destination.inputEnvironmentNamePlaceHolder',
        })}
        onChange={onEnvNameChange}
        required
        errorMessage={envNameErrorMessage}
      />
      <Dropdown
        placeholder={intlShape.formatMessage({
          id: 'environment.Dropdown.selectRegion',
        })}
        label={intlShape.formatMessage({ id: 'locations.Dropdown' })}
        options={envProps.envRegions}
        required
        styles={dropdownStyles}
        onChange={envProps.onEnvRegionChange}
      />
      <Dropdown
        placeholder={intlShape.formatMessage({
          id: 'environment.Dropdown.typePlaceholder',
        })}
        onRenderLabel={() =>
          onRenderLabel({
            icon: 'Info',
            iconText: intlShape.formatMessage({
              id: 'environment.type.iconHelpText',
            }),
            labelText: 'Type',
          } as LableIconProps)
        }
        options={environmentTypes}
        styles={dropdownStyles}
        aria-label={intlShape.formatMessage({
          id: 'environment.Dropdown.typePlaceholder',
        })}
        onChange={envProps.onEnvTypeChange}
      />
      <Dropdown
        label={intlShape.formatMessage({ id: 'environment.currency' })}
        defaultSelectedKey={usdCurrencyCode}
        options={envProps.envCurrency}
        required
        styles={dropdownStyles}
        onChange={envProps.onEnvCurrencyChange}
      />
      <span style={currencyInfoStyles}>{intlShape.formatMessage({ id: 'environment.environmentCurrencyInfo' })}</span>
      <Dropdown
        label={intlShape.formatMessage({ id: 'environment.language' })}
        defaultSelectedKey={englishLanguageCode.toString()}
        options={envProps.envLanguage}
        required
        styles={dropdownStyles}
        onChange={envProps.onEnvLanguageChange}
      />
    </Stack>
  );
};
