import * as Type from '../common/Type';
import * as ApiType from '../solutionCenterApi/gen';

function isString(obj: any): obj is string {
  return typeof obj === 'string';
}

function isBoolean(obj: any): obj is boolean {
  return typeof obj === 'boolean';
}

function isStringArrayType(array: any[]): boolean {
  return array.every((item) => {
    return isString(item);
  });
}

const min = (min: number, actual: number) => actual >= min;

const error: <T>(message: string) => Type.Result<T> = (message) => ({
  ok: false,
  message: message,
});

export function validateDataverseDeploymentParams(
  deploymentPayload: ApiType.DeploymentPayloadV2
): Type.Result<ApiType.DeploymentPayloadV2> {
  // Deployment Name must be a string and cannot be empty
  let res;
  res = verifyStringNonEmptyProperties(deploymentPayload.deploymentName, 'deploymentName');
  if (res?.ok === false) return res;

  // InstanceApiUrl must be a string and cannot be empty
  res = verifyStringNonEmptyProperties(deploymentPayload.instanceApiUrl, 'instanceApiUrl');
  if (res?.ok === false) return res;

  // InstanceId must be a string and cannot be empty
  res = verifyStringNonEmptyProperties(deploymentPayload.instanceId, 'instanceId');
  if (res?.ok === false) return res;

  // InstanceName must be a string and cannot be empty
  res = verifyStringNonEmptyProperties(deploymentPayload.instanceName, 'instanceName');
  if (res?.ok === false) return res;

  // EnvironmentId must be a string and cannot be empty
  res = verifyStringNonEmptyProperties(deploymentPayload.environmentId, 'environmentId');
  if (res?.ok === false) return res;

  // l03RowKeys must be a string array and cannot be empty
  res = verifyStringArrayNonEmptyProperties(deploymentPayload.l03RowKeys, 'l03RowKeys');
  if (res?.ok === false) return res;

  // installSampleData must be a boolean
  res = verifyBooleanProperty(deploymentPayload.installSampleData, 'installSampleData');
  if (res?.ok === false) return res;

  // installAdditionalSampleData must be a boolean
  res = verifyBooleanProperty(deploymentPayload.installAdditionalSampleData, 'installAdditionalSampleData');
  if (res?.ok === false) return res;

  // l01RowKey must be a string array and cannot be empty
  res = verifyStringNonEmptyProperties(deploymentPayload.l01RowKey, 'l01RowKey');
  if (res?.ok === false) return res;

  return {
    ok: true,
    message: deploymentPayload,
  };
}

export function validateCIDeploymentParams(
  deploymentPayload: ApiType.DeploymentPayloadV2
): Type.Result<ApiType.DeploymentPayloadV2> {
  let res;
  // Deployment Name must be a string and cannot be empty
  res = verifyStringNonEmptyProperties(deploymentPayload.deploymentName, 'deploymentName');
  if (res?.ok === false) return res;

  // CIInstanceId must be a string and cannot be empty
  res = verifyStringNonEmptyProperties(deploymentPayload.ciInstanceId, 'ciInstanceId');
  if (res?.ok === false) return res;

  // CIInstanceName must be a string and cannot be empty
  verifyStringNonEmptyProperties(deploymentPayload.ciInstanceName, 'ciInstanceName');

  // l03RowKeys must be a string array and cannot be empty
  res = verifyStringArrayNonEmptyProperties(deploymentPayload.l03RowKeys, 'l03RowKeys');
  if (res?.ok === false) return res;

  // installSampleData must be a boolean
  res = verifyBooleanProperty(deploymentPayload.installSampleData, 'installSampleData');
  if (res?.ok === false) return res;

  // installAdditionalSampleData must be a boolean
  res = verifyBooleanProperty(deploymentPayload.installAdditionalSampleData, 'installAdditionalSampleData');
  if (res?.ok === false) return res;

  // l01RowKey must be a string array and cannot be empty
  res = verifyStringNonEmptyProperties(deploymentPayload.l01RowKey, 'l01RowKey');
  if (res?.ok === false) return res;

  return {
    ok: true,
    message: deploymentPayload,
  };
}

export function validateFabricDeploymentParams(
  deploymentPayload: ApiType.DeploymentPayloadV2
): Type.Result<ApiType.DeploymentPayloadV2> {
  let res;
  // Deployment Name must be a string and cannot be empty
  res = verifyStringNonEmptyProperties(deploymentPayload.deploymentName, 'deploymentName');
  if (res?.ok === false) return res;

  // l03RowKeys must be a string array and cannot be empty
  res = verifyStringArrayNonEmptyProperties(deploymentPayload.l03RowKeys, 'l03RowKeys');
  if (res?.ok === false) return res;

  // l01RowKey must be a string array and cannot be empty
  res = verifyStringNonEmptyProperties(deploymentPayload.l01RowKey, 'l01RowKey');
  if (res?.ok === false) return res;

  res = verifyStringNonEmptyProperties(deploymentPayload.workspaceId, 'workspaceId');
  if (res?.ok === false) return res;

  return {
    ok: true,
    message: deploymentPayload,
  };
}

export function validateARMDeploymentParams(
  armPayload: Type.AzureDeployableInstance | null
): Type.Result<Type.AzureDeployableInstance> {
  if (!armPayload) {
    return error('Azure Instance cannot be null');
  }
  return {
    ok: true,
    message: armPayload,
  };
}

const verifyStringNonEmptyProperties = (propertyName: string | undefined, property: string) => {
  if (!isString(propertyName)) {
    return error(`${property} must be a string but was ${typeof propertyName}`);
  } else if (!min(1, propertyName.length)) {
    return error(`${property} must not be empty.`);
  }
};

const verifyStringArrayNonEmptyProperties = (propertyNameList: string[], property: string) => {
  if (!isStringArrayType(propertyNameList)) {
    return error(`${property} must be a string array but was ${typeof propertyNameList}`);
  } else if (!min(1, propertyNameList.length)) {
    return error(`${property} must not be empty.`);
  }
};

const verifyBooleanProperty = (propertyValue: boolean, property: string) => {
  if (!isBoolean(propertyValue)) {
    return error(`${property} must be a boolean but was ${typeof propertyValue}`);
  }
};
