import { ClientConfiguration } from '../webAppApi';

const currentSessionStorage = window.sessionStorage;
const storageKey = 'runtimeConfiguration';

const RuntimeConfigStateContainer = {
  getConfiguration: (): ClientConfiguration => {
    const runtimeConfig = currentSessionStorage.getItem(storageKey);
    if (runtimeConfig) {
      return JSON.parse(runtimeConfig);
    }
  },

  setConfiguration: (config: ClientConfiguration) => {
    currentSessionStorage.setItem(storageKey, JSON.stringify(config));
  },
};

export default RuntimeConfigStateContainer;
