import React from 'react';
import { Route, Switch } from 'react-router';
import Home from '../pages/home/home';
import Products from '../pages/products';
import Integration from '../pages/integrations';
import Install from '../pages/install';
import { Error } from '../pages/error';
import { AppProps } from '../App';
import { ErrorScreenContactPartner } from '../pages/error/error-screen-contact-partner/error-screen-contact-partner';
import DeploymentDetails from '../pages/deploymentDetails/deploymentDetails';
import config from '../config';
import Deployments from '../pages/home/yourDeployments-section';
import { GettingStarted } from '../components/trial/gettingStarted';
import { TrialLanding } from '../components/trial/landingPage/landingPage';
import { ProvisionReady } from '../components/trial/provisionReady';
import { NoAvailabilityRegion } from '../components/trial/noAvailabilityRegion';
import { ErrorTrial } from '../components/trial/errorTrial';
import WithBaseQueryParameterProcessing from '../common/withBaseQueryParamProcessing';
import DataInjectedProducts from '../pages/products/dataInjectedProducts';
import RuntimeConfigStateContainer from '../stateContainers/runtimeConfigStateContainer';
import * as WebAppApiType from '../webAppApi/index';
import DataInjectedInstall from '../pages/install/dataInjectedInstall';
import { LearningHub } from '../pages/home/Learning/learningHub';
import { SetupGuides } from '../pages/home/Learning/setupGuides/setupGuides';

const Router = (appProps: AppProps) => {
  const runtimeConfig: WebAppApiType.ClientConfiguration = RuntimeConfigStateContainer.getConfiguration();
  const enableDeepLinkingForFilters = runtimeConfig.featureFlag.enableDeepLinkingForFilters;
  const enableDeepLinkingForInstall = runtimeConfig.featureFlag.enableDeepLinkingForInstall;
  const ProductsPage = (props) => WithBaseQueryParameterProcessing(DataInjectedProducts, appProps, props);
  const InstallPage = (props) => WithBaseQueryParameterProcessing(DataInjectedInstall, appProps, props);
  return (
    <Switch>
      <Route exact path={config.routes.home} {...appProps} component={Home} />
      <Route exact path={config.routes.errorScreenContactPartner} {...appProps} component={ErrorScreenContactPartner} />
      <Route exact path={config.routes.learningHub} {...appProps} component={LearningHub} />
      <Route exact path={config.routes.setupGuides} {...appProps} component={SetupGuides} />
      <Route exact path={config.routes.yourDeployments} {...appProps} component={Deployments} />
      <Route exact path={config.routes.deploymentDetails} {...appProps} component={DeploymentDetails} />
      <Route
        exact
        path={config.routes.products}
        {...appProps}
        component={enableDeepLinkingForFilters ? ProductsPage : Products}
      />
      <Route exact path={config.routes.integrations} {...appProps} component={Integration} />
      <Route
        path={config.routes.installBase}
        {...appProps}
        component={enableDeepLinkingForInstall ? InstallPage : Install}
      />
      <Route exact path={config.routes.trialLanding} {...appProps} component={TrialLanding} />
      <Route exact path={config.routes.error} {...appProps} component={Error} />
      <Route exact path={config.routes.trial} {...appProps} component={GettingStarted} />
      <Route exact path={config.routes.trialProvision} {...appProps} component={ProvisionReady} />
      <Route exact path={config.routes.trialNonAvailability} {...appProps} component={NoAvailabilityRegion} />
      <Route exact path={config.routes.trialError} {...appProps} component={ErrorTrial} />
    </Switch>
  );
};

export default Router;
