import { mergeStyleSets } from '@fluentui/react';

const successStyles = mergeStyleSets({
  topSection: {
    width: '75%',
    display: 'inline-block',
    '@media(max-width: 960px)': {
      width: '95%',
    },
    paddingBottom: '34px',
  },
  topSectionContainer: {
    display: 'inline',
    paddingLeft: '0px',
  },
  topSectionTitle: {
    lineHeight: '28px',
    paddingBottom: '4px',
    paddingTop: '32px',
    marginTop: '0px',
  },
  topSectionSecondaryTitle: {
    lineHeight: '20px',
  },
  mainSectionContainer: {
    maxWidth: '95%',
  },
  dependencyListContainer: {
    marginLeft: '-10px',
  },
  deploymentSuccessScreenSection: {
    marginTop: '7px',
    marginBottom: '40px',
    backgroundColor: '#f4f0ec',
    paddingTop: '8px',
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingBottom: '5px',
    maxWidth: '95%',
  },
  deploymentSuccessScreenHeader: {
    display: 'block',
    marginInlineStart: '10px',
    marginInlineEnd: '0px',
    fontWeight: 'bold',
    marginBlockStart: '5px',
    marginBlockEnd: '0px',
  },
  deploymentSuccessScreenActions: {
    paddingLeft: '23px',
  },
  deploymentSuccessScreenFooter: {
    position: 'relative',
    right: '10px',
    bottom: '10px',
    textAlign: 'right',
  },
  flexBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerImg: {
    display: 'inline-block',
    width: '36px',
    height: '36px',
    marginBlockStart: '8px',
  },
});

export default successStyles;
