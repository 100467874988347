import { mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  outerContainer: {
    verticalAlign: 'top',
    display: 'inline-block',
    width: '320px',
    height: '221px',
    background: '#FFFFFF',
    borderRadius: '2px',
    margin: '8px',
    '@media(max-width: 480px)': {
      width: '190px',
    },
  },
  header: {
    height: '31px',
    borderBottom: '1px solid #EDEBE9',
    padding: '12px 16px 12px 16px',
  },

  headerDependency: {
    lineHeight: '16px',
    display: 'inline-block',
    margin: '0',
    marginLeft: '8px',
    width: 'calc(100% - 44px)',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  headerP: {
    marginTop: '2px',
    color: '#605E5C',
    fontSize: '12px',
    fontWeight: 'normal',
  },
  headerImg: {
    width: '36px',
    height: '32px',
    verticalAlign: 'top',
    display: 'inline-block',
  },
  errorIconContainerStyle: {
    width: '220px',
  },
  hrStyle: {
    height: 1,
    margin: '10px 0 0 0',
    backgroundColor: '#EDEBE9',
    border: 0,
  },
  main: {
    height: '35px',
    padding: '16px',
  },
  mainH3: {
    padding: '0',
    margin: '0',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  mainP: {
    overflow: 'hidden',
    height: '32px',
    margin: '0',
    marginTop: '4px',
    marginBottom: '4px',
    padding: '0',
    fontSize: '12px',
    color: '#605E5C',
  },
  mainA: {
    textDecoration: 'none',
    color: '#0078D4',
    fontSize: '12px',
  },
  actionContainerDeployment: {
    display: 'flex',
    flexDirection: 'row',
  },
  actionContainerButtonsStyles: {
    height: '24px',
    marginTop: '5px',
    paddingBottom: '2px',
  },
  footer: {
    padding: '16px 5px',
    paddingTop: '8px',
  },
  footerFlex: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignContent: 'stretch',
    paddingTop: '10px',
  },
  footerSvgError: {
    fill: '#A80000',
    verticalAlign: 'bottom',
  },
  footerSvgSuccess: {
    fill: '#107C10',
    verticalAlign: 'bottom',
  },
  footerSpan: {
    lineHeight: '16px',
    fontSize: '14px',
    marginLeft: '6px',
    color: '#323130',
  },
});

export default styles;
