import React, { FC, ReactElement, useEffect } from 'react';
import * as ApiType from '../../../../../solutionCenterApi/gen';
import { TextFieldControlBuilder } from '../azComponents/customComponentControls/textFieldControlBuilder';
import { DropdownControlBuilder } from '../azComponents/customComponentControls/dropdownControlBuilder';
import * as Type from '../../../../../common/Type';
import { useDispatch } from 'react-redux';
import { setErrorValidationControlType } from '../../../../../redux/azureInstance/azureInstanceActions';
import config, { telemetryConstants } from '../../../../../config';
import { telemetry } from '../../../../../services/telemetryService';
import { ArmDeploymentScope } from '../../../../../solutionCenterApi/gen';
import RuntimeConfigStateContainer from '../../../../../stateContainers/runtimeConfigStateContainer';
import Utils from '../../../../../utils';

interface InitialProps {
  azureOfferRowKey: string;
  armDeploymentScope: ArmDeploymentScope;
  parameterDefinitions: ApiType.ParameterDefinition[];
  parameterDefinitionsDictionaryCallback: (parameterDefinitionDictionary: Type.ParameterDefinitionDictionary) => void;
  parameterDefinitionsCallback: ApiType.ParameterDefinition;
  isUpdate?: boolean;
}

const InstanceComponentBuilder: FC<InitialProps> = (props: InitialProps): ReactElement => {
  const [parameterValidationMap, setParameterValidationMap] = React.useState(new Map<string, boolean>());
  const [isInitialRender, setIsInitialRender] = React.useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isInitialRender) {
      props.parameterDefinitions.forEach((eachParameter) => {
        setIsInitialRender(false);
        if (
          eachParameter.type !== ApiType.ParameterType.Subscription &&
          eachParameter.type !== ApiType.ParameterType.ResourceGroup &&
          !(props.isUpdate && eachParameter.readOnly)
        ) {
          setParameterValidationMap(
            parameterValidationMap.set(
              eachParameter.name,
              Utils.isNullOrUndefined(eachParameter.value) && nonRequiredField(eachParameter) === null
            )
          );
        }
      });
      dispatchErrorValidationControlType();
    }
  }, [props.parameterDefinitions, isInitialRender]);

  const handleParameterDefinitionsValue = (parameterDefinition: ApiType.ParameterDefinition) => {
    props.parameterDefinitions.forEach((eachDefinition) => {
      if (eachDefinition.name === parameterDefinition.name) {
        eachDefinition.value = parameterDefinition.value;
      }
    });
    props.parameterDefinitionsDictionaryCallback({
      armDeploymentScope: props.armDeploymentScope,
      azureOfferRowKey: props.azureOfferRowKey,
      parameterDefinitions: props.parameterDefinitions,
    });
  };

  const handleErrorValidationCheck = (parameterValidation: Type.ParameterValidation) => {
    if (parameterValidationMap.has(parameterValidation.name)) {
      setParameterValidationMap(
        parameterValidationMap.set(parameterValidation.name, parameterValidation.isValidationFailed)
      );
      dispatchErrorValidationControlType();
    }
  };

  const dispatchErrorValidationControlType = () => {
    for (let [name, isValidationFailed] of parameterValidationMap) {
      if (isValidationFailed === true) {
        telemetry.logTrace(
          `${telemetryConstants.azureResourceManager.CONTROL_VALIDATION_CHECK} name: ${name} enabledValidationCheck: ${isValidationFailed}`,
          telemetryConstants.severity.SEVERITY_INFO
        );
        dispatch(setErrorValidationControlType(true));
        break;
      }
      dispatch(setErrorValidationControlType(false));
    }
  };

  const permissionAuthorizationFieldIncluded = (parameterName: string): Type.permissionAuthorizationFieldIncluded => {
    let permAuthField: Type.permissionAuthorizationFieldIncluded = {
      fieldName: '',
      isHidden: false,
    };
    permAuthField.fieldName = parameterName
      .toUpperCase()
      .includes(config.armTemplates.azureSynapseAnalytics.parameters.setSbdcRbacOnStorageAccount)
      ? parameterName
      : null;
    permAuthField.isHidden = permAuthField.fieldName !== null;
    return permAuthField;
  };

  const parameterParserEngine = (parameterDefinitions: ApiType.ParameterDefinition[]): JSX.Element[] | null => {
    let customControl: JSX.Element[] | null = [];
    const runtimeConfig = RuntimeConfigStateContainer.getConfiguration();
    parameterDefinitions?.forEach((parameterElements, index) => {
      const parameterType = parameterElements?.type ?? null;
      if (parameterType && Object.values(ApiType.ParameterType).includes(parameterType)) {
        if (parameterElements.name.startsWith('_')) {
          //Hiding Internal Parameters
          telemetry.logTrace(
            `Skipping Internal Parameter with name= ${parameterElements.name} having defaultValue= ${
              parameterElements.hasOwnProperty('defaultValue') ? parameterElements.defaultValue : null
            }`,
            telemetryConstants.severity.SEVERITY_WARNING
          );
          return;
        } else if (props.isUpdate && parameterElements.readOnly) {
          //Hiding readonly parameters in update flow
          telemetry.logTrace(
            `Skipping readonly Parameter in update flow with name= ${parameterElements.name} having defaultValue= ${
              parameterElements.hasOwnProperty('defaultValue') ? parameterElements.defaultValue : null
            }`,
            telemetryConstants.severity.SEVERITY_WARNING
          );
          return;
        } else if (
          parameterElements.hasOwnProperty('defaultValue') &&
          runtimeConfig?.arm?.hiddenParameters?.includes(parameterElements.name)
        ) {
          telemetry.logTrace(
            `Hiding Parameter with name= ${parameterElements.name} having defaultValue= ${
              parameterElements.hasOwnProperty('defaultValue') ? parameterElements.defaultValue : null
            }`,
            telemetryConstants.severity.SEVERITY_WARNING
          );
          return;
        } else if (
          (parameterType && parameterType === ApiType.ParameterType.Subscription) ||
          parameterType === ApiType.ParameterType.ResourceGroup
        ) {
          return;
        } else if (parameterElements.allowedValues || parameterType === ApiType.ParameterType.Bool) {
          let permissionAuthorizationField = permissionAuthorizationFieldIncluded(parameterElements.name);
          customControl?.push(
            <DropdownControlBuilder
              key={index}
              parameterElements={parameterElements}
              dropdownValidationCallback={handleErrorValidationCheck}
              parameterDefinitionsCallback={handleParameterDefinitionsValue}
              azureOfferRowKey={null}
              permissionAuthorizationFieldIncluded={permissionAuthorizationField}
            />
          );
        } else {
          let nonReqField = nonRequiredField(parameterElements);
          customControl?.push(
            <TextFieldControlBuilder
              key={index}
              parameterElements={parameterElements}
              textFieldValidationCallback={handleErrorValidationCheck}
              parameterDefinitionsCallback={handleParameterDefinitionsValue}
              nonRequiredField={nonReqField}
            />
          );
        }
      }
    });
    return customControl;
  };
  return <>{parameterParserEngine(props.parameterDefinitions)}</>;
};
export default InstanceComponentBuilder;

export const nonRequiredField = (parameterDefinition: ApiType.ParameterDefinition): string | null => {
  return parameterDefinition.hasOwnProperty('defaultValue') ? parameterDefinition.name : null;
};
