import * as React from 'react';
import { FC, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import styles from './backButton.styles';
import { IIconProps, BaseButton, IconButton } from '@fluentui/react';

export interface BackButtonProps {
  onBackButtonClick: (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | HTMLSpanElement,
      MouseEvent
    >
  ) => void;
}

const backIcon: IIconProps = { iconName: 'ChromeBack' };

export const BackButton: FC<BackButtonProps> = (props: BackButtonProps): ReactElement => {
  const { onBackButtonClick } = props;

  const intlShape = useIntl();

  return (
    <div>
      <IconButton
        onClick={onBackButtonClick}
        iconProps={backIcon}
        ariaLabel={intlShape.formatMessage({ id: 'buttons.back' })}
        className={styles.button}
      />
    </div>
  );
};
