import React, { FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { DefaultButton, Panel, PanelType } from 'office-ui-fabric-react';
import { useIntl } from 'react-intl';
import styles, { panelContentStyle } from './previewPanel.styles';
import { Link } from '@fluentui/react';
import * as Constants from '../../../common/Constants';
import SolutionsStateContainer from '../../../stateContainers/solutionsStateContainer';

export interface InitialProps {
  isOpen: boolean;
  onDismiss: () => void;
}

export const PreviewPanel: FC<InitialProps> = (props: InitialProps): ReactElement => {
  const intlShape = useIntl();
  const previewFeedbackLink = SolutionsStateContainer.getCurrentSolution()?.previewFeedbackLink;

  return (
    <>
      <Panel
        id="previewPanel"
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        type={PanelType.smallFixedFar}
        hasCloseButton={true}
        styles={panelContentStyle}
        closeButtonAriaLabel={intlShape.formatMessage({ id: 'buttons.close' })}
      >
        <div>
          <div className={styles.panelContent}>
            <span className={styles.panelTitle}>
              <FormattedMessage id="preview.panel.title" />
            </span>
            <div>
              <div className={styles.panelInfoSection}>
                <div className={styles.panelsubTitle}>
                  <FormattedMessage id="preview.panel.subTitle" />
                </div>
                <div className={styles.panelText}>
                  <ul className={styles.listStyle}>
                    <li>
                      <FormattedMessage id="preview.panel.information1" />
                    </li>
                    <li>
                      <FormattedMessage id="preview.panel.information2" />
                    </li>
                    <li>
                      <FormattedMessage id="preview.panel.information3" />
                    </li>
                    <li>
                      <FormattedMessage id="preview.panel.information4" />
                    </li>
                    <li>
                      <FormattedMessage id="preview.panel.information5" />
                    </li>
                  </ul>
                  <br />
                  <FormattedMessage id="preview.panel.message" />
                  <br />
                  <br />
                  <FormattedMessage
                    id="preview.panel.learn"
                    values={{
                      Link: (learnPreviewText: string) => (
                        <Link target="_blank" href={Constants.enablePreviewLink}>
                          {learnPreviewText}
                        </Link>
                      ),
                    }}
                  />
                  <br />
                  <br />
                  <FormattedMessage
                    id="preview.panel.feedback"
                    values={{
                      Link: (previewFeedbackText: string) => (
                        <Link target="_blank" href={previewFeedbackLink}>
                          {previewFeedbackText}
                        </Link>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <DefaultButton className={styles.closeButton} onClick={props.onDismiss}>
              {' '}
              {intlShape.formatMessage({ id: 'buttons.close' })}
            </DefaultButton>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default PreviewPanel;
