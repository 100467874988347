import React, { FC, ReactElement, useEffect } from 'react';
import { Stack, TextField } from '@fluentui/react';
import { IntlShape, useIntl } from 'react-intl';
import { stackTokens } from './textFieldControl.styles';
import styles, { textfieldStyles } from '../../armStyling/index.styles';
import CustomRenderLabel from './customLabelRenderer';
import * as ApiType from '../../../../../../solutionCenterApi/gen';
import * as Type from '../../../../../../common/Type';
import Utils from '../../../../../../utils';
import { telemetry } from '../../../../../../services/telemetryService';

export const checkPasswordValidation = (
  parameterElements: ApiType.ParameterDefinition,
  textValue: string,
  intlShape: IntlShape
): Type.passwordValidationError => {
  let isValid: boolean = true;
  let errorMessage = '';
  if (parameterElements.name.toLowerCase().includes('password')) {
    try {
      let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
      textValue = textValue.trim();
      isValid = regex.test(textValue);
      errorMessage = isValid
        ? ''
        : intlShape.formatMessage({
            id: 'arm_password_validation',
          });
    } catch (error) {
      isValid = false;
    }
  }
  return { errorMessage, isValid };
};

export const checkTypeValidation = (valueType: ApiType.ParameterType, textValue: string): boolean => {
  let isValid: boolean = true;
  try {
    let parsedValue = JSON.parse(textValue);
    switch (valueType) {
      case ApiType.ParameterType.Object:
        isValid = typeof parsedValue === 'object' && textValue[0] === '{';
        break;
      case ApiType.ParameterType.Array:
        isValid = Array.isArray(parsedValue);
        break;
      case ApiType.ParameterType.Int:
        isValid = typeof parsedValue === 'number';
        break;
      default:
        break;
    }
  } catch (error) {
    isValid = false;
  }
  return isValid;
};

export interface TextFieldControlBuilderProps {
  parameterElements: ApiType.ParameterDefinition;
  parameterDefinitionsCallback: (parameterDefinition: ApiType.ParameterDefinition) => void;
  textFieldValidationCallback: (parameterValidation: Type.ParameterValidation) => void;
  nonRequiredField: string | null;
}

export const TextFieldControlBuilder: FC<TextFieldControlBuilderProps> = (
  props: TextFieldControlBuilderProps
): ReactElement => {
  const { parameterElements, nonRequiredField } = props;

  const [textFieldValue, setTextFieldValue] = React.useState(
    props.parameterElements?.value
      ? Utils.stringifyIfObject(props.parameterElements?.value)
      : Utils.stringifyIfObject(props.parameterElements?.defaultValue)
  );
  const [errorMessage, setErrorMessage] = React.useState<string | null>('');
  const intlShape = useIntl();

  useEffect(() => {
    if (!textFieldValue && nonRequiredField === null) {
      props.textFieldValidationCallback({
        name: parameterElements.name,
        isValidationFailed: true,
      });
      setErrorMessage(intlShape.formatMessage({ id: 'arm_textfield_empty' }));
    }
  }, []);

  const onTextValueChange = React.useCallback(
    (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: any) => {
      let errorDisplayMessage: string = '';
      let validationResult: Type.validationResult = {
        value: newValue,
        errorDisplayMessage: '',
      };
      switch (parameterElements.type) {
        case ApiType.ParameterType.Int:
          validationResult = validateNewValue(newValue, 'arm.textfield.number.validation.message');
          break;
        case ApiType.ParameterType.SecureString:
          setTextFieldValue(newValue || '');
          errorDisplayMessage = checkLengthAndNumericValidation(
            newValue.length,
            parameterElements?.minLength,
            parameterElements?.maxLength,
            'arm.textfield.validation.minLength.message',
            'arm.textfield.validation.maxLength.message'
          );
          let passwordValidationError: Type.passwordValidationError = checkPasswordValidation(
            parameterElements,
            newValue,
            intlShape
          );
          telemetry.logTrace(
            `Password validation helper returned: ${passwordValidationError.isValid} with errorMesage ${passwordValidationError.errorMessage}`
          );
          errorDisplayMessage =
            passwordValidationError.errorMessage?.length > 0
              ? errorDisplayMessage?.concat('\n', passwordValidationError.errorMessage)
              : errorDisplayMessage;
          validationResult.errorDisplayMessage = errorDisplayMessage;
          break;
        case ApiType.ParameterType.String:
          setTextFieldValue(newValue || '');
          errorDisplayMessage = checkLengthAndNumericValidation(
            newValue.length,
            parameterElements?.minLength,
            parameterElements?.maxLength,
            'arm.textfield.validation.minLength.message',
            'arm.textfield.validation.maxLength.message'
          );
          validationResult.errorDisplayMessage = errorDisplayMessage;
          break;
        case ApiType.ParameterType.Object:
          validationResult = validateNewValue(newValue, 'arm.textfield.object.validation.message');
          break;
        case ApiType.ParameterType.Array:
          validationResult = validateNewValue(newValue, 'arm.textfield.array.validation.message');
          break;
        default:
          break;
      }
      errorDisplayMessage = validationResult.errorDisplayMessage;
      newValue = validationResult.value;
      parameterElements.value = newValue;
      props.parameterDefinitionsCallback(parameterElements);
      emitValidationState(newValue, errorDisplayMessage);
    },
    []
  );

  const onRenderLabel = (): JSX.Element => {
    return CustomRenderLabel(
      intlShape,
      'destination.instanceName',
      Utils.getTitleCase(parameterElements.name),
      'tooltip.instance.description',
      parameterElements.description ?? '',
      'destination.accessibility.armInfoIcon',
      nonRequiredField
    );
  };

  const validateNewValue = (newValue: string, typeErrorMessage: string): Type.validationResult => {
    setTextFieldValue(newValue || '');
    let errorDisplayMessage = checkLengthAndNumericValidation(
      newValue.length,
      parameterElements?.minLength,
      parameterElements?.maxLength,
      'arm.textfield.validation.minLength.message',
      'arm.textfield.validation.maxLength.message'
    );
    let typeValidationError: string = checkTypeValidation(parameterElements.type, newValue)
      ? ''
      : intlShape.formatMessage({ id: typeErrorMessage });
    telemetry.logTrace(`Type validation helper returned with errorMessage: ${typeValidationError}.`);
    errorDisplayMessage =
      typeValidationError?.length > 0 ? errorDisplayMessage?.concat('\n', typeValidationError) : errorDisplayMessage;
    if (!typeValidationError) {
      newValue = JSON.parse(newValue);
    }
    return { value: newValue, errorDisplayMessage: errorDisplayMessage };
  };

  const checkLengthAndNumericValidation = (
    value: string | Array<any> | number,
    minValueLength: number,
    maxValueLength: number,
    minBoundMessage: string,
    maxBoundMessage: string
  ): string => {
    let errorDisplayMessage = '';
    if (minValueLength || maxValueLength) {
      const errorMessageMin = intlShape.formatMessage({ id: minBoundMessage }, { minValueLength: minValueLength });
      const errorMessageMax = intlShape.formatMessage({ id: maxBoundMessage }, { maxValueLength: maxValueLength });
      if (value < minValueLength) errorDisplayMessage = errorMessageMin;
      else if (value > maxValueLength) errorDisplayMessage = errorMessageMax;
    }
    return errorDisplayMessage;
  };

  const emitValidationState = (value: string | number, errors: string | null) => {
    if (value && !errors) {
      props.textFieldValidationCallback({
        name: parameterElements.name,
        isValidationFailed: false,
      });
      setErrorMessage('');
    } else {
      props.textFieldValidationCallback({
        name: parameterElements.name,
        isValidationFailed: nonRequiredField === null,
      });
      errors !== '' || nonRequiredField !== null
        ? setErrorMessage(errors)
        : setErrorMessage(intlShape.formatMessage({ id: 'arm_textfield_empty' }));
    }
  };

  return (
    <div className={styles.verticalSpacing}>
      <Stack tokens={stackTokens}>
        <TextField
          value={textFieldValue}
          type={parameterElements.type === ApiType.ParameterType.SecureString ? 'password' : ''}
          canRevealPassword={parameterElements.type === ApiType.ParameterType.SecureString ? true : false}
          ariaLabel={intlShape.formatMessage({
            id: 'destination.accessibility.customControlTextField',
          })}
          onChange={onTextValueChange}
          errorMessage={errorMessage}
          styles={textfieldStyles}
          onRenderLabel={onRenderLabel}
          data-test-id={'text-field-control-builder-wrapper'}
        />
      </Stack>
    </div>
  );
};
export default checkPasswordValidation;
