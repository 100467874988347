import { Checkbox, Link, MessageBar, MessageBarType, Separator } from '@fluentui/react';
import React from 'react';
import { termsStyle } from './AITermsSection.styles';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Constants from '../../../common/Constants';

const AITermsSection = ({
  display,
  isDisabled,
  isChecked,
  onChange,
}: {
  display: boolean;
  isDisabled: boolean;
  isChecked: boolean;
  onChange: () => void;
}) => {
  const intl = useIntl();
  const [displayMessage, setDisplayMessage] = React.useState<boolean>(true);
  const onRenderLabel = () => {
    return (
      <div>
        <FormattedMessage
          id="aiterms.terms.message"
          values={{
            Link: (termsLinkText: string) => (
              <Link href={Constants.aiTermsLinks.copilotTerms} target="_blank" rel="noopener noreferrer">
                {termsLinkText}
              </Link>
            ),
          }}
        />
      </div>
    );
  };

  return display ? (
    <div className={termsStyle.termsContainer}>
      {displayMessage && (
        <>
          <Separator />
          <MessageBar
            messageBarType={MessageBarType.info}
            onDismiss={() => setDisplayMessage(false)}
            dismissButtonAriaLabel="Close"
          >
            <div>
              <FormattedMessage
                id="aiterms.message"
                values={{
                  Link: (info: string) => (
                    <Link href={Constants.aiTermsLinks.aiPreviewTerms} target="_blank" rel="noopener noreferrer">
                      {info}
                    </Link>
                  ),
                }}
              />
              <br />
            </div>
          </MessageBar>
        </>
      )}
      <Separator />
      <div className={termsStyle.title}>{intl.formatMessage({ id: 'aiterms.header' })}</div>
      <br />
      <div className={termsStyle.subtitle}>{intl.formatMessage({ id: 'aiterms.subheader' })}</div>
      <br />
      <Checkbox onRenderLabel={onRenderLabel} disabled={isDisabled} checked={isChecked} onChange={onChange} />
    </div>
  ) : null;
};

export default AITermsSection;
