import { IPanelStyles, ISpinnerStyles, mergeStyleSets } from '@fluentui/react';

export const style = mergeStyleSets({
  iframe: {
    width: '100%',
    minHeight: 'calc(100vh - 48px)',
    '@media(max-width: 960px)': {
      minHeight: 'calc(100vh - 8px)',
    },
    borderStyle: 'none',
  },

  dialogContentPropsText: {
    fontSize: '20px',
    margin: '0',
  },
  dialogContentParagraph: {
    fontSize: '14px',
  },
  dialogContent: {
    overflowWrap: 'break-word',
  },
  dialogButton: {
    float: 'right',
    marginTop: '30px',
    marginBottom: '30px',
  },
  errorContainer: {
    display: 'none',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '5%',
  },

  errorTitle: {
    fontWeight: 600,
    fontSize: '20px',
  },
  errorDescription: {
    fontWeight: 400,
    fontSize: '18px',
    textAlign: 'center',
  },
});

export const spinnerLabelStyle: Partial<ISpinnerStyles> = {
  root: {
    position: 'absolute',
    top: '40%',
    left: '40%',
  },
};

export const panelContentStyle: Partial<IPanelStyles> = {
  content: {
    padding: 0,
  },
  commands: {
    '@media(max-width: 960px)': {
      marginTop: 0,
    },
  },
};
