import * as React from 'react';
import {
  DefaultButton,
  FontIcon,
  IStackTokens,
  Link,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
} from '@fluentui/react';
import {
  adminCenter,
  environmentCreationErrors,
  environmentCreationStates,
  envsEndpoint,
  newPpacEnvEndpoint,
} from '../../../common/Constants';
import {
  environmentCreationResultStyles,
  primaryButtonDoneStyle,
  retryButtonStyles,
} from './environment-creation-styles';
import { FormattedMessage, useIntl } from 'react-intl';
import config from '../../../config';
import RuntimeConfigStateContainer from '../../../stateContainers/runtimeConfigStateContainer';

export interface EnvironmentCreationResultProps {
  environmentName: string;
  envCreationResult: string;
  onRetryButtonClick: () => Promise<void>;
  onCancelButtonClick: () => Promise<void>;
}

export const EnvironmentCreationResult: React.FunctionComponent<EnvironmentCreationResultProps> = (
  props: EnvironmentCreationResultProps
) => {
  const intlShape = useIntl();
  const stackTokens: IStackTokens = { childrenGap: 15 };
  let currentEnvStatus = props.envCreationResult;

  const runtimeConfig = RuntimeConfigStateContainer.getConfiguration();

  switch (true) {
    case currentEnvStatus === environmentCreationStates.SUCCESS:
      return (
        <Stack tokens={stackTokens}>
          <div className={environmentCreationResultStyles.resultStack}>
            <Stack tokens={stackTokens}>
              <FontIcon
                iconName="Completed"
                className={environmentCreationResultStyles.iconStyle}
                style={{ color: '#0078D4' }}
                aria-hidden="true"
              ></FontIcon>
              <Text className={environmentCreationResultStyles.resultHeader}>
                {props.environmentName}
                <FormattedMessage id="destination.environmentCreated" />
              </Text>
            </Stack>
            <PrimaryButton onClick={props.onCancelButtonClick} styles={primaryButtonDoneStyle}>
              {intlShape.formatMessage({ id: 'buttons.done' })}
            </PrimaryButton>
          </div>
        </Stack>
      );

    case currentEnvStatus === environmentCreationStates.FAILED:
      return (
        <Stack tokens={stackTokens}>
          <div className={environmentCreationResultStyles.resultStack}>
            <Stack tokens={stackTokens}>
              <img
                className={environmentCreationResultStyles.resultImg}
                src="./Assets/icons/env_creation_failed.png"
                alt={intlShape.formatMessage({ id: 'createEnvironment.createNewEnvironmentFailedText' })}
              />
              <Text className={environmentCreationResultStyles.resultHeader}>
                <FormattedMessage id="destination.environmentFailed" />
                <br />
                {props.environmentName}
              </Text>
              <br />
              <Text className={environmentCreationResultStyles.resultText}>
                <FormattedMessage id="destination.createEnvironmentInPpac" />
                {adminCenter}
              </Text>
            </Stack>
            <PrimaryButton
              href={runtimeConfig.dataverse.environmentCreationUrl + newPpacEnvEndpoint}
              target={'_blank'}
              styles={retryButtonStyles}
            >
              {intlShape.formatMessage({ id: 'buttons.CreateInPPAC' })}
            </PrimaryButton>
            <DefaultButton onClick={props.onCancelButtonClick}>
              {' '}
              {intlShape.formatMessage({ id: 'buttons.cancel' })}
            </DefaultButton>
          </div>
        </Stack>
      );
    case currentEnvStatus === environmentCreationStates.TIMEOUT:
      return (
        <Stack tokens={stackTokens}>
          <div className={environmentCreationResultStyles.resultStack}>
            <Stack tokens={stackTokens}>
              <FontIcon
                iconName="PlugDisconnected"
                className={environmentCreationResultStyles.iconStyle}
                aria-hidden="true"
              ></FontIcon>
              <Text className={environmentCreationResultStyles.resultHeader}>
                {props.environmentName}
                <FormattedMessage id="destination.environmentTimeout" />
              </Text>
              <br />
              <Text className={environmentCreationResultStyles.resultText}>
                <FormattedMessage id="destination.createEnvironmentTimeout" />
                <Link href={runtimeConfig.dataverse.environmentCreationUrl + envsEndpoint} target={'_blank'}>
                  {adminCenter}
                </Link>
              </Text>
            </Stack>
            <PrimaryButton onClick={() => props.onRetryButtonClick()} styles={retryButtonStyles}>
              {intlShape.formatMessage({ id: 'buttons.retry' })}
            </PrimaryButton>
            <DefaultButton onClick={props.onCancelButtonClick}>
              {' '}
              {intlShape.formatMessage({ id: 'buttons.cancel' })}
            </DefaultButton>
          </div>
        </Stack>
      );

    case currentEnvStatus === environmentCreationStates.STUCK_INPROGRESS ||
      currentEnvStatus === environmentCreationStates.IN_PROGRESS:
      return (
        <Stack tokens={stackTokens}>
          <div className={environmentCreationResultStyles.resultStack}>
            <Stack tokens={stackTokens}>
              <FontIcon
                iconName="DateTimeMirrored"
                className={environmentCreationResultStyles.iconStyle}
                aria-hidden="true"
              ></FontIcon>
              <Text className={environmentCreationResultStyles.inProgressText}>
                <FormattedMessage
                  id={
                    currentEnvStatus === environmentCreationStates.IN_PROGRESS
                      ? 'destination.environmentInProgressSpinnerText'
                      : 'destination.environmentStuckInProgressSpinnerText'
                  }
                />
              </Text>
              <ProgressIndicator />
            </Stack>
          </div>
        </Stack>
      );

    case currentEnvStatus === environmentCreationErrors.STATUS_CHECK_FAILED:
      return (
        <Stack tokens={stackTokens}>
          <div className={environmentCreationResultStyles.resultStack}>
            <Stack tokens={stackTokens}>
              <img
                className={environmentCreationResultStyles.resultImg}
                src="./Assets/icons/env_creation_failed.svg"
                alt={'env failed'}
              />
              <Text className={environmentCreationResultStyles.conflictErrorMessagesHeader}>
                <FormattedMessage id="destination.environmentStatusCheckFailed" />
              </Text>
              <br />
              <Text className={environmentCreationResultStyles.resultText}>
                <FormattedMessage id="destination.checkEnvironmentStatusInPpac" />
                <Link href={runtimeConfig.dataverse.environmentCreationUrl + envsEndpoint} underline target={'_blank'}>
                  {adminCenter}
                </Link>
              </Text>
            </Stack>
            <DefaultButton styles={primaryButtonDoneStyle} onClick={props.onCancelButtonClick}>
              {' '}
              {intlShape.formatMessage({ id: 'buttons.cancel' })}
            </DefaultButton>
          </div>
        </Stack>
      );

    case currentEnvStatus === environmentCreationErrors.INSUFFICIENT_CAPACITY:
      return (
        <Stack tokens={stackTokens}>
          <div className={environmentCreationResultStyles.resultStack}>
            <Stack tokens={stackTokens}>
              <img
                className={environmentCreationResultStyles.resultImg}
                src="./Assets/icons/env_creation_failed.svg"
                alt={'env failed'}
              />
              <Text className={environmentCreationResultStyles.conflictErrorMessagesHeader}>
                {props.environmentName}
                <FormattedMessage id="destination.environmentInsufficientCapacity" />
              </Text>
              <Text className={environmentCreationResultStyles.resultText}>
                <Link href={runtimeConfig.dataverse.databaseCapacityUrl} underline target={'_blank'}>
                  <FormattedMessage id="destination.monitorCapacity" />
                </Link>
                <br />
                <Link href={config.learnMoreStorageCapacity} underline target={'_blank'}>
                  <FormattedMessage id="success.learnMore" />
                </Link>
              </Text>
            </Stack>
            <DefaultButton styles={primaryButtonDoneStyle} onClick={props.onCancelButtonClick}>
              {' '}
              {intlShape.formatMessage({ id: 'buttons.cancel' })}
            </DefaultButton>
          </div>
        </Stack>
      );

    case currentEnvStatus === environmentCreationErrors.RESTRICTED_ACCESS ||
      currentEnvStatus === environmentCreationErrors.MISSING_POLICY:
      return (
        <Stack tokens={stackTokens}>
          <div className={environmentCreationResultStyles.resultStack}>
            <Stack tokens={stackTokens}>
              <img
                className={environmentCreationResultStyles.resultImg}
                src="./Assets/icons/env_creation_failed.svg"
                alt={'env failed'}
              />
              <Text className={environmentCreationResultStyles.resultHeader}>
                {props.environmentName}
                <FormattedMessage id="destination.environmentFailed" />
              </Text>
              {currentEnvStatus === environmentCreationErrors.RESTRICTED_ACCESS ? (
                <Text className={environmentCreationResultStyles.resultText}>
                  <FormattedMessage id="destination.environmentAccessRestricted" />
                </Text>
              ) : (
                <Text className={environmentCreationResultStyles.resultText}>
                  <FormattedMessage id="destination.environmentMissingLicensePolicy" />
                </Text>
              )}
            </Stack>
            <DefaultButton styles={primaryButtonDoneStyle} onClick={props.onCancelButtonClick}>
              {' '}
              {intlShape.formatMessage({ id: 'buttons.cancel' })}
            </DefaultButton>
          </div>
        </Stack>
      );
  }
};
