import { v4 as uuidv4 } from 'uuid';
const subscribers = {};

const PubSub = {
  subscribe: (name, callback) => {
    const id = uuidv4();
    if (subscribers[name]) {
      subscribers[name].push({
        id: id,
        callback: callback,
      });
    } else {
      subscribers[name] = [
        {
          id: id,
          callback: callback,
        },
      ];
    }
    return id;
  },
  unsubscribe: (name, id) => {
    subscribers[name] = subscribers[name].filter((x) => x.id !== id);
  },
  publish: (name, value) => {
    for (const subscriber of subscribers[name]) {
      subscriber.callback(value);
    }
  },
};

export default PubSub;
