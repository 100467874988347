import { mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  dependencyListContainer: {
    paddingLeft: '0px',
    marginBottom: '16px',
    marginTop: '16px',
    marginRight: '16px',
  },
  dependenciesRecheckContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    '@media(max-width: 960px)': {
      paddingRight: '16px',
    },
  },
  dependenciesRecheckButtonArea: {
    display: 'flex',
    cursor: 'pointer',
  },
  dependenciesRecheckIcon: {
    padding: '3px 8px',
  },
  dependencyTitle: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  marginUnset: {
    margin: 'unset',
  },
  dependencyDescription: {
    paddingTop: '5px',
  },
  dependencyActionSection: {
    paddingTop: '20px',
  },
  dependencyFooterContainerBody: {
    width: 'calc(50% - 2px)',
    textAlign: 'left',
    display: 'inline-block',
  },
  configurationPageFooterRightButton: {
    width: 'calc(50% - 2px)',
    textAlign: 'right',
    display: 'inline-block',
    '@media(max-width: 960px)': {
      textAlign: 'left',
    },
  },
  configurationPageFooterLeftButton: {
    marginRight: '8px',
  },
  cardStyleContainer: {
    marginRight: ' 16px',
    marginTop: '8px',
    marginBottom: '8px',
    marginLeft: '0px',
  },
  titleDescriptionStyles: {
    paddingLeft: '0px',
  },
  dependenciesRecheckIconCss: {
    color: 'black',
    height: '25px !important',
  },
  dependencyCardList: {
    paddingBottom: '130px',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  keyPostDeploymentSection: {
    marginTop: '7px',
    marginBottom: '15px',
    backgroundColor: '#f4f0ec',
    paddingTop: '8px',
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingBottom: '5px',
  },
  keyPostDeploymentHeader: {
    display: 'block',
    marginInlineStart: '10px',
    marginInlineEnd: '0px',
    fontWeight: 'bold',
    marginBlockStart: '5px',
    marginBlockEnd: '0px',
  },
  keyPostDeploymentActions: {
    paddingLeft: '23px',
  },
  keyPostDeploymentFooter: {
    position: 'relative',
    right: '10px',
    bottom: '10px',
    textAlign: 'right',
  },
  headerImg: {
    display: 'inline-block',
    width: '36px',
    height: '36px',
    marginBlockStart: '8px',
  },
  flexBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  visuallyHidden: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
  },
});

export const checkboxStyles = { root: { display: 'inline-block' } };

export default styles;
