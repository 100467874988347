import React, { FC, ReactElement } from 'react';
import { DefaultButton } from '@fluentui/react';
import Utils from '../../../../utils';
import { QuickView } from '../quick-view';
import styles, { skipLicenseOfferButtonStyles } from './skipLicenseCheckOffer.styles';
import {
  Capability,
  DATA_TEST_IDS,
  L03_AVAILABILITY_PRIVATE_PREVIEW,
  L03_AVAILABILITY_PUBLIC_PREVIEW,
} from '../../../../common/Constants';
import { useIntl } from 'react-intl';
import * as ApiType from '../../../../solutionCenterApi/gen/index';
import SolutionsStateContainer from '../../../../stateContainers/solutionsStateContainer';
import AuthenticationStateContainer from '../../../../stateContainers/authenticationStateContainer';

export interface SkipLicenseCheckOfferProps {
  offer: ApiType.L03;
  index: number;
}

export const SkipLicenseCheckOffer: FC<SkipLicenseCheckOfferProps> = (
  props: SkipLicenseCheckOfferProps
): ReactElement => {
  const { offer, index } = props;

  const intlShape = useIntl();

  const l01 = SolutionsStateContainer.getCurrentSolution();
  const tenantId = AuthenticationStateContainer.getTenantId();
  const l02 = l01.l02s.find((l02) => l02.rowKey === offer.parentL02Id);
  const l02Name = l02.l02Type === Capability ? l02.capabilityName : l02.name;

  const offerIcon = (): JSX.Element => {
    return (
      <img
        className={styles.headerImg}
        src={offer.icon ?? Utils.getIconSvg('healthcare_for_provider')}
        alt={intlShape.formatMessage({ id: 'altText.offer.icon' }, { offerName: offer.name })}
        title={intlShape.formatMessage({ id: 'altText.offer.name' }, { offerName: offer.name })}
      />
    );
  };

  const footerButton = (): JSX.Element => {
    const buttonText = intlShape.formatMessage({
      id: Utils.getL03ButtonTextId(offer.linkButtonText),
    });
    return (
      <DefaultButton
        text={buttonText}
        title={buttonText}
        styles={skipLicenseOfferButtonStyles}
        href={offer.endUserLaunchLink}
        target="_blank"
        data-customl01={l01.solutionName}
        data-customl01rowkey={l01.rowKey}
        data-customl03={offer.name}
        data-customl03rowkey={offer.rowKey}
        data-customl02={l02Name}
        data-customtenantId={tenantId}
        data-customl02rowkey={l02.rowKey}
        data-customevent={Utils.getL03ButtonEvent(offer.linkButtonText)}
      />
    );
  };

  return (
    <div
      data-test-id={DATA_TEST_IDS.activeOfferPrefix + index}
      className={`product-container-hover ${styles.container}`}
    >
      <div>
        {offerIcon()}
        <div className={styles.headerTextContainer}>
          <h3 title={offer.name} className={styles.headerTitle}>
            {offer.name}
          </h3>
        </div>
      </div>
      {offer.availability && offer.availability === L03_AVAILABILITY_PRIVATE_PREVIEW && (
        <div className={styles.availabilityContainer}>
          <h5 className={styles.availabilitytitle}>{intlShape.formatMessage({ id: 'privatePreview.label' })}</h5>
        </div>
      )}
      {offer.availability && offer.availability === L03_AVAILABILITY_PUBLIC_PREVIEW && (
        <div className={styles.availabilityContainer}>
          <h5 className={styles.availabilitytitle}>{intlShape.formatMessage({ id: 'publicPreview.label' })}</h5>
        </div>
      )}
      <div className={styles.descriptionContainer} title={offer.shortDescription}>
        {offer.shortDescription}
      </div>
      <div className={styles.footer}>
        <div className={styles.footerLeftContainer}>
          <QuickView entityData={offer} />
        </div>
        <div className={styles.footerRightContainer}>
          <div className={styles.footerRightContainerInner}>{footerButton()}</div>
        </div>
      </div>
    </div>
  );
};
