import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { segoeRegular } from '../../common/Constants';

export const noAvailabilityRegStyles = mergeStyleSets({
  imgPositionSettings: {
    marginLeft: '525px',
    marginTop: '120px',
    '@media (max-width: 960px)': {
      marginLeft: '250px',
      marginTop: '20px',
    },
    '@media (max-width: 480px)': {
      width: '100%',
      marginLeft: '120px',
      marginTop: '20px',
    },
  },
  imgLogoCss: {
    width: '200px',
    height: '200px',
    '@media(max-width: 960px)': {
      marginLeft: '10px',
    },
    '@media(max-width: 480px)': {
      width: '100px',
      height: '100px',
    },
  },
  microsoftLogoCss: {
    width: '175px',
    height: '53px',
    marginLeft: '150px',
    marginBottom: '10px',
    top: '2px',
    '@media(max-width: 960px)': {
      marginLeft: '10px',
    },
    '@media(max-width: 480px)': {
      marginLeft: '10px',
      width: '90px',
      height: '30px',
    },
  },
  nonAvailableSolTitle: {
    marginTop: '20px',
    width: '500px',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '32px',
    alignItems: 'center',
    color: '#323130',
    marginLeft: '380px',
    '@media(max-width: 960px)': {
      fontSize: '7px',
      marginLeft: '250px',
      height: '20px',
    },
    '@media(max-width: 480px)': {
      fontSize: '7px',
      marginLeft: '80px',
    },
  },
  supportedRegionText: {
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '450px',
    alignItems: 'center',
    '@media(max-width: 960px)': {
      fontSize: '7px',
      marginLeft: '260px',
    },
    '@media(max-width: 480px)': {
      fontSize: '7px',
      marginLeft: '90px',
    },
  },
  marginTop: {
    marginTop: '10px',
    '@media(max-width: 960px)': {
      marginTop: '10px',
      fontSize: '10px',
    },
    '@media(max-width: 480px)': {
      fontSize: '8px',
      lineHeight: '20px',
      marginTop: '10px',
    },
  },
  rowContainer: {
    width: '100%',
    borderTop: '1px solid #C8C8C8',
    '@media (max-width: 980px)': {
      width: '100%',
    },
    '@media (max-width: 480px)': {
      width: '100%',
    },
  },
});
