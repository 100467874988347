import { mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  l03Container: {
    minWidth: 360,
    minHeight: 112,
    flex: '0 0 366',
    maxWidth: 386,
    inlineSize: 'min-content',
    overflowWrap: 'anywhere',
    justifyContent: 'space-evenly',
    '@media(min-width: 1920px)': {
      flex: '0 0 calc(25% - 20px)',
      maxWidth: 386,
    },
    '@media(max-width: 1920px)': {
      flex: '0 0 calc(25% - 20px)',
      maxWidth: 366,
    },
    selectors: {
      '> *': {
        width: '100%',
      },
    },
    padding: '10px 20px 10px 0',
    '@media(max-width: 925px)': {
      width: '360',
    },
    '@media(max-width: 480px)': {
      width: '360',
    },
  },
});

export default styles;
