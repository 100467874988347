import * as React from 'react';
import { MessageBar, MessageBarType, IStackProps, Stack } from '@fluentui/react';
import { FC } from 'react';
import styles from '../armStyling/index.styles';
import { cloudErrorResponse } from '../../../../../common/Type';
import { useIntl } from 'react-intl';

interface IValidationMessageProps {
  message?: cloudErrorResponse;
  templateName?: string | null;
}

const verticalStackProps: IStackProps = {
  grow: true,
  tokens: { childrenGap: 20 },
};

const ValidationMessage: FC<IValidationMessageProps> = (props: IValidationMessageProps): JSX.Element => {
  const intlShape = useIntl();

  const errorMessage = (response: cloudErrorResponse | undefined): string => {
    let errorDetailedMessage: string = '';
    response?.details?.forEach((errorResponse) => {
      if (errorResponse?.details) {
        errorResponse.details.forEach((errorResp) => {
          errorDetailedMessage = errorResp?.message;
        });
      } else {
        errorDetailedMessage = errorResponse?.message;
      }
    });
    return errorDetailedMessage;
  };

  return (
    <>
      <Stack {...verticalStackProps}>
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          ariaLabel={props.message.message}
          dismissButtonAriaLabel="Close"
          className={styles.messageBarAz}
        >
          <b>{props.templateName}</b>
          <br />
          {props.message?.message}
          <br />
          {props.message?.details && (
            <div>
              <b>{intlShape.formatMessage({ id: 'arm_error_details' })}</b>
              <div>{errorMessage(props.message)}</div>
            </div>
          )}
        </MessageBar>
      </Stack>
    </>
  );
};

export default ValidationMessage;
