import { mergeStyleSets } from '@fluentui/react';
import { IStackTokens } from '@fluentui/react';

export const tooltipContent = {
  fontSize: '12px',
  fontFamily: 'var(--Font-familyBase)',
  lineHeight: '16px',
};

export const numericalSpacingStackTokens: IStackTokens = {
  childrenGap: '0px',
  padding: 'l1 l2',
};

export const styles = mergeStyleSets({
  page: {
    backgroundColor: '#FAFAFA',
  },
  pageTitle: {
    fontSize: '20px',
    marginTop: '0',
    marginBottom: '8px',
    fontFamily: 'var(--Font-familyBase)',
    fontWeight: '600',
    lineHeight: 'var(--Line-height500)',
    color: '#323130',
  },
  pageDescription: {
    fontSize: '14px',
    marginBottom: '0',
    fontFamily: 'var(--Font-familyBase)',
    fontWeight: '400',
    lineHeight: 'var(--Line-height400)',
    color: '#323130',
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  setupGuideHeading: {
    fontSize: '16px',
    marginBottom: '8px',
    fontFamily: 'var(--Font-familyBase)',
    fontWeight: '600',
    lineHeight: 'var(--Line-height500)',
    color: '#323130',
  },
  setupGuideDescription: {
    fontSize: '14px',
    marginBottom: '8px',
    fontFamily: 'var(--Font-familyBase)',
    fontWeight: '400',
    lineHeight: 'var(--Line-height400)',
    color: '#323130',
  },
  industryCloudDocumentationHeading: {
    fontSize: '16px',
    marginBottom: '8px',
    marginTop: '0',
    fontFamily: 'var(--Font-familyBase)',
    fontWeight: '600',
    lineHeight: 'var(--Line-height500)',
    color: '#323130',
  },
  industryCloudDocumentationHeadingDescription: {
    fontSize: '14px',
    marginBottom: '18px',
    fontFamily: 'var(--Font-familyBase)',
    fontWeight: '400',
    lineHeight: 'var(--Line-height400)',
    color: '#323130',
  },
  mainContainer: {
    height: '234px',
    boxShadow: '0 0.3px 0.9px rgba(0, 0, 0, 0.1), 0 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    background: 'linear-gradient(270.72deg, #053385 0%, #0B53CE 43.8%, #1F6CF9 95.19%)',
    overflow: 'hidden',
    marginBottom: '8px',
    '@media (max-width: 960px)': {
      height: '150px',
    },
    '@media (max-width: 480px)': {
      height: '290px',
    },
  },
  spinnerContainer: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '220px',
  },
  spinnerContainerLearnings: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '500px',
  },
  scrollableSection: {
    height: '200px',
    overflowY: 'auto',
  },
  scrollableContent: {
    padding: '0',
  },
});

export default styles;
