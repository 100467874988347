import * as React from 'react';
import { IButtonProps } from '@fluentui/react/lib/Button';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { useBoolean } from '@fluentui/react-hooks';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import styles from './teachingbubble.styles';

type teachingBubbleGuidedTourProps = {
  targetId: string;
  headline: string;
  content: string;
};

export const TeachingBubbleGuidedTour: FC<teachingBubbleGuidedTourProps> = (props: teachingBubbleGuidedTourProps) => {
  const intlShape = useIntl();
  const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(true);
  const examplePrimaryButtonProps: IButtonProps = {
    children: intlShape.formatMessage({ id: 'guidedTour.button.got_it' }),
    onClick: toggleTeachingBubbleVisible,
  };

  return (
    <div>
      {teachingBubbleVisible && (
        <TeachingBubble
          target={`#${props.targetId}`}
          primaryButtonProps={examplePrimaryButtonProps}
          headline={intlShape.formatMessage({ id: props.headline })}
          closeButtonAriaLabel="Close"
          styles={styles}
        >
          {intlShape.formatMessage({ id: props.content })}
        </TeachingBubble>
      )}
    </div>
  );
};
