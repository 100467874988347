import React, { FC, ReactElement, useEffect } from 'react';
import { regionStyles } from '../regionalStyling/index.styles';
import { Stack, IChoiceGroupOption, ChoiceGroup, Text } from '@fluentui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import './../../../custom.css';
import { PrimaryButton } from '@fluentui/react';
import { telemetry } from '../../../services/telemetryService';
import { DataverseRegions } from './orchestratorComponents/dataverseRegions';
import { AzureRegions } from './orchestratorComponents/azureRegions';
import { CustomerInsightsRegions } from './orchestratorComponents/cutomerInsightsRegions';
import { ParterAppsRegions } from './orchestratorComponents/partnerAppsRegions';
import {
  DATA_TEST_IDS,
  L03ByRegionFilter,
  L03ByRegionTelemetryPropertyNames,
  shellSettingsId,
} from '../../../common/Constants';
import SolutionsStateContainer from '../../../stateContainers/solutionsStateContainer';
import { useDispatch, useSelector } from 'react-redux';
import { setCIRegionPayload, setRegionsByOrchestratorType } from '../../../redux/deployments/deploymentActions';
import { RegionsByOrchestratorType } from '../../../common/Type';
import { RootStore } from '../../../redux';
import { OrchestratorType } from '../../../common/Enum';
import {
  setRegionOrchestratorTypePayload,
  updateOfficeHeaderCustomButton,
} from '../../../utils/regionAvailabilityUtil';
import AuthenticationStateContainer from '../../../stateContainers/authenticationStateContainer';
import { CIRegionPayload } from '../../../solutionCenterApi/gen';

export interface ParentRegionalContainerProps {
  onDiscardButtonClick: () => void;
  locale?: string;
}

export const ParentRegionalContainer: FC<ParentRegionalContainerProps> = (
  props: ParentRegionalContainerProps
): ReactElement => {
  const locale: any = props.locale;
  useEffect(() => {
    loadSelectedRegionPanel();
  }, [locale]);

  const intlShape = useIntl();
  const dispatch = useDispatch();

  const regionsByL03Payload = useSelector((state: RootStore) => state.deployment.regionsByOrchestratorType);

  const choiceGroupSelected =
    SolutionsStateContainer.getFilterL03sByRegion() === true ? L03ByRegionFilter.HIDE_ALL : L03ByRegionFilter.SHOW_ALL;

  const [selectedFilterOption, setSelectedFilterOption] = React.useState<string | undefined>(choiceGroupSelected);
  const [regionSetting, setRegionSetting] = React.useState<RegionsByOrchestratorType[] | null>(regionsByL03Payload);
  const [ciPayload, setCIPayload] = React.useState<CIRegionPayload | null>();

  const loadSelectedRegionPanel = async () => {
    telemetry.logTrace(`Selected region panel loaded`);
  };

  const onApplyButtonClick = (): void => {
    SolutionsStateContainer.setFilterL03sByRegion(L03ByRegionFilter.HIDE_ALL === selectedFilterOption);
    dispatch(setRegionsByOrchestratorType(regionSetting));
    dispatch(setCIRegionPayload(ciPayload));
    addOrUpdateCustomHeaderButton();
    props.onDiscardButtonClick();
  };

  const addOrUpdateCustomHeaderButton = () => {
    const headerButtonName = updateOfficeHeaderCustomButton(regionsByL03Payload);
    const officeHeaderButonObject = constructCustomHeaderButton(headerButtonName);
    window?.O365Shell?.Extensibility?.AddOrUpdateCustomHeaderButton(officeHeaderButonObject);
  };

  const filterSolutionsOptions: IChoiceGroupOption[] = [
    {
      key: L03ByRegionFilter.SHOW_ALL,
      text: intlShape.formatMessage({ id: 'region_Panel.Show_all.text' }),
    },
    {
      key: L03ByRegionFilter.HIDE_ALL,
      text: intlShape.formatMessage({ id: 'region_Panel.Hide_all.text' }),
    },
  ];

  const handleRegionSettingApplied = async (orchestratorType: OrchestratorType, region: string) => {
    const updateRegionSetting = regionSetting;
    updateRegionSetting.forEach((o) => {
      if (o.orchestrator === orchestratorType) {
        o.region = region;
      }
    });
    const regionPayload = await setRegionOrchestratorTypePayload(regionSetting, orchestratorType, region);
    setRegionSetting(regionPayload);
  };

  const constructCustomHeaderButton = (headerButtonName: string) => {
    return {
      id: shellSettingsId,
      iconFontName: '\ue774',
      iconFontFamily: 'FabricMDL2Icons',
      ariaLabel: 'Working offline',
      affordanceMenuItemText: 'meetNow',
      buttonText: intlShape.formatMessage({ id: headerButtonName }),
      overflowToAffordanceMenu: true,
    };
  };

  const handleRegionPayload = (payload: CIRegionPayload) => {
    setCIPayload(payload);
  };

  const onFilterChange = React.useCallback((ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
    setSelectedFilterOption(option.key);
    var filterL03s: boolean = option.key === L03ByRegionFilter.HIDE_ALL ? true : false;
    telemetry.logTrace(`Request to ${L03ByRegionTelemetryPropertyNames.FilterSolutionsByRegion} set to ${filterL03s}`);
  }, []);
  return (
    <>
      <div className={regionStyles.divCss} data-test-id={DATA_TEST_IDS.regionPanel}>
        <div className={regionStyles.displayInline}>
          <div className={regionStyles.helpDivWidth}>
            <Text className={regionStyles.fontWeight800}>
              {intlShape.formatMessage({
                id: 'region_Panel.title',
              })}
            </Text>
          </div>
        </div>
        <div className={regionStyles.pageInfoWidth}>
          <Text className={regionStyles.fontWeight400}>
            <FormattedMessage
              id="region_Panel_Information_1"
              values={{
                b: (azureRegions: string) => <b>{azureRegions}</b>,
              }}
            />
          </Text>
        </div>
        <div className={regionStyles.pageInfoWidth}>
          <Text className={regionStyles.fontWeight400}>
            {intlShape.formatMessage({
              id: 'region_Panel_Information_2',
            })}
          </Text>
        </div>
        <div className={regionStyles.pageInfoWidth}>
          <Text className={regionStyles.fontWeight400}>
            {intlShape.formatMessage({
              id: 'region_Panel_Information_3',
            })}
          </Text>
        </div>
        <div className={regionStyles.verticalSpacing}>
          <ParterAppsRegions />
        </div>
        <div className={regionStyles.verticalSpacing}>
          <DataverseRegions />
        </div>
        <div className={regionStyles.verticalSpacing}>
          <AzureRegions regionSettingApplied={handleRegionSettingApplied} />
        </div>
        <div className={regionStyles.verticalSpacing}>
          <CustomerInsightsRegions
            regionSettingApplied={handleRegionSettingApplied}
            regionPayload={handleRegionPayload}
          />
          <Stack className={regionStyles.toggleSpecing}>
            <ChoiceGroup
              selectedKey={selectedFilterOption}
              options={filterSolutionsOptions}
              onChange={onFilterChange}
              label={intlShape.formatMessage({
                id: 'region_Panel.Filter_Toggle.title',
              })}
            />
          </Stack>
        </div>
        <div className={regionStyles.footerContainer}>
          <div className={regionStyles.displayInline}>
            <div className={regionStyles.verticalSpacing}>
              <PrimaryButton
                id="applyButtonRegionalPanel"
                text={intlShape.formatMessage({
                  id: 'buttons.apply',
                })}
                ariaLabel={intlShape.formatMessage({
                  id: 'buttons.apply',
                })}
                data-customtenantId={AuthenticationStateContainer.getTenantId()}
                allowDisabledFocus
                onClick={onApplyButtonClick}
              />
            </div>
            <div className={regionStyles.buttonSpacing}>
              <PrimaryButton
                id="discardButtonRegionalPanel"
                text={intlShape.formatMessage({
                  id: 'buttons.discard',
                })}
                ariaLabel={intlShape.formatMessage({
                  id: 'buttons.discard',
                })}
                data-customtenantId={AuthenticationStateContainer.getTenantId()}
                allowDisabledFocus
                onClick={props.onDiscardButtonClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
