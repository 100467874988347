import React, { FC, ReactElement } from 'react';
import * as ApiType from '../../../solutionCenterApi/gen/index';
import ListViewOfferActionButton from './listViewOfferActionButton';

export interface ListViewAddButtonProps {
  offer: ApiType.L03;
  isChecked: boolean;
  toggleCheck: (offer: ApiType.L03) => void;
  handlePreviewSelection?: (value: ApiType.L03) => void;
  updateItemData: (offer: ApiType.L03, isChecked: boolean) => void;
}

export const ListViewAddButton: FC<ListViewAddButtonProps> = (props: ListViewAddButtonProps): ReactElement => {
  const { offer, toggleCheck, isChecked, handlePreviewSelection, updateItemData } = props;
  const handleCheckChange = (e: any): void => {
    if (
      e.target.nodeName !== 'INPUT' ||
      (e.target.nodeName === 'INPUT' && e.charCode === 13) ||
      (e.target.nodeName === 'INPUT' && e.charCode === 32)
    ) {
      toggleCheck(offer);
      updateItemData(offer, !isChecked);
    }
  };

  return (
    <div>
      <ListViewOfferActionButton
        offer={offer}
        isChecked={isChecked}
        onHandleCheckChange={handleCheckChange}
        handlePreviewSelection={handlePreviewSelection}
      />
    </div>
  );
};
