import * as ApiType from '../../../../solutionCenterApi/gen/index';
import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, IDropdownOption, Stack, IconButton, ISelectableOption, TooltipHost } from '@fluentui/react';
import { telemetry } from '../../../../services/telemetryService';
import { RootStore } from '../../../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { CustomToolTip } from '../../../../common/tooltip/customTooltip';
import { getSelectedFabricWorkspace } from '../../../../redux/deployments/deploymentActions';
import Utils from '../../../../utils';
import { setErrorValidationFabricWorkspaceType } from '../../../../redux/fabricWorkspace/workspaceAction';
import styles, { dropdownStyles, verticalStackStyles } from '../destination.styles';
import { DATA_TEST_IDS } from '../../../../common/Constants';
import Visible from '../../../../components/visible/visible';
import fabricWorkspaceService from '../../../../services/fabricWorkspaceService';
import { CreationPanel } from '../../createPanel/create-panel';
import { WorkspaceCreationInputs } from '../../workspace-creation-input';
import FabricWorkspaceService from '../../../../services/fabricWorkspaceService';
import InstallationStateContainer from '../../../../stateContainers/installationStateContainer';

const FabricWorkspace: FC = (): JSX.Element => {
  const selectedFabricWorkspace: ApiType.Workspace | undefined = useSelector(
    (state: RootStore) => state.deployment.fabricWorkspace
  );
  const intl = useIntl();
  const [fabricWorkspaceList, setFabricWorkspaceList] = React.useState<Array<ApiType.Workspace>>([]);
  const [isFetchingWorkspaces, setFetchingWorkspaces] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [fabricWs, setFabricWs] = React.useState<ApiType.Workspace>(selectedFabricWorkspace);
  const dispatch = useDispatch();

  useEffect(() => {
    GetWorkspaces();
  }, []);

  const GetWorkspaces = async (newWorkspaceId?: string) => {
    dispatch(getSelectedFabricWorkspace(null));
    setFetchingWorkspaces(true);
    try {
      const workspaces: ApiType.Workspace[] = await fabricWorkspaceService.getFabricWorkspaces();
      setFabricWorkspaceList(workspaces);
      setWorkspaceErrorMessage(workspaces);
      const selectedWorkspace = determineSelectedWorkspace(workspaces, newWorkspaceId);
      setFabricWs(selectedWorkspace);
      dispatch(getSelectedFabricWorkspace(selectedWorkspace));
      InstallationStateContainer.setSelectedFabricWorkspace(selectedWorkspace);
    } catch (error) {
      console.error(error);
      telemetry.logException(error);
    }
    setFetchingWorkspaces(false);
  };

  const setWorkspaceErrorMessage = (response: ApiType.Workspace[]) => {
    console.log('response', response);
    if (!response || response.length === 0) {
      console.log('err');
      setErrorMessage(intl.formatMessage({ id: 'fabricWorkspace.errormessage' }));
      dispatch(setErrorValidationFabricWorkspaceType(true));
    }
  };

  const setSelectedFabricWorkpace = (listOfWorkspaces: ApiType.Workspace[], workspaceId: string) => {
    return listOfWorkspaces.filter((e) => e.workspaceId === workspaceId)[0];
  };

  const determineSelectedWorkspace = (
    workspaces: ApiType.Workspace[],
    newWorkspaceId?: string
  ): ApiType.Workspace | null => {
    if (!Utils.isNullOrUndefinedOrEmpty(newWorkspaceId)) {
      return workspaces.find((w) => w?.workspaceId === newWorkspaceId);
    } else if (selectedFabricWorkspace) {
      return setSelectedFabricWorkpace(workspaces, selectedFabricWorkspace.workspaceId);
    } else {
      return null;
    }
  };

  const FabricWorkspaceOptions = useMemo<IDropdownOption[]>(
    () =>
      fabricWorkspaceList?.map((ws: ApiType.Workspace, index: number) => ({
        index: index,
        key: ws.workspaceId,
        text: ws.displayName,
      })),
    [fabricWorkspaceList]
  );

  const refreshFabricWorkspaceList = () => {
    GetWorkspaces();
    setErrorMessage('');
    dispatch(setErrorValidationFabricWorkspaceType(false));
  };

  const onWorkspaceChange = (option: IDropdownOption): void => {
    const workspaceSelected = fabricWorkspaceList.filter((e) => option.key === e.workspaceId)[0];
    setFabricWs(workspaceSelected);
    dispatch(getSelectedFabricWorkspace(workspaceSelected));
    InstallationStateContainer.setSelectedFabricWorkspace(workspaceSelected);
    setErrorMessage('');
    dispatch(setErrorValidationFabricWorkspaceType(false));
  };

  return (
    <div>
      <Stack horizontal>
        <Stack styles={verticalStackStyles} verticalAlign="end">
          <div className={styles.headerSpacing}>
            <span className={styles.headerText}>
              <FormattedMessage id="destination.selectFabricWorkspaceDropdown" />
            </span>
            <CustomToolTip
              content={intl.formatMessage({ id: 'destination.fabricInstallTooltip' })}
              iconLabel={intl.formatMessage({
                id: 'destination.accessibility.workspaceInfoIcon',
              })}
            />
            <div className={styles.iconContainer}>
              <Visible when={!isFetchingWorkspaces} fallback={!isFetchingWorkspaces}>
                <TooltipHost
                  content={intl.formatMessage({
                    id: 'destination.refreshWorkspaces',
                  })}
                  calloutProps={{ gapSpace: 0 }}
                >
                  <IconButton
                    className={styles.iconSize}
                    iconProps={{ iconName: 'Refresh' }}
                    ariaLabel={intl.formatMessage({
                      id: 'destination.refreshWorkspaces',
                    })}
                    onClick={refreshFabricWorkspaceList}
                  >
                    <span className={styles.iconText}>
                      <FormattedMessage id="destination.refreshIconText" />
                    </span>
                  </IconButton>
                </TooltipHost>
              </Visible>
            </div>
          </div>
          <div className={styles.refreshIconDiv}>
            <div>
              <Dropdown
                disabled={isFetchingWorkspaces}
                data-test-id={DATA_TEST_IDS.workspaceDropdown}
                placeholder={intl.formatMessage({
                  id: fabricWorkspaceList ? 'destination.selectLabel' : 'destination.selectLabel_NoWorkspaces',
                })}
                selectedKey={fabricWs?.workspaceId}
                errorMessage={errorMessage}
                onChanged={onWorkspaceChange}
                defaultSelectedKey={fabricWs?.workspaceId}
                options={FabricWorkspaceOptions}
                ariaLabel={intl.formatMessage({
                  id: 'destination.accessibility.setupWorkspace',
                })}
                onRenderOption={(option: ISelectableOption | undefined): JSX.Element => (
                  <div key={option!.key} data-test-id={DATA_TEST_IDS.fabricOptionPrefix + option!.index}>
                    {option!.text}
                  </div>
                )}
                styles={dropdownStyles}
              />
            </div>
          </div>
        </Stack>
      </Stack>
      <div className={styles.createNewContainer}>
        <CreationPanel
          panelOpenText={intl.formatMessage({
            id: 'destination.createWorkspaceText',
          })}
          panelOpenButtonText={intl.formatMessage({
            id: 'destination.createWorkspaceButtonText',
          })}
          footerSuccessButtonText={intl.formatMessage({
            id: 'destination.createButton',
          })}
          footerCancelButtonText={intl.formatMessage({ id: 'buttons.cancel' })}
          refreshData={refreshFabricWorkspaceList}
          createPage={<WorkspaceCreationInputs />}
          createPageTitle="destination.createNewWorkspace"
          inProgressTitle="creatingTheWorkspace.createNewWorkspaceInProgressText"
          inProgressText="destination.workspaceInProgressSpinnerText"
          createSuccessTextTitle="newWorkspace.createNewWorkspaceSuccessText"
          createSuccessText="destination.environmentCreated"
          createFailedTextTitle="createEnvironment.createNewEnvironmentFailedText"
          createFailedText="destination.environmentFailed"
          reduxStateName="workspace"
          createMethod={FabricWorkspaceService.createWorkspace}
        />
      </div>
    </div>
  );
};

export default FabricWorkspace;
