import React, { FC, ReactElement } from 'react';
import { ProgressIndicator } from '@fluentui/react';
import { FormattedMessage } from 'react-intl';
import { FontIcon } from '@fluentui/react';
import styles, {
  successProgressIndicatorStyles,
  failureProgressIndicatorStyles,
} from '../progressStyling/index.styles';
import { DeployStartIndex } from '../../../../../common/Constants';

export interface InitialProps {
  percentComplete: number;
  label: string;
  success: boolean;
}

export const DeploymentProgressIndicator: FC<InitialProps> = (props: InitialProps): ReactElement => {
  const percentComplete = Math.floor(props.percentComplete * 100);
  const started = percentComplete !== 0;

  return (
    <>
      <ProgressIndicator
        percentComplete={started ? props.percentComplete : DeployStartIndex}
        label={props.label}
        styles={props.success ? successProgressIndicatorStyles : failureProgressIndicatorStyles}
      />
      {props.success ? (
        <div id="InProgressDescription" className={styles.progressDescriptor}>
          {started ? (
            <FormattedMessage id="progressIndicator.percentComplete" values={{ percentComplete: percentComplete }} />
          ) : (
            <FormattedMessage id="progressIndicator.gettingThingsReady" />
          )}
        </div>
      ) : (
        <div id="ErrorDescription" className={styles.progressDescriptor}>
          <FontIcon iconName="ErrorBadge" className={styles.errorIconColor} />
          {started ? (
            <FormattedMessage id="status.deploymentFailed" />
          ) : (
            <FormattedMessage id="progressIndicator.stoppedFailure" />
          )}
        </div>
      )}
    </>
  );
};
