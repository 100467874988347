import { mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  container: {
    backgroundColor: '#FAF9F8',
    minHeight: '100%',
  },
});

export default styles;
