import React from 'react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import styles, { spinnerLabelStyle } from './loadingScreen.styles';
import { useIntl } from 'react-intl';
import { IsDesktopMode, IsZoomMode } from '../responsiveMode';

const LoadingScreen = ({ isVisible, label }: { isVisible: boolean; label: string }) => {
  const intl = useIntl();
  const placeHolder = intl.formatMessage({ id: 'loading.pleaseWait' });

  if (!isVisible) return null;

  const displayInIFrame = sessionStorage['displayInIFrame'];

  return (
    <div
      role="alert"
      className={`${styles.loadingScreenContainer} ${displayInIFrame ? styles.loadingScreenIframeContainer : ''}`}
    >
      <div className={styles.innerLoadingContainer}>
        <Spinner
          styles={IsZoomMode() ? spinnerLabelStyle : undefined}
          size={IsDesktopMode() ? SpinnerSize.large : SpinnerSize.small}
          label={label ? label : placeHolder}
        />
      </div>
    </div>
  );
};
export default LoadingScreen;
