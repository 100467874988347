import { PropsWithChildren, ReactElement, useState } from 'react';
import { IsDesktopMode, IsZoomMode } from './responsiveMode';
import React from 'react';
import { Zoom_NumberOfItemsShown } from './Constants';
import { Link } from 'office-ui-fabric-react';

interface ResponsiveListProps<T> {
  items: T[];
  showMoreLinkStyle: string;
  renderItem: (item: T, index: number) => JSX.Element;
}

export const ResponsiveList = <T extends {}>(props: PropsWithChildren<ResponsiveListProps<T>>): ReactElement => {
  const numberOfItems = props.items.length;
  const [numberOfItemsShown, setNumberOfItemsShown] = useState<number>(Zoom_NumberOfItemsShown);

  const shouldShowMore = IsZoomMode() && numberOfItemsShown < numberOfItems;
  const shouldShowLess = IsZoomMode() && numberOfItemsShown >= numberOfItems && numberOfItems > Zoom_NumberOfItemsShown;

  const showMoreItems = () => {
    setNumberOfItemsShown(numberOfItemsShown + Zoom_NumberOfItemsShown);
  };

  const showLessItems = () => {
    setNumberOfItemsShown(Zoom_NumberOfItemsShown);
  };

  const renderList = (items: T[]) => {
    return items.map((item: T, index: number) => {
      return props.renderItem(item, index);
    });
  };

  return (
    <>
      {IsDesktopMode() && renderList(props.items)}
      {IsZoomMode() && renderList(props.items.slice(0, numberOfItemsShown))}
      {shouldShowMore && (
        <div className={props.showMoreLinkStyle}>
          <Link onClick={showMoreItems}>Show More</Link>
        </div>
      )}
      {shouldShowLess && (
        <div className={props.showMoreLinkStyle}>
          <Link onClick={showLessItems}>Show Less</Link>
        </div>
      )}
    </>
  );
};
