import { getTheme } from 'office-ui-fabric-react';
import { mergeStyleSets } from '@fluentui/react';
import { segoeRegular } from '../../../../common/Constants';

const theme = getTheme();
const styles = mergeStyleSets({
  quickViewModalContainer: {
    boxShadow: '0 4.8px 14.4px rgb(0 0 0 / 18%), 0 25.6px 57.6px rgb(0 0 0 / 22%)',
    borderRadius: '2px',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  quickViewLink: {
    zIndex: 1,
  },
  outerContainer: {
    width: '1076px',
    '@media (max-width: 960px)': {
      width: '100%',
    },
  },
  container: {
    padding: '16px 24px 16px 24px',
    display: 'block',
  },
  rowContainer: {
    display: 'flex',
    '@media (max-width: 960px)': {
      float: 'left',
      display: 'block',
      width: '100%',
    },
  },
  headerContainer: {
    flex: '95%',
  },
  headerTitle: {
    display: 'inline-block',
    width: '400px',
    fontFamily: segoeRegular,
    fontSize: '20px',
    fontWeight: '600',
    color: '#323130',
    padding: '0',
    marginTop: '0',
    marginBottom: '0',
    '@media (max-width: 960px)': {
      width: '90%',
    },
  },
  closeButton: {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: '0',
      marginTop: '4px',
      marginRight: '0',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  },
  headerDescription: {
    paddingTop: '16px',
    fontSize: '14px',
    width: '700px',
    '@media (max-width: 962px)': {
      width: '100%',
      float: 'left',
      paddingBottom: '16px',
      paddingTop: '6px',
    },
  },
  main: {
    paddingTop: '16px',
  },
  rightBoxPostConfigs: {
    flex: '50%',
    width: '498px',
    marginLeft: '32px',
    '@media (max-width: 965px)': {
      width: '100%',
      marginLeft: 0,
      paddingTop: '16px',
    },
  },
  rightBoxMoreInfo: {
    display: 'inline-block',
    width: '498px',
    borderTop: '1px solid #C8C8C8',
    height: '140px',
    overflowY: 'auto',
    paddingTop: '11px',
    marginLeft: '32px',
    float: 'right',
    '@media (max-width: 965px)': {
      float: 'left',
      width: '100%',
      marginLeft: '0',
    },
  },
  leftBox: {
    flex: '50%',
    width: '498px',
    borderTop: '1px solid #C8C8C8',
    overflowY: 'auto',
    paddingTop: '11px',
    '@media (max-width: 965px)': {
      width: '100%',
    },
  },
  imageBox: {
    flex: '50%',
    width: '498px',
    marginLeft: '32px',
    height: '286px',
    padding: '0',
    textAligin: 'center',
    verticalAlign: 'top',
    '@media (max-width: 965px)': {
      width: '100%',
      marginLeft: 0,
      paddingBottom: '20px',
    },
  },
  img: {
    height: '286px',
    maxWidth: '100%',
    display: 'inline-block',
  },
  defaultTitle: {
    fontSize: '19px',
    fontWeight: '700',
    color: '#323130',
    padding: '0',
    marginTop: '0',
    marginBottom: '20px',
  },
  atGlanceParagraph: {
    fontSize: '14px',
    marginTop: '10px',
  },
  divider: {
    display: 'block',
    height: '20px',
  },
  solutionDependenciesDescription: {
    color: 'black',
  },
  dependencyRow: {
    color: 'black',
    marginTop: '8px',
  },
  dependencyIcon: {
    verticalAlign: 'middle',
    marginRight: '12px',
    height: '16px',
    width: '16px',
  },
  moreInfoLink: {
    color: '#0078D4',
  },
  footer: {
    float: 'right',
    textAlign: 'right',
    width: '100%',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  cancelButton: {
    display: 'inline-block',
    marginRight: '24px',
    float: 'right',
  },
  errorSvg: {
    fill: '#A80000',
  },
  errorIconContainer: {
    marginTop: '10px',
    display: 'inline-block',
    '@media(max-width: 480px)': {
      marginTop: '5px',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-evenly',
      flexDirection: 'column-reverse',
      flexWrap: 'wrap',
      alignContent: 'space-around',
    },
  },
  successIconContainer: {
    marginTop: '8px',
    color: '#107C10',
    fontSize: '16px',
  },
  text: {
    fontSize: '14px',
    color: '#323130',
    verticalAlign: 'top',
    paddingTop: '10px',
    paddingLeft: '8px',
    display: 'inline-flex',
    '@media(max-width: 480px)': {
      whiteSpace: 'pre-wrap',
      paddingTop: '1px',
      paddingLeft: '4px',
    },
  },
  paddingTop: {
    paddingTop: '8px',
  },
  paddingLeft: {
    paddingLeft: '12px',
  },
  closeButtonContainer: {
    float: 'right',
  },
});

export const renderLeftBox = (capabilityData: boolean) => {
  var leftBox = {};
  if (capabilityData) {
    leftBox = {
      width: '498px',
      borderTop: '1px solid #C8C8C8',
      overflowY: 'auto',
      paddingTop: '11px',
      '@media (max-width: 965px)': {
        width: '100%',
        paddingTop: '20px',
        paddingBottom: '20px',
      },
    };
  } else {
    leftBox = {
      flex: '50%',
      width: '498px',
      borderTop: '1px solid #C8C8C8',
      overflowY: 'auto',
      paddingTop: '11px',
      '@media (max-width: 965px)': {
        width: '100%',
        paddingTop: '20px',
        paddingBottom: '20px',
      },
    };
  }

  const styles = mergeStyleSets({
    leftBox,
  });

  return styles.leftBox;
};

export default styles;
