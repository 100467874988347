import { mergeStyleSets } from '@fluentui/react';

export const numericalSpacingStackTokens = {
  childrenGap: 10,
  padding: 'l1 l2',
};

export const spinnerLabelStyles = mergeStyleSets({
  fontFamily: 'var(--Font-familyBase)',
  fontSize: 'var(--Font-size500)',
  fontWeight: 600,
  lineHeight: 'var(--Line-height500)',
  textAlign: 'left',
  color: '#242424',
});

export const setupGuideStyles = mergeStyleSets({
  root: {
    backgroundColor: '#FAFAFA',
    borderRadius: '8px',
    padding: '65px 10px',
    marginBottom: 16,
    height: '200px',
    display: 'flex',
    margin: '10px 0',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  page: {
    backgroundColor: '#FAFAFA',
  },
  pageTitle: {
    fontSize: '20px',
    marginBottom: '8px',
    fontFamily: 'var(--Font-familyBase)',
    fontWeight: 600,
    lineHeight: 'var(--Line-height500)',
    color: '#323130',
  },
  embed: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    marginBottom: '0px',
    marginTop: '-10px',
    maxWidth: '100%',
    height: 'auto',
    overflow: 'hidden',
    paddingBottom: '5px',
  },
  loadingContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
  pageContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  title: {
    marginLeft: '30px',
    marginTop: '20px',
    maxWidth: '700px',
    fontFamily: 'Segoe UI',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    textAlign: 'left',
    color: '#1B1A19',
  },
  spinnerLabel: {
    fontFamily: 'var(--Font-familyBase)',
    fontSize: 'var(--Font-size500)',
    fontWeight: 600,
    lineHeight: 'var(--Line-height500)',
    textAlign: 'left',
    color: '#242424',
  },
  description: {
    marginLeft: '30px',
    marginTop: '-10px',
    maxWidth: '550px',
    fontFamily: 'Segoe UI',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    color: '#323130',
  },
  pageDescription: {
    fontSize: '14px',
    marginBottom: '50px',
    fontFamily: 'var(--Font-familyBase)',
    fontWeight: 400,
    lineHeight: 'var(--Line-height400)',
    color: '#323130',
  },
  toggleBtn: {
    marginLeft: '25px',
    marginTop: '-10px',
  },
  toggle: {
    fontFamily: 'Segoe UI',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    border: 'none',
    background: 'none',
    color: '#0F6CBD',
    cursor: 'pointer',
  },
  navBtn: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  setupGuideHeading: {
    fontSize: '16px',
    marginBottom: '8px',
    fontFamily: 'var(--Font-familyBase)',
    fontWeight: 600,
    lineHeight: 'var(--Line-height500)',
    color: '#323130',
  },
  setupGuideDescription: {
    fontSize: '14px',
    marginBottom: '8px',
    fontFamily: 'var(--Font-familyBase)',
    fontWeight: 400,
    lineHeight: 'var(--Line-height400)',
    color: '#323130',
  },
  industryCloudDocumentationHeading: {
    fontSize: '16px',
    marginBottom: '8px',
    fontFamily: 'var(--Font-familyBase)',
    fontWeight: 600,
    lineHeight: 'var(--Line-height500)',
    color: '#323130',
  },
  industryCloudDocumentationHeadingDescription: {
    fontSize: '14px',
    marginBottom: '8px',
    fontFamily: 'var(--Font-familyBase)',
    fontWeight: 400,
    lineHeight: 'var(--Line-height400)',
    color: '#323130',
  },
  mainContainer: {
    height: '234px',
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    background: 'linear-gradient(270.72deg, #053385 0%, #0B53CE 43.8%, #1F6CF9 95.19%)',
    overflow: 'hidden',
    marginBottom: '8px',
    '@media(max-width: 960px)': {
      height: '150px',
    },
    '@media(max-width: 480px)': {
      height: '290px',
    },
  },
  username: {
    fontSize: '28px',
    lineHeight: '32px',
    display: 'inline-block',
    verticalAlign: 'text-bottom',
    left: 'calc(50% - 285px/2 - 277.5px)',
    marginBottom: 10,
    fontWeight: 600,
    '@media(max-width: 960px)': {
      lineHeight: '28px',
    },
  },
  usernameSpan: {
    '@media(max-width: 960px)': {
      display: 'flex',
    },
  },
  bannerDiv: {
    marginTop: '45px',
    display: 'inline-block',
    marginLeft: '32px',
    '@media(max-width: 960px)': {
      marginTop: '10px',
    },
  },
  bannerContent: {
    margin: '0 24px',
    float: 'left',
  },
  startLearningBtn: {
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  backBtn: {
    borderRadius: '4px',
    marginTop: '16px',
    marginLeft: '32px',
    border: '1px solid #D1D1D1',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  backBtnIcon: {
    marginRight: '5px',
    marginTop: '5px',
  },
  backBtnText: {
    fontFamily: 'var(--Font-familyBase)',
    fontSize: 'var(--Font-size300)',
    fontWeight: 600,
    lineHeight: 'var(--Line-height300)',
    textAlign: 'left',
    color: '#242424',
  },
  titleText: {
    fontFamily: 'var(--Font-familyBase)',
    fontSize: 'var(--Font-size400)',
    fontWeight: 600,
    lineHeight: 'var(--Line-height400)',
    textAlign: 'left',
    marginLeft: '240px',
    marginTop: '-100px',
  },
  navigationButton: {
    minWidth: '117px',
    padding: 'var(--VerticalSNudge) var(--HorizontalM) var(--VerticalSNudge) var(--HorizontalM)',
    borderRadius: '4px',
    border: '1px solid #D1D1D1',
    backgroundColor: 'white',
    opacity: 1,
    marginTop: '10px',
    marginLeft: '25px',
    fontFamily: 'var(--Font-familyBase)',
    fontSize: 'var(--Font-size300)',
    fontWeight: 600,
    lineHeight: 'var(--Line-height300)',
    textAlign: 'center',
    marginBottom: '5px',
    marginRight: '0px',
    whiteSpace: 'nowrap',
    '&:next-button': {
      border: '1px solid blue',
      backgroundColor: 'blue',
      color: 'white',
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
  navigationButtonNext: {
    minWidth: '117px',
    padding: 'var(--VerticalSNudge) var(--HorizontalM) var(--VerticalSNudge) var(--HorizontalM)',
    borderRadius: '4px',
    border: '0px solid #0F6CBD',
    opacity: 1,
    marginTop: '10px',
    marginLeft: '10px',
    marginBottom: '5px',
    marginRight: '30px',
    whiteSpace: 'nowrap',
    backgroundColor: '#0F6CBD',
    fontFamily: 'var(--Font-familyBase)',
    fontSize: 'var(--Font-size300)',
    fontWeight: 600,
    lineHeight: 'var(--Line-height300)',
    textAlign: 'center',
    color: 'white',
    '&:hover': {
      border: '0px solid #0F6CBD',
      backgroundColor: '#0F6CBD',
      color: 'white',
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    '&.clicked': {
      backgroundColor: '#0F6CBD',
      color: 'white',
    },
  },
  loadingStack: {
    verticalFill: true,
    verticalAlign: 'center',
    horizontalAlign: 'center',
    height: '100vh',
  },
});

export default setupGuideStyles;
