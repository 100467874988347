import * as Type from '../../common/Type';
export const AZURE_TOKEN = 'AZURE_TOKEN';
export const PBI_TOKEN = 'PBI_TOKEN';

export interface AzureAccessToken {
  type: typeof AZURE_TOKEN;
  payload: string;
}

export interface PBIAccessToken {
  type: typeof PBI_TOKEN;
  payload: Type.TokenResponse;
}

export type AuthenticationDispatchTypes = AzureAccessToken | PBIAccessToken;
