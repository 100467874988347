import { IStackTokens, mergeStyleSets } from '@fluentui/react';

export const styles = mergeStyleSets({
  partnerTrialSectionContainer: {
    paddingTop: '42px',
    paddingLeft: '16px',
    width: '70%',
  },
  sectionHeader: {
    font: 'Segoe UI',
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 600,
    display: 'block',
    marginBottom: '3px',
  },
  sectionSubheader: {
    font: 'Segoe UI',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
  },
  galleryStyle: {
    marginTop: '12px',
  },
});

export const sectionStackTokens: IStackTokens = { childrenGap: 16 };
