import { mergeStyleSets, IPanelStyles } from '@fluentui/react';
import { segoeRegular } from '../../../common/Constants';

const styles = mergeStyleSets({
  panelContent: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  panelTitle: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    font: segoeRegular,
  },
  panelInfoSection: {
    marginTop: '16px',
  },
  panelsubTitle: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    font: segoeRegular,
  },
  panelText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    font: segoeRegular,
  },
  listStyle: {
    paddingInlineStart: '20px',
  },
  closeButton: {
    fontWeight: 600,
  },
  footer: {
    position: 'absolute',
    bottom: '0',
    padding: '20px',
  },
});

export const panelContentStyle: Partial<IPanelStyles> = {
  content: {
    padding: 0,
  },
  commands: {
    '@media(max-width: 960px)': {
      marginTop: 0,
    },
  },
};

export default styles;
