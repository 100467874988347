import { IButtonStyles, mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  container: {
    display: 'inline-block',
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    padding: '16px 0 0 0',
    border: '2px solid rgb(0, 0, 0, 0)',
    verticalAlign: 'top',
    boxSizing: 'border-box',
    backgroundColor: '#FFFFFF',
    '@media(max-width: 480px)': {
      maxWidth: '100%',
      width: '235px',
    },
  },
  containerHeader: {
    padding: '0 16px 0 16px',
  },
  availabilityContainer: {
    background: 'black',
    borderRadius: '5px',
    paddingLeft: '5px',
    paddingBottom: '1px',
    width: '98px',
    height: '16px',
    justifyContent: 'center',
    marginBottom: '5px',
  },
  availabilitytitle: {
    color: 'white',
    marginBlockStart: '1px',
  },
  errorLicenseText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '190px',
    cursor: 'pointer',
    '@media(max-width: 480px)': {
      whiteSpace: 'pre-wrap',
    },
  },
  errorContainer: {
    height: '36px',
    width: '100%',
    background: '#FDE7E9',
    paddingLeft: '12px',
    marginTop: '10px',
    boxSizing: 'border-box',
    '@media(max-width: 480px)': {
      height: '40px',
      paddingLeft: '1px',
    },
  },
  errorIconContainer: {
    marginTop: '10px',
    display: 'inline-block',
    '@media(max-width: 480px)': {
      marginTop: '5px',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-evenly',
      flexDirection: 'column-reverse',
      flexWrap: 'wrap',
      alignContent: 'space-around',
    },
  },
  errorText: {
    fontSize: '12px',
    color: '#323130',
    verticalAlign: 'top',
    paddingTop: '10px',
    paddingLeft: '8px',
    display: 'inline-flex',
    '@media(max-width: 480px)': {
      whiteSpace: 'pre-wrap',
      paddingTop: '1px',
      paddingLeft: '4px',
    },
  },
  headerImg: {
    display: 'inline-block',
    width: '36px',
    height: '36px',
  },
  headerTextContainer: {
    display: 'inline-block',
    width: 'calc(100% - 36px)',
    height: '36px',
    verticalAlign: 'top',
    fontSize: '16px',
    fontWeight: '600',
    margin: 0,
    boxSizing: 'border-box',
    paddingLeft: '8px',
  },
  headerTitle: {
    fontSize: '16px',
    fontWeight: '600',
    margin: 0,
    textOverflow: 'ellipsis',
    maxWidth: '225px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
  descriptionContainer: {
    fontWeight: '12px',
    lineHeight: '18px',
    height: '92px',
    maxHeight: '72px',
    color: '#323130',
    display: '-webkit-box',
    maxWidth: '100%',
    '-webkit-line-clamp': '4',
    '-webkit-box-orient': 'vertical',
    padding: '8px 10px 0 16px',
    'margin-bottom': '16px',
    overflow: 'hidden',
  },
  footer: {
    boxSizing: 'border-box',
    paddingLeft: '16px',
    paddingRight: '16px',
    height: '45px',
  },
  footerLeftContainer: {
    display: 'inline-block',
    boxSizing: 'border-box',
    width: '49%',
  },
  footerRightContainer: {
    display: 'inline-block',
    boxSizing: 'border-box',
    width: '50%',
    textAlign: 'right',
  },
  footerRightContainerInner: {
    display: 'inline-block',
  },
  disabledCheckbox: {
    color: '#A19F9D',
  },
  iconFill: {
    fill: '#A80000',
  },
});

export const nonConfirmedOfferButtonStyles: IButtonStyles = {
  label: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
  },
};

export default styles;
