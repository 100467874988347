import RestService from './restService';
import config, { telemetryConstants } from '../config';
import Utils from '../utils';
import * as Type from '../common/Type';
import * as ApiType from '../solutionCenterApi/gen/index';
import InstallationStateContainer from '../stateContainers/installationStateContainer';
import DeploymentStateContainer from '../stateContainers/deploymentStateContainer';
import { telemetry } from './telemetryService';
import * as Constants from '../common/Constants';

const DependencyService = {
  getDependencies: (): string[] => {
    const selectedOffers = InstallationStateContainer.getSelectedOffers();
    let dependencies: string[] = Utils.getDependencies(selectedOffers).map((dependency) => dependency.rowKey);

    let customProps: any = {};
    customProps[Constants.TelemetryPropertyNames.l03dependencyKeys] = dependencies;
    telemetry.logTrace(
      `Number of dependencies for l03s: ${dependencies.length}`,
      telemetryConstants.severity.SEVERITY_INFO,
      customProps
    );

    const selectedOptionalComponents: ApiType.OptionalComponent[] = DeploymentStateContainer.getOptionalComponents();
    let selectedOCDependencies: string[] = Utils.getDependencies(selectedOptionalComponents).map(
      (dependency) => dependency.rowKey
    );

    customProps[Constants.TelemetryPropertyNames.optionalComponentDependencyKeys] = selectedOCDependencies;
    telemetry.logTrace(
      `Number of dependencies for OCs: ${selectedOCDependencies.length}`,
      telemetryConstants.severity.SEVERITY_INFO,
      customProps
    );

    if (dependencies.length !== 0) {
      dependencies = dependencies.concat(selectedOCDependencies);
    } else {
      dependencies = selectedOCDependencies;
    }

    return dependencies;
  },

  executeDependencyCheck: async (
    selectedEnvironment: Type.Environment,
    dependencyRowKeys: string[]
  ): Promise<ApiType.DependencyCheckResult> => {
    const data: Type.DependencyCheckRequest = {
      instanceApiUrl: selectedEnvironment?.apiUrl,
      instanceId: selectedEnvironment?.id,
      l03DependencyIds: dependencyRowKeys,
    };
    return await RestService.post({
      endPoint: config.endpoints.dependencyCheck,
      data,
    });
  },
  getPreDeploymentDependencies: async (
    instanceApiUrl: string,
    instanceId: string,
    uniquePreDependencies: ApiType.L03Dependency[]
  ): Promise<ApiType.DependencyCheckResult> => {
    const dependencies = uniquePreDependencies.map((dependency: ApiType.L03Dependency) => dependency.rowKey);

    const data = {
      instanceApiUrl: instanceApiUrl,
      instanceId: instanceId,
      l03DependencyIds: dependencies,
    };

    return await RestService.post({
      endPoint: config.endpoints.dependencyCheck,
      data,
    });
  },
};

export default DependencyService;
