import * as React from 'react';
import { getRenderSettings, shimOfficeHeader, OfficeHeaderShellBootstrapHost } from './renderSettings';
import { loadShellNavbarData } from '../../services/authenticationService';
import AuthenticationStateContainer from '../../stateContainers/authenticationStateContainer';
import * as Constants from '../../common/Constants';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import * as ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { messageStrings } from '../../App';
import { connect, Provider } from 'react-redux';
import { store } from '../..';
import { ParentRegionalContainer } from '../regionAvailabilityPanel/regionalPanelComponents/parentRegionalContainer';
import { select_deployment_region, shellSettingsId } from '../../common/Constants';
import { updateOfficeHeaderCustomButton } from '../../utils/regionAvailabilityUtil';
import { RootStore } from '../../redux';
import * as Type from '../../common/Type';
import RuntimeConfigStateContainer from '../../stateContainers/runtimeConfigStateContainer';

type HeaderProps = {
  accessToken: string;
  regionsByOrchestratorType?: Type.RegionsByOrchestratorType[];
} & WrappedComponentProps;

type HeaderState = {
  navData: any;
};

declare global {
  interface Window {
    O365Shell: any;
  }
}

export const mapStateToProps = (state: RootStore) => ({
  regionsByOrchestratorType: state.deployment.regionsByOrchestratorType,
});

class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props) {
    super(props);
    this.state = {
      navData: '',
    };
  }

  getShellService(accessToken) {
    if (accessToken) {
      loadShellNavbarData(accessToken)
        .then((res) => {
          this.setState({ navData: res });
        })
        .catch(console.error);
    }
  }

  checkSessionStorage() {
    const accessToken = AuthenticationStateContainer.getHeaderAccessToken();
    if (accessToken) {
      this.getShellService(accessToken);
    }
  }

  componentDidMount() {
    if (!this.state.navData && this.props.accessToken) {
      this.getShellService(this.props.accessToken);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.navData && this.props.accessToken) {
      this.getShellService(this.props.accessToken);
    } else if (this.state.navData && !prevState.navData) {
      shimOfficeHeader(this.onHeaderBootstrap, this.onHeaderBootstrapFailed);
    } else if (prevProps.accessToken !== this.props.accessToken) {
      this.checkSessionStorage();
    }
  }

  render() {
    return <div id="top" />;
  }

  private onDiscardButtonClick = (): void => {
    window?.O365Shell?.FlexPane?.CloseFlexPaneForProvider(null);
  };

  private showRegionsButton = (headerbuttonName: string) => {
    return {
      id: shellSettingsId,
      iconFontName: '\ue774',
      iconFontFamily: 'FabricMDL2Icons',
      ariaLabel: this.props.intl.formatMessage({
        id: headerbuttonName,
        defaultMessage: headerbuttonName,
      }),
      affordanceMenuItemText: 'meetNow',
      buttonText: this.props.intl.formatMessage({
        id: headerbuttonName,
        defaultMessage: headerbuttonName,
      }),
      overflowToAffordanceMenu: true,
    };
  };

  private addOrUpdateCustomHeaderButton = () => {
    const headerButtonName = updateOfficeHeaderCustomButton(this.props.regionsByOrchestratorType);
    const officeHeaderButonObject = this.showRegionsButton(headerButtonName);
    window?.O365Shell?.Extensibility?.AddOrUpdateCustomHeaderButton(officeHeaderButonObject);
  };

  private onHeaderBootstrap = () => {
    console.log('header bootstrap started');
    const { locale } = this.props.intl;
    const shellSettingsId = Constants.RegionPanelSettingsId;
    const headerTestFlexpane = {
      render: (container: HTMLDivElement) => {
        this.renderFlexPane(locale, container);
      },
      onFlexPaneClose: () => {
        return;
      },
      flexPaneID: shellSettingsId,
      triggerControlID: shellSettingsId,
    };
    const showRegionsButton = this.showRegionsButton(select_deployment_region);
    if (window.O365Shell) {
      const initialRenderData = {
        AppBrandTheme: {
          Primary: '#03245C',
        },
        IsConsumer: false,
        Culture: locale,
        Language: locale,
        WorkloadId: 'PowerPlatform',
        ShellBootHost: OfficeHeaderShellBootstrapHost,
        UserDisplayName: (this.state.navData && this.state.navData.UserDisplayName) || '',
      };

      const shellDataOverrides = {
        AppHeaderLinkText: Constants.SOLUTION_PRODUCT_NAME + ' ' + Constants.APP_NAME,
        AppHeaderLinkUrl: '',
        HelpPaneDisabled: true,
      };

      const renderSettings = getRenderSettings('top', initialRenderData, shellDataOverrides);
      window.O365Shell.RenderAsync(
        renderSettings,
        this.onHeaderRendered,
        this.onHeaderLoaded,
        this.onHeaderBootstrapFailed
      );

      const runtimeConfig = RuntimeConfigStateContainer.getConfiguration();
      if (runtimeConfig.featureFlag.enableL03Region) {
        window.O365Shell.Extensibility.AddOrUpdateCustomHeaderButton(showRegionsButton);
        window.O365Shell.Extensibility.AddOrUpdateCustomFlexpane(headerTestFlexpane);
        this.addOrUpdateCustomHeaderButton();
      }
      window.O365Shell.SetData(this.state.navData);
    }
  };

  private onHeaderBootstrapFailed = () => {
    console.log('header failed');
  };

  private onHeaderLoaded = () => {
    console.log('header loaded');
  };

  private onHeaderRendered = () => {
    console.log('header rendered');
  };

  private renderFlexPane(locale: string, container: any) {
    const messages = { ...messageStrings[locale as string] };

    ReactDOM.render(
      <Provider store={store}>
        <IntlProvider locale={locale} key={locale} messages={messages}>
          <ParentRegionalContainer onDiscardButtonClick={this.onDiscardButtonClick} locale={locale} />
        </IntlProvider>
      </Provider>,
      container
    );
  }
}
export default connect(mapStateToProps, null)(injectIntl(Header));
