import { mergeStyleSets } from '@fluentui/react';
const styles = mergeStyleSets({
  dependencyHeader: {
    height: '78px',
    margin: '0px',
    borderTop: '1px solid #C8C8C8',
    paddingTop: '11px',
    '@media(max-width: 480px)': {
      height: '125px',
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: '700',
    color: 'rgb(50, 49, 48)',
    padding: '0px',
  },
  dependencyIcon: {
    verticalAlign: 'middle',
    marginRight: '12px',
    height: '16px',
    width: '16px',
  },
  dependencyContainer: {
    height: '116px',
    overflowY: 'scroll',
  },
  dependencyRow: {
    color: 'black',
    paddingTop: '8px',
  },
  solutionDependenciesDescription: {
    margin: '14px 0px 14px 0px',
  },
  footer: {
    marginBottom: '4px',
  },
});
export default styles;
