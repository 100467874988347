import * as React from 'react';
import { Card, CardHeader } from '@fluentui/react-components';
import cardStyles from './learningCard.styles';

interface LearningCardProps {
  title: string;
  description: string;
  url: string;
  icon: string;
}
export const LearningCard: React.FC<LearningCardProps> = ({ title, description, url, icon }) => {
  const styles = cardStyles();
  return (
    <div className={styles.main}>
      <a href={url} className={styles.card} target="_blank" rel="noopener noreferrer">
        <Card>
          <CardHeader
            image=<img src={icon} alt="Icon" className={styles.headerImage} />
            header={<div className={styles.header}>{title}</div>}
          />
          <p className={styles.text}>{description}</p>
        </Card>
      </a>
    </div>
  );
};
export default LearningCard;
