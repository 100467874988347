import AuthenticationStateContainer from '../../stateContainers/authenticationStateContainer';

const UserName = {
  getUserName: (accessToken) => {
    if (!accessToken) {
      const token = AuthenticationStateContainer.getAccessToken();
      if (token) {
        const userData = AuthenticationStateContainer.parseJwt(token);
        return userData.name;
      }
    } else {
      const userData = AuthenticationStateContainer.parseJwt(accessToken);
      if (userData) {
        return userData.name;
      }
    }
    return 'Guest';
  },
};

export default UserName;
