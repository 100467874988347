import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import deploymentReducers from './deployments/deploymentReducers';
import authenticationReducers from './authentication/authenticationReducers';
import azureInstanceReducer from './azureInstance/azureInstanceReducers';
import environmentReducer from './environment/environmentReducers';
import fabricWorkspaceReducer from './fabricWorkspace/fabricReducers';
export default function configureStore(history: any, initialState: any) {
  const middleware = [thunk, routerMiddleware(history)];
  const rootReducer = combineReducers({
    deployment: deploymentReducers,
    authentication: authenticationReducers,
    azureInstance: azureInstanceReducer,
    environment: environmentReducer,
    workspace: fabricWorkspaceReducer,
    router: connectRouter(history),
  });

  const Store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));
  return Store;
}
