import React, { FC, ReactElement } from 'react';
import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import { inlineStyles, messageBarStyles } from './statusBars.styles';
import { FormattedMessage, useIntl } from 'react-intl';
import config from '../../config';

export interface DeploymentFailedMessageBarProps {
  correlationId: string;
}

export const DeploymentFailedMessageBar: FC<DeploymentFailedMessageBarProps> = (
  props: DeploymentFailedMessageBarProps
): ReactElement => {
  const intl = useIntl();

  return (
    <>
      <MessageBar
        className={messageBarStyles.messageBarContainer}
        id="failed.message.bar"
        messageBarIconProps={inlineStyles}
        overflowButtonAriaLabel={intl.formatMessage({ id: 'buttons.close' })}
        dismissButtonAriaLabel={intl.formatMessage({ id: 'buttons.close' })}
        truncated={true}
        messageBarType={MessageBarType.severeWarning}
        actions={
          <div>
            <MessageBarButton onClick={() => window.open(config.supportLink, '_blank')}>
              {intl.formatMessage({ id: 'installStepper.ContactSupport' })}
            </MessageBarButton>
          </div>
        }
      >
        {' '}
        {<FormattedMessage id="installStepper.installationError2" />} {props.correlationId}
      </MessageBar>
    </>
  );
};
