import { mergeStyleSets } from '@fluentui/react';

const styles = mergeStyleSets({
  errorContainer: {
    backgroundColor: '#e5e5e5',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '15%',
  },
  errorText: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 600,
    marginTop: '28px',
  },
});

export default styles;
