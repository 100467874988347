import * as React from 'react';
import { useState } from 'react';
import {
  contentStyles,
  updateDialogIconButtonStyles,
  footerStackTokens,
} from '../../pages/deploymentDetails/update-dialog/update-dialog.styles';
import { modalStyle, spinnerStyle, styles } from './dialog.styles';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox, DefaultButton, IconButton, Link, Modal, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import * as ApiType from '../../solutionCenterApi/gen/index';
import { telemetry } from '../../services/telemetryService';
import SolutionsStateContainer from '../../stateContainers/solutionsStateContainer';
import TermsDialog from '../../pages/install/destination/dialogs/termsDialog';
import { InProgressDialog } from './inProgressDialog';
import DeployService from '../../services/deployService';
import DeploymentStateContainer from '../../stateContainers/deploymentStateContainer';
import { TelemetryPropertyNames } from '../../common/Constants';
import InstallationStateContainer from '../../stateContainers/installationStateContainer';
import { telemetryConstants } from '../../config';
import { generateDeploymentPayload, getOptionalComponentRowKeys, getPBIL03RowKeys } from '../../utils/helper';
import * as Util from '../../utils/helper';
import Utils from '../../utils';
import EnvironmentService from '../../services/environmentsService';
import * as Type from '../../common/Type';

export interface AddComponentsDialogProps {
  isOpen: boolean;
  onDismiss: () => void;
  l01RowKey: string;
  deploymentL03s: ApiType.DeploymentDefinitionEntity;
  getDeploymentL03s: () => Promise<void>;
}

export const AddComponentsDialog: React.FunctionComponent<AddComponentsDialogProps> = (
  props: AddComponentsDialogProps
) => {
  const intl = useIntl();

  const { isOpen, onDismiss, l01RowKey, deploymentL03s } = props;

  const [deploymentId, setDeploymentId] = useState<string | undefined>(undefined);
  const [callingDeploy, setCallingDeploy] = useState<boolean>(false);
  const [hideTermsDialog, setHideTermsDialog] = useState<boolean>(true);
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [isInProgressDialogOpen, setIsInProgressDialogOpen] = useState<boolean>(false);

  const findTerms = (): ApiType.L01 => {
    const solutions: ApiType.L01[] = SolutionsStateContainer.getAllSolutions();
    var l01: ApiType.L01[] = solutions.filter((solution: ApiType.L01) => solution.rowKey === l01RowKey);
    return l01[0];
  };

  const renderLabelWithLink = (): JSX.Element => {
    var l01 = findTerms();
    const termsLinkText: string = l01.termsLinkText
      ? l01.termsLinkText
      : intl.formatMessage({
          id: 'destination.complianceDisclaimer',
        });
    return (
      <span className={styles.checkBoxLabel}>
        <FormattedMessage
          id="destination.termsPreLabel"
          values={{
            complianceDisclaimer: termsLinkText,
            Link: (termsLinkText: string) => (
              <Link
                tabIndex={0}
                underline={true}
                href={l01.termsLink !== null ? l01.termsLink : undefined}
                target={l01.termsLink !== null ? '_blank' : undefined}
                rel={l01.termsLink !== null ? 'noopener noreferrer' : undefined}
                onClick={l01.termsLink === null ? () => setHideTermsDialog(false) : undefined}
              >
                {termsLinkText}
              </Link>
            ),
          }}
        />
      </span>
    );
  };

  const onAddClick = async (): Promise<void> => {
    setCallingDeploy(true);
    var customProps: Record<
      string,
      string | ApiType.OptionalComponent[] | number | ApiType.DeploymentPayloadV2 | undefined
    > = {};
    const selectedOptionalComponents: ApiType.OptionalComponent[] = DeploymentStateContainer.getOptionalComponents();
    var optionalComponents: ApiType.OptionalComponent[] = [];
    if (Util.isAnyOptionalComponentsSampleData(selectedOptionalComponents)) {
      if (deploymentL03s.l03s) {
        optionalComponents = Util.getAllSampleDataOptionalComponents(deploymentL03s.l03s, selectedOptionalComponents);
      }
    } else {
      optionalComponents = selectedOptionalComponents;
    }
    customProps[TelemetryPropertyNames.optionalComponentsKey] = optionalComponents;

    const deploymentPayload: ApiType.DeploymentPayloadV2 = generateDeploymentPayload(
      optionalComponents,
      deploymentL03s
    );
    if (!Utils.isNullOrUndefinedOrEmpty(deploymentPayload.instanceId)) {
      const envs = await EnvironmentService.getEnvironments(deploymentPayload.l01RowKey);
      const existingEnv: ApiType.Instance[] = envs.filter(
        (env: Type.Environment) => env.id === deploymentPayload.instanceId
      );
      if (existingEnv.length !== 0) {
        deploymentPayload.environmentId = existingEnv[0].environmentId!;
      }
    }
    deploymentPayload.l03RowKeys = getOptionalComponentRowKeys(optionalComponents);
    deploymentPayload.pbiTemplateAppPayload = getPBIL03RowKeys(optionalComponents);

    customProps[TelemetryPropertyNames.deploymentPayload] = deploymentPayload;
    customProps[TelemetryPropertyNames.instanceNameKey] = deploymentPayload.instanceName;
    customProps[TelemetryPropertyNames.l01RowKey] = l01RowKey;

    if (deploymentL03s.rowKey) {
      telemetry.logEvents(telemetryConstants.events.ADD_OC_TO_EXISTING_DEPLOYMENT_CLICKED, customProps);
      try {
        var result = await DeployService.simpleUpdateDeploy(
          deploymentL03s.rowKey,
          optionalComponents,
          deploymentPayload
        );

        if (result) {
          if (result.deploymentId) {
            InstallationStateContainer.setDeploymentId(result?.deploymentId);
            setDeploymentId(result?.deploymentId);
            customProps[TelemetryPropertyNames.deploymentIdKey] = result.deploymentId;
            InstallationStateContainer.setDeploymentCorrelationId(result.correlationId);
            customProps[TelemetryPropertyNames.correlationIdKey] = result.correlationId;
            setCallingDeploy(false);
            props.getDeploymentL03s();
          }
        } else {
          telemetry.logEvents(telemetryConstants.events.DEPLOYMENT_CALL_FAILED, customProps);
          setCallingDeploy(false);
        }
      } catch (error) {
        telemetry.logException(error);
        telemetry.logEvents(telemetryConstants.events.DEPLOYMENT_CALL_FAILED, customProps);
        setCallingDeploy(false);
      }
    }
    setIsInProgressDialogOpen(true);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onDismiss={onDismiss}
        containerClassName={contentStyles.container}
        isBlocking={true}
        styles={modalStyle}
      >
        <div id="addComponentsHeader" className={contentStyles.header}>
          <span>{intl.formatMessage({ id: 'addComponents_header' })}</span>
          <IconButton
            styles={updateDialogIconButtonStyles}
            iconProps={{ iconName: 'Cancel' }}
            onClick={onDismiss}
            disabled={callingDeploy}
          />
        </div>
        {callingDeploy && !isInProgressDialogOpen ? (
          <>
            <Spinner
              size={SpinnerSize.large}
              label={intl.formatMessage({ id: 'loading.pleaseWait' })}
              styles={spinnerStyle}
            />
          </>
        ) : (
          <>
            <div id="addComponentsBody" className={styles.body}>
              <span>{intl.formatMessage({ id: 'addComponents_description' })}</span>
              <div id="termsCheckbox" className={styles.checkBoxContainer}>
                <Checkbox
                  onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) =>
                    setTermsChecked(!!checked)
                  }
                  onRenderLabel={renderLabelWithLink}
                />
              </div>
            </div>
            <Stack
              id="addComponentsFooter"
              className={styles.footer}
              horizontal
              horizontalAlign="end"
              tokens={footerStackTokens}
            >
              <PrimaryButton
                disabled={!termsChecked && !callingDeploy}
                onClick={() => onAddClick()}
                text={intl.formatMessage({ id: 'subSolutions.addLO3' })}
              />
              <DefaultButton onClick={onDismiss} text={intl.formatMessage({ id: 'buttons.cancel' })} />
            </Stack>
          </>
        )}
        {!hideTermsDialog ? (
          <TermsDialog
            hidden={hideTermsDialog}
            handleDialog={() => setHideTermsDialog(true)}
            termsHtml={findTerms().terms}
          />
        ) : null}
        <InProgressDialog
          isOpen={isInProgressDialogOpen}
          onDismiss={() => setIsInProgressDialogOpen(false)}
          closeAddComponents={() => props.onDismiss()}
          deploymentId={deploymentId}
        />
      </Modal>
    </>
  );
};
