import { IStackTokens, IDropdownStyles, mergeStyleSets, IPanelStyles } from '@fluentui/react';

export const stackTokens: IStackTokens = { childrenGap: 10 };

export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: {
    width: 290,
    color: '#605E5C!important',
    marginTop: '5px',
    '@media(max-width: 945px)': {
      width: 285,
    },
    '@media(max-width: 480px)': {
      width: 260,
    },
    '&::after': {
      content: '*',
      color: 'rgb(164, 38, 44)',
      paddingRight: '12px',
    },
  },
  dropdownOptionText: {
    display: 'block',
    whiteSpace: 'none',
  },

  title: {
    color: '#605E5C',
    border: '1px solid #605E5C',
  },
  caretDown: {
    color: '#605E5C',
  },
};

export const panelStyles: Partial<IPanelStyles> = {
  main: {
    width: '360px!important',
    '@media(max-width: 480px)': {
      width: 'unset!important',
    },
    marginTop: '48px',
  },
};

export const regionStyles = mergeStyleSets({
  linkFontColour: {
    color: '#0078D4 !important',
  },
  verticalSpacing: {
    marginTop: '10px',
    display: 'block',
  },

  buttonSpacing: {
    marginTop: '10px',
    display: 'block',
    marginLeft: '10px',
  },

  toggleSpecing: {
    marginTop: '20px',
  },

  fontWeight400: {
    fontWeight: '400',
  },

  fontWeight800: {
    fontWeight: '800',
  },

  fontBold: {
    fontWeight: '600',
  },

  dropdownSpacing: {
    marginTop: '0px!important',
  },
  infoIconBtn: {
    color: 'black !important',
  },

  displayNone: {
    display: 'none',
  },

  displayInline: {
    display: 'Inline-flex',
  },

  divCss: {
    minHeight: '20%',
    position: 'relative',
  },

  helpDivWidth: {
    width: '280px',
    '@media(max-width: 640px)': {
      width: '250px',
    },
    '@media(min-width: 640px)': {
      marginTop: '10px',
    },
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: '600',
  },

  onDismissDivWidth: {
    marginRight: '10px',
    width: '10px',
  },

  dismissBtnColor: {
    color: 'black',
  },

  pageInfoWidth: {
    marginTop: '20px',
    fontSize: '12px',
    lineHeight: '16px',
    width: '95%',
    fontWeight: '400',
  },

  iframe: {
    width: '100%',
    minHeight: 'calc(100vh - 48px)',
    borderStyle: 'none',
  },

  linkColor: {
    color: '#1d66ec !important',
    paddingLeft: '0px !important',
  },

  dialogContentPropsText: {
    fontSize: '20px',
    margin: '0',
  },

  footerContainer: {
    width: 'calc(100% - 1000px)',
    paddingBottom: '16px',
    bottom: 0,
    background: '#ffff',
    zIndex: 1,
    '@media(min-width: 960px)': {
      position: 'relative',
    },
  },
});
