import * as React from 'react';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';

import NavMenu from '../navigation/NavMenu';
import { Pane } from '@business-app/fabric/lib/components/Pane';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import Header from '../header/Header';

const layoutStyles = mergeStyleSets({
  root: {
    display: 'flex',
    background: '#f3f2f1',
    height: 'calc(100vh - 48px)',
    '@media(max-width: 960px)': {
      'overflow-x': 'hidden',
    },
  },
  pane: {
    overflowY: 'auto',
    width: '100%',
    height: '100%',
    minWidth: '800px',
    '@media(max-width: 960px)': {
      minWidth: '130px',
    },
    '@media(max-width: 480px)': {
      minWidth: '130px',
    },
    selectors: {
      'div.ba-Pane-scrollableContent': {
        height: '100%',
        width: '100%',
        overflowX: 'hidden',
        paddingLeft: '2px',
        '@media(max-width: 480px)': {
          'div.ba-Pane-scrollableContent': {
            paddingLeft: '30px',
            height: '100%',
            width: '100%',
          },
          '@media(min-width: 200px) and (max-width: 400px)': {
            width: '88%',
          },
        },
        selectors: {
          'div.ba-Pane-content': {
            padding: 'unset',
            height: '100%',
          },
        },
      },
    },
  },
});

const layout = ({ children, accessToken }) => {
  return (
    <>
      {/* TODO uncomment and change config for first-party authentication */}
      {process.env.NODE_ENV === 'production' ? <Header accessToken={accessToken} /> : null}
      {/*<Header accessToken={accessToken} />*/}
      <main className={layoutStyles.root}>
        {/* Solution header */}

        <NavMenu />
        <Pane isOpen className={layoutStyles.pane} theme={getTheme()}>
          {children}
        </Pane>
        {/** TODO we need 232px total from header top bar of microsoft to start of the card list container.
                 for more details please check https://www.figma.com/file/RUs7n1IQdfSPVQvvh4yRN3/Shell_Deploy-Solution-Kenz?node-id=735%3A20743
                 Needs to be in this column right after left side menu. Also need to wrap children for the dynamic content of react cards*/}
      </main>
    </>
  );
};

export default layout;
