import RestService from './restService';
import config from '../config';
import * as ApiType from '../solutionCenterApi/gen';
import {
  BapProvisionEnvironment,
  BapProvisionEnvironmentResponse,
  EnvironmentLifecycleOperation,
  InstanceMetadata,
} from '../solutionCenterApi/gen';
import { failedToCheckEnvStatus, locationUnitedStates } from '../common/Constants';
import RuntimeConfigStateContainer from '../stateContainers/runtimeConfigStateContainer';
import Utils from '../utils';

const EnvironmentService = {
  getEnvironments: async (solutionRowKey: string | undefined): Promise<ApiType.Instance[]> => {
    let result: ApiType.Instance[] = await RestService.get({
      endPoint: config.endpoints.getInstances + '/' + solutionRowKey,
    });
    if (result?.length > 0) {
      result.sort((a, b) => (a.friendlyName.toLowerCase() > b.friendlyName.toLowerCase() ? 1 : -1));
    } else {
      result = [];
    }

    return result;
  },
  fetchCIInstances: async (ciRegionPayload: ApiType.CIRegionPayload) => {
    let instancesEndpoint: string = config.endpoints.getCIInstances;
    let result: Promise<InstanceMetadata[] | null>;
    const runtimeConfig = RuntimeConfigStateContainer.getConfiguration();

    if (runtimeConfig.featureFlag.enableL03Region && !Utils.isNullOrUndefined(ciRegionPayload)) {
      result = await RestService.post({
        endPoint: instancesEndpoint,
        data: ciRegionPayload,
      });
    } else {
      result = await RestService.get({
        endPoint: instancesEndpoint,
      });
    }
    return result;
  },
  getExistingDeployments: async (instanceId: string, solutionRowKey: string): Promise<ApiType.DeployedItem> => {
    const result = await RestService.get({
      endPoint: config.endpoints.getExistingDeployments + instanceId + '/' + solutionRowKey,
    });
    return result;
  },
  getExistingPBIDeployments: async (
    l01RowKey: string,
    templateAppIds: string[]
  ): Promise<ApiType.PBIDeployedTemplateApps[]> => {
    let url_ = config.endpoints.getExistingPBIDeployments + l01RowKey;
    if (templateAppIds !== undefined && templateAppIds !== null) {
      url_ += '?';
      templateAppIds &&
        templateAppIds.forEach((item) => {
          url_ += 'templateAppIds=' + encodeURIComponent('' + item) + '&';
        });
    }

    const result = await RestService.get({
      endPoint: url_,
    });
    return result;
  },
  getBapEnvironmentLanguages: async (location?: string): Promise<Map<string, string>> => {
    location ??= locationUnitedStates;
    const result = await RestService.get({
      endPoint: config.endpoints.getBapEnvironmentLanguages + location,
    });
    return result;
  },
  getBapEnvironmentCurrencies: async (location?: string): Promise<Map<string, string>> => {
    const result = await RestService.get({
      endPoint: config.endpoints.getBapEnvironmentCurrencies + locationUnitedStates,
    });
    return result;
  },
  createEnvironment: async (envPayload: BapProvisionEnvironment): Promise<BapProvisionEnvironmentResponse> => {
    const result = await RestService.post({
      endPoint: config.endpoints.createEnvironment,
      data: JSON.stringify(envPayload),
    });

    if (!result) {
      throw new Error('Error creating new environment!');
    }

    return result;
  },
  checkNewEnvironmentStatus: async (
    envOperationPayload: BapProvisionEnvironment
  ): Promise<EnvironmentLifecycleOperation> => {
    const result = await RestService.post({
      endPoint: config.endpoints.newEnvironmentStatus,
      data: JSON.stringify(envOperationPayload),
    });

    if (!result) {
      throw new Error(failedToCheckEnvStatus);
    }

    return result;
  },
  validateInstanceApplicationPackages: async (
    environmentId: string | null,
    selectedComponents: string[] | null
  ): Promise<ApiType.InstanceValidationResponse> => {
    let data_ = '{"environmentId":"' + environmentId + '","selectedComponents":[';
    if (selectedComponents !== null) {
      selectedComponents &&
        selectedComponents.forEach((item) => {
          data_ += '"' + item + '",';
        });
      data_ = data_.substring(0, data_.length - 1) + ']}';
    }

    const result = await RestService.post({
      endPoint: config.endpoints.validateApplicationPackages,
      data: data_,
    });

    return result;
  },
  validateInstanceUser: async (instanceApiUrl: string): Promise<ApiType.InstanceUserValidationResponse> => {
    const queryParam = `?InstanceApiUrl=${instanceApiUrl}`;
    let result: ApiType.InstanceUserValidationResponse = await RestService.get({
      endPoint: config.endpoints.validateInstanceUser.concat(queryParam),
    });

    return result;
  },
};

export default EnvironmentService;
