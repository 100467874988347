import React from 'react';
import { Link } from '@fluentui/react';
import SolutionsStateContainer from '../../../stateContainers/solutionsStateContainer';
import styles from './solution-card.styles';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import * as WebAppApiType from '../../../webAppApi/index';
import RuntimeConfigStateContainer from '../../../stateContainers/runtimeConfigStateContainer';
import * as Constants from '../../../common/Constants';
import * as ApiType from '../../../solutionCenterApi/gen';
import * as Util from '../../../utils/helper';
import { AssociatedIndustryVertical } from '../../../../src/common/Type';
import { telemetryConstants } from '../../../config';
import { telemetry } from '../../../services/telemetryService';
import { TelemetryPropertyNames } from '../../../common/Constants';

const SolutionCard = ({ solution }: { solution: ApiType.L01 }) => {
  const history = useHistory();
  const onKeyboardAndMouseEventHandler = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.preventDefault();
    SolutionsStateContainer.setSelectedSolution(solution.rowKey as string);
    let solutionCardSelected: Record<string, string> = {};
    solutionCardSelected[TelemetryPropertyNames.homePageClickEvent] = solution.solutionName;
    telemetry.logEvents(telemetryConstants.events.SOLUTION_CARD_CLICKED, solutionCardSelected);
    history.push({
      pathname: solution.solutionName,
      state: { solutionRowKey: solution.rowKey },
    });
  };
  const onClickHandler = (e: React.MouseEvent<HTMLInputElement | HTMLAnchorElement | HTMLDivElement>) => {
    onKeyboardAndMouseEventHandler(e);
  };
  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement | HTMLAnchorElement | HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onKeyboardAndMouseEventHandler(e);
    }
  };
  const formatMessage = useIntl().formatMessage(
    { id: 'altText.solutionCard.image' },
    { solutionName: solution?.solutionName }
  );

  const runtimeConfig: WebAppApiType.ClientConfiguration = RuntimeConfigStateContainer.getConfiguration();
  const industryVerticalsEnabled = runtimeConfig.featureFlag.enableIndustryVerticals;

  const verticalLinkClickHandler = (key: string, name: string) => {
    SolutionsStateContainer.setSelectedIndustryVerticalKey(key);
    history.push({ pathname: solution.solutionName });
  };
  const renderIndustryVerticalsList = (): JSX.Element => {
    const industryVerticalsList: JSX.Element[] = [];
    const associatedIndustryVerticals: AssociatedIndustryVertical[] = Util.parseAssociatedIndustryVerticals(solution);
    associatedIndustryVerticals
      .slice(0, Constants.maxNumVerticals)
      ?.forEach((industryVertical: AssociatedIndustryVertical) => {
        industryVerticalsList.push(
          <li>
            <Link
              onClick={() => verticalLinkClickHandler(industryVertical.rowKey, industryVertical.name)}
              className={styles.industryVerticalTitle}
            >
              {industryVertical.name}
            </Link>
          </li>
        );
      });
    industryVerticalsList.push(
      <li>
        <Link onClick={onClickHandler} className={styles.industryVerticalTitle}>
          <FormattedMessage id="home.viewAllSolutions" />
        </Link>
      </li>
    );
    return <ul className={styles.industryVerticalList}>{industryVerticalsList}</ul>;
  };

  if (!industryVerticalsEnabled) {
    return (
      <div onClick={onClickHandler} onKeyDown={onKeyDownHandler} className={styles.root} tabIndex={0}>
        <img src={solution?.icon} className={styles.headerImage} alt={formatMessage} />
        <div className={styles.titleContainer}>
          <span className={styles.descriptionContent}>{solution?.solutionName}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.rootIndustryVertical} tabIndex={0}>
        <img src={solution?.icon} className={styles.headerImageIndustryVertical} alt={formatMessage} />
        <div className={styles.titleContainerIndustryVertical}>
          <span className={styles.descriptionContentIndustryVertical}>{solution?.solutionName}</span>
          {solution && renderIndustryVerticalsList()}
        </div>
      </div>
    );
  }
};

export default SolutionCard;
