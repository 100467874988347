import axios from 'axios';
import AuthenticationStateContainer from '../stateContainers/authenticationStateContainer';
import RuntimeConfigStateContainer from '../stateContainers/runtimeConfigStateContainer';
import InstallationStateContainer from '../stateContainers/installationStateContainer';
import globalConfig from '../config';
import AuthenticationService from './authenticationService';
import { v4 as uuidv4 } from 'uuid';
import Utils from '../utils';

const buildHeaders = (url) => {
  let headers = {
    'Content-Type': 'application/json',
  };

  const token = AuthenticationStateContainer.getAccessToken();
  if (token) {
    headers = { ...headers, Authorization: 'Bearer ' + token };
  }

  const correlationId = uuidv4();
  if (correlationId) {
    headers = { ...headers, 'x-ms-correlation-id': correlationId };
    if (url.includes(globalConfig.endpoints.deployL01) || url.includes(globalConfig.endpoints.optInEnvironment)) {
      InstallationStateContainer.setxMsCorrelationId(correlationId);
    }
  }

  const ciAccessToken = AuthenticationStateContainer.getCIAccessToken();
  if (ciAccessToken) {
    return {
      ...headers,
      'ci-access-token': ciAccessToken,
    };
  }

  return headers;
};

const getUrlEncodedFormDataFromJson = (obj, prefix = '', postfix = '') => {
  var formBody = [];
  for (const property in obj) {
    const encodedKey = encodeURIComponent(property);
    if (obj[`${property}`]) {
      const boxedKey = prefix + encodedKey + postfix;
      if (typeof obj[`${property}`] !== 'object') {
        const encodedValue = encodeURIComponent(obj[`${property}`]);
        formBody.push(boxedKey + '=' + encodedValue);
      } else if (Array.isArray(obj[`${property}`])) {
        const nestedPrefix = boxedKey + '[';
        const nested = getUrlEncodedFormDataFromJson(obj[`${property}`], nestedPrefix, ']');
        formBody = formBody.concat(nested);
      } else {
        const nestedPrefix = boxedKey + '.';
        const nested = getUrlEncodedFormDataFromJson(obj[`${property}`], nestedPrefix);
        formBody = formBody.concat(nested);
      }
    }
  }
  formBody = formBody.join('&');
  return formBody;
};

const RestService = {
  onUserTokenUpdate: null,
  init: (onUserTokenUpdate) => {
    RestService.onUserTokenUpdate = onUserTokenUpdate;
  },

  post: async ({ endPoint, data }) => {
    const apiEndpoint = RuntimeConfigStateContainer.getConfiguration().solutionCenter.apiEndpoint;
    const url = `${apiEndpoint}${endPoint}`;
    return await RestService.makeBodyUrl('POST', url, data);
  },

  put: async ({ endPoint, data }) => {
    const apiEndpoint = RuntimeConfigStateContainer.getConfiguration().solutionCenter.apiEndpoint;
    const url = `${apiEndpoint}${endPoint}`;
    return await RestService.makeBodyUrl('PUT', url, data);
  },

  get: async ({ endPoint, params = null }) => {
    const apiEndpoint = RuntimeConfigStateContainer.getConfiguration().solutionCenter.apiEndpoint;
    const url = `${apiEndpoint}${endPoint}`;
    return await RestService.makeNoBodyUrl('GET', url, params);
  },

  urlEncodedPost: async ({ data }) => {
    try {
      const actualUrl = `${globalConfig.identityUrl}${globalConfig.tokenEndpoint}`;
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Sec-Fetch-Dest': 'empty',
        'Sec-Fetch-Mode': 'cors',
        'Sec-Fetch-Site': 'cross-site',
      };
      const body = getUrlEncodedFormDataFromJson(data);
      const options = { method: 'POST', headers, body };
      return await fetch(actualUrl, options);
    } catch (error) {
      console.error(error);
    }
  },

  refreshTokenIfCloseToExpire: async () => {
    const token = AuthenticationStateContainer.getAccessToken();
    const parsedToken = AuthenticationStateContainer.parseJwt(token);
    const runtimeConfig = RuntimeConfigStateContainer.getConfiguration();

    try {
      const expireDate = new Date(parsedToken.exp * 1000);
      const issuedDate = new Date(parsedToken.iat * 1000);
      const lifeTime = (expireDate - issuedDate) / (1000 * 60);
      const now = new Date();
      const diffMinutes = (expireDate - now) / (1000 * 60);
      if (diffMinutes < lifeTime * 0.25) {
        await AuthenticationService.getAccessToken(
          runtimeConfig.tokenScope.solutionCenter,
          RestService.onUserTokenUpdate
        );
      }
    } catch (error) {
      console.error(error);
    }
  },

  makeRequest: async (requestConfig, retry = false, customHeader = false) => {
    await RestService.refreshTokenIfCloseToExpire();
    const completeConfig =
      customHeader === true
        ? { ...requestConfig }
        : {
            ...requestConfig,
            headers: buildHeaders(requestConfig.url),
          };
    const runtimeConfig = RuntimeConfigStateContainer.getConfiguration();
    try {
      var response = await axios.request(completeConfig);
      return response.data;
    } catch (err) {
      if (err && err.response && err.response.status === 401) {
        if (!retry) {
          await AuthenticationService.getAccessToken(
            runtimeConfig.tokenScope.solutionCenter,
            RestService.onUserTokenUpdate
          );
          return await RestService.makeRequest(requestConfig, true);
        } else {
          throw new Error(response.data);
        }
      } else if (err.response && err.response.status === 409) {
        console.error(err);
        throw err;
      } else if (err && err.response && err.response.status === 500) {
        console.error(err);
        return err.response;
      } else {
        console.error(err);
      }
    }
  },

  makeBodyUrl: async (method, url, data) => {
    const config = { url, method, data };
    return await RestService.makeRequest(config);
  },

  makeNoBodyUrl: async (method, url, params = null) => {
    let config = { url, method };
    if (!Utils.isNullOrUndefinedOrEmpty(params)) {
      config = { ...config, params };
    }
    return await RestService.makeRequest(config);
  },

  getOrganization: async ({ endPoint, accessToken }) => {
    const headers = {
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + accessToken,
      Accept: 'application/json',
    };
    const config = { url: endPoint, method: 'GET', headers };
    return await RestService.makeRequest(config, false, true);
  },
};

export default RestService;
