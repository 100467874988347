import { mergeStyleSets, IDetailsColumnStyles, IStackStyles, IStackTokens } from '@fluentui/react';

const styles = mergeStyleSets({
  extendYourSolutionsContainer: {
    width: '954px',
    '@media(max-width: 1245px)': {
      width: 400,
    },
  },
  postDeploymentConfigurationsContainer: {
    background: '#FFFFFF',
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
  },
  postDeploymentTitle: {
    marginLeft: '2px',
  },
  configurationDetailsContainer: {
    background: '#FAF9F8',
    width: '922px',
    minHeight: '74px',
    marginLeft: '16px',
    marginTop: '16px',
    border: '1px solid #EDEBE9',
    boxSizing: 'border-box',
    borderRadius: '2px',
    '@media(max-width: 1245px)': {
      width: 370,
    },
  },
  configurationDetails: {
    paddingTop: '12px',
    marginLeft: '16px',
  },
  configurationDetailsTitle: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
  },
  configurationDetailsSubTitle: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  configurationDetailsSubTitleContainer: {
    paddingTop: '6px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconHeaderIcon: {
    paddingLeft: 10,
    fontSize: '16px',
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  listContainer: {
    marginLeft: '16px',
    marginRight: '16px',
  },
  textStyle: {
    color: '#323130',
  },
  containerTextStyle: {
    marginTop: '10px',
    whiteSpace: 'pre-wrap',
  },
});

export const tableHeaderStyles: Partial<IDetailsColumnStyles> = {
  cellName: {
    fontWeight: 600,
  },
};

export const headerStackStyles: IStackStyles = {
  root: {
    height: 48,
    borderBottom: '1px solid rgb(237, 235, 233)',
  },
};

export const headerTokens: IStackTokens = { padding: 10 };

export default styles;
