import React from 'react';
import Utils from '../../../utils/index';
import styles from './error-screen-contact-partner.styles';
import { FormattedMessage } from 'react-intl';

export const ErrorScreenContactPartner = () => {
  return (
    <div className={styles.errorContainer}>
      <img src={Utils.getIconSvg('error')} alt="" />
      <br />
      <span className={styles.errorTitle}>
        <FormattedMessage id="errorMessages.unexpectedError" />
      </span>
      <br />
      <span className={styles.errorDescription}>
        <FormattedMessage id="errorMessages.unexpectedErrorDescription" />
      </span>
    </div>
  );
};
