import { makeStyles } from '@fluentui/react';

const cardStyles = makeStyles({
  main: {
    gap: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    filter: 'drop-shadow(0px 1.6px 3.6px rgba(0, 0, 0, 0.13))',
  },
  card: {
    width: '300px',
    maxWidth: '100%',
    height: '150px',
    backgroundColor: 'White',
    color: 'transparent',
    borderRadius: '8px',
    top: '0px',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    marginRight: '10px',
    textDecoration: 'none',
    display: 'block',
    transition: 'background-color 0.3s',
    '&:hover': {
      textDecoration: 'underline,',
    },
  },
  title: {
    margin: '0 0 12px',
  },
  horizontalCardImage: {
    width: '64px',
    height: '64px',
  },
  headerImage: {
    marginTop: '10px',
    marginLeft: '10px',
    maxWidth: '22px',
    maxHeight: '22px',
    color: '#242424',
    left: '3px',
    top: '2px',
  },
  caption: {
    color: '0F6CBD',
  },
  header: {
    fontWeight: '600',
    fontFamily: 'var(--Font-familyBase)',
    fontSize: '16px',
    color: '#0F6CBD',
    marginRight: '10px',
    marginTop: '10px',
    lineHeight: '22px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  text: {
    marginTop: '-10px',
    fontSize: '12px',
    width: '260px',
    height: '48px',
    top: '55px',
    left: '0px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'left',
    padding: '10px',
    fontWeight: 400,
    lineHeight: '16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#323130',
    marginRight: '10px',
    fontFamily: 'var(--Font-familyBase)',
  },
});

export default cardStyles;
