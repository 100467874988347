import { IButtonStyles, IModalStyles, ISpinnerStyles, IStackTokens } from '@fluentui/react';
import { FontWeights } from '@fluentui/react/lib/Styling';
import { getTheme, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';

const theme = getTheme();
export const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    margin: '0 0 30px',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
    '@media(min-width: 961px)': {
      width: '742px',
    },
  },
  footer: {
    left: '0',
    bottom: '0',
    height: '70px',
    width: '100%',
    lineHeight: '100px',
  },
  cancelButton: {
    marginRight: '24px',
  },
  checkBoxContainer: {
    marginTop: '30px',
  },
  checkBox: {
    color: '#000000',
  },
  loadingImage: {
    width: '205px',
    height: '139px',
  },
  updateContainer: {
    width: '632px',
    flex: '4 4 auto',
    display: 'hidden',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  imageContainer: {
    textAlign: 'center',
    marginTop: '22px',
  },
  confirmedFooter: {
    marginTop: '50px',
  },
  okayButtonStyle: {
    margin: '24px',
  },
  bulletListStyle: {
    marginTop: '3px',
  },
  versionStyle: {
    marginTop: '7px',
    marginLeft: '7px',
  },
});

export const updateDialogIconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export const footerStackTokens: IStackTokens = {
  childrenGap: 8,
};

export const modalStyle: Partial<IModalStyles> = {
  scrollableContent: {
    minHeight: '300px',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    '@media(min-width: 961px)': {
      minWidth: '742px',
    },
  },
};

export const linkStyle: Partial<IButtonStyles> = {
  root: {
    color: '#1465FA',
    ':hover': {
      color: '#1465FA',
    },
  },
};

export const spinnerStyle: Partial<ISpinnerStyles> = {
  root: {
    position: 'fixed',
    zIndex: 1031,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};
