import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { BaseButton, Button, IconButton, PrimaryButton } from '@fluentui/react';
import * as Enum from '../../../common/Enum';
import styles from './progressWidget.styles';
import { ProgressPanel } from './progressPanel/progressPanel';
import { CustomSupportPanel } from '../../../components/customSupportPanel/customSupportPanel';
import DeployService from '../../../services/deployService';
import * as ApiType from '../../../solutionCenterApi/gen/index';
import { telemetry } from '../../../services/telemetryService';
import { telemetryConstants } from '../../../config';
import * as Constant from '../../../common/Constants';
import * as Util from '../../../utils/helper';

type ProgressWidgetProps = {
  deploymentL03s: ApiType.DeploymentDefinitionEntity;
} & WrappedComponentProps;

type DeploymentDetailsState = {
  panelOpen: boolean;
  deploymentL03s: ApiType.DeploymentDefinitionEntity;
  failedSolutionsMap?: Map<string, string>;
};

class ProgressWidget extends React.Component<ProgressWidgetProps, DeploymentDetailsState> {
  constructor(props: ProgressWidgetProps) {
    super(props);

    this.state = {
      panelOpen: false,
      deploymentL03s: this.props.deploymentL03s,
    };
    this.getFailedDeploymentsCorrelation();
  }

  componentDidUpdate = async (prevProps: ProgressWidgetProps, prevState: DeploymentDetailsState) => {
    if (prevProps.deploymentL03s !== this.props.deploymentL03s) {
      this.getDeploymentL03s();
    }
  };

  onButtonClick = () => {
    this.getDeploymentL03s();
    this.setState({
      panelOpen: true,
    });
  };

  onDismissClick = () => {
    this.setState({
      panelOpen: false,
    });
  };

  displayProgress = () => {
    return (
      this.props.deploymentL03s.deploymentStatus !== Enum.DeploymentStatus[Enum.DeploymentStatus.Success] &&
      this.props.deploymentL03s.deploymentStatus !== Enum.DeploymentStatus[Enum.DeploymentStatus.Completed]
    );
  };

  filter = (deploymentL03s: ApiType.DeploymentDefinitionEntity): ApiType.DeploymentL03ProgressStatus[] => {
    let progressStatus: ApiType.DeploymentL03ProgressStatus[] = [];
    deploymentL03s.l03s.forEach((value) => {
      if (value.progressStatus) {
        progressStatus.push(value.progressStatus);
      }
    });
    if (deploymentL03s.optionalComponents) {
      deploymentL03s.optionalComponents.forEach((value) => {
        if (value.progressStatus) {
          progressStatus.push(value.progressStatus);
        }
      });
    }
    return Util.filterSampleDataDeploymentProgressStatus(progressStatus);
  };

  isUpdate = (deploymentL03ProgressStatus: ApiType.DeploymentL03ProgressStatus[]): boolean => {
    let isUpdate: boolean = false;
    deploymentL03ProgressStatus.forEach((progressStatus) => {
      if (progressStatus.requestType === Constant.DeploymentRequestV2Type.Update) {
        isUpdate = true;
      }
    });
    return isUpdate;
  };

  getDeploymentL03s = async () => {
    var customProps: any = {};
    try {
      let deploymentL03s = await DeployService.getDeploymentDetails(this.props.deploymentL03s.rowKey);
      customProps[Constant.TelemetryPropertyNames.deploymentL03sKey] = deploymentL03s;
      this.setState({
        deploymentL03s: deploymentL03s,
      });
      telemetry.logEvents(telemetryConstants.events.DEPLOYMENT_CALL_SUCCEEDED, customProps);
    } catch (error) {
      telemetry.logTrace('Get deploymentL03s failed', telemetryConstants.severity.SEVERITY_ERROR, customProps);
      telemetry.logEvents(telemetryConstants.events.ERROR_PAGE_SHOWN, customProps);
      return;
    }
  };

  renderCorrelationsIdsForFailedDeployedSolutions = (
    failedSolutions: Map<string, string>,
    deploymentFailed: boolean
  ): JSX.Element => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={styles.progressMessage} id={Constant.DATA_TEST_IDS.deploymentFailedCorrelationId}>
        <>
          <FormattedMessage id="deploymentDetails.correlationID" />
          <IconButton
            iconProps={{ iconName: 'Copy' }}
            onClick={(event) => this.handleCopyClick(event, deploymentFailed, failedSolutions)}
            data-selection-disabled={true}
            aria-label={formatMessage({
              id: 'copyAll.correlationIds.accessibility',
            })}
          />
          {deploymentFailed && failedSolutions.size === 1
            ? [...failedSolutions.keys()].map((k) => <li>{k}</li>)
            : [...failedSolutions.keys()].map((k) => (
                <li>
                  {k}({failedSolutions.get(k)})
                </li>
              ))}
        </>
      </div>
    );
  };

  handleCopyClick(
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | Button | HTMLDivElement | BaseButton | HTMLSpanElement,
      MouseEvent
    >,
    deploymentFailed: boolean,
    failedSolutionsMap: Map<string, string>
  ): void {
    event.stopPropagation();
    let correlationIdSolutionNameList: Array<string> = [];
    if (deploymentFailed && failedSolutionsMap !== undefined) {
      failedSolutionsMap.size === 1
        ? [...failedSolutionsMap.keys()].map((key) => correlationIdSolutionNameList.push(key))
        : [...failedSolutionsMap.keys()].map((key) => {
            const correlationIdSolutionName = key + failedSolutionsMap.get(key);
            correlationIdSolutionNameList.push(correlationIdSolutionName);
            return correlationIdSolutionNameList;
          });
    }
    navigator.clipboard.writeText(correlationIdSolutionNameList.toString());
  }

  getFailedDeploymentsCorrelation = () => {
    let failedSolutionsMap = new Map<string, string>();
    this.props.deploymentL03s?.l03s &&
      this.props.deploymentL03s?.l03s
        ?.filter((o) => o.correlationId != null)
        .map((o) => failedSolutionsMap.set(o?.correlationId, o.name));
    this.props.deploymentL03s?.optionalComponents &&
      this.props.deploymentL03s?.optionalComponents
        .filter((o) => o.correlationId != null)
        .map((o) => failedSolutionsMap.set(o?.correlationId, o.name));
    return failedSolutionsMap;
  };

  render() {
    const deploymentFailed =
      this.props.deploymentL03s.deploymentStatus === Enum.DeploymentStatus[Enum.DeploymentStatus.Failure];
    let progressStatus: ApiType.DeploymentL03ProgressStatus[] = this.filter(this.state.deploymentL03s);
    const failedSolutions: Map<string, string> = this.getFailedDeploymentsCorrelation();
    if (progressStatus.length === 0) {
      return <></>;
    }
    const isUpdate = this.isUpdate(progressStatus);
    const progressTitle = deploymentFailed
      ? 'deploymentDetails.deploymentFailed.title'
      : isUpdate
      ? 'updateDialog.updateInProgress'
      : 'deploymentSplash.title';
    const progressMessage = deploymentFailed
      ? 'deploymentDetails.deploymentFailedDetailed.message'
      : isUpdate
      ? 'deploymentDetails.update.message'
      : 'deploymentDetails.inProgress.message';
    const progressButton = deploymentFailed ? 'installStepper.ContactSupport' : 'deploymentDetails.checkProgress';

    return (
      <>
        {this.displayProgress() && (
          <>
            <div className={styles.progressBox}>
              <div className={styles.progressTitleContainer}>
                <div className={styles.progressTitle}>
                  <FormattedMessage id={progressTitle} />
                </div>
                <div className={styles.progressMessage}>
                  <FormattedMessage id={progressMessage} />
                </div>
                {deploymentFailed &&
                  this.renderCorrelationsIdsForFailedDeployedSolutions(failedSolutions, deploymentFailed)}
                <div className={styles.progressButtonFooter}>
                  <PrimaryButton onClick={this.onButtonClick} className={styles.progressButtonStyle}>
                    <FormattedMessage id={progressButton} />
                  </PrimaryButton>
                </div>
              </div>
            </div>
            {deploymentFailed ? (
              <CustomSupportPanel
                isOpen={this.state.panelOpen}
                onDismiss={this.onDismissClick}
                selectedL01RowKey={this.props.deploymentL03s.l01RowKey}
              />
            ) : (
              <ProgressPanel
                isOpen={this.state.panelOpen}
                onDismiss={this.onDismissClick}
                deploymentProgressStatus={progressStatus}
                deploymentFailed={deploymentFailed}
              />
            )}
          </>
        )}
      </>
    );
  }
}

export default injectIntl(ProgressWidget);
