import * as React from 'react';
import { contentStyles, updateDialogIconButtonStyles, footerStackTokens, modalStyle } from './update-dialog.styles';
import SolutionsStateContainer from '../../../stateContainers/solutionsStateContainer';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';
import {
  Checkbox,
  IconButton,
  Link,
  MessageBar,
  MessageBarType,
  Modal,
  Spinner,
  SpinnerSize,
  Stack,
} from '@fluentui/react';
import * as ApiType from '../../../solutionCenterApi/gen/index';
import TermsDialog from '../../install/destination/dialogs/termsDialog';
import { useEffect } from 'react';
import { PBITemplateAppPackageType } from '../../../common/Constants';
import { spinnerStyle } from './update-dialog.styles';
import { ArmDeploymentScope } from '../../../solutionCenterApi/gen/index';
import { AzureManagementService } from '../../../services/azureManagementService';
import { ParameterDefinitionDictionary } from '../../../common/Type';
import { AzureComponentBuilder } from '../../install/destination/azureResourceWidget/componentBuilder/azureComponentBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux';
import Utils from '../../../utils';
import { getSelectedAzureOffers } from '../../../redux/azureInstance/azureInstanceActions';
import ValidationMessage from '../../install/destination/azureResourceWidget/azComponents/validationMessage';
import * as Type from '../../../common/Type';

type updateDialogProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onConfirmClick: () => void;
  confirmed: boolean;
  onTermsChecked: (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => void;
  termsChecked: boolean;
  solution: ApiType.L03 | ApiType.OptionalComponent;
  l01RowKey: string;
  deploymentdefinitionRowKey: string;
  isValidating?: boolean;
  isQueueingUpdate: boolean;
  azureValidationErrors?: Type.deploymentValidationError[];
} & WrappedComponentProps;

export const UpdateDialog: React.FunctionComponent<updateDialogProps> = (props: updateDialogProps) => {
  const [hiddenDialog, setHiddenDialog] = React.useState<boolean>(true);
  const [onConfirmClicked, setonConfirmClickedValue] = React.useState<boolean>(false);
  const {
    isOpen,
    onDismiss,
    onConfirmClick,
    confirmed,
    onTermsChecked,
    termsChecked,
    intl,
    solution,
    isValidating,
    isQueueingUpdate,
  } = props;
  const [additionalDeploymentParameters, setAdditionalDeploymentParameters] = React.useState<
    ParameterDefinitionDictionary[]
  >([]);
  const [isAzParametersLoading, setIsAzParametersLoading] = React.useState(false);
  const azureValidationError = useSelector((state: RootStore) => state.azureInstance.errorValidationControlType);
  const dispatch = useDispatch();

  const getAzValidationMessage = (
    deploymentValidationError: Type.deploymentValidationError[]
  ): JSX.Element[] | null => {
    let customValidationControl: JSX.Element[] | null = [];
    customValidationControl.push(
      <div>
        {deploymentValidationError?.map(
          (o) => o.errorMessage?.message && <ValidationMessage message={o.errorMessage} templateName={o.templateName} />
        )}
      </div>
    );
    return customValidationControl;
  };

  const fetchAdditionalDeploymentParameters = async () => {
    try {
      setIsAzParametersLoading(true);
      const parameters = await AzureManagementService.getL03ParameterDefinitionListforRp(
        solution.rowKey,
        props.deploymentdefinitionRowKey
      );
      const additionalParamDictionary: ParameterDefinitionDictionary = {
        armDeploymentScope: ArmDeploymentScope.RESOURCE_GROUP,
        azureOfferRowKey: solution.rowKey,
        parameterDefinitions: parameters,
      };
      setAdditionalDeploymentParameters([additionalParamDictionary]);
    } catch (error) {
      console.error('Error fetching additional deployment parameters:', error);
    } finally {
      setIsAzParametersLoading(false);
    }
  };

  useEffect(() => {
    if (Utils.hasCustomRpResource(solution)) {
      dispatch(getSelectedAzureOffers([solution as ApiType.L03]));
      fetchAdditionalDeploymentParameters();
    }
  }, [solution.rowKey]);

  const onConfirmedClick = (value: boolean) => {
    setonConfirmClickedValue(value);
    onConfirmClick();
  };

  const isArmValidated = (): boolean => {
    if (Utils.hasCustomRpResource(solution)) return !azureValidationError;
    return true;
  };

  const findTerms = (): ApiType.L01 => {
    const solutions: ApiType.L01[] = SolutionsStateContainer.getAllSolutions();
    var l01: ApiType.L01[] = solutions.filter((solution: ApiType.L01) => solution.rowKey === props.l01RowKey);
    return l01[0];
  };

  const renderLabelWithLink = () => {
    var l01 = findTerms();
    const termsLinkText: string = l01.termsLinkText
      ? l01.termsLinkText
      : props.intl.formatMessage({
          id: 'destination.complianceDisclaimer',
        });
    return (
      <span className={contentStyles.checkBox}>
        <FormattedMessage
          id="destination.termsPreLabel"
          values={{
            complianceDisclaimer: termsLinkText,
            Link: (termsLinkText: string) => (
              <Link
                tabIndex={0}
                underline={true}
                href={l01.termsLink !== null ? l01.termsLink : undefined}
                target={l01.termsLink !== null ? '_blank' : undefined}
                rel={l01.termsLink !== null ? 'noopener noreferrer' : undefined}
                onClick={l01.termsLink === null ? () => setHiddenDialog(false) : undefined}
              >
                {termsLinkText}
              </Link>
            ),
          }}
        />
      </span>
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onDismiss={onDismiss}
        containerClassName={contentStyles.container}
        isBlocking={true}
        styles={modalStyle}
      >
        <div className={contentStyles.header}>
          <span>
            {!confirmed
              ? intl.formatMessage({ id: 'updateDialog.confirmUpdate' })
              : intl.formatMessage({ id: 'updateDialog.updateInProgress' })}
          </span>
          <IconButton
            styles={updateDialogIconButtonStyles}
            iconProps={{ iconName: 'Cancel' }}
            onClick={onDismiss}
            disabled={onConfirmClicked && !confirmed}
          />
        </div>
        {!confirmed ? (
          <div className={contentStyles.body}>
            {onConfirmClicked && !confirmed && Utils.isNullOrUndefinedOrEmpty(props.azureValidationErrors) && (
              <Spinner
                size={SpinnerSize.large}
                label={intl.formatMessage({
                  id: isValidating ? 'destination.checkAzureValidationMessage' : 'loading.pleaseWait',
                })}
                styles={spinnerStyle}
              />
            )}
            {Utils.hasCustomRpResource(solution) &&
              (isAzParametersLoading ? (
                <Spinner label={intl.formatMessage({ id: 'loading.pleaseWait' })} />
              ) : (
                <>
                  {getAzValidationMessage(props.azureValidationErrors)}
                  <AzureComponentBuilder
                    armScopeToOffersMap={null}
                    currentDeploymentScope={ArmDeploymentScope.RESOURCE_GROUP}
                    parameterDefinitions={additionalDeploymentParameters}
                    isUpdate={true}
                  />
                </>
              ))}
            <span>
              {solution?.instanceType === PBITemplateAppPackageType && <FormattedMessage id="pbi_update_name" />}
            </span>
            <br />
            {solution?.instanceType !== PBITemplateAppPackageType ? (
              <div></div>
            ) : (
              <div>
                <br />
                <MessageBar messageBarType={MessageBarType.severeWarning}>
                  {intl.formatMessage({ id: 'pbi_update_description' })}
                </MessageBar>
              </div>
            )}
            <div className={contentStyles.checkBoxContainer}>
              {!(onConfirmClicked && !confirmed) && (
                <Checkbox onChange={onTermsChecked} onRenderLabel={renderLabelWithLink} />
              )}
            </div>
          </div>
        ) : (
          <div className={contentStyles.updateContainer}>
            <div>
              <span>
                <FormattedMessage id="updateDialog.description" />
              </span>
            </div>
            <br />
            <div className={contentStyles.imageContainer}>
              <img className={contentStyles.loadingImage} src="../../../assets/images/loading_animation.gif" alt="" />
            </div>
          </div>
        )}
        <Stack
          className={!confirmed ? contentStyles.footer : contentStyles.confirmedFooter}
          horizontal
          horizontalAlign="end"
          tokens={footerStackTokens}
        >
          {!confirmed ? (
            <PrimaryButton
              disabled={!termsChecked || isValidating || isAzParametersLoading || !isArmValidated() || isQueueingUpdate}
              onClick={() => onConfirmedClick(true)}
              text={intl.formatMessage({ id: 'buttons.confirm' })}
            />
          ) : (
            <PrimaryButton
              onClick={onDismiss}
              className={contentStyles.okayButtonStyle}
              text={intl.formatMessage({ id: 'buttons.ok' })}
            />
          )}
          {!confirmed ? (
            <DefaultButton
              className={contentStyles.cancelButton}
              onClick={onDismiss}
              text={intl.formatMessage({ id: 'buttons.cancel' })}
            />
          ) : null}
        </Stack>
        {hiddenDialog === false ? (
          <TermsDialog hidden={hiddenDialog} handleDialog={() => setHiddenDialog(true)} termsHtml={findTerms().terms} />
        ) : null}
      </Modal>
    </>
  );
};
