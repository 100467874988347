import React, { FC, ReactElement } from 'react';
import { ErrorIcon } from '../error-icon/error-icon';
import styles from './statusBars.styles';
import { FormattedMessage, useIntl } from 'react-intl';
import './../../custom.css';

export interface PreValidationCheckStatusBarProps {}

export const PreValidationCheckStatusBar: FC<PreValidationCheckStatusBarProps> = (
  props: PreValidationCheckStatusBarProps
): ReactElement => {
  const intlShape = useIntl();

  return (
    <div className={styles.errorContainer}>
      <span className={styles.errorIconContainer}>
        <ErrorIcon />
      </span>
      <span className={styles.errorText}>
        <span
          className={styles.errorDeploymentCheckText}
          title={intlShape.formatMessage({
            id: 'products.PreValidationCheckFailed',
          })}
        >
          <FormattedMessage id="products.PreValidationCheckFailed" />
        </span>{' '}
      </span>
    </div>
  );
};
