import React, { FC, ReactElement, ReactFragment } from 'react';
import styles, { inlineStyles } from './solutionsTable.styles';
import { Icon } from 'office-ui-fabric-react';
import { FormattedMessage } from 'react-intl';
import * as ApiType from '../../../../solutionCenterApi/gen/index';
import {
  createDeploymentOverview,
  getUniqueLaunchLinks,
  mapPackageTypeToString,
  replaceDataversePlaceholders,
} from '../../../../utils/deployHelper';
import { LocalizedStringKeys } from '../../../../common/Constants';
import Utils from '../../../../utils';
import { PackageType } from '../../../../common/Enum';
import { getEnvironmentTypeString } from '../../../../common/EnumUtils';
import InstallationStateContainer from '../../../../stateContainers/installationStateContainer';

export interface EnvironmentTableProps {
  selectedOffer: ApiType.L03 | ApiType.OptionalComponent;
  instanceApiUrl: string;
}

export const returnEndUserLaunchName = (): string => {
  return LocalizedStringKeys.openSolutionName;
};

export const returnEndUserLaunchLink = (link, type, instanceApiUrl): string => {
  if (instanceApiUrl != null && type === getEnvironmentTypeString(PackageType.DATAVERSE_PACKAGE)) {
    const instanceUrl: string = instanceApiUrl.replace('api.', '');
    const { geo, orgName } = Utils.splitInstanceUrl(instanceUrl);
    return replaceDataversePlaceholders(link, orgName, geo);
  }
  return link;
};

export const returnLaunchLinkComponent = (endUserLaunchLink, type, instanceApiUrl): JSX.Element | null => {
  if (endUserLaunchLink) {
    return (
      <div id="endUserLaunchLink" className={styles.tableRowContent}>
        <a
          className={styles.tableRowActionAnchor}
          href={returnEndUserLaunchLink(endUserLaunchLink, type, instanceApiUrl)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon style={inlineStyles.tableRowActionIcon} iconName="OpenInNewWindow" />
          <span className={`ms-fontSize-14 FontSizes.size14 ${styles.tableRowActionTitle}`}>
            <FormattedMessage id={returnEndUserLaunchName()} />
          </span>
        </a>
      </div>
    );
  } else {
    return null;
  }
};

const renderDeployedSolutions = (
  entity: ApiType.L03 | ApiType.OptionalComponent,
  instanceApiUrl: string
): ReactFragment => {
  const deploymentOverview = createDeploymentOverview([entity], InstallationStateContainer.getArmDeploymentScopes());
  const launchLinkWithTypeSet = getUniqueLaunchLinks(
    deploymentOverview,
    entity.endUserLaunchLinkList,
    entity.endUserLaunchLink
  );
  return Array.from(launchLinkWithTypeSet).map((launchLinkType, index) => (
    <React.Fragment key={launchLinkType.packageType}>
      <div id="solutionRow" className={styles.tableRowContainer}>
        <div className={`ms-fontSize-14 FontSizes.size14 ms-fontWeight-regular ${styles.envrionmentTableRowName}`}>
          <FormattedMessage id={mapPackageTypeToString(launchLinkType.packageType)} />
        </div>
        <div role="button" aria-label="Open Link" className={styles.endUserLaunchLink}>
          {returnLaunchLinkComponent(launchLinkType.link, launchLinkType.packageType, instanceApiUrl)}
        </div>
      </div>
      {index < launchLinkWithTypeSet.length - 1 && <div className={styles.tableRowSeparator} />}
    </React.Fragment>
  ));
};

const EnvironmentTable: FC<EnvironmentTableProps> = (props: EnvironmentTableProps): ReactElement => {
  const { selectedOffer, instanceApiUrl } = props;
  return (
    <>
      <div className={styles.sectionSeparator} />
      {renderDeployedSolutions(selectedOffer, instanceApiUrl)}
    </>
  );
};

export default EnvironmentTable;
