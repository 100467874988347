import { AZURE_TOKEN, AuthenticationDispatchTypes, PBI_TOKEN } from './authenticationTypes';
import * as Type from '../../common/Type';

export const getAzureAccessToken = (azureADToken: string): AuthenticationDispatchTypes => ({
  type: AZURE_TOKEN,
  payload: azureADToken,
});

export const getPBIAccessToken = (accessToken: Type.TokenResponse): AuthenticationDispatchTypes => ({
  type: PBI_TOKEN,
  payload: accessToken,
});
