import React from 'react';
import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p'],
  allowedAttributes: {
    a: ['href', 'target', 'rel'],
    p: ['style'],
  },
  allowedIframeHostnames: ['www.microsoft.com', 'go.microsoft.com', 'docs.microsoft.com'],
};

const sanitize = (dirty, options) => {
  const finalOptions = { ...defaultOptions, ...options };
  return {
    __html: sanitizeHtml(dirty, finalOptions),
  };
};

const SanitizedHTML = ({ html, options }) => <div dangerouslySetInnerHTML={sanitize(html, options)} />;

export default SanitizedHTML;
