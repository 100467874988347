import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { segoeRegular } from '../../common/Constants';

export const provisionReadyStyles = mergeStyleSets({
  mainContainer: {
    height: '500px',
    width: '450px',
    boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    borderRadius: '2px',
    background: '#2266E3',
    overflow: 'hidden',
    '@media(max-width: 960px)': {
      boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
      borderRadius: '2px',
      background: '#2266E3',
    },
    '@media(max-width: 480px)': {
      width: '282px',
      marginTop: '30%',
      boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
      borderRadius: '2px',
      background: '#2266E3',
    },
  },
  backgroundPage: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    background: '#7d7b7c',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconWidth: {
    width: '400px',
    marginTop: '40px',
    display: 'flex',
    marginLeft: '30px',
    '@media(max-width: 960px)': {
      fontSize: '14px',
      lineHeight: '20px',
      marginTop: '10px',
    },
    '@media(max-width: 480px)': {
      fontSize: '10px',
      width: '120px',
      marginTop: '15px',
    },
  },
  solTitle: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: segoeRegular,
    fontWeight: '600',
    color: '#605E5C',
    marginTop: '30px',
    marginLeft: '40px',
    marginRight: '15px',
    '@media(max-width: 960px)': {
      fontSize: '8px',
      lineHeight: '10px',
      marginTop: '25%',
    },
    '@media(max-width: 480px)': {
      fontSize: '8px',
      lineHeight: '10px',
      marginTop: '90%',
    },
  },
  pageInfoTitle: {
    fontSize: '24px',
    lineHeight: '32px',
    fontFamily: segoeRegular,
    fontWeight: '700',
    color: '#323130',
    marginTop: '20px',
    marginLeft: '45px',
    paddingBottom: '80px',
    height: '50px',
    maxWidth: '234px',
    '@media(max-width: 960px)': {
      fontSize: '14px',
      lineHeight: '20px',
      marginTop: '10px',
    },
    '@media(max-width: 480px)': {
      fontSize: '10px',
      lineHeight: '10px',
      marginTop: '10px',
    },
  },
  pageInfoDesc: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: segoeRegular,
    fontWeight: '600',
    color: '#323130',
    marginLeft: '45px',
    marginTop: '20px',
    maxWidth: '370px',
    '@media(max-width: 960px)': {
      fontSize: '8px',
      lineHeight: '20px',
      marginTop: '10px',
    },
    '@media(max-width: 480px)': {
      fontSize: '8px',
      lineHeight: '10px',
      marginTop: '10px',
    },
  },
  staticText: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: segoeRegular,
    fontWeight: '400',
    color: '#1F6CF9',
    marginLeft: '45px',
    marginTop: '50px',
    display: 'flex',
    marginRight: '8px',
    maxWidth: '350px',
    '@media (max-width: 960px)': {
      marginLeft: '10px',
    },
    '@media (max-width: 480px)': {
      fontSize: '6px',
      lineHeight: '8px',
      maxWidth: '120px',
      marginTop: '25px',
    },
  },
  staticPatienceText: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: segoeRegular,
    fontWeight: '400',
    color: '#1F6CF9',
    marginLeft: '76px',
    marginTop: '25px',
    display: 'flex',
    marginRight: '8px',
    maxWidth: '380px',
    '@media (max-width: 480px)': {
      fontSize: '6px',
      marginTop: '2px',
    },
  },
  getStartedBtnDivStyle: {
    marginTop: '30px',
    marginLeft: '43px',
    marginBottom: '70px',
    '@media (max-width: 480px)': {
      fontSize: '4px',
      marginLeft: '27px',
    },
  },
  marginTop: {
    marginTop: '10px',
  },
  marginTopHeader: {
    marginTop: '40px',
    fontSize: '19px',
    fontWeight: '700',
    color: '#323130',
    '@media(max-width: 960px)': {
      marginTop: '40px',
      fontSize: '19px',
      fontWeight: '700',
      color: '#323130',
    },
    '@media(max-width: 480px)': {
      fontSize: '10px',
      lineHeight: '20px',
      width: '163px',
    },
  },
  marginBottom: {
    marginBottom: '50px',
    width: '354px',
  },
  containerStyle: {
    width: '500px',
    margin: 'auto',
  },
  getStartedDiv: {
    float: 'left',
  },
  marginButtonTop: {
    marginTop: '160px',
  },
  marRight: {
    marginRight: '10px',
    marginTop: '7px',
    color: '#1f6cf9',
    '@media(max-width: 960px)': {
      marginTop: '0px',
    },
    '@media(max-width: 480px)': {
      marginTop: '2px',
    },
  },
  staticTextStyle: {
    display: 'flex',
  },
  minutesTextStyle: {
    backgroundColor: '#ffff80',
    fontWeight: 'bold',
  },
  marTop: {
    marginTop: '50px',
    marginLeft: '230px',
    '@media(max-width: 960px)': {
      marginLeft: '10px',
    },
    '@media(max-width: 480px)': {
      marginLeft: '10px',
    },
  },
  iconProvisionCss: {
    marginTop: '60px',
    marginBottom: '50px',
    marginLeft: '60px',
    marginRight: '50px',
    width: '320px',
    color: '#FFFFFF',
    '@media(max-width: 960px)': {
      marginLeft: '10px',
    },
    '@media(max-width: 480px)': {
      marginTop: '60px',
      marginLeft: '10px',
      fontSize: '10px',
      width: '120px',
    },
  },
  circle: {
    color: '#FFFFFF',
    '@media(max-width: 960px)': {
      marginLeft: '10px',
    },
    '@media(max-width: 480px)': {
      width: '10px!important',
      height: '10px!important',
    },
  },
  imgLogoCss: {
    width: '175px',
    height: '53px',
    marginLeft: '221px',
    marginBottom: '10px',
    top: '2px',
    '@media(max-width: 960px)': {
      marginLeft: '10px',
    },
    '@media(max-width: 480px)': {
      marginLeft: '10px',
    },
  },
  noBullets: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  liDivStyleCss: {
    fontFamily: segoeRegular,
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    marginTop: '40px',
    width: '341px',
    '@media (max-width: 960px)': {
      fontSize: '8px',
    },
    '@media (max-width: 480px)': {
      fontSize: '8px',
    },
  },
  descriptionContainer: {
    display: 'flex',
    fontWeight: '12px',
    lineHeight: '18px',
    height: '500px',
    color: '#323130',
    maxWidth: '100%',
    '-webkit-line-clamp': '4',
    '-webkit-box-orient': 'vertical',
    'margin-bottom': '16px',
    overflow: 'hidden',
    border: '1px solid #C8C8C8',
    background: '#FFFFFF',
    boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
    borderRadius: '4px',
    position: 'absolute',
    '@media (max-width: 960px)': {
      marginLeft: '10px',
      width: '650px',
      height: '450px',
      marginTop: '15px',
    },
    '@media (max-width: 480px)': {
      marginLeft: '10px',
      marginTop: '10px',
      width: '290px',
      height: '400px',
      position: 'absolute',
    },
  },
  getStartedCss: {
    width: '306px',
    height: '32px',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#323130',
  },
  signingTextCss: {
    width: '306px',
    height: '24px',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '12px',
    alignItems: 'center',
    color: '#605E5C',
    '@media (max-width: 960px)': {
      fontSize: '8px',
    },
    '@media (max-width: 480px)': {
      fontSize: '8px',
    },
  },
  marDivDescContainer: {
    marginLeft: '20px',
    marginTop: '20px',
  },
  descContainerHeaderTitle: {
    width: '240px',
    height: '20px',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#605E5C',
  },
  linkColor: {
    color: '#605e5c',
  },
  displayFlex: {
    display: 'flex',
  },
  startTrialBtnCss: {
    marginTop: '160px',
    width: '180px',
    height: '20px',
    fontFamily: segoeRegular,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'center',
    color: '#FFFFFF',
    '@media (max-width: 960px)': {
      marginTop: '23px',
    },
    '@media (max-width: 480px)': {
      marginTop: '23px',
    },
  },
  iconStyle: {
    fontSize: '9px',
    color: '#1f6cf9',
    marginRight: '6px',
    '@media (max-width: 960px)': {
      fontSize: '8px',
      marginTop: '6px',
    },
    '@media (max-width: 480px)': {
      fontSize: '4px',
      marginTop: '6px',
    },
  },
  divContainer: {
    '@media (max-width: 960px)': {
      width: '300px',
    },
    '@media (max-width: 480px)': {
      width: '200px',
    },
  },
});
