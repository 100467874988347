import { mergeStyleSets, getTheme } from 'office-ui-fabric-react/lib/Styling';
const theme = getTheme();

const baseText = {
  marginLeft: '10px',
  color: theme.palette?.black,
};

const textSmall = {
  ...baseText,
  fontSize: '12px',
};

const textTouchedSmall = {
  ...textSmall,
  fontWeight: 400,
};

const verticalStyle = mergeStyleSets({
  container: {
    padding: '0 1rem',
    display: 'flex',
    flexDirection: 'row',
  },
  labelContainer: {
    paddingTop: '1px',
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    height: '36px',
  },
  rootIntermediate: {
    height: '24px',
  },
  separatorWithDot: {
    paddingTop: '4px',
  },
  defaultDot: {
    paddingLeft: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dotTouched: {
    color: theme.palette?.themePrimary,
  },
  smallDot: {
    fontSize: '8px',
  },
  dotSuccess: {
    color: '#00ad56',
  },
  error: {
    color: '#A81000',
  },
  defaultSeparator: {
    selectors: {
      div: {
        paddingLeft: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&::after': {
        width: '2px',
        backgroundColor: '#979593',
      },
    },
  },
  separatorTouched: {
    selectors: {
      div: {
        color: theme.palette?.themePrimary,
      },
      '&::after': {
        backgroundColor: theme.palette?.themePrimary,
      },
    },
  },
  success: {
    selectors: {
      div: {
        color: '#00AD56',
      },
      '&::after': {
        backgroundColor: '#00ad56',
      },
    },
  },
  text: {
    ...baseText,
  },
  textTouched: {
    ...baseText,
    fontWeight: 600,
  },
  textSmall: {
    ...textSmall,
  },
  textTouchedSmall: {
    ...textTouchedSmall,
  },
  textSmallDisabled: {
    ...textSmall,
    color: theme.palette?.neutralTertiary,
  },
  textTouchedSmallDisabled: {
    ...textTouchedSmall,
    color: theme.palette?.neutralTertiary,
  },
});

export default verticalStyle;
