import * as React from 'react';
import { useEffect } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { EnvironmentCreationInputs } from './environment-creation-input';
import { DefaultButton, IDropdownOption, Link, PrimaryButton } from '@fluentui/react';
import { EnvironmentCreationResult } from './environment-creation-result';
import EnvironmentService from '../../../services/environmentsService';
import Utils from '../../../utils';
import {
  BapProvisionEnvironment,
  BapProvisionEnvironmentResponse,
  EnvironmentLifecycleOperation,
} from '../../../solutionCenterApi/gen';
import * as ApiType from '../../../solutionCenterApi/gen/index';
import { telemetry } from '../../../services/telemetryService';
import { telemetryConstants } from '../../../config';
import {
  createEnvAccessRestricted,
  databaseTypeCDS,
  englishLanguageCode,
  environmentCreationErrors,
  environmentCreationStates,
  failedToCheckEnvStatus,
  geoRegionMap,
  insufficientCapacityErr,
  missingEnvCreationPolicy,
  usdCurrencyCode,
} from '../../../common/Constants';
import { useIntl } from 'react-intl';
import InstallationStateContainer from '../../../stateContainers/installationStateContainer';
import { linkStyle, panelHeaderTextStyles, primaryButtonStyles } from './environment-creation-styles';
import { Panel } from 'office-ui-fabric-react';
import SolutionsStateContainer from '../../../stateContainers/solutionsStateContainer';
import RuntimeConfigStateContainer from '../../../stateContainers/runtimeConfigStateContainer';
import { ClientConfiguration } from '../../../webAppApi';

export interface EnvironmentCreationPanelProps {
  panelOpenText: string;
  footerSuccessButtonText: string;
  footerCancelButtonText: string;
  environmentRegions: string;
  refreshEnvironments: (id?: string) => void;
}

export const isNewEnvironmentAvailabileInGDS = async (newEnvironmentId: string): Promise<boolean> => {
  try {
    const currentSolution = SolutionsStateContainer.getSelectedSolution();
    if (currentSolution) {
      const envs: ApiType.Instance[] = await EnvironmentService.getEnvironments(currentSolution);
      return envs.some((env) => env?.environmentId === newEnvironmentId);
    }
  } catch (error) {
    telemetry.logException(error);
  }
};

export const EnvironmentCreationPanel: React.FunctionComponent<EnvironmentCreationPanelProps> = (
  props: EnvironmentCreationPanelProps
) => {
  const intlShape = useIntl();
  const [isOpen, { setTrue: openPanel, setFalse: closePanel }] = useBoolean(false);
  const [envCreationState, setEnvCreationState] = React.useState<string>(environmentCreationStates.NOT_STARTED);
  const [envLanguages, setEnvLanguage] = React.useState<IDropdownOption[]>();
  const [envCurrencies, setEnvCurrencies] = React.useState<IDropdownOption[]>();
  const [envAppTemplates, setEnvAppTemplates] = React.useState<string[]>();
  const [environmentName, setEnvironmentName] = React.useState<string>(undefined);
  const [selectedEnvType, setSelectedEnvType] = React.useState<string>(undefined);
  const [selectedEnvLanguage, setSelectedEnvLanguage] = React.useState<number>(englishLanguageCode);
  const [selectedEnvCurrency, setSelectedEnvCurrency] = React.useState<string>(usdCurrencyCode);
  const [selectedEnvRegion, setSelectedEnvRegion] = React.useState<string>(undefined);
  const runtimeConfig: ClientConfiguration = RuntimeConfigStateContainer.getConfiguration();
  const { bapEnvStatusRetryInMs, gdsGetInstancesRetryInMs, envStuckRetryCount, envTimeoutRetryCount } =
    runtimeConfig.environmentCreation;

  useEffect(() => {
    const fetchData = async () => {
      const languages = await getEnvironmentLanguages();
      const currencies = await getEnvironmentCurrencies();
      const appTemplates = await getTemplateApps();
      setEnvLanguage(languages);
      setEnvCurrencies(currencies);
      setEnvAppTemplates(appTemplates);
    };

    fetchData().catch(console.error);
  }, []);

  const getTemplateApps = () => {
    let uniqueTemplateApps: Set<string> = new Set<string>();
    InstallationStateContainer.getSelectedOffers()?.forEach((l03) => {
      l03?.l03Dependencies?.forEach((dep) => {
        if (dep?.templateApps) {
          uniqueTemplateApps.add(dep.templateApps);
        }
      });
    });

    return Array.from(uniqueTemplateApps);
  };

  const getEnvironmentLanguages = async (): Promise<IDropdownOption[]> => {
    let languagesDropdownOptions: IDropdownOption[] = [];
    EnvironmentService.getBapEnvironmentLanguages()
      ?.then((response) => {
        Object.keys(response).forEach((k) => {
          languagesDropdownOptions.push({ key: k, text: response[k as string] });
        });
      })
      .catch((err) => {
        telemetry.logTrace('Failed to get environment languages ', telemetryConstants.severity.SEVERITY_ERROR, err);
      });

    return languagesDropdownOptions;
  };

  const getEnvironmentCurrencies = async (): Promise<IDropdownOption[]> => {
    let currenciesDropdownOptions: IDropdownOption[] = [];
    EnvironmentService.getBapEnvironmentCurrencies()
      ?.then((response) => {
        Object.keys(response).forEach((k) => {
          currenciesDropdownOptions.push({
            key: k,
            text: k.toUpperCase().concat(' (' + response[k as string] + ')'),
          });
        });
      })
      .catch((err) => {
        telemetry.logTrace('Failed to get environment currencies ', telemetryConstants.severity.SEVERITY_ERROR, err);
      });

    return currenciesDropdownOptions;
  };

  const onEnvNameChange = (newValue?: string): void => {
    setEnvironmentName(newValue);
  };

  const onEnvRegionChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    setSelectedEnvRegion(item.key as string);
  };

  const onEnvTypeChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    setSelectedEnvType(item.key as string);
  };

  const onEnvLanguageChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    setSelectedEnvLanguage(item.key as number);
  };

  const onEnvCurrencyChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    setSelectedEnvCurrency(item.key as string);
  };

  const mapEnvironmentRegions = (): IDropdownOption[] => {
    let currentL03Regions: string[] = props.environmentRegions?.split(',');

    let regionDropdownOptions: IDropdownOption[] = [];
    currentL03Regions?.forEach((l03Region) => {
      regionDropdownOptions.push({
        key: geoRegionMap.get(l03Region.trim())?.regionValue,
        text: geoRegionMap.get(l03Region.trim())?.regionDisplayText,
      });
    });

    return regionDropdownOptions;
  };

  const startEnvironmentCreationProcess = async (): Promise<void> => {
    let newEnvId: string;
    createEnvironment()
      .then(async (envResponse) => {
        let status: EnvironmentLifecycleOperation;
        let statusPollCount = 0;
        do {
          telemetry.logTrace(
            'Polling for status of environment : ',
            environmentName,
            telemetryConstants.severity.SEVERITY_INFO
          );
          status = await EnvironmentService.checkNewEnvironmentStatus(envResponse);
          await timeout(bapEnvStatusRetryInMs);

          if (++statusPollCount > envStuckRetryCount) {
            setEnvCreationState(environmentCreationStates.STUCK_INPROGRESS);
          }

          if (statusPollCount > envTimeoutRetryCount) {
            setEnvCreationState(environmentCreationStates.TIMEOUT);
            return Promise.reject();
          }
        } while (status?.state?.id !== 'Succeeded');

        return { status: status?.state?.id, path: status?.links?.environment?.path };
      })
      .then(async (envState) => {
        if (envState.status === 'Succeeded') {
          const guids = Utils.extractGuids(envState.path);
          if (!Utils.isNullOrUndefinedOrEmpty(guids)) {
            newEnvId = guids[0];
            while (!(await isNewEnvironmentAvailabileInGDS(newEnvId))) {
              await timeout(gdsGetInstancesRetryInMs);
            }
          }
          setEnvCreationState(environmentCreationStates.SUCCESS);
        } else {
          setEnvCreationState(environmentCreationStates.FAILED);
        }
      })
      .then(() => {
        props.refreshEnvironments(newEnvId);
      })
      .catch((err) => {
        if (err.config && err.config.headers && err.config.headers.Authorization) {
          delete err.config.headers.Authorization;
        }

        if (isInsufficientCapacityError(err)) {
          telemetry.logTrace(
            'Environment Creation failed due to insufficient capacity',
            telemetryConstants.severity.SEVERITY_ERROR,
            err
          );
          setEnvCreationState(environmentCreationErrors.INSUFFICIENT_CAPACITY);
        } else if (isAccessRestrictedError(err)) {
          telemetry.logTrace(
            'Environment Creation failed due to no permission to create an environment in the tenant',
            telemetryConstants.severity.SEVERITY_ERROR,
            err
          );
          setEnvCreationState(environmentCreationErrors.RESTRICTED_ACCESS);
        } else if (isMissingPolicyError(err)) {
          telemetry.logTrace(
            'Environment Creation failed because the user does not have a license that allows environment creation.',
            telemetryConstants.severity.SEVERITY_ERROR,
            err
          );
          setEnvCreationState(environmentCreationErrors.MISSING_POLICY);
        } else if (statusCheckFailed(err)) {
          telemetry.logTrace(
            'Failed to check the status of environment',
            telemetryConstants.severity.SEVERITY_ERROR,
            err
          );
          setEnvCreationState(environmentCreationErrors.STATUS_CHECK_FAILED);
        } else {
          telemetry.logTrace('Environment Creation failed ', telemetryConstants.severity.SEVERITY_ERROR, err);
          setEnvCreationState(environmentCreationStates.FAILED);
        }
      });
  };

  const statusCheckFailed = (err: any): boolean => {
    return err?.message === failedToCheckEnvStatus;
  };

  const isMissingPolicyError = (err: any): boolean => {
    return err?.response?.data === missingEnvCreationPolicy;
  };

  const isAccessRestrictedError = (err: any): boolean => {
    return err?.response?.data === createEnvAccessRestricted;
  };

  const isInsufficientCapacityError = (err: any): boolean => {
    return err?.response?.status === 409 && err?.response?.data === insufficientCapacityErr;
  };

  const createEnvironment = async (): Promise<BapProvisionEnvironmentResponse> => {
    onEnvironmentStageChange(environmentCreationStates.IN_PROGRESS);
    let environmentPayload: BapProvisionEnvironment = {
      location: selectedEnvRegion,
      properties: {
        displayName: environmentName,
        environmentSku: selectedEnvType,
        databaseType: databaseTypeCDS,
        linkedEnvironmentMetadata: {
          baseLanguage: selectedEnvLanguage,
          currency: {
            code: selectedEnvCurrency,
          },
          templates: envAppTemplates,
        },
      },
    };

    try {
      return await EnvironmentService.createEnvironment(environmentPayload);
    } catch (err) {
      throw err;
    }
  };

  const timeout = (delayInMs: number) => {
    return new Promise((res) => setTimeout(res, delayInMs));
  };

  const dismissPanel = async (): Promise<void> => {
    closePanel();
    await timeout(200);
    setEnvCreationState(environmentCreationStates.NOT_STARTED);
    clearInputValues();
  };

  const clearInputValues = (): void => {
    setEnvironmentName(undefined);
    setSelectedEnvType(undefined);
    setSelectedEnvLanguage(englishLanguageCode);
    setSelectedEnvCurrency(usdCurrencyCode);
    setSelectedEnvRegion(undefined);
  };

  function hasInvalidInput() {
    return !environmentName || !selectedEnvType || !selectedEnvLanguage || !selectedEnvCurrency || !selectedEnvRegion;
  }

  const onRenderFooterContent = React.useCallback(
    () =>
      envCreationState === environmentCreationStates.NOT_STARTED && (
        <div>
          <PrimaryButton
            data-createnewenvironmentinputname={environmentName}
            onClick={() => startEnvironmentCreationProcess()}
            styles={primaryButtonStyles}
            disabled={hasInvalidInput()}
          >
            {props.footerSuccessButtonText}
          </PrimaryButton>
          <DefaultButton onClick={dismissPanel}>{props.footerCancelButtonText}</DefaultButton>
        </div>
      ),
    [dismissPanel]
  );

  const onEnvironmentStageChange = (updatedStage: string): void => {
    setEnvCreationState(updatedStage);
  };

  const getPanelHeaderId = (envCreationState: string): string => {
    switch (envCreationState) {
      case environmentCreationStates.IN_PROGRESS:
        return 'creatingTheEnvironment.createNewEnvironmentInProgressText';
      case environmentCreationStates.SUCCESS:
        return 'newEnvironment.createNewEnvironmentSuccessText';
      case environmentCreationStates.NOT_STARTED:
        return 'createAnEnvironment.createNewEnvironmentNotStartedText';
      default:
        return 'createEnvironment.createNewEnvironmentFailedText';
    }
  };

  return (
    <div>
      <Link onClick={openPanel} data-createnewenvironment={'CreateNewEnvironmentPanelOpen'} style={linkStyle}>
        {props.panelOpenText}
      </Link>
      <Panel
        styles={panelHeaderTextStyles}
        isOpen={isOpen}
        onDismiss={dismissPanel}
        headerText={intlShape.formatMessage({ id: getPanelHeaderId(envCreationState) })}
        closeButtonAriaLabel={intlShape.formatMessage({ id: 'buttons.close' })}
        onRenderFooterContent={onRenderFooterContent}
      >
        {envCreationState === environmentCreationStates.NOT_STARTED ? (
          <EnvironmentCreationInputs
            envRegions={mapEnvironmentRegions()}
            envLanguage={envLanguages}
            envCurrency={envCurrencies}
            onEnvNameChange={onEnvNameChange}
            onEnvCurrencyChange={onEnvCurrencyChange}
            onEnvLanguageChange={onEnvLanguageChange}
            onEnvTypeChange={onEnvTypeChange}
            onEnvRegionChange={onEnvRegionChange}
          />
        ) : (
          <EnvironmentCreationResult
            environmentName={environmentName}
            envCreationResult={envCreationState}
            onRetryButtonClick={() => startEnvironmentCreationProcess()}
            onCancelButtonClick={dismissPanel}
          />
        )}
      </Panel>
    </div>
  );
};
