import { mergeStyleSets } from '@fluentui/react';
import { segoeRegular } from '../../../common/Constants';

const styles = mergeStyleSets({
  noSupportModalContainer: {
    background: '#f5f5f5',
    borderRadius: '2px',
    Height: '110px',
    '@media(min-width: 960px)': {
      width: '544px',
    },
  },
  noSupportMessageHearder: {
    color: '#323130',
    fontFamily: segoeRegular,
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '600',
    Width: '226px',
    Height: '22px',
    marginLeft: '16px',
    paddingTop: '11px',
  },
  noSupportMessageDetails: {
    color: '#201F1E',
    fontFamily: segoeRegular,
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '16px',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '16px',
  },
});

export default styles;
