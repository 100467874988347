import { mergeStyleSets, getTheme } from '@fluentui/react';
import { segoeRegular } from '../../../../common/Constants';

const theme = getTheme();

export const styles = mergeStyleSets({
  learnMoreModalContainer: {
    width: 408,
    maxHeight: 'none',
    boxShadow: '0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)',
    borderRadius: '2px',
  },
  licenseContent: {
    '@media(max-width: 480px)': {
      display: 'flex',
    },
  },
  headerTitle: {
    display: 'inline-block',
    width: '362px',
    fontFamily: segoeRegular,
    fontSize: '20px',
    fontWeight: '600',
    color: '#323130',
    padding: '0',
    marginTop: '0',
    marginBottom: '0',
    boxSizing: 'border-box',
    paddingLeft: '24px',
    paddingTop: '16px',
  },
  closeButton: {
    color: theme.palette?.neutralPrimary,
    marginLeft: '0',
    marginTop: '4px',
    marginRight: '0',
    ':hover': {
      color: theme.palette?.neutralDark,
    },
  },
  additionalText: {
    paddingTop: '16px',
    paddingLeft: '22px',
    paddingRight: '22px',
    color: '#323130',
    fontSize: '14px',
  },
  listDependencies: {
    padding: '24px',
  },
  missingLicenseContainer: {
    marginTop: '8px',
    '@media(max-width: 480px)': {
      display: 'flex',
    },
  },
  licenseLink: {
    paddingLeft: '8px',
    display: 'inline-block',
    verticalAlign: 'top',
    width: '334px',
  },
  licenseText: {
    paddingLeft: '8px',
    display: 'inline-block',
    verticalAlign: 'top',
    width: '334px',
    color: '#000000',
  },
  footer: {
    padding: '16px',
    textAlign: 'right',
  },
});
